import { Injectable } from '@angular/core';
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { MatDialog, MatDialogRef } from '@angular/material';
import { I18nGlvService } from './i18n-glv.service';

@Injectable({
  providedIn: 'root'
})
export class MessageDialogService {

  showModal = false;
  MessageDialogRef: MatDialogRef<MessageDialogComponent>;

  constructor(
    public MessageDialogComponent: MatDialog,
	public i18n: I18nGlvService
  ) { }

  openMessageDialog(message = null) {
	  // COM tradução:
    this.showModal = true;
    this.MessageDialogRef = this.MessageDialogComponent.open(MessageDialogComponent, { disableClose: true });
    let modalInstance = this.MessageDialogRef.componentInstance;
	var message2=this.i18n.getTranslationsBD(message).toUpperCase();
    modalInstance.setMessage(message2);
  }

  openMessageDialog2(message = null) {
	  // SEM tradução:
    this.showModal = true;
    this.MessageDialogRef = this.MessageDialogComponent.open(MessageDialogComponent, { disableClose: true });
    let modalInstance = this.MessageDialogRef.componentInstance;
    modalInstance.setMessage(message);
  }

  closeMessageDialog() {
    this.MessageDialogComponent.closeAll();
  }

  toggleMessageDialog(message = null) {
    this.showModal = !this.showModal;
    if (this.showModal) {
      this.MessageDialogRef = this.MessageDialogComponent.open(MessageDialogComponent, { disableClose: true });
      let modalInstance = this.MessageDialogRef.componentInstance;
      modalInstance.setMessage(message);
    } else {
      this.MessageDialogComponent.closeAll();
    }
  }
}
