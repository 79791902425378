export class Client {
  id: number;
  company: string;
  commercialName: string;
  vat: string;
  address: string;
  postalCode: string;
  country: string;
  email: string;
  phoneNumber: string;
  website: string;
  productionsYear: string;
  businessType: any;
  businessTypeOther: string;
  users: any;
}
