import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
declare let $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Vimusica';

  constructor(public authService: AuthService, private router: Router) {


    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (this.router.url.includes('catalogos') || this.router.url.includes('playlists')|| this.router.url.includes('projects')) {

          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          // trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
          // if you need to scroll back to top, here is the right place
          window.scrollTo(0, 0);
        }
      }
    });
  }

  getBG(){
    return this.authService.isLoggedIn ? this.authService.user.Pref_BodyBgColor : '#fff';
  }

 
  ngOnInit(){
    window.addEventListener("dragover", e => {
      e.stopPropagation();
      e.preventDefault();
    }, false);
    window.addEventListener("dragleave", e => {
      e.stopPropagation();
      e.preventDefault();
    }, false);
    window.addEventListener("drop", e => {
    }, false);
  }
}

