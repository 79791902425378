import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { disableBindings } from '@angular/core/src/render3';
import { I18nGlvService } from '../i18n-glv.service';
import { UserService } from '../user.service';
import { AuthService } from '../auth/auth.service';



declare var $: any;

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, AfterViewInit {

  fragment: string;
  private infoUrl = environment.baseUrl + 'info';  // URL to web api

  public infoData: any;
  public lableKeys: any[];
  loading = true;

  constructor(private route: ActivatedRoute, private http: HttpClient, public i18n: I18nGlvService,
    public authService: AuthService, private userService: UserService) {
  }

  /** GET videos from the server */
  getInfo(): Observable<any[]> {
    return this.http.get<any[]>(this.infoUrl)
      .pipe(
        tap(_ => { })
      );
  }
  
  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
    this.infoData = {
      'about_us': { 'title': '', 'text': '' },
      'copyright': { 'title': '', 'text': '' },
      'faq': { 'title': '', 'text': '' },
      'terms_and_conditions': { 'title': '', 'text': '' },
      'privacy_policy': { 'title': '', 'text': '' }
    };

    this.getInfo().subscribe(infos => {
      this.lableKeys = [];
      const idioma = this.i18n.language;
      this.infoData = infos;
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    const hash = '#' + this.fragment;
    hash && $('a[href="' + hash + '"]').tab('show');
    $('html, body').scrollTop(0);
  }


}
