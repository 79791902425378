import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Video } from './video';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private videosUrl = environment.baseUrl + 'home/highlights';  // URL to web api

  constructor(private http: HttpClient, private messageService: MessageService) {
  }

  /** Log a VideoService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`VideoService: ${message}`);
  }

  /** GET videos from the server */
  getVideos(): Observable<any[]> {
    return this.http.get<any[]>(this.videosUrl)
      .pipe(
        tap(_ => this.log('fetched videos')),
        catchError(this.handleError('getVideos', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
