import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { MessageService } from '../message.service';
import { User } from '../user';
import { catchError, map, tap, delay } from 'rxjs/operators';
import { SaveStateService } from '../save-state.service';
import { DataService } from '../data.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  user = null;
  preferences = null;
  private loginUrl = `${environment.baseUrl}login/`;  // URL to web api
  private logoutUrl = `${environment.baseUrl}logout/`;  // URL to web api
  private preferencesURL = `${environment.baseUrl}preferences`;
  // store the URL so we can redirect after logging in
  // ver https://stackoverflow.com/questions/32775342/how-to-disable-chromes-saved-password-prompt-setting-through-javascript

  redirectUrl: string;
  constructor(private dataService: DataService, public saveState: SaveStateService,  private http: HttpClient) {
    const currentUser = this.getLocalStorage('currentUser');
    if (currentUser) {
      this.user = new User();
      this.user.email = currentUser.email;
      this.user.firstName = currentUser.firstName;
      this.user.lastName = currentUser.lastName;
      this.user.yyyMetadataAccsN  = currentUser.yyyMetadataAccsN ;
      this.user.yyyMetadataSortN  = currentUser.yyyMetadataSortN ;

      this.user.id = currentUser.id;
	  this.user.clientCountry = currentUser.clientCountry;
      this.user.clientId = currentUser.clientId;
      this.user.username = currentUser.username;
      this.user.password = 'hidden';
      this.user.phoneNumber = currentUser.phoneNumber;
      this.user.token = currentUser.token;
      this.user.prefLanguage = currentUser.prefLanguage;
      this.user.Context2J_PrefCatalogs = currentUser.Context2J_PrefCatalogs;

      // this.user.level = 5;
      this.user.level = currentUser.level;
      this.user.Pref_BodyBgColor = currentUser.Pref_BodyBgColor;
      this.user.Pref_PageResults = currentUser.Pref_PageResults;
      this.user.Pref_DownloadSingleTrackZip = currentUser.Pref_DownloadSingleTrackZip;
      if (currentUser.hasOwnProperty('Accs_DownloadFromSearch')) {
        this.user.Accs_DownloadFromSearch = currentUser.Accs_DownloadFromSearch;
      } else {
        this.user.Accs_DownloadFromSearch = 'N';
      }
      if (currentUser.hasOwnProperty('Accs_MaxPlays')) {
        this.user.Accs_MaxPlays = currentUser.Accs_MaxPlays;
      } else {
        this.user.Accs_MaxPlays = 0;
      }
      if (currentUser.hasOwnProperty('Accs_MaxDownloads')) {
        this.user.Accs_MaxDownloads = currentUser.Accs_MaxDownloads;
      } else {
        this.user.Accs_MaxDownloads = 0;
      }

      if (currentUser.hasOwnProperty('Pref_TitlesView2')) {
        this.user.Pref_TitlesView2 = currentUser.Pref_TitlesView2;
      } else {
        this.user.Pref_TitlesView2 = "4";
      }

      this.user.Pref_ShowAlbumInfoBelow = currentUser.Pref_ShowAlbumInfoBelow;

      this.user.Accs_MusicSearchOnly = currentUser.Accs_MusicSearchOnly;

      this.user.Pref_DownloadFormat = currentUser.Pref_DownloadFormat;

      this.isLoggedIn = true;
    }
  }


  /* Login  */
  login(username: string, password: string): Observable<boolean> {
    return this.http.post<any>(this.loginUrl, { 'login': username, 'password': password }, httpOptions)
      .pipe(map(user => {

        // login successful if there's a jwt token in the response
        this.user = new User();

        if (user && user.Token) {
			
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.user.email = user.Email;
          this.user.firstName = user.FirstName === null ? '---' : user.FirstName;
          this.user.lastName = user.LastName === null ? '--' : user.LastName;
		  this.user.yyyMetadataAccsN = user.yyyMetadataAccsN === null ? -1 : user.yyyMetadataAccsN;
		  this.user.yyyMetadataSortN = user.yyyMetadataSortN === null ? -1 : user.yyyMetadataSortN;
		  
          this.user.id = user.Id;
		  this.user.clientCountry = user.ClientCountry;
          this.user.clientId = user.ClientId;
          this.user.username = user.Login;
          this.user.password = 'hidden';
          this.user.phoneNumber = user.PhoneNumber;
          this.user.token = user.Token;
          this.user.prefLanguage = user.Pref_Language.toLowerCase();

          this.user.Pref_BodyBgColor = user.Pref_BodyBgColor;
          this.user.level = user.Accs_UserLevel;
          this.user.Pref_PageResults = user.Pref_PageResults;
          this.user.Pref_DownloadSingleTrackZip = user.Pref_DownloadSingleTrackZip;

          this.user.Context2J_PrefCatalogs = user.Context2J_PrefCatalogs;

          if (user.hasOwnProperty('Accs_DownloadFromSearch')) {
            this.user.Accs_DownloadFromSearch = user.Accs_DownloadFromSearch;
          } else {
            this.user.Accs_DownloadFromSearch = 'N';
          }
          if (user.hasOwnProperty('Accs_MaxPlays')) {
            this.user.Accs_MaxPlays = user.Accs_MaxPlays;
          } else {
            this.user.Accs_MaxPlays = 0;
          }
          if (user.hasOwnProperty('Accs_MaxDownloads')) {
            this.user.Accs_MaxDownloads = user.Accs_MaxDownloads;
          } else {
            this.user.Accs_MaxDownloads = 0;
          }
          if (user.hasOwnProperty('Pref_TitlesView2')) {
            this.user.Pref_TitlesView2 = user.Pref_TitlesView2;
          } else {
            this.user.Pref_TitlesView2 = "4";
          }
          this.user.Pref_ShowAlbumInfoBelow = user.Pref_ShowAlbumInfoBelow;
          this.user.Accs_MusicSearchOnly = user.Accs_MusicSearchOnly;

          this.user.Pref_DownloadFormat = user.Pref_DownloadFormat;
          this.setLocalStorage('currentUser', this.user, 2879); // 2879 = dois dias menos um minuto
		  
		  
//PG		  
		var prepareUrl = this.loginUrl+"ddls/"+username; 
        this.http.get<any>(prepareUrl).subscribe(data => {
          this.setLocalStorage('currentUser', this.user, 2879); // 2879 = dois dias menos um minuto
		})
//PG
		  
		  
		  
		  
        }
        this.isLoggedIn = true;

        // this.router.navigate(['./']);

        return user;
      }), catchError(this.handleError<User>('addHero')));
    // .catchError(this.handleError());
  }

  logout() {
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('playedSongs');
    const user_suggestions = localStorage.getItem('user_suggestions');
    localStorage.clear();
    sessionStorage.clear();
    this.saveState.setLastFindPlaylists(null); 
    this.saveState.removelastSearchPlaylists();
    this.dataService.setInitialPlaylistsGroups([]);
    this.dataService.setInitialCatalogsGroups([]);
    if (user_suggestions != null) {
      localStorage.setItem('user_suggestions', user_suggestions);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.user.token
      })
    };
    return this.http.get<any[]>(this.logoutUrl, httpOptions).subscribe(data => {
      this.user = new User();
      this.isLoggedIn = false;
    });
  }

  setLocalStorage(chave, valor, minutos) {
    const expirarem = (new Date().getTime()) + (60000 * minutos);
    localStorage.setItem(chave, JSON.stringify({
      'value': valor,
      'expires': expirarem
    }));
  }

  getLocalStorage(chave) {
    this.localStorageExpires(); // Limpa itens
    const itemValue = localStorage[chave];
    if (itemValue && /^\{(.*?)\}$/.test(itemValue)) {
      const current = JSON.parse(itemValue);
      return current.value;
    }
    return false;
  }

  localStorageExpires() {
    const toRemove = []; // Itens para serem removidos
    const currentDate = new Date().getTime(); // Data atual em milissegundos
    for (let i = 0, j = localStorage.length; i < j; i++) {
      const current = localStorage.getItem(localStorage.key(i));
      // Verifica se o formato do item para evitar conflitar com outras aplicações
      if (current && /^\{(.*?)\}$/.test(current)) {
        const current2 = JSON.parse(current);
        if (current2.expires && current2.expires <= currentDate) {
			// PG: Nunca Expires
			// toRemove.push(localStorage.key(i));
        }
      }
    }
    // Remove itens que já passaram do tempo
    // Se remover no primeiro loop isto poderia afetar a ordem,
    // pois quando se remove um item geralmente o objeto ou array são reordenados
    for (let i = toRemove.length - 1; i >= 0; i--) {
      localStorage.removeItem(toRemove[i]);
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
