import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ProjectService } from '../project.service';
import { I18nGlvService } from '../i18n-glv.service';
import { AuthService } from '../auth/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser'
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-newprojectmodal',
  templateUrl: './newprojectmodal.component.html',
  styleUrls: ['./newprojectmodal.component.scss']
})

export class NewprojectmodalComponent implements OnInit {

  safeSrc: SafeResourceUrl | string = '';

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<NewprojectmodalComponent>,
    private projectService: ProjectService, public i18n: I18nGlvService,
    private authService: AuthService,
	private sanitizer: DomSanitizer) 
	{ }

  projectForm: FormGroup;
  error: boolean;
  messageError: string;
  productiontypes = [];
  productiontypes1stLevel = [];
  laodingProductionTypes = true;
  project = null;
  mode = null;
  xsProjectsID=-1;
  isProject2024a=null; // Se é a nova versão do projeto

  actualName = '';
  nameExists = false;

  ngOnInit() {

		if (this.project)
		{
			this.xsProjectsID=this.project.ProjectsID;
		}
		else
		{
			// quando se esta a fazer insert de um novo projeto
			this.xsProjectsID=-1;
		}
		this.isProject2024a=this.fIsProject2024a(this.project);

	if (this.isProject2024a) {

		let xsStatusNew = "";
		if(this.project && this.project.hasOwnProperty('StatusNew'))
		{
			xsStatusNew=this.project.Status;
		}

		let xsbaseUrl = `${environment.baseUrl}`;
		let xsurl = "";
		if (xsbaseUrl.includes('http://localhost')) 			 {xsurl="http://localhost:56660/newprojectmodal.aspx?tbUtilizadoresID="+this.authService.user.id+"&ProjectsID="+this.xsProjectsID+"&StatusNew="+xsStatusNew;}		// desenvolvimento
		if (xsbaseUrl.includes('https://api.vimusica.com')) 	 {xsurl="https://ivmold.vimusica.com/newprojectmodal.aspx?tbUtilizadoresID="+this.authService.user.id+"&ProjectsID="+this.xsProjectsID+"&StatusNew="+xsStatusNew;}	// testes / qualidade
		if (xsbaseUrl.includes('https://api.vimusica.com')) 	 {xsurl="https://old.vimusica.com/newprojectmodal.aspx?tbUtilizadoresID="+this.authService.user.id+"&ProjectsID="+this.xsProjectsID+"&StatusNew="+xsStatusNew;}		// produção
		this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(xsurl);
	
		this.setmodal('O'); // 'O' - Open ; 'C' - Close
		setTimeout(() => {
			this.getmodal(); 
		}, 100);	
	}

	this.getProductionTypes();
	
    if (this.project == null) {
      this.mode = 'new';
      this.projectForm = this.fb.group({
        titulo: ['', [Validators.required, Validators.maxLength(50)]],
        subtitulo: ['', [Validators.maxLength(50)]],
        cliente: ['', [Validators.maxLength(50)]],
        comentarios: ['', [Validators.maxLength(1024)]],
        tipo: [null, []],
        tipo2ndnivel: [null, []],
        tipo3rdnivel: [null, []],
        estado: ['', []],
        groupname: ['', []]
      });
    } else {
		if (this.project.ProjectTitle=='')
		{
			this.mode = 'new';
		}
		else
		{
			this.mode = 'edit';
		}
      let firstType = null;
      if (this.project.ProductionType1 != null) {
        firstType = this.project.ProductionType1.trim();
      }

      let secondType = null;
      if (this.project.ProductionType2 != null) {
        secondType = this.project.ProductionType1.trim() + this.project.ProductionType2.trim();
        secondType = secondType.trim();
      }
      let thirdType = null;
      if (this.project.ProductionType3 != null) {
        thirdType = this.project.ProductionType1.trim() + this.project.ProductionType2.trim() + this.project.ProductionType3.trim();
        thirdType = thirdType.trim();
      }
      this.projectForm = this.fb.group({
        titulo: [this.project.ProjectTitle, [Validators.required, Validators.maxLength(50)]],
        subtitulo: [this.project.ProjectSubTitle, [Validators.maxLength(50)]],
        cliente: [this.project.Client, [Validators.maxLength(50)]],
        comentarios: [this.project.ProjectComments, [Validators.maxLength(1024)]],
        tipo: [firstType, []],
        tipo2ndnivel: [secondType, []],
        tipo3rdnivel: [thirdType, []],
        estado: [this.project.Status, []],
        groupname: [this.project.GroupName, []]
      });

      this.actualName = this.project.ProjectTitle.split(' ').join(',') + ',' + this.project.ProjectSubTitle.split(' ').join(',');
    }
  }
  
  fIsProject2024a(project) 
  {
	if (!this.authService.isLoggedIn) {return false;}
	if (this.authService.user.clientCountry.toLowerCase() != 'es') {return false;}
	if (project==null) {return true;} // new project
	if (project.StartDate instanceof Date) {
		let year=project.StartDate.substring(0,4);
		if (parseInt(year)<2024) {return false;}
	}
	return true
  }

  checkSecondLevel(productType) {
    if (productType.Level == 2 && productType.Code[0] == this.projectForm.get('tipo').value.trim()) {
      return true;
    }
    return false;
  }

  showSecondLevel() {
    const value = this.projectForm.get('tipo').value;
    if (value != null && value != "") {
      return true;
    }
    return false;
  }

  showThirdLevel() {
    const value = this.projectForm.get('tipo2ndnivel').value;
    if (value != null && value != "") {
      return true;
    }
    return false;
  }

  checkThirdLevel(productType) {
    if (productType.Level != '3') {
      return false;
    }
    if (productType.Code[0] == this.projectForm.get('tipo').value.trim() &&
      productType.Code[1] == this.projectForm.get('tipo2ndnivel').value[1]) {
      return true;
    } else {
      return false;
    }
  }

  getProductionTypes() {

    this.projectService.getProductionTypes().subscribe(data => {
      this.productiontypes = data;
      this.productiontypes.forEach(function (type) {
        if (type.Level === 1) {
          this.productiontypes1stLevel.push(type);
        }
      });
      this.laodingProductionTypes = false;
    });
  }

  getmodal() {
    this.projectService.getmodal(this.xsProjectsID).subscribe(data => {
		if (data.toString()=='C') {
			this.dialogRef.close({ message: 'success' });
		}
		else
		{
			setTimeout(() => {
				this.getmodal(); 
			}, 100);	
		}
    });
  } 

  setmodal(modalState) {
    this.projectService.setmodal(this.xsProjectsID,modalState).subscribe(data => {
    });
  } 

  cancelar() {
    this.dialogRef.close({ action: 'cancel' });
  }

  criar() {
    const title = this.projectForm.get('titulo').value.trim();
    const substitle = this.projectForm.get('subtitulo').value.trim();
    const combinedTitle = title.split(' ').join(',') + ',' + substitle.split(' ').join(',');

    if (combinedTitle == this.actualName) {
      this.nameExists = false;
    } else {
      this.projectService.checkName(combinedTitle).subscribe(data => {
        this.nameExists = data;
        if (!this.nameExists) {
          this.projectForm.updateValueAndValidity();
          if (this.projectForm.invalid) {
            Object.keys(this.projectForm.controls).forEach(key => {
              this.projectForm.get(key).markAsDirty();
            });
            return;
          }

          let firstType = null;
          if (this.projectForm.get('tipo').value !== "" && this.projectForm.get('tipo').value !== null) {
            firstType = this.projectForm.get('tipo').value;
          }

          let secondType = null;
          if (this.projectForm.get('tipo2ndnivel').value !== "" && this.projectForm.get('tipo2ndnivel').value !== null) {
            secondType = this.projectForm.get('tipo2ndnivel').value[1];
          }
          let thirdType = null;
          if (this.projectForm.get('tipo3rdnivel').value !== null && this.projectForm.get('tipo3rdnivel').value !== "") {
            thirdType = this.projectForm.get('tipo3rdnivel').value[2];
          }
          let project = {
            tbUtilizadoresID: this.authService.user.id,
            ProjectTitle: this.projectForm.get('titulo').value,
            ProjectSubTitle: this.projectForm.get('subtitulo').value,
            Client: this.projectForm.get('cliente').value,
            ProjectComments: this.projectForm.get('comentarios').value,
            ProductionType1: firstType,
            ProductionType2: secondType,
            ProductionType3: thirdType,
            ProductionType4: '',
            ProductionType5: '',
            Status: this.projectForm.get('estado').value,
            GroupName: this.projectForm.get('groupname').value
          };

          this.projectService.createProject(project).subscribe(data => {
            this.dialogRef.close({ message: 'success' });
          },
            (err) => {
              this.error = true;
              this.messageError = err.error;
              setTimeout(() => {
                this.error = false;
                this.messageError = '';
              }, 3000);
            });
        }
      });
    }
  }

  editar() {
    const title = this.projectForm.get('titulo').value.trim();
    const substitle = this.projectForm.get('subtitulo').value.trim();
    const combinedTitle = title.split(' ').join(',') + ',' + substitle.split(' ').join(',');

    this.projectService.checkName(combinedTitle).subscribe(data => {
      this.nameExists = data;

      if (combinedTitle.toUpperCase() == this.actualName.toUpperCase()) {
        this.nameExists = false;
      }

      if (!this.nameExists) {
        this.projectForm.updateValueAndValidity();
        if (this.projectForm.invalid) {
          Object.keys(this.projectForm.controls).forEach(key => {
            this.projectForm.get(key).markAsDirty();
          });
          return;
        }

        let firstType = null;
        if (this.projectForm.get('tipo').value !== "" && this.projectForm.get('tipo').value !== null) {
          firstType = this.projectForm.get('tipo').value;
        }

        let secondType = null;
        if (this.projectForm.get('tipo2ndnivel').value !== "" && this.projectForm.get('tipo2ndnivel').value !== null) {
          secondType = this.projectForm.get('tipo2ndnivel').value[1];
        }
        let thirdType = null;
        if (this.projectForm.get('tipo3rdnivel').value !== null && this.projectForm.get('tipo3rdnivel').value !== "") {
          thirdType = this.projectForm.get('tipo3rdnivel').value[2];
        }

        let project = {
          tbUtilizadoresID: this.authService.user.id,
          ProjectTitle: this.projectForm.get('titulo').value,
          ProjectSubTitle: this.projectForm.get('subtitulo').value,
          Client: this.projectForm.get('cliente').value,
          ProjectComments: this.projectForm.get('comentarios').value,
          ProductionType1: firstType,
          ProductionType2: secondType,
          ProductionType3: thirdType,
          ProductionType4: '',
          ProductionType5: '',
          Status: this.projectForm.get('estado').value,
          GroupName: this.projectForm.get('groupname').value
        };

        this.projectService.editProject(project, this.project.ProjectsID).subscribe(data => {
          this.dialogRef.close({ message: 'success' });
        },
          (err) => {
            this.error = true;
            this.messageError = err.error;
            setTimeout(() => {
              this.error = false;
              this.messageError = '';
            }, 3000);
          });
      }
    });

  }

  changeFirstType() {
    if (this.projectForm.controls['tipo2ndnivel'] != undefined) {
      this.projectForm.controls['tipo2ndnivel'].setValue(null);
    }
    if (this.projectForm.controls['tipo3ndnivel'] != undefined) {
      this.projectForm.controls['tipo3ndnivel'].setValue(null);
    }
  }

  checkProjectName() {
    const title = this.projectForm.get('titulo').value;
    const substitle = this.projectForm.get('subtitulo').value;
    const combinedTitle = title.split(' ').join(',') + ',' + substitle.split(' ').join(',');
    const projectId = this.project.ProjectsID;

    if (combinedTitle == this.actualName) {
      this.nameExists = false;
    } else {
      this.projectService.checkName(combinedTitle, projectId).subscribe(data => {
        this.nameExists = data;
      });
    }
  }

  // GETTERS
  get titulo() {
	  return '';
    //return this.projectForm.get('titulo');
  }
  get subtitulo() {
	  return '';
    //return this.projectForm.get('subtitulo');
  }
  get cliente() {
	  return '';
    //return this.projectForm.get('cliente');
  }
  
  get groupname() {
    return this.projectForm.get('groupname');
  }

  get comentarios() {
    return this.projectForm.get('comentarios');
  }
  get tipo() {
    return this.projectForm.get('tipo');
  }

  get tipo2ndnivel() {
    return this.projectForm.get('tipo2ndnivel');
  }

  get tipo3rdnivel() {
    return this.projectForm.get('tipo3rdnivel');
  }

  get estado() {
    return this.projectForm.get('estado');
  }



}
