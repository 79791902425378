import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';
import { I18nGlvService } from '../i18n-glv.service';



@Component({
  selector: 'app-download-zip-mail',
  templateUrl: './download-zip-mail.component.html',
  styleUrls: ['./download-zip-mail.component.scss']
})
export class DownloadZipMailComponent implements OnInit {

  constructor(public i18n: I18nGlvService, public dialogRef: MatDialogRef<DownloadZipMailComponent>) { }

  emails = null;
  type = null;
  projectId = null;
  musicList = null;

  ngOnInit() {
  }

  cancelar() {
    this.dialogRef.close({ action: 'cancel' });
  }

  sendEmail() {
    this.dialogRef.close({ emails: this.emails });
  }
}
