import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FreePlaylistsComponent } from './free-playlists/playlists.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { RegisterComponent } from './register/register.component';
import { RecoverAccessComponent } from './recover-access/recover-access.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProjectsComponent } from './projects/projects.component';
import { SearchComponent } from './search/search.component';
import { UserComponent } from './user/user.component';
import { AuthGuard } from './auth/auth.guard';
import { SharedProjectsComponent } from './shared-projects/shared-projects.component';
import { SfxComponent } from './sfx/sfx.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'info', component: InfoComponent },
  { path: 'catalogos', component: CatalogsComponent, canActivate: [AuthGuard] },
  { path: 'autocomplete', component: AutocompleteComponent },
  { path: 'catalogos/:id', component: CatalogsComponent, canActivate: [AuthGuard] },
  { path: 'catalogos/:id/:id2', component: CatalogsComponent, canActivate: [AuthGuard] },
  { path: 'playlists', component: PlaylistsComponent },

  { path: 'sfx', component: SfxComponent, canActivate: [AuthGuard] },
  
  { path: 'playlists/free', component: FreePlaylistsComponent },
  { path: 'playlists/:id', component: PlaylistsComponent },
  { path: 'playlists/:id/playSingle', component: PlaylistsComponent },
  { path: 'playlists/:id/playAll', component: PlaylistsComponent },
  { path: 'playlists/:id/playShuffle', component: PlaylistsComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recover-access', component: RecoverAccessComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },

  { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'projects/:id', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'projects/:id/playSingle', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'projects/:id/playAll', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'projects/:id/playShuffle', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'sharedProjects/:token', component: SharedProjectsComponent }
];

// as rotas são case sensitive

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0] // [x, y]
  })]
})

export class AppRoutingModule {

}
