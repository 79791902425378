import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Contact } from './contact';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private contactsUrl = `${environment.baseUrl}email/contact`;  // URL to web api
  private freePLurl = `${environment.baseUrl}email/playlist`;
  constructor(private http: HttpClient, private messageService: MessageService) {

  }

  /** Log a ContactService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`ContactService: ${message}`);
  }

  /** POST: add a new contact to the server */
  addContact(contact: any): Observable<any> {
    const data = {
      'EmailFrom': contact.email,
      'Name': contact.name,
      'Telephone': contact.telephone,
      'Message': contact.message,
      'Language': contact.language
    };
    return this.http.post<Contact>(this.contactsUrl, data, httpOptions).pipe(
      tap((contact: Contact) => this.log(`added contact w/ id=${contact.id}`)),
      catchError(this.handleError<Contact>('addContact'))
    );
  }

  addFreePlaylistForm(body): Observable<any> {
    return this.http.post<Contact>(this.freePLurl, body, httpOptions).pipe(
      tap((contact: Contact) => this.log(`added contact w/ id=${contact.id}`)),
      catchError(this.handleError<Contact>('addContact'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
