import { Component, OnInit, HostListener } from '@angular/core';
import { Playlist } from '../playlist';
import { PlaylistService } from '../playlist.service';
import { AuthService } from '../auth/auth.service';
import { I18nGlvService } from '../i18n-glv.service';
import { Router } from '@angular/router';
import { HomeinfoService } from '../homeinfo.service';
declare var $: any;

@Component({
  selector: 'app-home-playlists',
  templateUrl: './home-playlists.component.html',
  styleUrls: ['./home-playlists.component.scss']
})
export class HomePlaylistsComponent implements OnInit {

  playlists: Playlist[];

  constructor(private playlistService: PlaylistService, public authService: AuthService,
    public i18n: I18nGlvService, public router: Router, private homeInfo: HomeinfoService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setKnowMoreHeight();
  }

  
  ngOnInit() {
    this.getPlaylists();
  }

  setKnowMoreHeight() {
    const height = $('#playlist-0').height();
    $('#knowMore').css('height', height + 'px');
  }

  checkVisibility() {
    if (this.i18n.language != 'en') {
      this.setKnowMoreHeight();
      return true;
    } else {
      return false;
    }
  }

  getPlaylists(): void {
    const tempPlaylists = this.homeInfo.getHomePlaylists();
    if (tempPlaylists.length == 0) {
      this.playlistService.getPlaylists().subscribe(playlist => {
        const idioma = this.i18n.language;
        let playlists = [];
        switch (idioma) {
          case 'pt':
          case 'es':
          case 'en':
            playlists = playlist[idioma].playlists;
            break;
          default:
            playlists = playlist['en'].playlists;
            break;
        }
        const listPlaylists = [];
        for (let index = 0; index < playlists.length; index++) {
          const element = playlists[index];
          listPlaylists[index] = {
            'id': index,
            'name': element.name,
            'description': element.description,
            'code': element.code,
            'image': element.file,
            'thumb': element.thumb
          };
        }
        this.playlists = listPlaylists;
        if(this.playlists.length > 10){
          this.playlists = this.playlists.slice(0, 10);
        }
        this.homeInfo.updateHomePlaylists(this.playlists);
      });
    } else {
      this.playlists = tempPlaylists;
    }
  }

  goToPlaylist(code) {
    if (this.i18n.language != 'en') {
      this.router.navigate(['./playlists/' + code]);
    }
  }
}
