import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Album } from './album';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AlbumService {

  private albumsUrl = `${environment.baseUrl}home/albuns`;
  private albumsFromCatalogUrl = `${environment.baseUrl}album/`;  // URL to web api

  constructor(private http: HttpClient, private messageService: MessageService) {
  }

  /** Log a AlbumService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`AlbumService: ${message}`);
  }

  /** GET albums from the server */
  getAlbums(): Observable<any[]> {
    return this.http.get<any[]>(this.albumsUrl)
      .pipe(
        tap(_ => this.log('fetched albums')),
        catchError(this.handleError('getAlbums', []))
      );
  }

  getNewReleases() {
    return this.http.get<any[]>(this.albumsFromCatalogUrl + 'newrelease')
      .pipe(
        tap(_ => this.log('fetched albums')),
        catchError(this.handleError('getAlbums', []))
      );
  }

  getAlbunsOfCatalog(context) {
    return this.http.get<any[]>(this.albumsFromCatalogUrl + context)
      .pipe(
        tap(_ => this.log('fetched albums')),
        catchError(this.handleError('getAlbums', []))
      );
  }

  getTracksOfAlbun(context1J, context1K) {
    return this.http.get<any[]>(this.albumsFromCatalogUrl + context1J + '/' + context1K + '/tracks')
      .pipe(
        tap(_ => this.log('fetched albums')),
        catchError(this.handleError('get tracks of album', []))
      );
  }

  getSameAlbum(track) {
    return this.http.get<any[]>(this.albumsFromCatalogUrl + track.id + '/samealbum')
      .pipe(
        tap(_ => this.log('fetched albums')),
        catchError(this.handleError('get tracks of album', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
