import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { I18nGlvService } from '../i18n-glv.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  public message = "";
  // toggle = false;

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    public i18n: I18nGlvService) { }


  ngOnInit() {
  }

  public setMessage(message = null) {
    this.message = message;
  }

  // public toggleWindow(message = null){
  //   this.toggle = !this.toggle;

  //   this.message = message;
  //   if (this.toggle == false) {
  //     this.dialogRef.close;
  //   }
  // }

}
