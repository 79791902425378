import { Component, OnInit } from '@angular/core';
import { SlideService } from '../slide.service';
import { SlidePlaylist } from '../slide';
import { UserService } from '../user.service';
import { AuthService } from '../auth/auth.service';
import { HomeinfoService } from '../homeinfo.service';
import { I18nGlvService } from '../i18n-glv.service';

import { Router } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-search-slider',
  templateUrl: './search-slider.component.html',
  styleUrls: ['./search-slider.component.scss']
})
export class SearchSliderComponent implements OnInit {

  slidesPlaylists: SlidePlaylist[];

  constructor(private userService: UserService, public authService: AuthService,
    private slideService: SlideService, public router: Router, private homeInfo: HomeinfoService, public i18n: I18nGlvService) {
  }

  ngOnInit() {
    this.getSlidesPlaylists();
    this.runSliderPlaylists();
    let timeoutID;

    function setup() {
      document.addEventListener('mousemove', resetTimer, false);
      document.addEventListener('mousedown', resetTimer, false);
      document.addEventListener('keypress', resetTimer, false);
      document.addEventListener('DOMMouseScroll', resetTimer, false);
      document.addEventListener('mousewheel', resetTimer, false);
      document.addEventListener('touchmove', resetTimer, false);
      document.addEventListener('MSPointerMove', resetTimer, false);

      startTimer();
    }

    setup();

    function startTimer() {
      // wait 1 second before calling goInactive
      timeoutID = window.setTimeout(goInactive, 1000);
    }

    function resetTimer(e) {
      window.clearTimeout(timeoutID);

      goActive();
    }

    function goInactive() {
      $('.carousel-indicators').addClass('inactive');
    }

    function goActive() {
      $('.carousel-indicators').removeClass('inactive');
      startTimer();
    }
  }

  runSliderPlaylists(): void {
    $('#home-slider-logged').carousel({
      interval: 4000
    });
  }

  getSlidesPlaylists(): void {
    const tempslidesPlaylists = this.homeInfo.getHomeSliderPlaylists();
    if (tempslidesPlaylists.length == 0) {
      this.slideService.getSlidesPlaylists().subscribe(playlist => {
        let idioma = navigator.language.split('-')[0];
        if (this.authService.isLoggedIn){
          idioma = this.authService.user.prefLanguage;
        }

        let path = '';
        let playlists = [];
        switch (idioma) {
          case 'pt':
          case 'es':
          case 'en':
            path = playlist[idioma].path + '/';
            playlists = playlist[idioma].playlists;
            break;
          default:
            path = playlist['en'].path + '/';
            playlists = playlist['en'].playlists;
            break;
        }
        const listPlaylists = [];
        for (let index = 0; index < playlists.length; index++) {
          const element = playlists[index];
          listPlaylists[index] = {
            'id': index,
            'name': element.name,
            'description': element.description,
            'code': element.code,
            'image': element.file,
          };
        }
        // console.table(listPlaylists);
        this.slidesPlaylists = listPlaylists;
        if(this.slidesPlaylists.length > 10){
          this.slidesPlaylists = this.slidesPlaylists.slice(0, 10);
        }
        this.homeInfo.updateHomeSliderPlaylists(this.slidesPlaylists);
      });
    } else {
      this.slidesPlaylists = tempslidesPlaylists;
    }
  }

  goToPlaylist(code) {
    this.router.navigate(['./playlists/' + code]);
  }
}
