import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private usersUrl = `${environment.baseUrl}user`;  // URL to web api
  private preferencesURL = `${environment.baseUrl}preferences`;
  private clientURL = `${environment.baseUrl}client`;
  private recoverURL = `${environment.baseUrl}email/recoverypassword/`;

  constructor(private http: HttpClient, private messageService: MessageService) {
  }

  /** Log a UserService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`UserService: ${message}`);
  }

  recoverPass(email) {
    const url = this.recoverURL + '?email=' + email;
    return this.http.post(url, httpOptions).pipe(
      tap(_ => this.log(`recover pass`)),
      catchError(this.handleError<any>(`recover pass`))
    );
  }

  searchClients(terms) {
    const url = this.clientURL + '/search/?terms=' + terms;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get clients`)),
      catchError(this.handleError<any>(`get clients`))
    );
  }

  //GET USERS FROM CLIENT
  getClient(id) {
    const url = this.clientURL + '/' + id;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get Cliente`)),
      catchError(this.handleError<any>(`get Cliente`))
    );
  }

  //GET USERS FROM CLIENT
  getUsersFromClient() {
    const url = this.usersUrl + '/client';
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get audition formats`)),
      catchError(this.handleError<any>(`get audition formats`))
    );
  }

  // GET AUDIOS
  getPreferedAudio(): Observable<any> {
    const url = this.preferencesURL + '/audition_formats';
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get audition formats`)),
      catchError(this.handleError<any>(`get audition formats`))
    );
  }

  // GET PREFERED DOWNLOAD
  getPreferedDownload(id): Observable<any> {
    const url = this.preferencesURL + '/download_formats/' + id;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get download formats`)),
      catchError(this.handleError<any>(`get download formats`))
    );
  }

   // GET SFX PREFERED DOWNLOAD
   getSFXPreferedDownload(id): Observable<any> {
    const url = this.preferencesURL + '/sfxdownload_formats/' + id;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get download formats`)),
      catchError(this.handleError<any>(`get download formats`))
    );
  }

  // GET TITLES VIEW2
  getTitleView2(): Observable<any> {
    const url = this.preferencesURL + '/titlesviews2';
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get titlesviews`)),
      catchError(this.handleError<any>(`get titlesviews`))
    );
  }

  getTitleView(): Observable<any> {
    const url = this.preferencesURL + '/titlesviews';
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get titlesviews2`)),
      catchError(this.handleError<any>(`get titlesviews2`))
    );
  }

  // GET AVAILABLE LANGUAGES
  getLanguages(): Observable<any> {
    const url = this.preferencesURL + '/languages';
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`get languages`)),
      catchError(this.handleError<any>(`get languages`))
    );
  }

  /** GET user by id. Will 404 if id not found */
  getUser(id: number): Observable<any> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<any>(`getUser id=${id}`))
    );
  }

  /** GET user preferences by id. Will 404 if id not found */
  getUserPreferences(id: number): Observable<any> {
    const url = `${this.usersUrl}/${id}/preferences`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<any>(`getUser id=${id}`))
    );
  }

  /** POST: update the user on the server */
  updateUserPreferences(data: any, id: number, userID: number): Observable<any> {
    const url = `${this.usersUrl}/${userID}/preferences`;
    return this.http.post(url, data, httpOptions).pipe(
      tap(_ => this.log(`updated user preferences id=${userID}`)),
      catchError(this.handleError<any>(`updateUserPreferences id=${userID}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
