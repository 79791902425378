import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlayerHistoryService {

  playedSongs = sessionStorage['playedSongs'] != undefined && sessionStorage['playedSongs'] != null ? sessionStorage['playedSongs'].split(',') : [];
  selectedSongsSEARCH = [];
  selectedSongsCATALOGS = [];
  selectedSongsSFX = [];


  favouriteSearches = sessionStorage['favouriteSearches'] != undefined && sessionStorage['favouriteSearches'] != null ? sessionStorage['favouriteSearches'] : [];
  recentSearches = sessionStorage['recentSearches'] != undefined && sessionStorage['recentSearches'] != null ? sessionStorage['recentSearches'] : [];


  constructor() {
  }

  addFavouriteSearch(search) {
    if (typeof (this.favouriteSearches) == "string") {
      const array = JSON.parse(this.favouriteSearches);
      array.unshift(search);
      this.favouriteSearches = array;
    } else {
      this.favouriteSearches.unshift(search);
    }
    sessionStorage.setItem('favouriteSearches', JSON.stringify(this.favouriteSearches));
  }

  getFavouriteSearchs() {
    if (typeof (this.favouriteSearches) == "string") {
      return JSON.parse(this.favouriteSearches);
    } else {
      return this.favouriteSearches;
    }
  }

  updateRecentSearchs(search) {
    if (typeof (this.recentSearches) == "string") {
      const array = JSON.parse(this.recentSearches);
      const alreadyExists = this.checkExistence(array, search);
      if (!alreadyExists) {
        array.unshift(search);
      }
      this.recentSearches = array;
    } else {
      const alreadyExists = this.checkExistence(this.recentSearches, search);
      if (!alreadyExists) {
        this.recentSearches.unshift(search);
      }
    }
    if (this.recentSearches.length > 5) {
      this.recentSearches.pop();
    }
    sessionStorage.setItem('recentSearches', JSON.stringify(this.recentSearches));
  }

  getRecentSearchs() {
    if (typeof (this.recentSearches) == "string") {
      return JSON.parse(this.recentSearches);
    } else {
      return this.recentSearches;
    }
  }

  checkExistence(array, search) {
    let alreadyExists = false;
    array.forEach(searchItem => {
      if (JSON.stringify(searchItem) === JSON.stringify(search)) {
        alreadyExists = true;
        return;
      }
    });
    return alreadyExists;
  }

  updatePlayedSongs(metadataID) {
    if (!this.playedSongs.includes(metadataID)) {
      this.playedSongs.push(metadataID.toString());
      sessionStorage.setItem('playedSongs', this.playedSongs);
    }
  }

  getPlayedSongs() {
    return this.playedSongs;
  }

  addSongToSelectedSEARCH(track) {
    if (track != null) {
      let exists = false;
      this.selectedSongsSEARCH.forEach(song => {
        if (track.id == song.id) {
          exists = true;
          return;
        }
      });
      if (!exists) {
        this.selectedSongsSEARCH.push(track);
      }
    } else {
      this.selectedSongsSEARCH = [];
    }
  }

  removeSongFromSelectedSEARCH(track) {
    this.selectedSongsSEARCH.forEach((element, id) => {
      if (element.id == track.id) {
        this.selectedSongsSEARCH.splice(id, 1);
      }
    });
  }

  getSelectedSongsSearch() {
    return this.selectedSongsSEARCH;
  }


  addSongToSelectedSFX(track) {
    if (track != null) {
      let exists = false;
      this.selectedSongsSFX.forEach(song => {
        if (track.id == song.id) {
          exists = true;
          return;
        }
      });
      if (!exists) {
        this.selectedSongsSFX.push(track);
      }
    } else {
      this.selectedSongsSFX = [];
    }
  }

  removeSongFromSelectedSFX(track) {
    this.selectedSongsSFX.forEach((element, id) => {
      if (element.id == track.id) {
        this.selectedSongsSFX.splice(id, 1);
      }
    });
  }

  getSelectedSongsSFX() {
    return this.selectedSongsSFX;
  }



  addSongToSelectedCATALOGS(track) {
    if (track != null) {
      let exists = false;
      this.selectedSongsCATALOGS.forEach(song => {
        if (track.id == song.id) {
          exists = true;
          return;
        }
      });
      if (!exists) {
        this.selectedSongsCATALOGS.push(track);
      }
    } else {
      this.selectedSongsCATALOGS = [];
    }
  }

  removeSongFromSelectedCATALOGS(track) {
    this.selectedSongsCATALOGS.forEach((element, id) => {
      if (element.id == track.id) {
        this.selectedSongsCATALOGS.splice(id, 1);
      }
    });
  }

  getSelectedSongsCatalogs() {
    return this.selectedSongsCATALOGS;
  }




  addSongToSelectedPROJECTS(track){
    if (track != null) {
      let exists = false;
      this.selectedSongsCATALOGS.forEach(song => {
        if (track.id == song.id) {
          exists = true;
          return;
        }
      });
      if (!exists) {
        this.selectedSongsCATALOGS.push(track);
      }
    } else {
      this.selectedSongsCATALOGS = [];
    }
  }

  

}
