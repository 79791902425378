import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchUrl = `${environment.baseUrl}search/`;  // URL to web api
  private donwloadUrl = `${environment.baseUrl}music/download/mp3/`; // URL to web api
  private donwloadMultipleUrl = `${environment.baseUrl}music/download/mp3/?musicList=`;
  private bufferUrl = `${environment.baseUrl}music/streaming/`;
  private freeBufferUrl = `${environment.baseUrl}music/free/streaming/`;
  private mataDataInfoUrl = `${environment.baseUrl}music/`;
  private subversionUrl = `${environment.baseUrl}music/subversion/`;
  private similarsUrl = `${environment.baseUrl}music/AIMSSimilars/`;
  private peaksUrl = `${environment.baseUrl}peaks/`;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public authService: AuthService
  ) { }

  /** Log a GenreService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`GenreService: ${message}`);
  }


  getPlaceholder(isDefault = false){
    const URL = isDefault ? `${environment.baseUrl}search/defaultplaceholder` : `${environment.baseUrl}search/placeholder`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('get placeholder')),
        catchError(this.handleError('get placeholder', []))
      );
  }

  getPlaceholderSFX(isDefault = false){
    const URL = isDefault ? `${environment.baseUrl}search/sfxdefaultplaceholder` : `${environment.baseUrl}search/sfxplaceholder`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('get placeholder')),
        catchError(this.handleError('get placeholder', []))
      );
  }

  setRatingAndComments(body) {
    const URL = this.mataDataInfoUrl + 'comment';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };
    return this.http.post(URL, body, httpOptions).pipe(
      tap(_ => this.log(`updated Comments and Ratings`)),
      catchError(this.handleError<any>('updated Comments and Ratings'))
    );
  }

  // getRatingAndComments(contextJ, contextK, track){
  //     const URL =this.mataDataInfoUrl +'comment/' + contextJ + '/' + contextK + '/' + track;
  //     const httpOptions = {
  //         headers: new HttpHeaders({'Content-Type': 'application/json',
  //         'Authorization': this.authService.user.token,
  //         'Username': this.authService.user.username})
  //     };

  //     return this.http.get<any[]>(URL, httpOptions )
  //         .pipe(
  //             tap(_ => this.log('get Ratings and Comments')),
  //             catchError(this.handleError('get Ratings and Comments', []))
  //         );
  // }
  getRatingAndComments(metadataID) {
    const URL = this.mataDataInfoUrl + 'comment/' + metadataID;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.get<any[]>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('get Ratings and Comments')),
        catchError(this.handleError('get Ratings and Comments', []))
      );
  }

  validateDownloadwithoutToken(projectId, musicList, type) {
    const URL = this.mataDataInfoUrl + 'download/validation/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=1234567890';
    const httpOptions = {
      'headers': new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  validateDownload(projectId, musicList, type) {
    const URL = this.mataDataInfoUrl + 'download/validation/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
    const httpOptions = {
      'headers': new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  validateDownloadErrors(projectId, musicList, type) {
    const URL = this.mataDataInfoUrl + 'download/validation/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
    return this.http.get<any>(URL);
  }

  downloadTracks(projectId, musicList, type) {
    const URL = this.mataDataInfoUrl + 'download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
    const httpOptions = {
      'headers': new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getPeaks(metadataID): Observable<any[]> {
    const URL = `${this.peaksUrl}?metadataId=${metadataID}`;
    const httpOptions = {
      'headers': new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  findSFX(ObjArray, page){
    const URL = environment.baseUrl+'v2/SFXSearch/'+page;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.post<any[]>(URL, ObjArray, httpOptions)
      .pipe(
        tap(_ => this.log('fetched sfx')),
        catchError(this.handleError('getGenres', []))
      );
  }

  findMusicsAims(ObjArray, page){
	var e: HTMLInputElement=document.getElementById("txtnoVocals") as HTMLInputElement;
	if (e) {
		if (e.value=="X") {page+=1000;}
	}
    const URL = environment.baseUrl+'v2/AIMSsearch/'+page;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.post<any[]>(URL, ObjArray, httpOptions)
      .pipe(
        tap(_ => this.log('fetched aims')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getAudioFromURL(obj){
    const URL = `${environment.baseUrl}music/AIMS/Download`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Disposition': 'attachment', 
        'filename':'linked-resource-identifier.mp3',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.post<any[]>(URL, obj)
      .pipe(
        tap(_ => this.log('fetched musics')),
        catchError(this.handleError('getGenres', []))
      );
  }

  newfindMusics(ObjArray, page) {
    // COMENTEI ENDPOINT ANTIGO
    // const URL = this.searchUrl + 'advanced/';
    const URL = `${environment.baseUrl}v3/search/advanced/${page}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };
    return this.http.post<any[]>(URL, ObjArray, httpOptions)
      .pipe(
        tap(_ => this.log('fetched musics')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getTracksToSelect(obj){
    const URL = `${environment.baseUrl}search/MetadataRangeIds`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };
    return this.http.post<any[]>(URL, obj, httpOptions)
      .pipe(
        tap(_ => this.log('fetched music ids')),
        catchError(this.handleError('fetched music ids', []))
      );
  }

  findMusicsByPage(pageNumber) {
    // const URL = this.searchUrl + 'page/' + pageNumber;
    const URL = `${environment.baseUrl}v2/search/page/${pageNumber}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.get<any[]>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('get results by page')),
        catchError(this.handleError('get results by page', []))
      );
  }

  findMusics(textSearch, contextSearch): Observable<any[]> {
    const URL =
      `${this.searchUrl}${this.authService.user.id}/?text=${textSearch.join(',')}&context=${contextSearch.join(',')}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.get<any[]>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  downloadUrl(url) {
    const httpOptions = {
      'headers': new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  findMusicsSimilars(metadataID, titleId, starttime = 0, endtime = 1): Observable<any[]> {
    const URL =
      `${this.similarsUrl}${metadataID}/${titleId}/${starttime}/${endtime}/?token=${this.authService.user.token}&username=${this.authService.user.username}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      })
    };

    return this.http.get<any[]>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  findMusicsRelated(metadataID): Observable<any[]> {
    const URL = this.authService.user != null ? 
      `${this.subversionUrl}${metadataID}/?token=${this.authService.user.token}&username=${this.authService.user.username}` :
      `${this.subversionUrl}${metadataID}/?token=${localStorage.getItem('sharedProjectsGuid')}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    if(this.authService.user != null){
      httpOptions['headers']['Authorization'] = this.authService.user.token;
      httpOptions['headers']['Username'] =  this.authService.user.username;
    }

    return this.http.get<any[]>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  donwloadMusic(id: number): Observable<any> {
    const URL = `${this.donwloadUrl}${id}`;

    const httpOptions = {
      'headers': new HttpHeaders({
        'Content-Type': 'audio/mpeg',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      }),
    };

    httpOptions['responseType'] = 'blob';
    return this.http.request<any>('GET', URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  downloadMusicList(idList) {
    let URL = `${this.donwloadMultipleUrl}${idList}`;
    URL += "&token=" + this.authService.user.token + "&username=" + this.authService.user.username;
    const httpOptions = {
      'headers': new HttpHeaders({
        'Content-Type': 'application/zip',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      }),
    };

    httpOptions['responseType'] = 'blob';
    return this.http.request<any>('GET', URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  bufferMusic(id: number): Observable<any> {
    const URL = `${this.bufferUrl}${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'audio/mpeg',
        'Authorization': this.authService.user.token,
        'Username': this.authService.user.username
      }),
    };

    httpOptions['responseType'] = 'arraybuffer';

    return this.http.request<any>('GET', URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  freeBufferMusic(body: any): Observable<any> {
    const URL = `${this.freeBufferUrl}`;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'audio/mpeg' }),
    };

    httpOptions['responseType'] = 'arraybuffer';
    httpOptions['body'] = body;
    return this.http.request<any>('POST', URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getMetaInfoMusic(id: number): Observable<any> {
    const URL = `${this.mataDataInfoUrl}${id}`;
    const httpOptions = {
      'headers': new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getNextOrPrevious(track, operation) {
    const URL = `${environment.baseUrl}v2/fplayer/play/${track.id}/${operation}`;
    const httpOptions = {
      'headers': new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getNextOrPreviousInfo(track, operation) {
    const URL = `${environment.baseUrl}v2/fplayer/musicInfo/${track.id}/${operation}`;
    const httpOptions = {
      'headers': new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
