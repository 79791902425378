import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Register } from '../register';
import { RegisterService } from '../register.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router, Event, NavigationStart } from '@angular/router';
import { I18nGlvService } from '../i18n-glv.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

declare var $: any;

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  businessTypes: any;
  terms:any;
  loadingTerms = true;
  countries = [
    {
      name: this.i18n.getTranslationsBD('spain'),
      value: 'ES',
    },
    {
      name: this.i18n.getTranslationsBD('portugal'),
      value: 'PT',
    }
  ];
  step = 1;
  selectedCountry = null;
  selectedActivity = null;
  capchaImageSrc = null;
  capchaKey = null;
  loadingCapcha = true;
  isCapchaValid = false;
  countriesDDL = false;
  activitiesDDL = false;
  isUserLoginExists = false;

  constructor(private registerService: RegisterService, private fb: FormBuilder,
    public i18n: I18nGlvService, public router: Router, private http: HttpClient,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.getBusinessTypes();
    this.getCaptcha();
    this.getTerms();

    if (this.i18n.language == 'es') {
      this.selectedCountry = 0;

    } else {
      this.selectedCountry = 1;
    }
    let countryLabel = null;
    if (this.selectedCountry != null) {
      countryLabel = this.countries[this.selectedCountry].name;
    }


    this.registerForm = this.fb.group({
      Country: [countryLabel != null ? countryLabel : '', []],
      VAT: ['', [Validators.required]],
      CompanyName: ['', [Validators.required]],
      CommercialName: ['', []],
      Address: ['', [Validators.required]],
      PostalCode: ['', [Validators.required]],
      Email: ['', [Validators.email]],
      PhoneNumber: ['', []],
      WebSite: ['', []],
      ProdYear: ['', []],
      BusinessType: ['', [Validators.required]],
      BusinessTypeOther: ['', []],
      UserLogin: ['', [Validators.required]],
      UserPassword: ['', [Validators.required]],
      UserPasswordConfirm: ['', [Validators.required]],
      UserFirstName: ['', [Validators.required]],
      UserLastName: ['', [Validators.required]],
      UserEmail: ['', [Validators.required, Validators.email]],
      UserPhoneNumber: ['', [Validators.required]],
      textCaptcha: ['', []],
      captcha: ['', [Validators.required]]
    }, {
      validator: MustMatch('UserPassword', 'UserPasswordConfirm')
    });
  }

  getTerms(){
    this.registerService.getTerms().subscribe(result => {
      this.terms = result;
      this.loadingTerms = false;
    });
  }

  getBusinessTypes() {
    this.registerService.getBusinessTypes().subscribe(result => {
      this.businessTypes = result;
    });
  }

  setCountry(country, index) {
    this.registerForm.patchValue({
      Country: country.name
    });
    this.selectedCountry = index;
    this.countriesDDL = false;
  }

  iterateCountry(side, event) {
    event.stopPropagation();
    if (side == 'up') {
      this.selectedCountry--;
      if (this.selectedCountry < 0) {
        this.selectedCountry = 1;
      }
    } else {
      this.selectedCountry++;
      if (this.selectedCountry > 1) {
        this.selectedCountry = 0;
      }
    }
    this.registerForm.controls['Country'].setValue(this.countries[this.selectedCountry].name);
  }

  checkWhatCountry() {
    if (this.countriesDDL) {
      setTimeout(() => {
        const idToGo = "country-" + this.selectedCountry;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 790;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#countriesDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  setActivity(businessType, i) {

    this.registerForm.patchValue({
      BusinessType: businessType['BusinessType' + (this.i18n.language.charAt(0).toUpperCase() + this.i18n.language.charAt(1))]
    });
    this.selectedActivity = i;
    this.activitiesDDL = false;
  }

  iterateActivity(side, event) {
    event.stopPropagation();
    if (side == 'up') {
      this.selectedActivity--;
      if (this.selectedActivity < 0) {
        this.selectedActivity = 25;
      }
    } else {
      this.selectedActivity++;
      if (this.selectedActivity > 25) {
        this.selectedActivity = 0;
      }
    }
    this.registerForm.controls['BusinessType'].setValue(this.businessTypes[this.selectedActivity]['BusinessType' + (this.i18n.language.charAt(0).toUpperCase() + this.i18n.language.charAt(1))]);
  }

  checkWhatActivity() {
    if (this.activitiesDDL) {
      setTimeout(() => {
        const idToGo = "activity-" + this.selectedActivity;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 920;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#activitiesDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  resetForm() {
    this.registerForm.patchValue({
      Country: 'ES',
      VAT: '',
      CompanyName: '',
      CommercialName: '',
      Address: '',
      PostalCode: '',
      Email: '',
      PhoneNumber: '',
      WebSite: '',
      ProdYear: '',
      BusinessType: 'O',
      BusinessTypeOther: '',
      UserLogin: '',
      UserPassword: '',
      UserPasswordConfirm: '',
      UserFirstName: '',
      UserLastName: '',
      UserEmail: '',
      UserPhoneNumber: '',
      textCaptcha: 'cApTcHaA@',
      captcha: ''
    });
  }

  sendForm() {
    const data = {
      'CompanyName': this.registerForm.get('CompanyName').value,
      'CommercialName': this.registerForm.get('CommercialName').value,
      'Address': this.registerForm.get('Address').value,
      'PostalCode': this.registerForm.get('PostalCode').value,
      'Email': this.registerForm.get('Email').value,
      'PhoneNumber': this.registerForm.get('PhoneNumber').value,
      'WebSite': this.registerForm.get('WebSite').value,
      'ProdYear': this.registerForm.get('ProdYear').value,
      'BusinessType': this.selectedActivity != null ? this.businessTypes[this.selectedActivity]['BusinessTypeSimbol'] : '',
      'BusinessTypeOther': this.registerForm.get('BusinessTypeOther').value,
      'VAT': this.registerForm.get('VAT').value,
      'Country': this.selectedCountry != null ? this.countries[this.selectedCountry].value : '',
      'ActiveClientAndUser': false,
      'Users': [{
        'Login': this.registerForm.get('UserLogin').value,
        'Password': this.registerForm.get('UserPassword').value,
        'FirstName': this.registerForm.get('UserFirstName').value,
        'LastName': this.registerForm.get('UserLastName').value,
        'Email': this.registerForm.get('UserEmail').value,
        'PhoneNumber': this.registerForm.get('UserPhoneNumber').value
      }]
    };

    if (this.registerForm.get('ProdYear').value == "0000") {
      data.ActiveClientAndUser = true;
    }

    this.registerService.addRegister(data).subscribe(result => {
      this.router.navigate(['./']);
    });

  }

  check(go = false) {
    this.registerForm.updateValueAndValidity();
    if (this.registerForm.invalid) {
      Object.keys(this.registerForm.controls).forEach(key => {
        this.registerForm.get(key).markAsDirty();
        this.registerForm.get(key).markAsTouched();
        return;
      });
      return;
    }
    if(go){
      this.step = 2;
    }
  }

  getCaptcha() {
    let key = this.uuidv4();
    let base = environment.baseUrl + 'captcha/image/?uid=' + key;
    return this.http.get<any[]>(base)
      .subscribe(json => {
        this.capchaImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(json['image']);
        this.capchaKey = json['key'];
        this.loadingCapcha = false;
      });
  }

  checkCapcha() {
    let valueInserted = this.registerForm.get('captcha').value;
    let base = environment.baseUrl + 'captcha/validate/?uid=' + this.capchaKey + '&key=' + valueInserted;
    return this.http.get<any>(base)
      .subscribe(isValid => {

        this.isCapchaValid = isValid;
        if (isValid) {
          this.check();
        }

      });
  }

  checkLogin() {
    let valueInserted = this.registerForm.get('UserLogin').value;
    let base = environment.baseUrl + 'user/login/validate?login=' + valueInserted;
    return this.http.get<any>(base)
      .subscribe(isValid => {
        this.isUserLoginExists = isValid;
      });
  }

  uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // GETTERS
  get Country() {
    return this.registerForm.get('Country');
  }
  get VAT() {
    return this.registerForm.get('VAT');
  }
  get CompanyName() {
    return this.registerForm.get('CompanyName');
  }
  get CommercialName() {
    return this.registerForm.get('CommercialName');
  }
  get Address() {
    return this.registerForm.get('Address');
  }
  get PostalCode() {
    return this.registerForm.get('PostalCode');
  }
  get Email() {
    return this.registerForm.get('Email');
  }
  get PhoneNumber() {
    return this.registerForm.get('PhoneNumber');
  }
  get WebSite() {
    return this.registerForm.get('WebSite');
  }
  get ProdYear() {
    return this.registerForm.get('ProdYear');
  }
  get BusinessType() {
    return this.registerForm.get('BusinessType');
  }
  get BusinessTypeOther() {
    return this.registerForm.get('BusinessTypeOther');
  }
  get UserLogin() {
    return this.registerForm.get('UserLogin');
  }
  get UserPassword() {
    return this.registerForm.get('UserPassword');
  }

  get UserPasswordConfirm() {
    return this.registerForm.get('UserPasswordConfirm');
  }

  get UserFirstName() {
    return this.registerForm.get('UserFirstName');
  }
  get UserLastName() {
    return this.registerForm.get('UserLastName');
  }
  get UserEmail() {
    return this.registerForm.get('UserEmail');
  }
  get UserPhoneNumber() {
    return this.registerForm.get('UserPhoneNumber');
  }

  get textCaptcha() {
    return this.registerForm.get('textCaptcha');
  }

  get captcha() {
    return this.registerForm.get('captcha');
  }

}

