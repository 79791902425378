import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ProjectService } from '../project.service';
import { I18nGlvService } from '../i18n-glv.service';


@Component({
  selector: 'app-delete-project',
  templateUrl: './delete-project.component.html',
  styleUrls: ['./delete-project.component.scss']
})
export class DeleteProjectComponent implements OnInit {
  projectID = null;
  userID = null;
  error: boolean;
  messageError: string;
  constructor(public dialogRef: MatDialogRef<DeleteProjectComponent>, private projectService: ProjectService,
    public i18n: I18nGlvService) { }

  ngOnInit() {

  }

  cancelar() {
    this.dialogRef.close({ action: 'cancel' });
  }

  apagar() {

    this.projectService.deleteProject(this.projectID, this.userID).subscribe(data => {
      this.dialogRef.close({ message: 'success' });
    },
      (err) => {
        this.error = true;
        this.messageError = err.error;
        setTimeout(() => {
          this.error = false;
          this.messageError = '';
        }, 3000);
      });
  }

}
