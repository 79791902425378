import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(public router: Router,  public authService: AuthService) { }

  ngOnInit() {
  }

  getClass() {
    if(this.router.url.includes('sharedProjects')){
      return 'upperupperLoading';
    } 
    else if (this.router.url.includes('search') || this.router.url.includes('sfx') || this.router.url.includes('catalogos')  || this.router.url.includes('projects')) {
      return 'upperLoading';
    } else if (this.router.url.includes('playlists')){
      if(this.authService.isLoggedIn){
        return 'upperLoading';
      } else {
        return 'upperupperLoading';
      }
    } 
    else  {
      return '';
    }
  }

  getZIndex(){
    if (window.location.href.includes('search')) {
      return '-1';  
    }
    return '1';
  }
}
