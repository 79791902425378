import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Playlist } from './playlist';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  private playlistsUrl = environment.baseUrl + 'home/playlists';  // URL to web api
  private playlistURL = environment.baseUrl + 'playlist/'


  constructor(private http: HttpClient, private messageService: MessageService) {
  }

  /** Log a PlaylistService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`PlaylistService: ${message}`);
  }

  /** GET playlists from the server */
  getPlaylists(): Observable<any[]> {
    return this.http.get<any[]>(this.playlistsUrl)
      .pipe(
        tap(_ => this.log('fetched playlists')),
        catchError(this.handleError('getPlaylists', []))
      );
  }

  searchPlaylist(searchString, userId) {
    return this.http.get<any[]>(this.playlistURL + '?userId=' + userId + '&text=' + searchString)
      .pipe(
        tap(_ => this.log('fetched playlists')),
        catchError(this.handleError('getPlaylists', []))
      );
  }

  searchPlaylistNew(ObjArray){
    const URL = this.playlistURL + "advanced";
    return this.http.post<any[]>(URL, ObjArray, httpOptions)
      .pipe(
        tap(_ => this.log('fetched musics')),
        catchError(this.handleError('getGenres', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
