import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepickerModule, MatNativeDateModule,
  MatDialogModule, MatInputModule, MatSelectModule, MatButtonModule, MatAutocompleteModule 
} from '@angular/material';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxFileDropModule } from 'ngx-file-drop';


import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { CustomListboxModule } from './custom-listbox/custom-listbox.module';

// import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
// import {InMemoryDataService} from './in-memory-data.service';

import { AppComponent } from './app.component';
import { MessagesComponent } from './messages/messages.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { HomeComponent } from './home/home.component';
import { HomeHighlightsComponent } from './home-highlights/home-highlights.component';
import { HomePlaylistsComponent } from './home-playlists/home-playlists.component';
import { FooterComponent } from './footer/footer.component';
import { InfoComponent } from './info/info.component';
import { HomeAlbumsComponent } from './home-albums/home-albums.component';
import { AutofocusDirective } from './autofocus.directive';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { TruncatePipe } from './truncate.pipe';
import { CallbackFilterPipe } from './callback-filter.pipe';
import { FreePlaylistsComponent } from './free-playlists/playlists.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { RegisterComponent } from './register/register.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { RecoverAccessComponent } from './recover-access/recover-access.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProjectsComponent } from './projects/projects.component';
import { SearchComponent } from './search/search.component';
import { SearchSliderComponent } from './search-slider/search-slider.component';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { UserComponent } from './user/user.component';
import { GroupByPipe } from './group-by.pipe';

import { NewprojectmodalComponent } from './newprojectmodal/newprojectmodal.component';
import { DeleteProjectComponent } from './delete-project/delete-project.component';
import { DeleteTrackFromProjectComponent } from './delete-track-from-project/delete-track-from-project.component';
import { TrackOperationsModalComponent } from './track-operations-modal/track-operations-modal.component';
import { SubListMusicsComponent } from './sub-list-musics/sub-list-musics.component';


import { ToastrModule } from 'ngx-toastr';
import { SendProjectEmailComponent } from './send-project-email/send-project-email.component';
import { SharedProjectsComponent } from './shared-projects/shared-projects.component';
import { FreezePlayerComponent } from './freeze-player/freeze-player.component';
import { LoadingComponent } from './loading/loading.component';
import { ShareProjectsComponent } from './share-projects/share-projects.component';
import { DownloadZipMailComponent } from './download-zip-mail/download-zip-mail.component';
import { NewReleasesComponent } from './new-releases/new-releases.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ProjectListExistanceModalComponent } from './project-list-existance-modal/project-list-existance-modal.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SfxComponent } from './sfx/sfx.component';
import { AlertmodalComponent } from './alertmodal/alertmodal.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';


// Create our const with the information about the format we want
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'MMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    DashboardComponent,
    NavbarComponent,
    HomeSliderComponent,
    HomeComponent,
    HomeHighlightsComponent,
    HomePlaylistsComponent,
    FooterComponent,
    InfoComponent,
    HomeAlbumsComponent,
    AutofocusDirective,
    CatalogsComponent,
    TruncatePipe,
    CallbackFilterPipe,
    FreePlaylistsComponent,
    PlaylistsComponent,
    RegisterComponent,
    ClickOutsideDirective,
    RecoverAccessComponent,
    ContactUsComponent,
    ProjectsComponent,
    SearchComponent,
    SearchSliderComponent,
    NumbersOnlyDirective,
    UserComponent,
    GroupByPipe,
    NewprojectmodalComponent,
    DeleteProjectComponent,
    DeleteTrackFromProjectComponent,
    TrackOperationsModalComponent,
    SubListMusicsComponent,
    SendProjectEmailComponent,
    SharedProjectsComponent,
    FreezePlayerComponent,
    LoadingComponent,
    ShareProjectsComponent,
    DownloadZipMailComponent,
    NewReleasesComponent,
    ProjectListExistanceModalComponent,
    MessageDialogComponent,
    SfxComponent,
    AlertmodalComponent,
    AutocompleteComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    AngularDualListBoxModule,
    CustomListboxModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
	MatAutocompleteModule,
    ToastrModule.forRoot(),
    ScrollingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DeferLoadModule,
    NgxFileDropModule
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    /*HttpClientInMemoryWebApiModule.forRoot(
        InMemoryDataService, {dataEncapsulation: false}
    )*/
  ],
  entryComponents: [
    NewprojectmodalComponent,
    DeleteProjectComponent,
    DeleteTrackFromProjectComponent,
    TrackOperationsModalComponent,
    SendProjectEmailComponent,
    ShareProjectsComponent,
    DownloadZipMailComponent,
    ProjectListExistanceModalComponent,
    MessageDialogComponent,
    AlertmodalComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
