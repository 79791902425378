import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SfxService {

  private categoriesSfxUrl = `${environment.baseUrl}categories/SFX`;
  private albunsSfxUrl = `${environment.baseUrl}home/Sfx/albuns`;
  private catalogSfxUrl = `${environment.baseUrl}catalog/SFX`;


  constructor(private http: HttpClient, 
              private authService: AuthService, 
              private messageService: MessageService) { }

  private log(message: string) {
    this.messageService.add(`SFXService: ${message}`);
  }


  // searchSFX(searchString, page) {
  //   return this.http.get<any[]>(this.projectsUrl + 'page/' + page + '?status=' + status + '&text=' + searchString)
  //     .pipe(
  //       tap(_ => this.log('fetched projects')),
  //       catchError(this.handleError('getProjects', []))
  //     );
  // }

  getCategories(searchText = "", searchContexts = ""){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    let fromUriParameters = "";
    if(searchText.trim() != '' || searchContexts.trim() != ''){
      fromUriParameters+= "?";
    }
    fromUriParameters += searchText != "" ? "terms=" + searchText : "";
    if(searchText.trim() != "" && searchContexts.trim() != ""){
      fromUriParameters += "&";
    }
    fromUriParameters += searchContexts != "" ? "contexts=" + searchContexts : "";
    return this.http.get(this.categoriesSfxUrl + '/search' + fromUriParameters, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }


  getSFXalbuns(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any[]>(this.albunsSfxUrl, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  getSFXcatalog(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.catalogSfxUrl, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
