import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { User } from '../user';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  isResult: boolean;
  model = new User();

  constructor(private dataService: DataService, public authService: AuthService) {
    if (this.authService.user) {
      this.model = this.authService.user;
    }
  }

  ngOnInit() {
    this.dataService.getIsResult().subscribe(data => {
      this.isResult = data;
    });
  }
}
