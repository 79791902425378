import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { PlayerHistoryService } from './player-history.service';
import { I18nGlvService } from './i18n-glv.service';
import * as moment from 'moment';
import { DataService } from './data.service';



@Injectable({
  providedIn: 'root'
})
export class MusicService {

  tonos = [];
  constructor(public i18n: I18nGlvService, public playerHistory: PlayerHistoryService, private dataService: DataService, public authService: AuthService) { }

  // (list returned from DB, tonos, isRelated-boolean, isSimilar-boolean, isFromAlbum-boolean)
  mountMusicList(results, tonos, isRelated, isSimilar, isFromAlbum, needsProjectIID) {

    this.tonos = tonos;
    const musics = [];

    for (let id = 0; id < results.length; id++) {
      const musica = results[id];
      const key = this.uuidv4();   

      const oneOrZero = (Math.random() > 0.5) ? 1 : 0;
      // const baseUrl = id%2 == 0 ? 'https://files2.vimusica.com/api/' : 'https://files3.vimusica.com/api';
      const music = {
        // id: musica.MetaDataID, // descomentar na próxima passagem a produção
        guid: key,
        name: musica.TrackTitle,
        cover: environment.baseUrl + 'image/album/' +
          musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_') +
          '/' + musica.Reference.replace(new RegExp(' ', 'g'), '_') + '/?token=' +
          this.authService.user.token + '&username=' + this.authService.user.username,
        link: 'https://vimusicaapi.glove-it.pt/api/music/streaming/11340771/?token=' +
          this.authService.user.token + '&username=' + this.authService.user.username,
        duration: musica.TrackTime,
        durationSecs: moment.duration('00:' + musica.TrackTime).asSeconds(),
        reference: musica.Reference,
        track: musica.Track,
        catalog: musica.Catalog,
        titleId: musica.TitleID != undefined ? musica.TitleID : musica.WorkID,
        albumTitle: musica.AlbumTitle,
        authors: musica.hasOwnProperty('AuthorsComposers') ? [musica.AuthorsComposers] : [musica.Authors],
        label: '',
        publisher: musica.Publisher,
        genres:
          musica.Categories.Genres[this.i18n.language.toUpperCase()],
        mindStates:
          musica.Categories.Moods[this.i18n.language.toUpperCase()],
        instruments:
          musica.Categories.Instruments[this.i18n.language.toUpperCase()],
        bpm: musica.BPM,
        keyWords:
          musica.Categories.Keys[this.i18n.language.toUpperCase()],
        description: musica.Description,
        isloadToWave: false,
        tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
        times: musica.Categories.Times,
        periodos: musica.Categories.Periods,
        tons: musica.Categories.Keys,
        keys: musica.KeyCode,
        metadataID: musica.MetaDataID,
        metaresultsID: musica.MetaresultsID,
        btVersao: (musica.btVersao === ' ') ? 0 : parseInt(musica.btVersao, 10),
        Relateds: musica.Relateds,
        Context1J: musica.Context1J,
        Context1K: musica.Context1K,
        Context1Q: musica.Context1Q,
        Context1E: musica.Context1E,
        Context1I: musica.Context1I,
        Categories: musica.Categories,
        Contexts: musica.Contexts,
        TrackTitle: musica.TrackTitle,
        Authors: musica.AuthorsComposers,
        Publisher: musica.Publisher,
        Artist: musica.Artist,
        Conductor: musica.Conductor,
        Orchestra: musica.Orchestra,
        Choir: musica.Choir,
        Soloist: musica.Soloist,
        Instrumentalist: musica.Instrumentalist,
        Intensity: musica.Intensity,
        Vocals: musica.Vocals,
        ReferenceIVM: musica.Reference,
        Track: musica.Track,
        UpdateRelease: musica.UpdateRelease,
        NewRelease: musica.NewRelease,
        palavrasChave: musica.Keywords,
        letra: musica.Lirics,
        show: false,
        id: musica.MetaDataID,
        alreadyPlayed: this.checkIfAlreadyPlayed(musica.MetaDataID),
        IsSFX : musica.hasOwnProperty('IsSFX') ? musica['IsSFX'] : false 
      };
      music['isPlaying'] = this.dataService.checkIfPlaying(music);
      if (isFromAlbum) {
        music['isFromAlbum'] = true;
      }
      if (isRelated) {
        music['isRelated'] = true;
        music['TrackVersion'] = musica.TrackVersion;
      }
      if (isSimilar) {
        music['isSimilar'] = true;
      }
      music['waveform'] = `${environment.baseUrl}image/waveform/` + music['id'] + '/?token=' + this.authService.user.token + '&username=' + this.authService.user.username + '&t=' + key;
      music['url'] = `${environment.baseUrl}v2/fplayer/play/${music['id']}/?token=${this.authService.user.token}&t=${key}`;
      music['info_url'] = `${environment.baseUrl}v2/fplayer/musicInfo/${music['id']}/?token=${this.authService.user.token}&t=${key}`;

      if (needsProjectIID) {
        music['ProjectsIID'] = musica.ProjectsIID;
        music['percentageUsed'] = musica.DurationUsed != null ? musica.DurationUsed : '0:00',
          music['Seq'] = musica.Reference;
      } 

      let finalMusic = this.getMusicTono(music);
      musics.push(finalMusic);
    }
    let finalMusics = this.checkCurrentTime(musics);
    return finalMusics;
  }

  checkCurrentTime(musics){
    let time = 0;
    let timeDisplay = '00:00';
    const objFP = this.dataService.getFPobject();
    if(objFP != null){
      const objFPtracks = objFP.tracks;
      musics.forEach(music => {
        music['currentTime'] = time;
        music['currentTimeDisplay'] = timeDisplay;
        for (let it = 0; it < objFPtracks.length; it++) {
          const Fptrack = objFPtracks[it];
          if(Fptrack.id == music.id){
            music['currentTime'] = Fptrack.currentTime;
            music['currentTimeDisplay'] = moment('2015-01-01').startOf('day').seconds(Fptrack.currentTime).format('mm:ss');
          }
        }
      });
    } else {
      musics.forEach(music => {
        music['currentTime'] = time;
        music['currentTimeDisplay'] = timeDisplay;
      });
    }
    return musics;
  }

  checkIfAlreadyPlayed(id){
    let playedTracks = this.playerHistory.getPlayedSongs();
    if(playedTracks.includes(id.toString())){
      return true;
    }else{
      return false;
    }
  }

  getMusicTono(musica) {
    if (musica.keyWords) {
      musica['tono'] = musica.keyWords;
      return musica;
    } else if (musica.Context1Q != null && musica.Context1Q != '') {
      if (musica.Context1Q.indexOf(' ') > -1) {
        const musicQContexts = musica.Context1Q.split(' ');
        for (let i = 0; i < this.tonos.length; i++) {
          for (let j = 0; j < musicQContexts.length; j++) {
            if (musicQContexts[j] == this.tonos[i].context) {
              musica['tono'] = this.tonos[i].name;
              return musica;
            }
          }
        }
      } else {
        for (let i = 0; i < this.tonos.length; i++) {
          if (musica.Context1Q == this.tonos[i].context) {
            musica['tono'] = this.tonos[i].name;
            return musica;
          }
        }
      }

    } else if (musica.Contexts != null && musica.Contexts != '') {
      const contextArray = musica.Contexts.split(' ');
      for (let i = 0; i < this.tonos.length; i++) {
        if (contextArray.includes(this.tonos[i].context)) {
          musica.Context1Q = this.tonos[i].context;
          musica['tono'] = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.KeyCode != null && musica.KeyCode != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (this.tonos[i].name.charAt(0) == musica.KeyCode) {
          musica.Context1Q = this.tonos[i].context;
          musica['tono'] = this.tonos[i].name;
          return musica;
        }
      }
    } else {
      musica.Context1Q = null;
      musica['tono'] = null;
      return musica;
    }
    return musica;
  }

  checkIfAbleToPlay() {
    if (this.authService.user.Accs_MaxPlays === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-play'));
      return false;
    }
    this.authService.user.Accs_MaxPlays--;
    return true;
  }

  checkIfAbleToDownload() {
    if (this.authService.user.Accs_MaxDownloads === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    this.authService.user.Accs_MaxDownloads--;
    return true;
  }

  checkAllAlreadyPlayed(tracks){
    tracks.forEach(track => {
      track.alreadyPlayed = this.checkIfAlreadyPlayed(track.id) && !this.dataService.checkIfPlaying(track);
    });
    return tracks;
  }

  uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
