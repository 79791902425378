import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ProjectService } from '../project.service';
import { AuthService } from '../auth/auth.service';
import { I18nGlvService } from '../i18n-glv.service';


@Component({
  selector: 'app-send-project-email',
  templateUrl: './send-project-email.component.html',
  styleUrls: ['./send-project-email.component.scss']
})
export class SendProjectEmailComponent implements OnInit {

  project = null;
  sendEmailForm: FormGroup;
  alowAcess = true;
  alowDownload = false;
  logs = [];

  user = null;

  userEmail = null;
  userName = null;
  NameIsInvalid = false;
  EmailIsInvalid = false;
  MessageIsInvalid = false;

  projectAcess;

  constructor(public dialogRef: MatDialogRef<SendProjectEmailComponent>,
    private projectService: ProjectService, private authService: AuthService,
    private fb: FormBuilder, public i18n: I18nGlvService) { }

  ngOnInit() {
    if (this.user != null) {
      this.getAcessState();
      //this.sendEmailForm = this.fb.group({
      //  Name: ['', [Validators.required]],
      //  Email: ['', [Validators.required]],
      //  Message: [''] // Não aplicamos o Validators.required aqui
      //});

      this.sendEmailForm = this.fb.group({
        Name: [''], // Não aplicamos o Validators.required aqui
        Email: [''], // Não aplicamos o Validators.required aqui
        Message: [''] // Não aplicamos o Validators.required aqui
      });


    } else {
      this.getEmailsSentLog();
      this.sendEmailForm = this.fb.group({
        Name: new FormControl({ value: this.userName, disabled: true }, Validators.required),
        Email: new FormControl({ value: this.userEmail, disabled: true }, Validators.required),
        Message: [''] // Não aplicamos o Validators.required aqui
      });
    }
  }

  getAcessState() {
    this.projectService.getAcessOfProject(this.project.ProjectsID).subscribe(data => {
      if (data != null) {
        this.projectAcess = data;
        if (data['AllowAccess'] == "S" || this.logs == null) {
          this.alowAcess = true;
        } else {
          this.alowAcess = false;
        }

        if (data['AllowDownload'] == "S") {
          this.alowDownload = true;
        } else {
          this.alowDownload = false;
        }
      }
      this.getEmailsSentLog();
    });
  }

  getEmailsSentLog() {
    this.projectService.getProjectSentLog(this.project.ProjectsID).subscribe(data => {
      this.logs = data;
    });
  }

  cancelar() {
    if (this.user != null) {
      let AllowAccess = 'N';
      let AllowDownload = 'N';

      if (this.alowAcess || this.projectAcess['Message'] == null ) 
	  {
        AllowAccess = 'S';
      }
	  
      if (this.alowDownload) 
	  {
        AllowDownload = 'S';
      } 
	  else 
	  {
        AllowDownload = 'N';
      }
	  
      const data = {
        ProjectId: this.project.ProjectsID,
        AllowAccess: AllowAccess,
        AllowDownload: AllowDownload,
        Name: this.sendEmailForm.get('Name').value,
        Email: this.sendEmailForm.get('Email').value,
        Message: this.sendEmailForm.get('Message').value,
        ProjectTitle: this.project.ProjectTitle,
        ProjectSubTitle: this.project.ProjectSubTitle
      };
      this.projectService.sendEmailWithProject(data, false).subscribe(data => {
        this.dialogRef.close({ action: 'cancel' });
      });
    } else {
      this.dialogRef.close({ action: 'cancel' });
    }

  }

  changeAcess() {
    this.alowAcess = !this.alowAcess;
    this.checkAceess(this.alowAcess);
  }

  changeDownload() {
    this.alowDownload = !this.alowDownload;
    this.checkDownloadAceess(this.alowDownload);
  }

  checkAceess (status) {
    if (status) {
      this.alowAcess = true;
	}
	else
	{
      this.alowAcess = false;
	  this.alowDownload = false;
    }
  }

  checkDownloadAceess(status) {
    if (status) {
      this.alowDownload = true;
      this.alowAcess = true;
	}
	else
	{
      this.alowDownload = false;
    }
  }

  sendEmail() {
	 this.NameIsInvalid = false;
	 this.EmailIsInvalid = false;
	 this.MessageIsInvalid = false;

	if (this.sendEmailForm.get('Name').value=='' && this.sendEmailForm.get('Email').value=='' && this.sendEmailForm.get('Message').value=='')
	{
		// será uma atulização das checkboxes
	}
	else
	{

	 if (this.sendEmailForm.get('Name').value=='')
     {
		 this.NameIsInvalid = true;
		 return;
	 }

	 if (this.sendEmailForm.get('Email').value=='')
     {
		 this.EmailIsInvalid = true;
		 return;
	 }

	 if (this.sendEmailForm.get('Message').value=='')
     {
		 this.MessageIsInvalid = true;
		 return;
	 }
	}
	  
    if (this.user != null) {

      let AllowAccess;
      if (this.alowAcess) {
        AllowAccess = 'S';
      } else {
        AllowAccess = 'N';
      }

      let AllowDownload;
      if (this.alowDownload) {
        AllowDownload = 'S';
      } else {
        AllowDownload = 'N';
      }

      const data = {
        ProjectId: this.project.ProjectsID,
        AllowAccess: AllowAccess,
        AllowDownload: AllowDownload,
        Name: this.sendEmailForm.get('Name').value,
        Email: this.sendEmailForm.get('Email').value,
        Message: this.sendEmailForm.get('Message').value,
        ProjectTitle: this.project.ProjectTitle,
        ProjectSubTitle: this.project.ProjectSubTitle
      };

      this.projectService.sendEmailWithProject(data, true).subscribe(data => {
        this.dialogRef.close({ action: 'result', nome: this.sendEmailForm.get('Name').value });
      });
    } else {
      const data = {
        ProjectId: this.project.ProjectsID,
        AllowAccess: this.project['AllowAccess'],
        AllowDownload: this.project['AllowDownload'],
        Name: this.sendEmailForm.get('Name').value,
        Email: this.sendEmailForm.get('Email').value,
        Message: this.sendEmailForm.get('Message').value,
        ProjectTitle: this.project.ProjectTitle,
        ProjectSubTitle: this.project.ProjectSubTitle
      };

      this.projectService.sendEmailWithProject(data, true).subscribe(data => {
        this.dialogRef.close({ action: 'result', nome: this.sendEmailForm.get('Name').value });
      });

    }
  }

  preventPropagation($event) {
    $event.stopPropagation();
  }

  // GETTERS
  get Name() {
    return this.sendEmailForm.get('Name');
  }
  get Email() {
    return this.sendEmailForm.get('Email');
  }
  get Message() {
    return this.sendEmailForm.get('Message');
  }
}
