import { Component, EventEmitter, IterableDiffers, Input, Output, OnChanges, OnInit } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';
import { I18nGlvService } from '../i18n-glv.service';


@Component({
  selector: 'app-custom-listbox',
  templateUrl: './custom-listbox.component.html',
  styleUrls: ['./custom-listbox.component.scss']
})
export class CustomListboxComponent extends DualListComponent {

  @Input() sourceName = '';
  @Input() targetName = '';

  @Input() selected: any;

  @Output() selectChange = new EventEmitter();
  lista = [];

  isItemHover: any[];

  constructor(differs: IterableDiffers, public i18n: I18nGlvService) {
    super(differs);
    this.isItemHover = [];
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    let previousSelected = [];
    for (let i = 0; i < this.selected.length; i++) {
      previousSelected.push({ _id: this.selected[i], _name: this.selected[i] });
    }
    this.confirmed.list = previousSelected;
    this.confirmed.sift = previousSelected;
  }

  checkList() {
    this.selectChange.emit(this.confirmed.sift);
  }

  moveAll() {
    this.selectAll(this.available);
    this.moveItem(this.available, this.confirmed);
  }

  removeAll() {
    this.selectAll(this.confirmed);
    this.moveItem(this.confirmed, this.available);
  }

  // Override function in DualListComponent to add custom selectChange event.
  selectItem(list: Array<any>, item: any) {
    const pk = list.filter((e: any) => {
      return Object.is(e, item);
    });
    if (pk.length > 0) {
      // Already in list, so deselect.
      for (let i = 0, len = pk.length; i < len; i += 1) {
        const idx = list.indexOf(pk[i]);
        if (idx !== -1) {
          list.splice(idx, 1);
          this.lista = list;
        }
      }
    } else {
      list.push(item);
      this.lista = list;
    }
  }

  moveUp(index: number) {
    if (index >= 1) {
      this.swap(index, index - 1);
    }
    this.checkList();
  }

  moveAllUp(index: number) {
    if (index >= 1) {
      const catalog = this.confirmed.sift[index];
      this.confirmed.sift.splice(index, 1);
      this.confirmed.sift.unshift(catalog);
    }
    this.checkList();
  }

  moveDown(index: number) {
    if (index < this.confirmed.sift.length - 1) {
      this.swap(index, index + 1);
    }
    this.checkList();
  }

  moveAllDown(index: number) {
    if (index < this.confirmed.sift.length - 1) {
      const catalog = this.confirmed.sift[index];
      this.confirmed.sift.splice(index, 1);
      this.confirmed.sift.push(catalog);
    }
    this.checkList();
  }

  private swap(x: any, y: any) {
    const b = this.confirmed.sift[x];
    this.confirmed.sift[x] = this.confirmed.sift[y];
    this.confirmed.sift[y] = b;
  }

}
