import { Component, OnInit } from '@angular/core';
import { I18nGlvService } from '../i18n-glv.service';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';


@Component({
  selector: 'app-recover-access',
  templateUrl: './recover-access.component.html',
  styleUrls: ['./recover-access.component.scss']
})
export class RecoverAccessComponent implements OnInit {

  constructor(public i18n: I18nGlvService, private sanitizer: DomSanitizer,
    private userService: UserService, private http: HttpClient) { }

  email = "";
  capchaImageSrc = null;
  captcha = "";
  capchaKey = null;
  loadingCapcha = true;
  isCapchaValid = false;


  ngOnInit() {
    this.getCaptcha();
  }


  getCaptcha() {
    const key = this.uuidv4();
    const base = environment.baseUrl + 'captcha/image/?uid=' + key;
    return this.http.get<any[]>(base)
      .subscribe(json => {
        this.capchaImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(json['image']);
        this.capchaKey = json['key'];
        this.loadingCapcha = false;
      });
  }

  checkCapcha() {
    const el = document.getElementById('captchaText') as HTMLInputElement;
    const valueInserted = el.value;
    const base = environment.baseUrl + 'captcha/validate/?uid=' + this.capchaKey + '&key=' + valueInserted;
    return this.http.get<any>(base)
      .subscribe(isValid => {
        this.isCapchaValid = isValid;
      });
  }

  uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  recoverPass() {
    this.userService.recoverPass(this.email).subscribe(
      data => {
        if (data !== undefined) {
          alert(this.i18n.getTranslationsBD('the-password-has-been-sent-to-your-email'));
        } else {
          alert(this.i18n.getTranslationsBD('incorrect-email'));
        }
      });
  }



}
