import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../data.service';
import { I18nGlvService } from '../i18n-glv.service';

declare var $: any;
@Component({
  selector: 'app-freeze-player',
  templateUrl: './freeze-player.component.html',
  styleUrls: ['./freeze-player.component.scss']
})
export class FreezePlayerComponent implements OnInit {
  public trackSelected = null;
  public navbar = null;
  public i = 0;
  show = false;

  height = '';

  constructor(
    public authService: AuthService,
    private dataService: DataService,
    public i18n: I18nGlvService) { }

  ngOnInit() {
    // this.dataService.getTrackSelected().subscribe(data => {

    //   this.trackSelected = data['trackSelected'];
    //   this.navbar = data['navbar'];
    //   this.i =  data['i'];
    //   this.show = data['show'];

    //   this.height = '156px';
    //   if ($(window).width() >  991) {
    //     this.height = '105px';
    //   }

    //   if ($(window).width() > 1220) {
    //     this.height = '120px';
    //   }

    //   if ($(window).width() > 1420) {
    //     this.height = '140px';
    //   }

    //   if ($(window).width() > 1600) {
    //     this.height = '150px';
    //   }

    //   if ($(window).width() > 1900) {
    //     this.height = '180px';
    //   }
    // });

  }

}
