import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AlertmodalComponent } from "../alertmodal/alertmodal.component";
import { AlertService } from '../alertmodal/alert.service';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {
  alertModalRef: MatDialogRef<AlertmodalComponent>;

  constructor(
    public authService: AuthService, 
    private router: Router,
    public alertModalComp: MatDialog,
    public alertService: AlertService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var xlDevelop=window.location.href.includes('http://localhost:4200') || window.location.href.includes('https://www.vimusica.com');
	
    if (!this.authService.isLoggedIn) {
      const localHeaders = request.headers;
      localHeaders.set('Authorization', '123456789');
      request = request.clone({
        headers: request.headers
          .set('Authorization', '123456789')
      });
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
          return event;
        }),
        catchError(er => {
		  

          if(er.status === 0){
            if(this.alertService.isOpen == false && xlDevelop == false){
			  // PG:alerta
              this.alertModalRef = this.alertModalComp.open(AlertmodalComponent, { disableClose: true });
              this.alertService.isOpen = true;
              this.alertModalRef.afterClosed().subscribe(data=>{
                this.alertService.isOpen = false;
              });
            }
          }
          return throwError(er);
        })
      );
    }
    const token: string = this.authService.user.token;

    if (token) {
      const localHeaders = request.headers;
      localHeaders.set('Authorization', token);
      localHeaders.set('Username', this.authService.user.username);
      request = request.clone({
        headers: request.headers
          .set('Authorization', token)
          .set('Username', this.authService.user.username)
      });
    }
    return next.handle(request).pipe(
      catchError(er => {

        if (er.status === 401 || er.status === 0) {
          sessionStorage.clear();
          localStorage.removeItem('currentUser');
          localStorage.clear();
          this.authService.logout();
          window.location.reload();
          if(er.status === 0){
            if(this.alertService.isOpen == false && xlDevelop == false){
			  // PG:alerta
              this.alertModalRef = this.alertModalComp.open(AlertmodalComponent, { disableClose: true });
              this.alertService.isOpen = true;
              this.alertModalRef.afterClosed().subscribe(data=>{
                this.alertService.isOpen = false;
              });
            }
          }
        }
        if (er.status === 412) {
        }
        return throwError(er);
      })
    );
  }
}
