import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  private catalogsURL = `${environment.baseUrl}catalog/`;

  constructor(private http: HttpClient, private authService: AuthService, private messageService: MessageService) {
  }

    /** Log a ProjectService message with the MessageService */
  private log(message: string) {
      this.messageService.add(`CatalogsService: ${message}`);
  }

  getFocus(){
    return this.http.get<any[]>(this.catalogsURL+'types')
    .pipe(
        tap(_ => this.log('get countries')),
        catchError(this.handleError('get countries', []))
    );
  }

  getCountries(){
    return this.http.get<any[]>(this.catalogsURL+'countries')
    .pipe(
        tap(_ => this.log('get countries')),
        catchError(this.handleError('get countries', []))
    );
  }

  getProducers(){
    return this.http.get<any[]>(this.catalogsURL+'producers')
    .pipe(
        tap(_ => this.log('get producers')),
        catchError(this.handleError('get producers', []))
    );
  }

   /** GET projects from the server */
   getCatalogs(): Observable<any[]> {
    let user = this.authService.user;
    return this.http.get<any[]>(this.catalogsURL + '?language=' + user.prefLanguage)
        .pipe(
            tap(_ => this.log('fetched projects')),
            catchError(this.handleError('getProjects', []))
        );
    }

    searchCatalogs(searchTerm){
      return this.http.get<any[]>(this.catalogsURL + 'search/?text=' + searchTerm)
      .pipe(
          tap(_ => this.log('get catalogs')),
          catchError(this.handleError('get catalogs', []))
      );
    }

    advancedCatalogsSearch(ObjArray){
      const URL =  this.catalogsURL + 'search/advanced/';
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json',
          'Authorization': this.authService.user.token,
          'Username': this.authService.user.username})
      };

      return this.http.post<any[]>(URL, ObjArray, httpOptions)
      .pipe(
          tap(_ => this.log('fetched catalogues')),
          catchError(this.handleError('get catalogues', []))
      );
    }

 /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
    };
  }

}
