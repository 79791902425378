import { Component, OnInit } from '@angular/core';
import { AlbumService } from '../album.service';
import { AuthService } from '../auth/auth.service';
import { I18nGlvService } from '../i18n-glv.service';
import { environment } from '../../environments/environment';
import { PagerService } from '../pager.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { HomeinfoService } from '../homeinfo.service';
import { SaveStateService } from '../save-state.service';


@Component({
  selector: 'app-new-releases',
  templateUrl: './new-releases.component.html',
  styleUrls: ['./new-releases.component.scss']
})
export class NewReleasesComponent implements OnInit {

  constructor(private albumService: AlbumService, public i18n: I18nGlvService,
    public authService: AuthService, private pagerService: PagerService, public saveState: SaveStateService, 
    public router: Router, private dataService: DataService, private homeInfo: HomeinfoService) { }

  albums = [];
  loadingAlbuns = true;

  pagedAlbuns = [];
  AlbunsPager: any = {};
  AlbunsPage = 1;
  AlbunsPerPage = 10;

  ngOnInit() {
    this.getNewReleases();

	if (this.dataService.navFromNewReleases)
	{
		var doc = document.documentElement;
		doc.scrollTop = this.dataService.scrollTopNewReleases;
		this.dataService.navFromNewReleases=false;
	}  

  }

  getNewReleases() {
    const tempAlbuns = this.homeInfo.getNewReleases();
    if (tempAlbuns.length == 0) {
      this.albumService.getNewReleases().subscribe(data => {
        this.albums = data;
        this.albums.forEach(album => {
          album.thumb = environment.baseUrl + 'image/album/'
            + album.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_')
            + '/' + album.Reference.replace(new RegExp(' & ', 'g'), '').replace(new RegExp(' ', 'g'), '_')
            + '/?&token=' + this.authService.user.token + '&username=' + this.authService.user.username;
          album.Catalog = album.Catalog;
          album.reference = album.Reference;
        });
        this.loadingAlbuns = false;
        this.setAlbunsPage(1);
        this.homeInfo.updateNewReleases(this.albums);
      });
    } else {
      this.albums = tempAlbuns;
      this.loadingAlbuns = false;
      this.setAlbunsPage(1);
    }
  }

  setAlbunsPage(page) {
    if (isNaN(page)) {
      return;
    }
    if (page < 1) {
      page = 1;
    }
    if (page > this.AlbunsPager.totalPages) {
      page = this.AlbunsPager.totalPages;
    }
    // get pager object from service
    if (this.albums) {
      this.AlbunsPager = this.pagerService.getPager(this.albums.length, page, this.AlbunsPerPage);
    }

    // get current page of items
    if (this.albums) {
      this.pagedAlbuns = this.albums.slice(this.AlbunsPager.startIndex, this.AlbunsPager.endIndex + 1);
    }
    this.AlbunsPage = +this.AlbunsPager.currentPage; // + Cast String to Number
  }

  goToCatalog(album) {
    //this.saveState.removelastSearchCatalogs();
    //this.dataService.updateSelectedAlbum(album);

	this.dataService.navFromNewReleases=true;
	
	var doc = document.documentElement;
	var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
	var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
	this.dataService.scrollTopNewReleases=top;
	
    this.router.navigate(['/catalogos/'+album.Context1J+'/'+album.Context1K]);
  }

  parseInteger(string) {
    return parseInt(string, 10);
  }
}
