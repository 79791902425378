export class User {
  id: number;
  token: string;
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  yyyMetadataSortN: number;
  yyyMetadataAccsN: number;
  prefLanguage: string;
  preferences: any;
  level: number;
  Accs_MaxPlays: number;
  Accs_MaxDownloads: number;
  Accs_DownloadFromSearch: string;
  Accs_MusicSearchOnly: string;

  constructor() {
    this.firstName = 'in authentication...';
    this.lastName = '';
	this.yyyMetadataSortN = 0;
	this.yyyMetadataAccsN = 0;
    this.token = '';
    this.username = '';
    this.Accs_DownloadFromSearch = 'N';
    this.Accs_MaxPlays = 0;
    this.Accs_MaxDownloads = 0;
  }
}
