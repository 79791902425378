import { Component, OnInit } from '@angular/core';
import { VideoService } from '../video.service';
import { Video } from '../video';
import { I18nGlvService } from '../i18n-glv.service';
import { HomeinfoService } from '../homeinfo.service';

@Component({
  selector: 'app-home-highlights',
  templateUrl: './home-highlights.component.html',
  styleUrls: ['./home-highlights.component.scss']
})
export class HomeHighlightsComponent implements OnInit {

  videos: Video[];

  constructor(private videoService: VideoService, public i18n: I18nGlvService,
    private homeInfo: HomeinfoService) {
  }

  ngOnInit() {
    this.getVideos();
  }

  getVideos(): void {
    const tempVideos = this.homeInfo.getHomeVideos();
    if (tempVideos.length == 0) {
      this.videoService.getVideos().subscribe(videos => {
        const idioma = this.i18n.language;
        let arrvideos = [];
        switch (idioma) {
          case 'pt':
          case 'es':
          case 'en':
            arrvideos = videos[idioma].highlights;
            break;
          default:
            arrvideos = videos['en'].highlights;
            break;
        }
        const listVideos = [];
        for (let index = 0; index < arrvideos.length; index++) {
          const element = arrvideos[index];
          listVideos[index] = {
            'id': index,
            'title': element.name,
            'link': element.external_link,
            'image': element.file
          };
        }
        this.videos = listVideos;
        this.homeInfo.updateHomeVideos(this.videos);
      });
    } else {
      this.videos = tempVideos;
    }

  }

}
