import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveStateService {

  constructor() { }

  private findMusicResults = null;
  private findCatalogsResults = null;
  private findAlbumsResults = null;
  private initialGenres = null;
  private findMusicSFX = null;
  private findSfx = null;

  private findCatalogs = null;

  private findProjects = null;

  //save state PROJECTS
  setLastSearchChipsProjects(obj)
  {
    this.setSessionStorage('search-chips-Projects', obj);
  }
  getLastSearchChipsProjects()
  {
    return this.getSessionStorage('search-chips-Projects');
  }
  removeLastSearchChipsProjects()
  {
    sessionStorage.removeItem('search-chips-Projects');
  }
  getLastFindProjectsTab(): any {
    return this.findProjects;
  }
  setLastFindProjectsTab(valor): void {
    this.findProjects = valor;
  }


  // save-state CATALOGS
  getLastSearchChipsCatalogs(): any {
    return this.getSessionStorage('search-chips-Catalogs');
  }
  removelastSearchCatalogs(): any {
    sessionStorage.removeItem('search-chips-Catalogs');
  }
  setLastSearchChipsCatalogs(valor): void {
    this.setSessionStorage('search-chips-Catalogs', valor);
  }
  getLastFindCatalogsTab(): any {
    return this.findCatalogs;
  }
  setLastFindCatalogsTab(valor): void {
    this.findCatalogs = valor;
  }


 
  setLastUrlCatalog(url){
    this.setSessionStorage('lastUrlCatalog', url);
  }
  getLastUrlCatalog(){
    return this.getSessionStorage('lastUrlCatalog');
  }
  removeLastUrlCatalog() {
    sessionStorage.removeItem('lastUrlCatalog');
  }


  private findPlaylists = null;
  private chipsPlaylisTracks = null;
  // save-state PLAYLISTS
  getLastSearchChipsPlaylists(): any {
    return this.getSessionStorage('search-chips-Playlists');
  }
  removelastSearchPlaylists(): any {
    sessionStorage.removeItem('search-chips-Playlists');
  }
  setLastSearchChipsPlaylists(valor): void {
    this.setSessionStorage('search-chips-Playlists', valor);
  }
  getLastFindPlaylists(): any {
    return this.findPlaylists;
  }
  setLastFindPlaylists(valor): void {
    this.findPlaylists = valor;
  }
  setLastSearchPlaylistsTracks(obj){
    this.chipsPlaylisTracks = obj;
  }
  getLastSearchPlaylistsTracks(){
    return this.chipsPlaylisTracks;
  }


  // save-state SFX
  getLastSearchSFX(){
    return this.findSfx;
  }
  setLastSearchSFX(obj){
    this.findSfx = obj;
  }
  getLastSearchChipsSFX(): any {
    return this.getSessionStorage('search-chips-SFX');
  }
  removelastSearchSFX(): any {
    sessionStorage.removeItem('search-chips-SFX');
  }
  setLastSearchChipsSFX(valor): void {
    this.setSessionStorage('search-chips-SFX', valor);
  }   
  getLastFindSFX(): any {
    return this.findMusicSFX;
  }
  setLastFindSFX(valor): void {
    this.findMusicSFX = valor;
  }
  ///////

  saveInitialGenres(genres) {
    this.initialGenres = genres;
    // this.setSessionStorage('initialGenres', genres);
  }

  getInitialGenres() {
    return this.initialGenres;
    // return this.getSessionStorage('initialGenres');
  }

  getLastPageVisited(): any {
    return this.getSessionStorage('lastPage');
  }

  setLastPageVisited(valor): any {
    this.setSessionStorage('lastPage', valor);
  }

  getLastFindMusic(): any {
    // return this.getSessionStorage('findMusicResults');
    return this.findMusicResults;
  }

  setLastFindMusic(valor): void {
    // this.setSessionStorage('findMusicResults', valor);
    this.findMusicResults = valor;
  }

  getLastFindCatalogs(): any{
    // return this.getSessionStorage('findCatalogsResults');
    return this.findCatalogsResults;
  }

  setLastFindCatalogs(value) : void {
    // this.setSessionStorage('findCatalogsResults', value);
    this.findCatalogsResults = value;
  }

  getLastFindAlbuns(): any{
    // return this.getSessionStorage('findAlbumsResults');
    return this.findAlbumsResults;
  }

  setLastFindAlbuns(value) : void {
    // this.setSessionStorage('findAlbumsResults', value);
    this.findAlbumsResults = value;
  }

  getLastSearchChips(): any {
    return this.getSessionStorage('search-chips');
  }

  removelastSearch(): any {
    // sessionStorage.removeItem('findMusicResults');
    this.findMusicResults = null;
    sessionStorage.removeItem('search-chips');
    sessionStorage.removeItem('lastPage');
  }

  setLastSearchChips(valor): void {
    this.setSessionStorage('search-chips', valor);
  }

  private setSessionStorage(chave, valor): void {
    sessionStorage.setItem(chave, JSON.stringify(valor));
  }
  private getSessionStorage(chave): any {
    const value = sessionStorage.getItem(chave);
    return JSON.parse(value);
  }

  
  setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + ( 24 * 60 * 60 * 1000000000000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}


