import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Project } from './project';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projectsUrl = `${environment.baseUrl}project/`;
  private reportURL = `${environment.baseUrl}report/`;  // URL to web api
  private playlistsUrl = `${environment.baseUrl}playlist/`;  // URL to web api
  private freePlaylistsUrl = `${environment.baseUrl}playlist/free/`;  // URL to web api

  constructor(private http: HttpClient, private authService: AuthService, private messageService: MessageService) {
  }

  /** Log a ProjectService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`ProjectService: ${message}`);
  }


  getFreePlaylistTitlesNr(projectList) {
    return this.http.get<any[]>(environment.baseUrl + 'playlist/free?projectList=' + projectList)
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );

  }

  validateAlbumExistance(projectID, string) {
    return this.http.get<any>(environment.baseUrl + "album/validation/" + projectID + "/?contexts=" + string)
      .pipe(
        tap(_ => this.log('validate album')),
        catchError(this.handleError('validate album', []))
      );
  }

  checkName(combinedTitle, projectId = 0) {
    return this.http.get<any>(this.projectsUrl + 'exists/?text=' + combinedTitle + '&projectId=' + projectId)
      .pipe(
        tap(_ => this.log('checkName')),
        catchError(this.handleError('checkName', []))
      );
  }

  // delete albuns from project
  deleteAlbunsToProject(projectId, body) {
    return this.http.post(this.projectsUrl + projectId + '/album/delete', body).pipe(
      tap(_ => this.log('move tracks')),
      catchError(this.handleError('move tracks', []))
    );
  }

  // add albuns to project
  addAlbunsToProject(projectId, body) {
    return this.http.post(this.projectsUrl + projectId + '/album/albumlist', body).pipe(
      tap(_ => this.log('move tracks')),
      catchError(this.handleError('move tracks', []))
    );
  }
  //get usage report


  // GET ACESSS FROM PROJECT SHARED
  getAcessOfProject(id) {
    return this.http.get<any[]>(this.projectsUrl + 'shared/?projectID=' + id)
      .pipe(
        tap(_ => this.log('get projects by token')),
        catchError(this.handleError('get projects by token', []))
      );
  }

  // PROJECT SHARE
  shareProject(body) {
    return this.http.post(this.projectsUrl + 'share/', body).pipe(
      tap(_ => this.log('send project')),
      catchError(this.handleError('send Project', []))
    );
  }

  // GET PROJECT BY TOKEN
  getProjectByToken(token) {
	  // PG: projecto enviado por email
    return this.http.get<any[]>(this.projectsUrl + 'email/?projectToken=' + token)
      .pipe(
        tap(_ => this.log('get projects by token')),
        catchError(this.handleError('get projects by token', []))
      );
  }

  // GET PROJECTS SENT LOG
  getProjectSentLog(id) {
    return this.http.get<any[]>(this.projectsUrl + 'email/?projectId=' + id)
      .pipe(
        tap(_ => this.log('get projects log')),
        catchError(this.handleError('get projects log', []))
      );
  }

  // POST email with project
  sendEmailWithProject(data, send) {
    return this.http.post(this.projectsUrl + 'email/?send=' + send, data).pipe(
      tap(_ => this.log('send project')),
      catchError(this.handleError('send Project', []))
    );
  }

  // updateMusicUsageTime
  updateMusicUsageTime(projectId, trackId, duration): Observable<any[]> {
    const URL = this.projectsUrl + projectId + '/track/' + trackId + '/?duration=' + duration + '&token=' + this.authService.user.token;
    return this.http.post<any[]>(URL, httpOptions)
      .pipe(
        tap(_ => this.log('update music usage')),
        catchError(this.handleError('update music usage', []))
      );
  }

  setTrackToProject(data): Observable<any[]> {
    const URL = `${this.projectsUrl}track`;
    return this.http.post<any[]>(URL, data, httpOptions)
      .pipe(
        tap(_ => this.log('Add track to projects')),
        catchError(this.handleError('Add track to projects', []))
      );
  }

  setMultipleTracksToProject(body, projectId) {
    return this.http.post(this.projectsUrl + projectId + '/tracklist', body).pipe(
      tap(_ => this.log('move tracks')),
      catchError(this.handleError('move tracks', []))
    );
  }

  // SEARCH PROJECTS
  // searchProjects(userId, searchString, status, page, sort, direction) {
  searchProjects(search_obj){
    const URL = this.projectsUrl + 'paginated/';
    return this.http.post<any[]>(URL, search_obj, httpOptions)
    // return this.http.get<any[]>(URL)
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );
  }

  /** GET projects from the server */
  getProjects(): Observable<any[]> {
    let user = this.authService.user;
    return this.http.get<any[]>(this.projectsUrl + 'list')
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );
  }


  /** GET projects from the server */
  getplaylist(): Observable<any[]> {
    let user = this.authService.user;
    return this.http.get<any[]>(this.playlistsUrl + '?userId=' + user.id + '&status=0')
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );
  }
  /** GET projects from the server */
  getFreePlaylist(): Observable<any[]> {
    return this.http.get<any[]>(this.freePlaylistsUrl + '')
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );
  }

  /**GET project tracks */
  getProjectTracks(projectId, keywords = null): Observable<any[]> {
    let url = this.projectsUrl + projectId + '/track';
    if (keywords != null) {
      url += '?keywords='+keywords;
    }
    return this.http.get<any[]>(url)
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );
  }

  // POST edit project
  getPlaylistTracks(obj) {
    return this.http.post(this.playlistsUrl + 'tracks', obj).pipe(
      tap(_ => this.log('getPlaylistTracks')),
      catchError(this.handleError('getPlaylistTracks', []))
    );
  }
  

  /**GET project tracks */
  getFreePlaylistTacks(projectId): Observable<any[]> {
    return this.http.get<any[]>(this.freePlaylistsUrl + projectId + '/tracks')
      .pipe(
        tap(_ => this.log('fetched projects')),
        catchError(this.handleError('getProjects', []))
      );
  }

  // POST create project
  createProject(project) {
    return this.http.post(this.projectsUrl, project).pipe(
      tap(_ => this.log('create project')),
      catchError(this.handleError('createProject', []))
    );
  }
  
  editProject(project, id) {
	  
  return this.http.post(this.projectsUrl + id, project).pipe(
    tap(_ => {
		this.log('Project edited successfully')
	}),
    catchError(error => {
      throw error;
    })
  );
}

  // POST delete project
  deleteProject(projectId, userId) {
    return this.http.post(this.projectsUrl + projectId + '/delete/?userId=' + userId, '').pipe(
      tap(_ => this.log('delete project')),
      catchError(this.handleError('deleteProject', []))
    );
  }

  getProductionTypes() {
    return this.http.get<any[]>(this.projectsUrl + 'productionTypes')
      .pipe(
        tap(_ => this.log('get productionTypes')),
        catchError(this.handleError('getProductionTypes', []))
      );
  }

/*

  getProdTypesV2() {
    return this.http.get<any[]>(this.projectsUrl + 'ProdTypesV2')
      .pipe(
        tap(_ => this.log('get getProdTypesV2')),
        catchError(this.handleError('getProdTypesV2', []))
      );
  }

  getTerritorys() {
    return this.http.get<any[]>(this.projectsUrl + 'territorys')
      .pipe(
        tap(_ => this.log('get getTerritorys')),
        catchError(this.handleError('getgetTerritorys', []))
      );
  }

  getMedias() {
    return this.http.get<any[]>(this.projectsUrl + 'medias')
      .pipe(
        tap(_ => this.log('get getMedias')),
        catchError(this.handleError('getMedias', []))
      );
  }

  getLicensePeriods() {
    return this.http.get<any[]>(this.projectsUrl + 'licensePeriods')
      .pipe(
        tap(_ => this.log('get getLicensePeriods')),
        catchError(this.handleError('getLicensePeriods', []))
      );
  }

*/

  getmodal(psProjectsID) {
    return this.http.get<any[]>(this.projectsUrl + 'getmodal/'+psProjectsID)
      .pipe(
        tap(_ => this.log('getmodal')),
        catchError(this.handleError('getmodal', []))
      );
  }


  setmodal(psProjectsID,psModal) {
    return this.http.post<any[]>(this.projectsUrl + 'setmodal/'+psProjectsID+'_'+psModal, psModal).pipe(
      tap(_ => this.log('setmodal')),
      catchError(this.handleError('setmodal', []))
    );

  }


  // APAGA TRACK DO PROJECTO
  deleteTrackFromProject(tracksToDelete, projectID) {
    return this.http.post(this.projectsUrl + projectID + '/track/delete', tracksToDelete).pipe(
      tap(_ => this.log('delete project')),
      catchError(this.handleError('deleteProject', []))
    );
  }

  // APAGA TRACK DO PROJECTO
  deleteTrackFromProjectByMID(tracksToDelete, projectID) {
    return this.http.post(this.projectsUrl + projectID + '/track/delete2', tracksToDelete).pipe(
      tap(_ => this.log('delete project')),
      catchError(this.handleError('deleteProject', []))
    );
  }

  // ORDENACAO DE TRACKS
  ordenaTracks(ordenacao, projectId) {
    return this.http.post(this.projectsUrl + projectId + '/track/ordenate', ordenacao).pipe(
      tap(_ => this.log('ordenate tracks')),
      catchError(this.handleError('ordenate tracks', []))
    );
  }

  // MOVE TRACKS FROM PROJECT TO PROJECT
  moveTracksToProject(body, copy = false) {
    return this.http.post(this.projectsUrl + 'track/move', body).pipe(
      tap(_ => this.log('move tracks')),
      catchError(this.handleError('move tracks', []))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
