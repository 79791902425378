import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { I18nGlvService } from '../i18n-glv.service';
import { i18n } from '@angular/core/src/render3';
import { Router, Event, NavigationStart } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isResult: any;
  isAlbumSelected: any;
  currentYear: number;
  selectedLanguage: string;

  constructor(private dataService: DataService,
    private i18n: I18nGlvService, public router: Router) {
  }

  ngOnInit() {
    this.dataService.getIsResult().subscribe(data => {
      this.isResult = data;
    });
    this.dataService.getIsAlbumSelected().subscribe(data => {
      this.isAlbumSelected = data;
    });
    this.currentYear = new Date().getFullYear();
  }

  scrollTop() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    return false;
  }

  setLanguage(language) {
    this.i18n.language = language;
  }

}
