import { Component, OnInit, Input } from '@angular/core';
import { Track } from '../track';
import { TrustedStyleString } from '@angular/core/src/sanitization/bypass';
import { SearchService } from '../search.service';
import { AuthService } from '../auth/auth.service';
import { I18nGlvService } from '../i18n-glv.service';
import { environment } from '../../environments/environment';




@Component({
  selector: 'app-sub-list-musics',
  templateUrl: './sub-list-musics.component.html',
  styleUrls: ['./sub-list-musics.component.scss']
})
export class SubListMusicsComponent implements OnInit {

  constructor(private searchService: SearchService, public i18n: I18nGlvService, public authService: AuthService) { }

  @Input() track: any;
  @Input() action: string;
  musics = [];
  isPlayerExtras = [];
  isTrackHover = [];

  ngOnInit() {
    if (this.action === 'related') {
      this.loadrelated();
    }
  }
  loadrelated() {
    this.searchService.findMusicsRelated(this.track.metadataID).subscribe(data => {

      for (let id = 0; id < data.length; id++) {
        const musica = data[id];
        this.musics.push(
          {
            id: musica.MetaDataID,
            name: musica.TrackTitle,
            cover: environment.baseUrl + 'image/album/' + musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~')
              .replace(new RegExp(' ', 'g'), '_') + '/' + musica.Reference + '/?token=' +
              this.authService.user.token + '&username=' + this.authService.user.username,
            link: 'https://vimusicaapi.glove-it.pt/api/music/streaming/11340771/?token=' +
              this.authService.user.token + '&username=' + this.authService.user.username,
            duration: musica.TrackTime,
            reference: musica.Reference,
            track: musica.Track,
            isPlaying: false,
            catalog: musica.Catalog,
            titleId: musica.MusicSearch5ID,
            albumTitle: musica.AlbumTitle,
            authors: [musica.AuthorsComposers],
            label: '',
            publisher: musica.Publisher,
            genres:
              musica.Categories.Genres[this.i18n.language.toUpperCase()],
            mindStates:
              musica.Categories.Moods[this.i18n.language.toUpperCase()],
            instruments:
              musica.Categories.Instruments[this.i18n.language.toUpperCase()],
            bpm: musica.BPM,
            keyWords:
              musica.Categories.Keys[this.i18n.language.toUpperCase()],
            description: musica.Description,
            isloadToWave: false,
            tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
            times: musica.Categories.Times,
            keys: musica.KeyCode,
            metadataID: musica.MetaDataID,
            btVersao: (musica.btVersao === ' ') ? 0 : parseInt(musica.btVersao, 10),
            Relateds: musica.Relateds,
            Context1J: musica.Context1J,
            Context1K: musica.Context1K,
            Context1Q: musica.Context1Q,
            Categories: musica.Categories,
          }
        );
      }
    });
  }

  trackByFunction(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }
}
