import { Component, OnInit } from '@angular/core';
import { Catalog } from '../catalog';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user.service';
import { User } from '../user';
import { ClientService } from '../client.service';
import { Client } from '../client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../custom-validators';
import { I18nGlvService } from '../i18n-glv.service';
import { CatalogsService } from '../catalogs.service';
import { RegisterService } from '../register.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageDialogService } from "../message-dialog.service";

declare var $: any;


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  isDarkTheme = false;
  isTitleDescription = false;
  isSpain = false;

  target = [];
  source: any[];
  languageSelected: any;
  user = new User();
  model = new User();

  client = new Client();
  isSearchVersion1 = true;
  searchVersion: string;
  titleViews = [
    'Agrupados por Catálogo',
    'Agrupados por Album',
    'Agrupados por Album sem Catálogo',
    'Agrupados por Título',
    'Agrupados por Título sem Catálogo'
  ];
  titleViewSelected: any;
  projectTitleViews = [
    'Tempo + Título + Descrição',
    'Referência + Faixa + Tempo + Título + Descrição',
    'Catálogo + Referência + Faixa + Tempo + Título + Descrição'
  ];
  projectTitleViewSelected: any;
  playerSelected: any;
  isSingleTrackDownloadFormat = false;
  downloadFormats = [
    'MP3 LQ (zip)',
    'MP3 320 (zip)',
    'WAV 44 (zip)',
    'WAV 48 (zip)',
    'MP3 320 (zip-email)',
    'WAV 44 (zip-email)',
    'WAV 48 (zip-email)'
  ];
  downloadFormatSelected: any;
  auditionFormats = ['MP3 64', 'MP3 320'];
  auditionFormatSelected: any;
  businessTypes;
  businessTypeSelected: any;
  isBusinessOpen = false;
  userForm: FormGroup;
  newuserForm: FormGroup;


  // REFACTOR
  showLanguagesDDL = false;
  showSearchDDL = false;
  showBackgroundDDL = false;
  showTitleViewDDL = false;
  showTitleViewDDL2 = false;
  showPlayerDDL = false;
  pageResultsDDL = false;
  suggestionsDDL = false;
  showDescriptionsDLL = false;
  showTitleHeightDLL = false;
  showProjectHeightDDL = false;
  showDeclarationsFormatDDL = false;
  downloadOneTrackDDL = false;
  downloadPrefDDL = false;

  audiosDDL = false;

  showBelowDDL = false;

  languages = [];
  preferencesForm: FormGroup;

  selectedLanguage: any;
  selectedLanguagePosition = 0;

  selectedSearch: any;
  selectedSearchPosition = 0;
  resultsPriority = [
    {
      key: 1,
      label: {
        pt: 'Exact+PrefCat, Exact+OtherCat, Contains+PrefCat, Contais+OtherCat',
        es: 'Exact+PrefCat, Exact+OtherCat, Contains+PrefCat, Contais+OtherCat',
        en: 'Exact+PrefCat, Exact+OtherCat, Contains+PrefCat, Contais+OtherCat',
      }
    },
    {
      key: 2,
      label: {
        pt: 'PrefCat+Exact, PrefCat+Contains, OtherCat+Exact, OtherCat+Contains',
        es: 'PrefCat+Exact, PrefCat+Contains, OtherCat+Exact, OtherCat+Contains',
        en: 'PrefCat+Exact, PrefCat+Contains, OtherCat+Exact, OtherCat+Contains',
      }
    }
  ];

  selectedColor: any;
  selectedColorPosition = 0;
  backgroundColors = ['#FFFFFF', '#D8D3BF'];

  selectedTitleView: any;
  selectedTitleViewPosition = 0;
  titlesView = [];

  selectedTitleView2: any;
  selectedTitleView2Position = 0;
  titlesView2 = [];

  selectedPlayer: any;
  selectedPlayerPosition = 0;
  players = [
    {
      key: 1,
      label: {
        pt: 'Slim (IE, Safari-Win, Opera)',
        es: 'Slim (IE, Safari-Win, Opera)',
        en: 'Slim (IE, Safari-Win, Opera)'
      }
    },
    {
      key: 2,
      label: {
        pt: 'Waveform (FF, Chrome e Safari-Mac)',
        es: 'Waveform (FF, Chrome e Safari-Mac)',
        en: 'Waveform (FF, Chrome e Safari-Mac)'
      }
    },
    {
      key: 3,
      label: {
        pt: 'Waveform',
        es: 'Waveform',
        en: 'Waveform'
      }
    }
  ];

  // selectedResults : any;
  // selectedResultsPosition = 0;

  selectedPageResults: any;
  selectedPageResultsPosition = 0;
  pageResults = [10, 15, 20, 25, 30, 35, 40, 45, 50, 100];

  selectedSuggestions: any;
  selectedSuggestionsPosition = 0;
  maxSugestions = [20, 50, 100, 200];


  selectedShowDescription: any;
  selectedShowDescriptionPosition = 0;
  showDescriptions = [
    {
      key: 'S',
      label: {
        pt: 'SIM',
        es: 'SÍ',
        en: 'YES'
      }
    },
    {
      key: 'N',
      label: {
        pt: 'NÃO',
        es: 'NO',
        en: 'NO'
      }
    }
  ];

  selectedTitleHeight: any;
  selectedTitleHeightPosition = 0;
  titleHeights = [1, 3, 5];

  selectedProjectHeight: any;
  selectedProjectHeightPosition = 0;
  projectsTitleHeights = [1, 3];

  selectedDeclarationFormat: any;
  selectedDeclarationFormatPosition = 0;
  declarationFormats = ['XLS', 'TXT', ' '];

  selectedOneTrack: any;
  selectedOneTrackPosition = 0;
  oneTrackDownload = [
    {
      key: 'S',
      label: {
        pt: 'SIM',
        es: 'SÍ',
        en: 'YES'
      }
    },
    {
      key: 'N',
      label: {
        pt: 'NÃO',
        es: 'NO',
        en: 'NO'
      }
    }
  ]

  preferedDownload: any;
  preferedDownloadPosition = 0;
  preferedDownloads = [];

  selectedAudio: any;
  selectedAudioPosition = 0;
  audios = [];

  catalogs = [];
  catalogsNames = [];
  preferedCatalogs: any;

  previousSelectedCatalogs = [];
  infoLoaded = false;

  countriesDDL = false;
  activitiesDDL = false;
  selectedActivity = null;
  countries = [
    {
      name: 'Espanã',
      value: 'ES',
    },
    {
      name: 'Portugal',
      value: 'PT',
    },
  ];


  preferedBelow: any;
  preferedBelowPosition = 0;
  preferedBelows = [
    {
      name: this.i18n.getTranslationsBD('cataloginfoalbumprefonhover'),
      value: false
    },
    {
      name: this.i18n.getTranslationsBD('cataloginfoalbumprefbelow'),
      value: true
    }
  ];


  selectedCountry = 0;
  xlpleasewait = false;

  isUserLoginExists = false;
  isUserLoginExists2 = false;
  actualLogin = "";

  report = {
    userIsChecked: true,
    clientIsChecked: false,
    reportType: "user",
    startDate: null,
    endDate: null
  }

  constructor(public authService: AuthService,
    private userService: UserService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private catalogsService: CatalogsService,
    private registerService: RegisterService,
    public i18n: I18nGlvService, private router: Router,
	public messageDialog: MessageDialogService,
    private http: HttpClient) {
    this.user.preferences = [];
    this.searchVersion = '';

    this.userForm = this.userFormGroup();
    this.newuserForm = this.newuserFormGroup();

  }

  fCreateNewUser()
  // PG:
  {
    const currentUser = this.authService.getLocalStorage('currentUser');
    const data = {
      'CompanyName': this.userForm.value.company,
      'CommercialName': this.userForm.value.commercialName,
      'VAT': this.userForm.value.vat,
      'Address': this.userForm.value.address,
      'PostalCode': this.userForm.value.postalCode,
      'Country': this.client.country,
      'Email': this.userForm.value.emailClient,
      'PhoneNumber': this.userForm.value.phoneNumberClient,
      'WebSite': this.userForm.value.website,
      'ProdYear': this.userForm.value.productionsYear,
      'BusinessType': this.client.businessType,
      'BusinessTypeOther': this.userForm.value.businessTypeOther,
      'Users': [{
        'Id': currentUser.id,
        'Login': this.newuserForm.value.username2,
        'Password': this.newuserForm.value.password2,
        'FirstName': this.newuserForm.value.firstName2,
        'LastName': this.newuserForm.value.lastName2,
		'yyyMetadataSortN' : currentUser.yyyMetadataSortN,
		'yyyMetadataAccsN' : currentUser.yyyMetadataAccsN,
        'Email': this.newuserForm.value.email2,
        'PhoneNumber': this.newuserForm.value.phoneNumber2,
		'NewUser': true
      }]
    };
    this.clientService.updateClient(data, currentUser.clientId, currentUser.id).subscribe((result) => {
		alert(result);
		this.getUser();
		this.getUserPreferences();
		this.getClient();
		window.location.reload();
    });
  }

  checkWhatBelowPref(event) {
    event.stopPropagation();
    if (this.showBelowDDL) {
      setTimeout(() => {
        const idToGo = "below-" + this.preferedBelowPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#showBelowDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  iterateBelowPref(direction, event){
    event.stopPropagation();
    this.showBelowDDL = false;
    if (direction == 'up') {
      this.preferedBelowPosition--;
      if (this.preferedBelowPosition < 0) {
        this.preferedBelowPosition = this.preferedBelows.length - 1;
      }
    } else {
      this.preferedBelowPosition++;
      if (this.preferedBelowPosition > this.preferedBelows.length - 1) {
        this.preferedBelowPosition = 0;
      }
    }
    this.preferedBelow = this.preferedBelows[this.preferedBelowPosition];
    this.setBelowPref(this.preferedBelow, this.preferedBelowPosition, null);
  }

  setBelowPref(below, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      ShowAlbumInfoBelow: below['name'],
    });
    this.preferedBelow = below;
    this.preferedBelowPosition = i;
    this.showBelowDDL = false;
  }

  checkLogin() {
    let valueInserted = this.userForm.get('username').value;
    if (valueInserted == this.actualLogin) {
      this.isUserLoginExists = false;
    } else {
      let base = environment.baseUrl + 'user/login/validate?login=' + valueInserted;
      return this.http.get<any>(base)
        .subscribe(isValid => {
          if (valueInserted == this.actualLogin) {
            this.isUserLoginExists = false;
          } else {
            this.isUserLoginExists = isValid;
          }
        });
    }
  }

  checkLogin2() {
    let valueInserted = this.newuserForm.get('username2').value;
	  let base = environment.baseUrl + 'user/login/validate?login=' + valueInserted;
	  return this.http.get<any>(base)
		.subscribe(isValid => {
		  this.isUserLoginExists2 = isValid;
		});
  }
  
  fnewuserFormTouched() 
  {
	if (this.newuserForm.controls['username2'].touched) {return true;}
	if (this.newuserForm.controls['firstName2'].touched) {return true;}
	if (this.newuserForm.controls['lastName2'].touched) {return true;}
	if (this.newuserForm.controls['confirmPassword2'].touched) {return true;}
	if (this.newuserForm.controls['email2'].touched) {return true;}
	if (this.newuserForm.controls['phoneNumber2'].touched) {return true;}
	return false;
  }

  recebe($event) {
    this.preferedCatalogs = $event;
  }

  setCountry(country, index) {
    this.selectedCountry = index;
    this.countriesDDL = false;
    if (country.name == 'Espanã') {
      this.isSpain = true;
    } else {
      this.isSpain = false;
    }
  }

  iterateCountry(side, event) {
    event.stopPropagation();
    if (side == 'up') {
      this.selectedCountry--;
      if (this.selectedCountry < 0) {
        this.selectedCountry = 1;
      }
    } else {
      this.selectedCountry++;
      if (this.selectedCountry > 1) {
        this.selectedCountry = 0;
      }
    }
    if (this.countries[this.selectedCountry].name == 'Espanã') {
      this.isSpain = true;
    } else {
      this.isSpain = false;
    }
  }

  checkCountry() {
    if (this.countriesDDL) {
      setTimeout(() => {
        let idToGo = "";
        if (this.isSpain) {
          idToGo = "country-" + 0;
        } else {
          idToGo = "country-" + 1;
        }
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#countriesDDL-user').scrollTop(positionTop);
      }, 100);
    }
  }

  setActivity(businessType, i) {
    this.selectedActivity = i;
    this.activitiesDDL = false;
    this.businessTypeSelected = businessType;
  }

  iterateActivity(side, event) {
    event.stopPropagation();
    if (side == 'up') {
      this.selectedActivity--;
      if (this.selectedActivity < 0) {
        this.selectedActivity = 25;
      }
    } else {
      this.selectedActivity++;
      if (this.selectedActivity > 25) {
        this.selectedActivity = 0;
      }
    }
    this.businessTypeSelected = this.businessTypes[this.selectedActivity];
  }

  checkWhatActivity() {
    if (this.activitiesDDL) {
      setTimeout(() => {
        const idToGo = "activity-" + this.selectedActivity;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 790;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#activities-user').scrollTop(positionTop);
      }, 100);
    }
  }

  ngOnInit() {
    this.getLanguages();
    this.getTitleView();
    // this.getTitleView2();
    this.getPreferedDownload();
    this.getPreferedAudio();
    this.isMobileDevice();
    this.getCatalogs();
    this.getBusinessTypes();

    this.preferencesForm = this.formBuilder.group({
      Language: ['', [Validators.required]],
      Background: ['', [Validators.required]],
      Results: ['', [Validators.required]],
      Suggests: ['', [Validators.required]],
      TracksView: ['', [Validators.required]],
      ResultsPage: ['', [Validators.required]],
      EarFormat: ['', [Validators.required]],
      DeclarationFormat: ['', [Validators.required]],
      DownloadPref: ['', [Validators.required]],
      OneTrackDownload: ['', [Validators.required]],
      ShowAlbumInfoBelow: ['', [Validators.required]],
      // Player : [{value: '', disabled: true}],
      ConsultBegin: ['', [Validators.required]],
      ConsultEnd: ['', [Validators.required]],
      RelatorioFormat: ['', [Validators.required]],
      // TrackHeight :  [{value: '', disabled: true}],
      // ProjectTracks :  [{value: '', disabled: true}],
      // TrackDesc : [{value: '', disabled: true}],
      // ProjectHeight :  [{value: '', disabled: true}],
    });
	
	  if (this.authService.isLoggedIn && this.authService.user.username=='spainsh') {
		  // PG:
		  this.router.navigateByUrl("/");
		  return;
	  }

    this.model = this.authService.user;
  }

  getBusinessTypes() {
    this.registerService.getBusinessTypes().subscribe(result => {
      this.businessTypes = result;
      this.getClient();
    });
  }

  getCatalogs() {
    this.catalogsService.getCatalogs().subscribe(catalogs => {
      this.catalogs = catalogs;
      this.catalogsNames = [];
      this.catalogs.forEach(catalog => {
        this.catalogsNames.push(catalog.Catalog);
      });
      this.getUser();
      this.getUserPreferences();

    });
  }

  getLanguages() {
    this.userService.getLanguages().subscribe(languages => {
      this.languages = languages;
    });
  }

  getTitleView() {
    this.userService.getTitleView2().subscribe(titlesView => {
      this.titlesView = titlesView;
    });
  }

  // getTitleView2(){
  //     this.userService.getTitleView2().subscribe(titlesView2 => {
  //         this.titlesView2 = titlesView2;
  //     });
  // }

  getPreferedDownload() {
    this.userService.getPreferedDownload(this.authService.user.id).subscribe(downloads => {
      this.preferedDownloads = downloads;
    });
  }

  getPreferedAudio() {
    this.userService.getPreferedAudio().subscribe(audios => {
      this.audios = audios;
    });
  }

  userFormGroup(): FormGroup {
    return this.formBuilder.group(
      {
        company: [
          null,
          Validators.required
        ],
        commercialName: [
          null
        ],
        vat: [
          null,
          Validators.required
        ],
        address: [
          null,
          Validators.required
        ],
        postalCode: [
          null,
          Validators.required
        ],
        emailClient: [
          null,
          Validators.email
        ],
        phoneNumberClient: [
          null
        ],
        website: [
          null
        ],
        productionsYear: [
          null,
        ],
        businessTypeOther: [
          null
        ],
        firstName: [
          null,
          Validators.required
        ],
        lastName: [
          null,
          Validators.required
        ],
        username: [
          null,
          Validators.required
        ],
        password: [
          null,
          Validators.required
        ],
        confirmPassword: [
          null,
          Validators.required
        ],
        email: [
          null,
          Validators.compose([Validators.required, Validators.email])
        ],
        phoneNumber: [
          null,
          Validators.required
        ]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  newuserFormGroup(): FormGroup {
    return this.formBuilder.group(
      {
        username2: [
          null,
          Validators.required
        ],
        firstName2: [
          null,
          Validators.required
        ],
        lastName2: [
          null,
          Validators.required
        ],
        password2: [
          null,
          Validators.required
        ],
        confirmPassword2: [
          null,
          Validators.required
        ],
        email2: [
          null,
          Validators.compose([Validators.required, Validators.email])
        ],
        phoneNumber2: [
          null,
          Validators.required
        ]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator2
      }
    );
  }

  isMobileDevice() {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  getClient(): void {
    const currentUser = this.authService.getLocalStorage('currentUser');
    this.model = this.authService.getLocalStorage('currentUser');
    if (currentUser) {
      const id = currentUser.clientId;
      this.clientService.getClient(id).subscribe(client => {


        this.client.company = client.CompanyName;
        this.client.commercialName = client.CommercialName;
        this.client.vat = client.VAT;
        this.client.address = client.Address;
        this.client.postalCode = client.PostalCode;
        this.client.country = client.Country;
        this.client.email = client.Email;
        this.client.phoneNumber = client.PhoneNumber;
        this.client.website = client.WebSite;
        this.client.productionsYear = client.ProdYear;
        this.client.businessType = client.BusinessType;
        this.client.businessTypeOther = client.BusinessTypeOther;

        if (this.client.country === 'PT') {
          this.isSpain = false;
        } else {
          this.isSpain = true;
        }

        for (let index = 0; index < this.businessTypes.length; index++) {
          const element = this.businessTypes[index];
          if (element.BusinessTypeSimbol == client.BusinessType) {
            this.businessTypeSelected = element;
            this.selectedActivity = index;
            break;
          }
        }
        this.userForm.patchValue({
          company: this.client.company,
          commercialName: this.client.commercialName,
          vat: this.client.vat,
          address: this.client.address,
          postalCode: this.client.postalCode,
          emailClient: this.client.email,
          phoneNumberClient: this.client.phoneNumber,
          website: this.client.website,
          productionsYear: this.client.productionsYear,
          businessTypeOther: this.client.businessTypeOther
        });
      }
      );
    }
  }

  updateClient(): void {
    if (this.isSpain) {
      this.client.country = 'ES';
    } else {
      this.client.country = 'PT';
    }
    this.client.businessType = this.businessTypeSelected.BusinessTypeSimbol;
    const currentUser = this.authService.getLocalStorage('currentUser');
    // this.client.users = this.user;

    const data = {
      'CompanyName': this.userForm.value.company,
      'CommercialName': this.userForm.value.commercialName,
      'VAT': this.userForm.value.vat,
      'Address': this.userForm.value.address,
      'PostalCode': this.userForm.value.postalCode,
      'Country': this.client.country,
      'Email': this.userForm.value.emailClient,
      'PhoneNumber': this.userForm.value.phoneNumberClient,
      'WebSite': this.userForm.value.website,
      'ProdYear': this.userForm.value.productionsYear,
      'BusinessType': this.client.businessType,
      'BusinessTypeOther': this.userForm.value.businessTypeOther,
      'Users': [{
        'Id': currentUser.id,
        'Login': this.userForm.value.username,
        'Password': (this.userForm.value.password === '12345%Qq') ? null : this.userForm.value.password,
        'FirstName': this.userForm.value.firstName,
        'LastName': this.userForm.value.lastName,
		'yyyMetadataSortN' : currentUser.yyyMetadataSortN,
		'yyyMetadataAccsN' : currentUser.yyyMetadataAccsN,
        'Email': this.userForm.value.email,
        'PhoneNumber': this.userForm.value.phoneNumber,
		'NewUser': false
      }]
    };
    this.clientService.updateClient(data, currentUser.clientId, currentUser.id).subscribe((result) => {
		this.getUser();
		this.getUserPreferences();
		this.getClient();
		window.location.reload();
    });
  }

  getUser(): void {
    const currentUser = this.authService.getLocalStorage('currentUser');
    if (currentUser) {
      const id = currentUser.id;
      this.userService.getUser(id).subscribe(user => {
        this.actualLogin = user.Login;

        this.user.id = user.userId;
        this.user.firstName = user.FirstName;
        this.user.lastName = user.LastName;
		this.user.yyyMetadataAccsN  = user.yyyMetadataAccsN ;
		this.user.yyyMetadataSortN  = user.yyyMetadataSortN ;
        this.user.email = user.Email;
        this.user.phoneNumber = user.PhoneNumber;
        this.user.username = user.Login;
        this.user.password = '12345%Qq';
        this.user.confirmPassword = '12345%Qq';

        this.userForm.patchValue({
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          username: this.user.username,
          password: this.user.password,
          confirmPassword: this.user.confirmPassword,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber
        });
      }
      );
    }
  }

  fpleasewait(l) {
    if (l) {
      if (this.xlpleasewait) {
		this.messageDialog.openMessageDialog('please-wait');
      }	
    } else {
      this.xlpleasewait = false;
      this.messageDialog.closeMessageDialog();
    }
  }

  updateUserPreferences(): void {
    this.xlpleasewait = true;
	setTimeout(() => this.fpleasewait(true), 1000);
    const currentUser = this.authService.getLocalStorage('currentUser');
    let preferedCatalogsName = [];
    if (this.preferedCatalogs != undefined) {
      for (let i = 0; i < this.preferedCatalogs.length; i++) {
        preferedCatalogsName.push(this.preferedCatalogs[i]._name);
      }
    } else {
      preferedCatalogsName = this.previousSelectedCatalogs;
    }
    const data = {
      'userId': this.authService.user.id,
      'Pref_Language': this.selectedLanguage.Language,
      'Pref_SearchVersion': this.selectedSearch.key,
      'Pref_BodyBgColor': this.selectedColor,
      // 'Pref_TitlesView': this.selectedTitleView.tbTitlesView2ID,
      'Pref_TitlesView2': this.selectedTitleView.tbTitlesView2ID,
      'Pref_Player': '',
      'Pref_PageResults': this.selectedPageResults,
      'Pref_SearchMaxRecSug': this.selectedSuggestions,
      'Pref_SearchShowDescription': '',
      'Pref_SearchRowHeight': 5,
      'Pref_ProjectsRowHeight': '',
      'Pref_ZipUtilizationFiles': this.selectedDeclarationFormat == ' ' ? '' : this.selectedDeclarationFormat,
      'Pref_DownloadSingleTrackZip': this.selectedOneTrack.key,
      'Pref_DownloadFormat': this.preferedDownload.DownloadFormat,
      'Pref_AuditionFormat': this.selectedAudio.AuditionFormat,
      'Pref_Catalogs': preferedCatalogsName,
      'Pref_ShowAlbumInfoBelow': this.preferedBelow.value
    };
    this.userService.updateUserPreferences(data, currentUser.clientId, currentUser.id).subscribe(() => {
      // this.userService.getUserPreferences(currentUser.id).subscribe(user => {
      // });
      let newUser = this.authService.user;
      newUser['email']= this.authService.user.email;
      newUser['firstName']= this.authService.user.firstName;
      newUser['lastName']= this.authService.user.lastName;
      newUser['yyyMetadataAccsN ']= this.authService.user.yyyMetadataAccsN ;
      newUser['yyyMetadataSortN ']= this.authService.user.yyyMetadataSortN ;
      newUser['id']= this.authService.user.id;
      newUser['clientId']= this.authService.user.clientId;
      newUser['username']= this.authService.user.username;
      newUser['password']= this.authService.user.password;
      newUser['phoneNumber']= this.authService.user.phoneNumber;
      newUser['token']= this.authService.user.token;
      newUser['prefLanguage']= this.selectedLanguage.Language.toLowerCase();
      newUser['Pref_BodyBgColor']= this.selectedColor;
      newUser['level']= this.authService.user.level;
      newUser['Pref_PageResults']= this.selectedPageResults;
      newUser['Pref_DownloadSingleTrackZip']= this.selectedOneTrack.key;
      newUser['Pref_DownloadFormat']= this.preferedDownload.DownloadFormat;
      newUser['Accs_DownloadFromSearch']= this.authService.user.Accs_DownloadFromSearch;
      newUser['Accs_MaxPlays']= this.authService.user.Accs_MaxPlays;
      newUser['Accs_MaxDownloads']= this.authService.user.Accs_MaxDownloads;
      newUser['Accs_MusicSearchOnly']= this.authService.user.Accs_MusicSearchOnly;
      newUser['Pref_TitlesView2']= this.selectedTitleView.tbTitlesView2ID;
      newUser['Pref_ShowAlbumInfoBelow']= this.preferedBelow.value;
      this.authService.setLocalStorage('currentUser', newUser, 2879);
      this.i18n.updateLanguage(this.selectedLanguage.Language.toLowerCase());
      window.location.reload();
    });
  }

  // REFACTOR METHODS
  // language
  checkLanguage(lang) {
    for (let i = 0; i < this.languages.length; i++) {
      if (lang == this.languages[i].Language) {
        this.selectedLanguage = this.languages[i];
        this.selectedLanguagePosition = i;
        break;
      }
    }
    return this.selectedLanguage;
  }

  setLanguage(language, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      Language: language['Language' + this.i18n.language.toUpperCase()].toUpperCase(),
    });
    this.selectedLanguage = language;
    this.selectedLanguagePosition = i;
    this.showLanguagesDDL = false;
  }

  iterateLanguages(direction, event) {
    event.stopPropagation();
    this.showLanguagesDDL = false;
    if (direction == 'up') {
      this.selectedLanguagePosition--;
      if (this.selectedLanguagePosition < 0) {
        this.selectedLanguagePosition = this.languages.length - 1;
      }
    } else {
      this.selectedLanguagePosition++;
      if (this.selectedLanguagePosition > this.languages.length - 1) {
        this.selectedLanguagePosition = 0;
      }
    }
    this.selectedLanguage = this.languages[this.selectedLanguagePosition];
    this.setLanguage(this.selectedLanguage, this.selectedLanguagePosition, null);
  }

  checkWhatLanguageSelected(event) {
    event.stopPropagation();
    if (this.showLanguagesDDL) {
      setTimeout(() => {
        const idToGo = "language-" + this.selectedLanguagePosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#languageDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // search version
  checkSearch(search) {
    for (let i = 0; i < this.resultsPriority.length; i++) {
      if (search == this.resultsPriority[i].key) {
        this.selectedSearch = this.resultsPriority[i];
        this.selectedSearchPosition = i;
        break;
      }
    }
    return this.selectedSearch;
  }

  setSearch(search, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      Results: search.label[this.i18n.language],
    });
    this.selectedSearch = search;
    this.selectedSearchPosition = i;
    this.showSearchDDL = false;
  }

  iterateSearch(direction, event) {
    event.stopPropagation();
    this.showSearchDDL = false;
    if (direction == 'up') {
      this.selectedSearchPosition--;
      if (this.selectedSearchPosition < 0) {
        this.selectedSearchPosition = this.resultsPriority.length - 1;
      }
    } else {
      this.selectedSearchPosition++;
      if (this.selectedSearchPosition > this.resultsPriority.length - 1) {
        this.selectedSearchPosition = 0;
      }
    }
    this.selectedSearch = this.resultsPriority[this.selectedSearchPosition];
    this.setSearch(this.selectedSearch, this.selectedSearchPosition, null);

  }

  checkWhatSearchSelected(event) {
    event.stopPropagation();
    if (this.showSearchDDL) {
      setTimeout(() => {
        const idToGo = "search-" + this.selectedSearchPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#searchDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // background
  checkBackground(color) {
    for (let i = 0; i < this.backgroundColors.length; i++) {
      if (color == this.backgroundColors[i]) {
        this.selectedColor = this.backgroundColors[i];
        this.selectedColorPosition = i;
        break;
      }
    }
    return this.selectedColor;
  }

  setColor(color, i) {
    this.preferencesForm.patchValue({
      Background: '',
    });
    this.selectedColor = color;
    
  }

  iterateColors(direction, event) {
    event.stopPropagation();
    this.showBackgroundDDL = false;
    if (direction == 'up') {
      this.selectedColorPosition--;
      if (this.selectedColorPosition < 0) {
        this.selectedColorPosition = this.backgroundColors.length - 1;
      }
    } else {
      this.selectedColorPosition++;
      if (this.selectedColorPosition > this.backgroundColors.length - 1) {
        this.selectedColorPosition = 0;
      }
    }
    this.selectedColor = this.backgroundColors[this.selectedColorPosition];
    this.setColor(this.selectedColor, this.selectedColorPosition);
  }

  // titleview
  checkTitleView(title) {
    for (let i = 0; i < this.titlesView.length; i++) {
      if (title == this.titlesView[i].tbTitlesView2ID) {
        this.selectedTitleView = this.titlesView[i];
        this.selectedTitleViewPosition = i;
        break;
      }
    }
    return this.selectedTitleView;
  }

  setTitleView(title, i, event) {
    if(event!= null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      TracksView: title['TitlesView' + (this.i18n.language.charAt(0).toUpperCase() + this.i18n.language.charAt(1))],
    });
    this.selectedTitleView = title;
    this.selectedTitleViewPosition = i;
    this.showTitleViewDDL = false;
  }

  iterateTitleView(direction, event) {
    event.stopPropagation();
    this.showTitleViewDDL = false;
    if (direction == 'up') {
      this.selectedTitleViewPosition--;
      if (this.selectedTitleViewPosition < 0) {
        this.selectedTitleViewPosition = this.titlesView.length - 1;
      }
    } else {
      this.selectedTitleViewPosition++;
      if (this.selectedTitleViewPosition > this.titlesView.length - 1) {
        this.selectedTitleViewPosition = 0;
      }
    }
    this.selectedTitleView = this.titlesView[this.selectedTitleViewPosition];
    this.setTitleView(this.selectedTitleView, this.selectedTitleViewPosition, null);
  }

  checkWhatTitleViewSelected(event) {
    event.stopPropagation();
    if (this.showTitleViewDDL) {
      setTimeout(() => {
        const idToGo = "titleView-" + this.selectedTitleViewPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#titleViewDDL').scrollTop(positionTop);
      }, 100);
    }
  }


  // titleview2
  // checkTitleView2(title2){
  //     for(let i=0; i<this.titlesView2.length; i++){
  //         if(title2 == this.titlesView2[i].tbTitlesView2ID){
  //             this.selectedTitleView2 = this.titlesView2[i];
  //             this.selectedTitleView2Position = i;
  //             break;
  //         }
  //     }
  //     return this.selectedTitleView2;
  // }

  // setTitleView2(title, i){
  //     this.preferencesForm.patchValue({
  //         ProjectTracks : title['TitlesView' + (this.i18n.language.charAt(0).toUpperCase() + this.i18n.language.charAt(1))],
  //     });
  //     this.selectedTitleView2 = title;
  //     this.selectedTitleView2Position = i;
  //     this.showTitleViewDDL2 = false;
  // }

  // iterateTitleView2(direction, event){
  //     event.stopPropagation();
  //     this.showTitleViewDDL2 = false;
  //     if(direction == 'up'){
  //         this.selectedTitleView2Position--;
  //         if(this.selectedTitleView2Position < 0){
  //             this.selectedTitleView2Position = this.titlesView2.length -1;
  //         }
  //     }else{
  //         this.selectedTitleView2Position++;
  //         if(this.selectedTitleView2Position > this.titlesView2.length -1){
  //             this.selectedTitleView2Position = 0;
  //         }
  //     }
  //     this.selectedTitleView2 = this.titlesView2[this.selectedTitleView2Position];
  //     this.setTitleView2(this.selectedTitleView2, this.selectedTitleView2Position);
  // }

  // checkWhatTitleView2Selected(){
  //     if(this.showTitleViewDDL2){
  //         setTimeout(() => {
  //             const idToGo = "titleView2-"+this.selectedTitleView2Position;
  //             const offset = $('#' + idToGo).offset();
  //             if (offset === undefined) {
  //                 return;
  //             }
  //             const positionTop = $('#' + idToGo).offset().top - 285;
  //             $('#' + idToGo).css("background", "#676767");
  //             $('#' + idToGo+' h3').css("color", "#f2f2f2");
  //             $('#titleView2DDL').scrollTop(positionTop);
  //         }, 100);
  //     }
  // }

  // player
  // checkPlayer(player){
  //     for(let i=0; i<this.players.length; i++){
  //         if(player == this.players[i].key){
  //             this.selectedPlayer = this.players[i];
  //             this.selectedPlayerPosition = i;
  //             break;
  //         }
  //     }
  //     return this.selectedPlayer;
  // }

  // setPlayer(player, i){
  //     this.preferencesForm.patchValue({
  //         Player : player.label[this.i18n.language],
  //     });
  //     this.selectedPlayer = player;
  //     this.showPlayerDDL = false;
  //     this.selectedPlayerPosition = i;
  // }

  // iteratePlayer(direction, event){
  //     event.stopPropagation();
  //     this.showPlayerDDL = false;
  //     if(direction == 'up'){
  //         this.selectedPlayerPosition--;
  //         if(this.selectedPlayerPosition < 0){
  //             this.selectedPlayerPosition = this.players.length -1;
  //         }
  //     }else{
  //         this.selectedPlayerPosition++;
  //         if(this.selectedPlayerPosition > this.players.length -1){
  //             this.selectedPlayerPosition = 0;
  //         }
  //     }
  //     this.selectedPlayer = this.players[this.selectedPlayerPosition];
  //     this.setPlayer(this.selectedPlayer, this.selectedPlayerPosition);
  // }

  // checkWhatPlayerSelected(){
  //     if(this.showPlayerDDL){
  //         setTimeout(() => {
  //             const idToGo = "player-"+this.selectedPlayerPosition;
  //             const offset = $('#' + idToGo).offset();
  //             if (offset === undefined) {
  //                 return;
  //             }
  //             const positionTop = $('#' + idToGo).offset().top - 285;
  //             $('#' + idToGo).css("background", "#676767");
  //             $('#' + idToGo+' h3').css("color", "#f2f2f2");
  //             $('#playerDDL').scrollTop(positionTop);
  //         }, 100);
  //     }
  // }

  // page Results
  checkPageResults(number) {
    for (let i = 0; i < this.pageResults.length; i++) {
      if (number == this.pageResults[i]) {
        this.selectedPageResults = this.pageResults[i];
        this.selectedPageResultsPosition = i;
        break;
      }
    }
    return this.selectedPageResults;
  }

  setPageResults(number, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      ResultsPage: number,
    });
    this.selectedPageResults = number;
    this.selectedPageResultsPosition = i;
    this.pageResultsDDL = false;
  }

  iteratePageResults(direction, event) {
    event.stopPropagation();
    this.pageResultsDDL = false;
    if (direction == 'up') {
      this.selectedPageResultsPosition--;
      if (this.selectedPageResultsPosition < 0) {
        this.selectedPageResultsPosition = this.pageResults.length - 1;
      }
    } else {
      this.selectedPageResultsPosition++;
      if (this.selectedPageResultsPosition > this.pageResults.length - 1) {
        this.selectedPageResultsPosition = 0;
      }
    }
    this.selectedPageResults = this.pageResults[this.selectedPageResultsPosition];
    this.setPageResults(this.selectedPageResults, this.selectedPageResultsPosition, null);
  }

  checkWhatPageResultsSelected(event) {
    event.stopPropagation();
    if (this.pageResultsDDL) {
      setTimeout(() => {
        const idToGo = "pageResult-" + this.selectedPageResultsPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#resultsDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // suggestions
  checkSuggestions(number) {
    for (let i = 0; i < this.maxSugestions.length; i++) {
      if (number == this.maxSugestions[i]) {
        this.selectedSuggestions = this.maxSugestions[i];
        this.selectedSuggestionsPosition = i;
        break;
      }
    }
    return this.selectedSuggestions;
  }

  setSuggestions(number, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      Suggests: number,
    });
    this.selectedSuggestions = number;
    this.suggestionsDDL = false;
    this.selectedSuggestionsPosition = i;
  }

  iterateSuggestions(direction, event) {
    event.stopPropagation();
    this.suggestionsDDL = false;
    if (direction == 'up') {
      this.selectedSuggestionsPosition--;
      if (this.selectedSuggestionsPosition < 0) {
        this.selectedSuggestionsPosition = this.maxSugestions.length - 1;
      }
    } else {
      this.selectedSuggestionsPosition++;
      if (this.selectedSuggestionsPosition > this.maxSugestions.length - 1) {
        this.selectedSuggestionsPosition = 0;
      }
    }
    this.selectedSuggestions = this.maxSugestions[this.selectedSuggestionsPosition];
    this.setSuggestions(this.selectedSuggestions, this.selectedSuggestionsPosition, null);
  }

  checkWhatSuggestionsSelected(event) {
    event.stopPropagation();
    if (this.suggestionsDDL) {
      setTimeout(() => {
        const idToGo = "suggestion-" + this.selectedSuggestionsPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#suggestionsDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // show descriptions
  // checkShowDescriptions(show){
  //     for(let i=0; i<this.showDescriptions.length; i++){
  //         if(show == this.showDescriptions[i].key){
  //             this.selectedShowDescription = this.showDescriptions[i];
  //             this.selectedShowDescriptionPosition = i;
  //             break;
  //         }
  //     }
  //     return this.selectedShowDescription;
  // }

  // setDescriptions(show, i){
  //     this.preferencesForm.patchValue({
  //         TrackDesc : show.label[this.i18n.language],
  //     });
  //     this.selectedShowDescription = show;
  //     this.showDescriptionsDLL = false;
  //     this.selectedShowDescriptionPosition = i;
  // }

  // iterateShowDescriptions(direction, event){
  //     event.stopPropagation();
  //     this.suggestionsDDL = false;
  //     if(direction == 'up'){
  //         this.selectedShowDescriptionPosition--;
  //         if(this.selectedShowDescriptionPosition < 0){
  //             this.selectedShowDescriptionPosition = this.showDescriptions.length -1;
  //         }
  //     }else{
  //         this.selectedShowDescriptionPosition++;
  //         if(this.selectedShowDescriptionPosition > this.showDescriptions.length -1){
  //             this.selectedShowDescriptionPosition = 0;
  //         }
  //     }
  //     this.selectedShowDescription = this.showDescriptions[this.selectedShowDescriptionPosition];
  //     this.setDescriptions(this.selectedShowDescription, this.selectedShowDescriptionPosition);
  // }

  // checkWhatDescriptionsSelected(){
  //     if(this.showDescriptionsDLL){
  //         setTimeout(() => {
  //             const idToGo = "descriptions-"+this.selectedShowDescriptionPosition;
  //             const offset = $('#' + idToGo).offset();
  //             if (offset === undefined) {
  //                 return;
  //             }
  //             const positionTop = $('#' + idToGo).offset().top - 285;
  //             $('#' + idToGo).css("background", "#676767");
  //             $('#' + idToGo+' h3').css("color", "#f2f2f2");
  //             $('#showDescriptionsDLL').scrollTop(positionTop);
  //         }, 100);
  //     }
  // }

  // titleHeight
  // checkTitleHeight(height){
  //     for(let i=0; i< this.titleHeights.length; i++){
  //         if(height == this.titleHeights[i]){
  //             this.selectedTitleHeight = this.titleHeights[i];
  //             this.selectedTitleHeightPosition = i;
  //             break;
  //         }
  //     }
  //     return this.selectedTitleHeight;
  // }

  // setTitleHeight(number, i){
  //     this.preferencesForm.patchValue({
  //         TrackHeight : number,
  //     });
  //     this.selectedTitleHeight = number;
  //     this.showTitleHeightDLL = false;
  //     this.selectedTitleHeightPosition = i;
  // }

  // iterateTitleHeights(direction, event){
  //     event.stopPropagation();
  //     this.showTitleHeightDLL = false;
  //     if(direction == 'up'){
  //         this.selectedTitleHeightPosition--;
  //         if(this.selectedTitleHeightPosition < 0){
  //             this.selectedTitleHeightPosition = this.titleHeights.length -1;
  //         }
  //     }else{
  //         this.selectedTitleHeightPosition++;
  //         if(this.selectedTitleHeightPosition > this.titleHeights.length -1){
  //             this.selectedTitleHeightPosition = 0;
  //         }
  //     }
  //     this.selectedTitleHeight = this.titleHeights[this.selectedTitleHeightPosition];
  //     this.setTitleHeight(this.selectedTitleHeight, this.selectedTitleHeightPosition);
  // }

  // checkWhatTitleHeight(){
  //     if(this.showTitleHeightDLL){
  //         setTimeout(() => {
  //             const idToGo = "titleheights-"+this.selectedTitleHeightPosition;
  //             const offset = $('#' + idToGo).offset();
  //             if (offset === undefined) {
  //                 return;
  //             }
  //             const positionTop = $('#' + idToGo).offset().top - 285;
  //             $('#' + idToGo).css("background", "#676767");
  //             $('#' + idToGo+' h3').css("color", "#f2f2f2");
  //             $('#showTitleHeightDLL').scrollTop(positionTop);
  //         }, 100);
  //     }
  // }

  // project height
  // checkProjectHeight(height){
  //     for(let i=0; i< this.projectsTitleHeights.length; i++){
  //         if(height == this.projectsTitleHeights[i]){
  //             this.selectedProjectHeight = this.projectsTitleHeights[i];
  //             this.selectedProjectHeightPosition = i;
  //             break;
  //         }
  //     }
  //     return this.selectedProjectHeight;
  // }

  // setProjectTitleHeight(number, i){
  //     this.preferencesForm.patchValue({
  //         ProjectHeight : number,
  //     });
  //     this.selectedProjectHeight = number;
  //     this.showProjectHeightDDL = false;
  //     this.selectedProjectHeightPosition = i;
  // }

  // iterateProjectTitleHeights(direction, event){
  //     event.stopPropagation();
  //     this.showProjectHeightDDL = false;
  //     if(direction == 'up'){
  //         this.selectedProjectHeightPosition--;
  //         if(this.selectedProjectHeightPosition < 0){
  //             this.selectedProjectHeightPosition = this.projectsTitleHeights.length -1;
  //         }
  //     }else{
  //         this.selectedProjectHeightPosition++;
  //         if(this.selectedProjectHeightPosition > this.projectsTitleHeights.length -1){
  //             this.selectedProjectHeightPosition = 0;
  //         }
  //     }
  //     this.selectedProjectHeight = this.projectsTitleHeights[this.selectedProjectHeightPosition];
  //     this.setProjectTitleHeight(this.selectedProjectHeight, this.selectedProjectHeightPosition);
  // }

  // checkWhatProjectHeight(){
  //     if(this.showProjectHeightDDL){
  //         setTimeout(() => {
  //             const idToGo = "projectheights-"+this.selectedProjectHeightPosition;
  //             const offset = $('#' + idToGo).offset();
  //             if (offset === undefined) {
  //                 return;
  //             }
  //             const positionTop = $('#' + idToGo).offset().top - 285;
  //             $('#' + idToGo).css("background", "#676767");
  //             $('#' + idToGo+' h3').css("color", "#f2f2f2");
  //             $('#showProjectHeightDDL').scrollTop(positionTop);
  //         }, 100);
  //     }
  // }

  // declaration format
  checkDeclarationFormat(format) {
    for (let i = 0; i < this.declarationFormats.length; i++) {
      if (format != null && format.trim() != '') {
        if (format == this.declarationFormats[i]) {
          this.selectedDeclarationFormat = this.declarationFormats[i];
          this.selectedDeclarationFormatPosition = i;
          break;
        }
      } else {
        this.selectedDeclarationFormat = this.declarationFormats[2];
        this.selectedDeclarationFormatPosition = 2;
        break;
      }

    }
    if (this.selectedDeclarationFormat == undefined) {
      this.selectedDeclarationFormat = this.declarationFormats[0];
      this.selectedDeclarationFormatPosition = 0;

    }
    return this.selectedDeclarationFormat;
  }

  setDeclarationFormat(format, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      DeclarationFormat: format,
    });
    this.selectedDeclarationFormat = format;
    this.showDeclarationsFormatDDL = false;
    this.selectedDeclarationFormatPosition = i;
  }

  iterateDeclarationFormats(direction, event) {
    event.stopPropagation();
    this.showDeclarationsFormatDDL = false;
    if (direction == 'up') {
      this.selectedDeclarationFormatPosition--;
      if (this.selectedDeclarationFormatPosition < 0) {
        this.selectedDeclarationFormatPosition = this.declarationFormats.length - 1;
      }
    } else {
      this.selectedDeclarationFormatPosition++;
      if (this.selectedDeclarationFormatPosition > this.declarationFormats.length - 1) {
        this.selectedDeclarationFormatPosition = 0;
      }
    }
    this.selectedDeclarationFormat = this.declarationFormats[this.selectedDeclarationFormatPosition];
    this.setDeclarationFormat(this.selectedDeclarationFormat, this.selectedDeclarationFormatPosition, null);
  }

  checkWhatDeclaration(event) {
    event.stopPropagation();
    if (this.showDeclarationsFormatDDL) {
      setTimeout(() => {
        const idToGo = "declaration-" + this.selectedDeclarationFormatPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#showDeclarationsFormatDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // oneTrackdownload
  checkOneTrackFormat(format) {
    for (let i = 0; i < this.oneTrackDownload.length; i++) {
      if (format == this.oneTrackDownload[i].key) {
        this.selectedOneTrack = this.oneTrackDownload[i];
        this.selectedOneTrackPosition = i;
        break;
      }
    }
    return this.selectedOneTrack;
  }

  setOneTrackDownload(format, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      OneTrackDownload: format.label[this.i18n.language],
    });
    this.selectedOneTrack = format;
    this.downloadOneTrackDDL = false;
    this.selectedOneTrackPosition = i;
  }

  iterateOneTrackDownload(direction, event) {
    event.stopPropagation();
    this.downloadOneTrackDDL = false;
    if (direction == 'up') {
      this.selectedOneTrackPosition--;
      if (this.selectedOneTrackPosition < 0) {
        this.selectedOneTrackPosition = this.oneTrackDownload.length - 1;
      }
    } else {
      this.selectedOneTrackPosition++;
      if (this.selectedOneTrackPosition > this.oneTrackDownload.length - 1) {
        this.selectedOneTrackPosition = 0;
      }
    }
    this.selectedOneTrack = this.oneTrackDownload[this.selectedOneTrackPosition];
    this.setOneTrackDownload(this.selectedOneTrack, this.selectedOneTrackPosition, null);
  }


  checkWhatOneTrack(event) {
    event.stopPropagation();
    if (this.downloadOneTrackDDL) {
      setTimeout(() => {
        const idToGo = "downloadOneTrack-" + this.selectedOneTrackPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#downloadOneTrackDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // check downloadFormat
  checkDownloadFormat(format) {
    for (let i = 0; i < this.preferedDownloads.length; i++) {
      if (format == this.preferedDownloads[i].DownloadFormat) {
        this.preferedDownload = this.preferedDownloads[i];
        this.preferedDownloadPosition = i;
        break;
      }
    }
    return this.preferedDownload;
  }

  setDownloadPref(format, i, event) {
    if(event != null){
      event.stopPropagation();
    }
    this.preferencesForm.patchValue({
      DownloadPref: format['DownloadFormat' + this.i18n.language.toUpperCase()],
    });
    this.preferedDownload = format;
    this.preferedDownloadPosition = i;
    this.downloadPrefDDL = false;
  }

  iterateDownloadPref(direction, event) {
    event.stopPropagation();
    this.downloadPrefDDL = false;
    if (direction == 'up') {
      this.preferedDownloadPosition--;
      if (this.preferedDownloadPosition < 0) {
        this.preferedDownloadPosition = this.preferedDownloads.length - 1;
      }
    } else {
      this.preferedDownloadPosition++;
      if (this.preferedDownloadPosition > this.preferedDownloads.length - 1) {
        this.preferedDownloadPosition = 0;
      }
    }
    this.preferedDownload = this.preferedDownloads[this.preferedDownloadPosition];
    this.setDownloadPref(this.preferedDownload, this.preferedDownloadPosition, null);
  }

  checkWhatDownloadPref(event) {
    event.stopPropagation();
    if (this.downloadPrefDDL) {
      setTimeout(() => {
        const idToGo = "downloadPref-" + this.preferedDownloadPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#downloadPrefDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  // audio format
  checkAudioFormat(audio) {
    for (let i = 0; i < this.audios.length; i++) {
      if (audio == this.audios[i].AuditionFormat) {
        this.selectedAudio = this.audios[i];
        this.selectedAudioPosition = i;
        break;
      }
    }
    return this.selectedAudio;
  }

  setaudioPref(format, i) {
    this.preferencesForm.patchValue({
      DownloadPref: format['DownloadFormat' + this.i18n.language.toUpperCase()],
    });
    this.selectedAudio = format;
  }

  setAudioFormat(format, i, event) {
    if(event != null){
      event.stopPropagation();

    }
    this.preferencesForm.patchValue({
      EarFormat: format.AuditionFormat,
    });
    this.selectedAudio = format;
    this.audiosDDL = false;
    this.selectedAudioPosition = i;
  }

  iterateaudioFormats(direction, event) {
    event.stopPropagation();
    this.audiosDDL = false;
    if (direction == 'up') {
      this.selectedAudioPosition--;
      if (this.selectedAudioPosition < 0) {
        this.selectedAudioPosition = this.audios.length - 1;
      }
    } else {
      this.selectedAudioPosition++;
      if (this.selectedAudioPosition > this.audios.length - 1) {
        this.selectedAudioPosition = 0;
      }
    }
    this.selectedAudio = this.audios[this.selectedAudioPosition];
    this.setAudioFormat(this.selectedAudio, this.selectedAudioPosition, null);
  }

  checkWhatAudition(event) {
    event.stopPropagation();
    if (this.audiosDDL) {
      setTimeout(() => {
        const idToGo = "audition-" + this.selectedAudioPosition;
        const offset = $('#' + idToGo).offset();
        if (offset === undefined) {
          return;
        }
        const positionTop = $('#' + idToGo).offset().top - 285;
        $('#' + idToGo).css("background", "#676767");
        $('#' + idToGo + ' h3').css("color", "#f2f2f2");
        $('#audiosDDL').scrollTop(positionTop);
      }, 100);
    }
  }

  getUserPreferences(): void {
    const currentUser = this.authService.getLocalStorage('currentUser');
    if (currentUser) {
      const id = currentUser.id;
      this.userService.getUserPreferences(id).subscribe(user => {
        const language = this.checkLanguage(user.Pref_Language);
        const searchVersion = this.checkSearch(user.Pref_SearchVersion);
        const color = this.checkBackground(user.Pref_BodyBgColor);
        const titleView = this.checkTitleView(user.Pref_TitlesView2);
        // const titleView2 = this.checkTitleView2(user.Pref_TitlesView2);
        // const player = this.checkPlayer(user.Pref_Player);
        const pageResults = this.checkPageResults(user.Pref_PageResults);
        const suggestions = this.checkSuggestions(user.Pref_SearchMaxRecSug);
        // const showDescription = this.checkShowDescriptions(user.Pref_SearchShowDescription);
        // const titleHeight = this.checkTitleHeight(user.Pref_SearchRowHeight);
        // const projectHeight = this.checkProjectHeight(user.Pref_ProjectsRowHeight);
        const declarationFormat = this.checkDeclarationFormat(user.Pref_ZipUtilizationFiles);
        const oneTrakcFormat = this.checkOneTrackFormat(user.Pref_DownloadSingleTrackZip);
        const downlaodFormat = this.checkDownloadFormat(user.Pref_DownloadFormat);
        const audioForamt = this.checkAudioFormat(user.Pref_AuditionFormat);
        // const belowPref = user.Pref_ShowAlbumInfoBelow ? this.preferedBelows[1] : this.preferedBelows[0];
        this.preferedBelow = user.Pref_ShowAlbumInfoBelow ? this.preferedBelows[1] : this.preferedBelows[0];
        this.setBelowPref(this.preferedBelow, this.preferedBelow ? 1 : 0, null);

        // resultsPriority
		
		if (!searchVersion) {
			// quando existe problemas com o preenchimento da tabela tbUtilizadores para o utilizador logado:
			alert('error 141');
		}
		
        this.preferencesForm.patchValue({
          Language: language['Language' + this.i18n.language.toUpperCase()].toUpperCase(),
          Results: searchVersion.label[this.i18n.language],
          Suggests: suggestions,
          TracksView: titleView['TitlesView' + (this.i18n.language.charAt(0).toUpperCase() + this.i18n.language.charAt(1))],
          ResultsPage: pageResults,
          EarFormat: audioForamt.AuditionFormat,
          DeclarationFormat: declarationFormat,
          DownloadPref: downlaodFormat['DownloadFormat' + this.i18n.language.toUpperCase()],
          OneTrackDownload: oneTrakcFormat.label[this.i18n.language],
          // ProjectTracks: titleView2['TitlesView' + (this.i18n.language.charAt(0).toUpperCase() + this.i18n.language.charAt(1))],
          // Player: player.label[this.i18n.language],
          // TrackDesc : showDescription.label[this.i18n.language],
          // TrackHeight: titleHeight,
          // ProjectHeight: projectHeight,
          ShowAlbumInfoBelow: this.preferedBelow['name']
        });
        this.previousSelectedCatalogs = user.Pref_Catalogs;
        for (let i = this.catalogsNames.length - 1; i >= 0; i--) {
          if (this.previousSelectedCatalogs.includes(this.catalogsNames[i])) {
            this.catalogsNames.splice(i, 1);
          }
        }
        this.infoLoaded = true;

      }
      );
    }
  }

  checkIfActive(id) {
    const element = document.getElementById(id) as HTMLLinkElement;
    if (element.classList.contains('active')) {
      return this.authService.user.Pref_BodyBgColor;
    }
    return 'transparent';
  }

  setReportCheckbox(reportType = "user") {
    if (reportType === "user") {
      this.report.userIsChecked = true;
      this.report.clientIsChecked = false;
    } else {
      this.report.userIsChecked = false;
      this.report.clientIsChecked = true;
    }
    this.report.reportType = reportType;
  }

  setReportDatetime(type, date) {
    if (type === "start") {
      this.report.startDate = date;
    } else {
      this.report.endDate = date;
    }
  }

  getReportDocument(reportExtension) {
    let day = this.report.startDate._i.date.toString().padStart(2, '0');
    let month = (this.report.startDate._i.month + 1).toString().padStart(2, '0');
    let year = this.report.startDate._i.year.toString().padStart(4, '0');
    let startDate = `${day}-${month}-${year}`;

    day = this.report.endDate._i.date.toString().padStart(2, '0');
    month = (this.report.endDate._i.month + 1).toString().padStart(2, '0');
    year = this.report.endDate._i.year.toString().padStart(4, '0');
    let endDate = `${day}-${month}-${year}`;

    const url = environment.baseUrl + 'report/' + this.report.reportType + '/' + reportExtension + '/?startDate=' + startDate + '&endDate=' + endDate + '&token=' + this.authService.user.token;
    let a = document.createElement("a");
    a.setAttribute("type", "hidden");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    a.remove();
    // setTimeout(() => {
    //     this.navbar.justGetProjects();
    // }, 500);
  }


  // GETTERS
  get Language() {
    return this.preferencesForm.get('Language');
  }
  get Results() {
    return this.preferencesForm.get('Results');
  }
  get TracksView() {
    return this.preferencesForm.get('TracksView');
  }
  get Player() {
    return this.preferencesForm.get('Player');
  }
  get Suggests() {
    return this.preferencesForm.get('Suggests');
  }
  get TrackHeight() {
    return this.preferencesForm.get('TrackHeight');
  }
  get DeclarationFormat() {
    return this.preferencesForm.get('DeclarationFormat');
  }
  get DownloadPref() {
    return this.preferencesForm.get('DownloadPref');
  }
  get RelatorioFormat() {
    return this.preferencesForm.get('RelatorioFormat');
  }
  get ConsultBegin() {
    return this.preferencesForm.get('ConsultBegin');
  }
  get Background() {
    return this.preferencesForm.get('Background');
  }
  get ProjectTracks() {
    return this.preferencesForm.get('ProjectTracks');
  }
  get ResultsPage() {
    return this.preferencesForm.get('ResultsPage');
  }
  get TrackDesc() {
    return this.preferencesForm.get('TrackDesc');
  }
  get ProjectHeight() {
    return this.preferencesForm.get('ProjectHeight');
  }
  get OneTrackDownload() {
    return this.preferencesForm.get('OneTrackDownload');
  }
  get EarFormat() {
    return this.preferencesForm.get('EarFormat');
  }
  get ConsultEnd() {
    return this.preferencesForm.get('ConsultEnd');
  }
}

