import { Component, OnInit, ElementRef , ViewChild, HostListener} from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { I18nGlvService } from '../i18n-glv.service';
import { SfxService } from '../sfx.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SearchService } from '../search.service';
import { MusicService } from '../music.service';
import { PagerService } from '../pager.service';
import { User } from '../user';
import { DataService } from '../data.service';
import { AlbumService } from '../album.service';
import * as moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ProjectListExistanceModalComponent } from '../project-list-existance-modal/project-list-existance-modal.component';
import { ProjectService } from '../project.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { DownloadFilesService } from "../download-files.service";
import { SaveStateService } from '../save-state.service';
import { UserService } from '../user.service';
import { DeleteTrackFromProjectComponent } from '../delete-track-from-project/delete-track-from-project.component';
import { PlayerHistoryService } from '../player-history.service';

declare var $: any;

@Component({
  providers: [NavbarComponent],
  selector: 'app-sfx',
  templateUrl: './sfx.component.html',
  styleUrls: ['./sfx.component.scss']
})
export class SfxComponent implements OnInit {
  @ViewChild('pageInput') pageInput: ElementRef;

  // search
  chips = [];
  isChipPlus = true;
  lastFocusInput = "row-one";
  isAddRemoveLine = false;
  isAdd = false;
  isSecondAdd = false;
  genres = [];
  tabOpen = null;
  countClicksOutTabs = 1;
  genresSearchString = "";
  temposSearchString = "";
  cataloguesSearchString = "";

  isResult = false;
  isSpeechBubbleOpen = true;
  visibleVideo = 0;
  slidesPlaylists = [
    "vimusica_banner_1.mp4"
  ];
  categories = [];
  tabCols: number;

  albuns :any;
  loadAlbunsSFX =true;
  
  findMusicaSubscribeCansel = null;
  loadingTracks = false;

  searchMode = null;
  sfxCatalogs : any;

  catalogsSearchResult: any;
  albunsSearchResult: any;
  pager: any = {};
  tracks = [];
  showMenuMobile= [];
  model = new User();
  showTrackInfo = [];
  showTrackRelatedOrSimilar = [];
  showTrackSimilar = [];
  showAlbumTracks = [];
  selectedProjectsTracks: any;
  isTrackHover = [];
  countTracksSelected = 0;

  pageValue : number;
  itensPerPage = null;
  indexGlobal = null;

  firstTimeSearchingTerm = false;
  value: number;
  selectedTrack = null;
  wasDBL = false;
  selectedProject: any;
  modalOpen = false;
  prevXhrRequest = null;
  searchParmeters = [];
  downloadFormats=[];

  loopOptions = null;

  selectedVideo = 0;
  prefDownloadLabel = "";
  minBPMone = null;
  maxBPMone = null;
  minDURone = null;
  maxDURone = null;

  placeholder = null;
  placeholderRequestSubscriber = null;

  deleteTrackDialog: MatDialogRef<DeleteTrackFromProjectComponent>;

  projectListExistanceModalComponent: MatDialogRef<ProjectListExistanceModalComponent>;

  //ADVANCED SEARCH
  moreSearchDropdownItemsLeft = [
    {
      label: this.i18n.getTranslationsBD('not').toUpperCase(),
      value:'Not',
    },
    {
      label: this.i18n.getTranslationsBD('and').toUpperCase(),
      value:'And',
    },
    {
      label: this.i18n.getTranslationsBD('or').toUpperCase(),
      value:'Or',
    }
  ];
  moreSearchDropdownItemLeft = this.moreSearchDropdownItemsLeft[0];

  secondMoreSearchDropdownItemsLeft = [
    {
      label: this.i18n.getTranslationsBD('not').toUpperCase(),
      value:'Not',
    },
    {
      label: this.i18n.getTranslationsBD('and').toUpperCase(),
      value:'And',
    },
    {
      label: this.i18n.getTranslationsBD('or').toUpperCase(),
      value:'Or',
    }
  ];
  secondMoreSearchDropdownItemLeft = this.secondMoreSearchDropdownItemsLeft[0];

  moreChips = [];
  secondaryInputs = [];
  secondMoreChips = [];
  secondaryInputsMore = [];
  secondaryInputsSecondMore = [];

  
  constructor(public i18n: I18nGlvService, public authService: AuthService, 
              private searchService: SearchService, public sfxService: SfxService,
              private musicService: MusicService, private dataService: DataService,
              private albumService: AlbumService, private pagerService: PagerService,
              public ProjectListExistanceModalComponent: MatDialog, private navbar: NavbarComponent,
              private projectService: ProjectService, public downloadService: DownloadFilesService,
              public saveState: SaveStateService, public router: Router,
              private userService: UserService, public deleteTrackFromProjectComponent: MatDialog,     public playerHistory: PlayerHistoryService,
              ) { }

  ngOnInit() {
    this.getSFXcatalog();
    this.isResult = false;
    this.dataService.updateIsResult(this.isResult);
    this.getSFXalbuns();
    this.model = this.authService.user;
    this.selectedProject = this.dataService.getSelectedProject();
    this.selectedProjectsTracks = this.dataService.selectedProjectsTracks();

    this.dataService.getPageSFX().subscribe(page => {
      let newPage : number = page;
      const targetPage = this.pager.currentPage + newPage;
      this.setPage(targetPage ,(newPage == -1 ? 'previous' : 'next'));
    });
    this.getUserDownlaodFormats();
    
    //check save state
    this.itensPerPage = this.authService.user.Pref_PageResults;
    if(this.saveState.getLastSearchChipsSFX() != undefined && this.saveState.getLastSearchChipsSFX() != null){
      let last_save_state = this.saveState.getLastSearchChipsSFX();
      this.chips = last_save_state[0].chips;
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
      if(this.saveState.getLastFindSFX() == undefined || this.saveState.getLastFindSFX() == null){
        this.search();
      } else {
        if(last_save_state.length > 1){
          this.isAdd = true;
          this.isAddRemoveLine = true;
          this.moreChips = last_save_state[1].chips;
          this.secondaryInputsMore = [];
          this.moreChips.forEach((chip, id) => {
            this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
          });
          for (let it = 0; it < this.moreSearchDropdownItemsLeft.length; it++) {
            const element = this.moreSearchDropdownItemsLeft[it];
            if(element.value == last_save_state[1].operador){
              this.moreSearchDropdownItemLeft = element;
              break;
            }
          }
        }
        if(last_save_state.length > 2){
          this.isSecondAdd = true;
          this.secondMoreChips = last_save_state[2].chips;
          this.secondaryInputsSecondMore = [];
          this.secondMoreChips.forEach((chip, id) => {
            this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
          });
          this.secondMoreSearchDropdownItemLeft = last_save_state[2].operador;
          for (let it = 0; it < this.secondMoreSearchDropdownItemsLeft.length; it++) {
            const element = this.secondMoreSearchDropdownItemsLeft[it];
            if(element.value == last_save_state[2].operador){
              this.secondMoreSearchDropdownItemLeft = element;
              break;
            }
          }
        }

        this.isResult = true;
        this.dataService.updateIsResult(this.isResult);
        this.tracks = this.saveState.getLastFindSFX()['musics'];
        this.pager = this.saveState.getLastFindSFX()['pager'];
        this.searchMode = this.saveState.getLastFindSFX()['searchMode'];
        this.value = this.saveState.getLastFindSFX()['page'];
        this.updateMusics();
        this.getCategories();                                   
      }
    } else {
      this.getPlaceholder(true);
    }
  }

  getUserDownlaodFormats() {
    if(this.dataService.getSFXDownloadFormats() == undefined){
      this.userService.getSFXPreferedDownload(this.authService.user.id).subscribe(downloads => {
        this.dataService.updateSFXDownloadFormats(downloads);
        this.downloadFormats = this.dataService.getSFXDownloadFormats();
        this.downloadFormats.forEach(format => {
          if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
            this.prefDownloadLabel = format.DownloadFormatEN;
            return;
          }
        });
      });
    } else {
      this.downloadFormats = this.dataService.getSFXDownloadFormats();
      this.downloadFormats.forEach(format => {
        if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
          this.prefDownloadLabel = format.DownloadFormatEN;
          return;
        }
      });
    }
  }

  // track.hasOwnProperty('IsSFX') && track.IsSFX
  
  globalKeys(event) { 
    event.stopPropagation();
    if (event.code == 'Space') {
      const focused = document.activeElement;
      if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
        event.preventDefault();
      }
    }
    if(this.authService.isLoggedIn && this.router.url.includes('sfx') && event.key == 'Enter'){
      this.checkIfSearchPlaceholder('#row-one');
      this.search();
    }
  }

  checkIfSearchPlaceholder(selector){
    const text = $(selector).val();
    if(this.chips != undefined && this.chips.length > 0 ||
       text != undefined && text.trim() != "" || 
       this.minBPMone != null ||
       this.maxBPMone != null ||
       this.minDURone != null ||
       this.maxDURone != null
       ){
      return;
    }
    this.placeholder['Keywords'+this.i18n.language.toUpperCase()].split(" ").forEach(text => {
      this.addChip(text, null);
    });
  }

  getSFXcatalog(){  
    this.sfxService.getSFXcatalog().subscribe(catalogs =>{
      this.sfxCatalogs = catalogs;
      this.getCategories();
    });
  }

  selectNextVideo(target){
    $("#mySlider").css("transform", "translateX("+(-100*target )+"%)");
    this.selectedVideo = target;
    this.slidesPlaylists.forEach((tempVideo, it) => {
      var video = document.getElementById("video_"+it) as HTMLVideoElement;
      video.pause();  
      video.currentTime = 0;
    });
    var video = document.getElementById("video_"+this.selectedVideo) as HTMLVideoElement;
    video.play();
  }

  skipBackward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event !=undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipBackward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime - 10;
      if (this.tracks[index].currentTime < 0) {
        this.tracks[index].currentTime = 0;
      }
      const obj = {
        track: this.tracks[index],
        i: index,
        tracks: this.tracks,
        from: 'sfx',
        search: this.saveState.getLastSearchSFX()
      };

      this.dataService.updateTrackSelected(obj);
    }
    this.indexGlobal = index;
  }

  skipForward($event, index){
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event !=undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipForward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime - 10;
      if (this.tracks[index].currentTime < 0) {
        this.tracks[index].currentTime = 0;
      }
      const obj = {
        track: this.tracks[index],
        i: index,
        tracks: this.tracks,
        from: 'sfx',
        search: this.saveState.getLastSearchSFX()
      };

      this.dataService.updateTrackSelected(obj);
    }
    this.indexGlobal = index;
  }

  playPrev($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event !=undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    const positionAbsolute = ((this.pager.currentPage - 1) * this.pager.pageSize) + index;
    if (positionAbsolute === 0 && this.pageValue == 1) {
      return;
    }
    if (index === 0) {
      this.setPage(this.pager.currentPage - 1, 'previous');
    } else {
      const prev = (index - 1) % this.itensPerPage;
      const obj = {
        track: this.tracks[prev],
        i: prev,
        tracks: this.tracks,
        from: 'sfx',
        search: this.saveState.getLastSearchSFX()
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = prev;
      this.tracks[prev].isPlaying = true;
    }
  }

  playNext($event, index){
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index);
      return;
    }
    if($event !=undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    const positionAbsolute = ((this.pager.currentPage - 1) * this.pager.pageSize) + index;
    if (positionAbsolute === this.tracks.length-1 && this.pageValue == this.pager.totalPages) {
      return;
    }
    if (index === this.tracks.length-1) {
      this.setPage(this.pager.currentPage + 1, 'next');
    } else {
      const next = (index + 1) % this.tracks.length;
      const obj = {
        track: this.tracks[next],
        i: next,
        tracks: this.tracks,
        from: 'sfx',
        search: this.saveState.getLastSearchSFX()
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = next;
      this.tracks[next].isPlaying = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkOpenDDLs();
  }

  checkOpenDDLs(){
    if (window.matchMedia('(min-width: 320px)').matches && !window.matchMedia('(min-width: 640px)').matches) {
      $('.show').removeClass('show');
    } if (window.matchMedia('(min-width: 640px)').matches) {
      this.showMenuMobile = [];
    }
  }

  removeTracksFromProject(track, event, i) {
    if(event != null){
      event.stopPropagation();
    }
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    let tracksToDelete = [];
    if (track != null) {
      const obj = {
        Context1J: track.Context1J,
        Context1K: track.Context1K,
        Track: track.Track,
        MetaDataID: track.id
      }
      tracksToDelete.push(obj);
    } else {
      for (let i = 0; i < this.playerHistory.selectedSongsSFX.length; i++) {
        if (this.playerHistory.selectedSongsSFX[i].isTrackSelected) {
          const obj = {
            Context1J: this.playerHistory.selectedSongsSFX[i].Context1J,
            Context1K: this.playerHistory.selectedSongsSFX[i].Context1K,
            Track: this.playerHistory.selectedSongsSFX[i]['Track'],
            MetaDataID: this.playerHistory.selectedSongsSFX[i].id
          };
          tracksToDelete.push(obj);
        }
      }
    }
    this.selectedProject = this.dataService.getSelectedProject();
    this.modalOpen = true;
    this.deleteTrackDialog = this.deleteTrackFromProjectComponent.open(DeleteTrackFromProjectComponent, { disableClose: true });
    let instance = this.deleteTrackDialog.componentInstance;
    instance.projectID = this.selectedProject.ProjectsID;
    instance.tracksToDelete = tracksToDelete;
    this.deleteTrackDialog.afterClosed().subscribe((result) => {
      this.navbar.justGetProjects();
      this.modalOpen = false;
    });
  }

  // add album to project
  addAlbumToProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    let tracksToAdd = [];
    const stringValidate = track.Context1J + "_" + track.Context1K;
    const obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
    };
    tracksToAdd.push(obj);
    this.selectedProject = this.dataService.getSelectedProject();
    this.projectService.validateAlbumExistance(this.selectedProject.ProjectsID, stringValidate).subscribe(data => {
      const errorList = data.ErrorList;
      if (errorList != undefined) {
        if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
          this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
          let instance = this.projectListExistanceModalComponent.componentInstance;
          instance.projectList = data['MusicInOtherProjectsList'];
          this.dataService.updateModalOpen(true);
          this.modalOpen = true;
          this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
            this.dataService.updateModalOpen(false);
            this.modalOpen = false;
            if (result['action'] == 'continue') {
              this.projectService.addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd).subscribe(data => {
                this.navbar.justGetProjects();
              });
            }
          });
        } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
          this.projectService.addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd).subscribe(data => {
            this.navbar.justGetProjects();
          });
        }
        else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      } else {
        if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
      }
    });
  }

  // delete Album from project
  deleteAlbumFromProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    const tracksToDelete = [];
    const obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
    };
    tracksToDelete.push(obj);
    this.selectedProject = this.dataService.getSelectedProject();
    this.projectService.deleteAlbunsToProject(this.selectedProject.ProjectsID, tracksToDelete).subscribe(data => {
      this.navbar.justGetProjects();
    });
  }

  // add multiple tracks To selected project,  playlist track results
  addMultipleTracksToSelectedProject(event) {
    if(event!=null){
      event.stopPropagation();
    }

    let musicList = "";
    const tracksToAdd = [];
    for (let idx = 0; idx < this.playerHistory.selectedSongsSFX.length; idx++) {
      if (this.playerHistory.selectedSongsSFX[idx].isTrackSelected) {
        const obj = {
          Context1J: this.playerHistory.selectedSongsSFX[idx].Context1J,
          Context1K: this.playerHistory.selectedSongsSFX[idx].Context1K,
          Track: this.playerHistory.selectedSongsSFX[idx].track,
          TrackTitle: this.playerHistory.selectedSongsSFX[idx].name,
          TrackTime: this.playerHistory.selectedSongsSFX[idx].duration,
          Description: this.playerHistory.selectedSongsSFX[idx].description,
          isTrackSelected: true,
          metadataID: this.playerHistory.selectedSongsSFX[idx].id
        };
        tracksToAdd.push(obj);
        musicList += this.playerHistory.selectedSongsSFX[idx].id + ",";
      }
    }
    musicList = musicList.substring(0, musicList.length - 1);
    const type = this.authService.user.Pref_DownloadFormat;
    this.selectedProject = this.dataService.getSelectedProject();
    const projectId = this.selectedProject.ProjectsID;

    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest =
      this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {

        const errorList = data.ErrorList;
        const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
            this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
            let instance = this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data['MusicInOtherProjectsList'];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
              this.dataService.updateModalOpen(false);
              this.modalOpen = false;
              if (result['action'] == 'continue') {
                this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
                  this.navbar.justGetProjects();
                });
              }
            });
          }
          else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
            this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProject.ProjectsID).subscribe(result => {
              this.navbar.justGetProjects();
            });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });
  } 

  addAlbumsToProject() {
    const tracksToAdd = [];
    let stringValidate = "";
    for (let id = 0; id < this.playerHistory.selectedSongsSFX.length; id++) {
      const track = this.playerHistory.selectedSongsSFX[id];
      if (track.isTrackSelected) {
        const obj = {
          Context1J: track.Context1J,
          Context1K: track.Context1K,
        };
        tracksToAdd.push(obj);
        stringValidate += track.Context1J + "_" + track.Context1K + ",";
      }
    }
    stringValidate = stringValidate.substring(0, stringValidate.length - 1);
    this.selectedProject = this.dataService.getSelectedProject();
    this.projectService.validateAlbumExistance(this.selectedProject.ProjectsID, stringValidate).subscribe(data => {
      const errorList = data.ErrorList;
      if (errorList != undefined) {
        if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
          this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
          let instance = this.projectListExistanceModalComponent.componentInstance;
          instance.projectList = data['MusicInOtherProjectsList'];
          this.dataService.updateModalOpen(true);
          this.modalOpen = true;
          this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
            this.dataService.updateModalOpen(false);
            this.modalOpen = false;
            if (result['action'] == 'continue') {
              this.projectService.addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd).subscribe(data => {
                this.navbar.justGetProjects();
              });
            }
          });
        } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
          this.projectService.addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd).subscribe(data => {
            this.navbar.justGetProjects();
          });
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      } else {
        if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
      }
    });
  }

   // delete albuns from project
   deleteAlbumsFromProject() {
    const tracksToDelete = [];
    for (let id = 0; id < this.playerHistory.selectedSongsSFX.length; id++) {
      const track = this.playerHistory.selectedSongsSFX[id];
      if (track.isTrackSelected) {
        const obj = {
          Context1J: track.Context1J,
          Context1K: track.Context1K,
        };
        tracksToDelete.push(obj);
      }
    }
    this.selectedProject = this.dataService.getSelectedProject();
    this.projectService.deleteAlbunsToProject(this.selectedProject.ProjectsID, tracksToDelete).subscribe(data => {
      this.navbar.justGetProjects();
    });
  }
  

  addChip(chip, position): void {
    if (typeof chip === 'string') {
      if (chip.includes(' ')) {
        const chipstoAdd = chip.split(' ');
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty('name')) {
      _chip = chip;
      chip = chip.name;
    }
    chip = chip.trim();
    if (!chip || this.chips.length > 5) {
      return;
    }
    if (chip.charAt(0) === '-') {
      this.isChipPlus = false;
      chip = chip.substr(1);
    } else {
      this.isChipPlus = true;
    }

    chip = chip.replace('_', ' ');

    const result = {
      'name': chip,
      'isChipPlus': this.isChipPlus
    };
    if (_chip !== null && _chip.hasOwnProperty('name')) {
      result['context'] = _chip.context;
    }
    if (position == null) {
      this.chips.push(result);
    } else {
      this.chips.splice(position, 0, result);
    }

    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
    });

  }

  updateChipValue(id, chip){
    event.preventDefault();
    const texto = $("#" + id).text();
    chip.name = texto;
  }

  stopPropagation(event, index) {
    event.stopPropagation();
    if(event.ctrlKey && index != null){
      this.selectTrack(null, index);
      return;
    }
  }

  // checkIfDeleteChip(chip) {
  //   if (chip.name.length == 0) {
  //     this.deleteChip(chip);
  //   }
  // }


  // checkIfDeleteChipFromSearchBar() {
  //   const el = document.getElementById('row-one') as HTMLInputElement;
  //   const value = el.value;
  //   if (value.length == 0) {
  //     if (this.chips.length > 0) {
  //       const tempChip = this.chips[this.chips.length - 1];
  //       const text = tempChip.name + " ";
  //       this.deleteChip(tempChip);
  //       el.value = text;
  //     }
  //   }
  // }

  checkWhereToAddChip(genre, position) {
    // if (this.lastFocusInput == 'row-one') {
      const context = genre.context;
      let alreadyExists = false;
      this.chips.forEach(chip => {
        if (chip.context == context) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addChip(genre, position);
      }
    // } else if (this.lastFocusInput == 'row-two') {
    //   const context = genre.context;
    //   let alreadyExists = false;
    //   this.chips.forEach(chip => {
    //     if (chip.context == context) {
    //       alreadyExists = true;
    //       return;
    //     }
    //   });
    //   if (!alreadyExists) {
    //     this.addMoreChip(genre, position);
    //   }
    // } else if (this.lastFocusInput == 'row-three') {
    //   const context = genre.context;
    //   let alreadyExists = false;
    //   this.chips.forEach(chip => {
    //     if (chip.context == context) {
    //       alreadyExists = true;
    //       return;
    //     }
    //   });
    //   if (!alreadyExists) {
    //     this.addSecondMoreChip(genre, position);
    //   }
    // }
  }

  searchSFXAlbum(album){
    const context = album.K;
    let alreadyExists = false;
    this.chips.forEach(chip => {
      if (chip.context == context) {
        alreadyExists = true;
        return;
      }
    });
    if (!alreadyExists) {
      var obj = {
        name: album.name,
        context: album.K
      };
      this.addChip(obj, null);
      this.search();
    }
  }

  mountSearchObj(){
    let rowOneElement: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
    const rowOneInput: string = rowOneElement.value;
    if (rowOneInput.replace(/\s+/, '').length !== 0) {
      this.addChip(rowOneInput, null);
      rowOneElement.value = '';
      rowOneElement.focus();
    }
    const normalizedchips = []; 
    const normalizedContext = [];
    for (const key in this.chips) {
      if (this.chips.hasOwnProperty(key)) {
        const chip = this.chips[key];
        if (chip.hasOwnProperty('context') && chip.isChipPlus) {
          normalizedContext.push(chip.context);
          continue;
        }
        if (chip.isChipPlus) {
          normalizedchips.push(chip.name);
        } else {
          normalizedchips.push('-' + chip.name);
        }
      }
    }
    if (normalizedchips.length === 0  && normalizedContext.length === 0 
        && (this.minBPMone == null || this.minBPMone.trim() == '')
        && (this.maxBPMone == null || this.maxBPMone.trim() == '')
        && (this.minDURone == null || this.minDURone.trim() == '')
        && (this.maxDURone == null || this.maxDURone.trim() == '')){
        return;
    }
    
    let obj = {
      text: normalizedchips.join(','),
      context: normalizedContext.join(','),
      searchLine: 1
    };
    let obj2;
    let obj3;
    if (this.minBPMone !== null && this.minBPMone.trim() != '') {
      obj['bpmMin'] = this.minBPMone;
    }
    if (this.maxBPMone !== null && this.maxBPMone.trim() != '') {
      obj['bpmMax'] = this.maxBPMone;
    }
    if (this.minDURone !== null && this.minDURone.trim() != '') {
      obj['durationMin'] = this.minDURone;
    }
    if (this.maxDURone !== null && this.maxDURone.trim() != '') {
      obj['durationMax'] = this.maxDURone;
    }

    if (this.isAddRemoveLine && this.isAdd) {
      const rowTwoElement: HTMLInputElement = document.getElementById('row-two') as HTMLInputElement;
      const rowTwoInput: string = rowTwoElement.value;
      if (rowTwoInput.replace(/\s+/, '').length !== 0) {
        this.addMoreChip(rowTwoInput, null);
        rowTwoElement.value = '';
        rowTwoElement.focus();
      }
      const normalizedchips2 = [];
      const normalizedContext2 = [];
      for (const key in this.moreChips) {
        if (this.moreChips.hasOwnProperty(key)) {
          const chip = this.moreChips[key];
          if (chip.hasOwnProperty('context') && chip.isChipPlus) {
            normalizedContext2.push(chip.context);
            continue;
          }
          if (chip.isChipPlus) {
            normalizedchips2.push(chip.name);
          } else {
            normalizedchips2.push('-' + chip.name);
          }
        }
      }
      obj2 = {
        text: normalizedchips2.join(','),
        context: normalizedContext2.join(','),
        searchLine: 2,
        searchOperator: this.moreSearchDropdownItemLeft.value
      };
    }
    if (this.isAddRemoveLine && this.isAdd && this.isSecondAdd) {
      const rowThreeElement: HTMLInputElement = document.getElementById('row-three') as HTMLInputElement;
      const rowThreeInput: string = rowThreeElement.value;
      if (rowThreeInput.replace(/\s+/, '').length !== 0) {
        this.addSecondMoreChip(rowThreeInput, null);
        rowThreeElement.value = '';
        rowThreeElement.focus();
      }

      const normalizedchips3 = [];
      const normalizedContext3 = [];
      for (const key in this.secondMoreChips) {
        if (this.secondMoreChips.hasOwnProperty(key)) {
          const chip = this.secondMoreChips[key];
          if (chip.hasOwnProperty('context') && chip.isChipPlus) {
            normalizedContext3.push(chip.context);
            continue;
          }
          if (chip.isChipPlus) {
            normalizedchips3.push(chip.name);
          } else {
            normalizedchips3.push('-' + chip.name);
          }
        }
      }
      obj3 = {
        text: normalizedchips3.join(','),
        context: normalizedContext3.join(','),
        searchLine: 3,
        searchOperator: this.secondMoreSearchDropdownItemLeft.value
      };
    }
   
    let objArray = [];
    objArray.push(obj);
    if(obj2 != undefined){
      objArray.push(obj2);
    }
    if(obj3 != undefined){
      objArray.push(obj3);
    }
    return objArray;
  }

  //search
  search(){
    let obj = this.mountSearchObj();
    if(obj != undefined){
      let lastObj = this.saveState.getLastSearchSFX();
      if(lastObj == null || JSON.stringify(obj) != JSON.stringify(lastObj)){  
        let saveStateArray = [];
        let saveStateObj_line_1 = {
          chips: this.chips,
          searchLine: 1,
          page: 1
        };
        saveStateArray.push(saveStateObj_line_1);
        if(obj.length>1){
          let saveStateObj_line_2 = {
            chips: this.moreChips,
            searchLine: 2,
            operador: this.moreSearchDropdownItemLeft.value
          };
          saveStateArray.push(saveStateObj_line_2);
        }
        if(obj.length>2){
          let saveStateObj_line_3 = {
            chips: this.secondMoreChips,
            searchLine: 3,
            operador: this.secondMoreSearchDropdownItemLeft.value
          };
          saveStateArray.push(saveStateObj_line_3);
        }
        this.saveState.setLastSearchChipsSFX(saveStateArray);
        this.loadingTracks = true;
        this.searchParmeters = obj;
        if (this.findMusicaSubscribeCansel !== null) {
          this.findMusicaSubscribeCansel.unsubscribe();
        }
        this.findMusicaSubscribeCansel = this.searchService.findSFX(obj, 0).subscribe(tks => {
          this.mountMusicList(tks);
          this.setPage(1);
        });
      }
    }
  }

  mountMusicList(tks){
    this.loadingTracks = false;
    this.isResult = true;
    this.dataService.updateIsResult(this.isResult);
    this.searchMode = tks['GroupBy'];
    this.findMusicaSubscribeCansel = null;
    let listMusica = [];
    this.firstTimeSearchingTerm = true;
    if(this.searchMode == 'Title'){
      if (tks['Data'] != null) {
        listMusica = this.musicService.mountMusicList(tks['Data'], [], false, false, false, false);
      }         
    } else if(this.searchMode == 'Catalog'){
      this.catalogsSearchResult = tks['Data'];
      if (this.catalogsSearchResult != null) {
        this.catalogsSearchResult.forEach(catalog => {
          catalog['AlbumList'].forEach(album => {
            let musicsFromAlbum = [];
            album['TrackList'].forEach(track => {
              musicsFromAlbum.push(track);
            });
            musicsFromAlbum = this.musicService.mountMusicList(musicsFromAlbum, [], false, false, false, false); 
            album['finalTrackList'] = musicsFromAlbum;
            album['finalTrackList'].forEach(track => {
              listMusica.push(track);
            });
          });
        });
      }
    } else if(this.searchMode == 'Album'){
      this.albunsSearchResult =  tks['Data'];
      if (this.albunsSearchResult != null) {
        this.albunsSearchResult.forEach(album => {
          let musicsFromAlbum = [];
          album['TrackList'].forEach(track => {
            musicsFromAlbum.push(track);
          });
          musicsFromAlbum = this.musicService.mountMusicList(musicsFromAlbum, [], false, false, false, false); 
          album['finalTrackList'] = musicsFromAlbum;
          album['finalTrackList'].forEach(track => {
            listMusica.push(track);
          });
        });
      }
    }
    this.tracks = listMusica;
    this.tracks.forEach((track, iterador) => {
      track.isTrackSelected = false;
      this.playerHistory.selectedSongsSFX.forEach(selectedTrack => {
        if (track.id == selectedTrack.id) {
          track.isTrackSelected = true;
        }
      });
    });
    this.pager = this.pagerService.getPager(this.tracks.length, 1, this.itensPerPage);
    this.pager.totalPages = tks['NumberOfPages'];
    this.pager.totalItems = tks['Total'];
    this.pager.currentPage = 1;
    const obj = {
      pager : this.pager,
      musics : listMusica,
      searchMode : tks['GroupBy'],
      page: 1
    };
    this.saveState.setLastFindSFX(obj);
    this.getCategories();
  }
  
  setPage(page: number, action = null) {
    if (page < 1 || page > this.pager.totalPages || isNaN(page)) {
      return;
    }
    this.showTrackInfo = [];
    // get pager object from service
    if (!this.firstTimeSearchingTerm) {
      this.loadingTracks = true;
      let obj = this.mountSearchObj();
      let saveStateArray = [];
        let saveStateObj_line_1 = {
          chips: this.chips,
          searchLine: 1,
          page: page
        };
        saveStateArray.push(saveStateObj_line_1);
        if(obj.length>1){
          let saveStateObj_line_2 = {
            chips: this.moreChips,
            searchLine: 2,
            operador: this.moreSearchDropdownItemLeft.value
          };
          saveStateArray.push(saveStateObj_line_2);
        }
        if(obj.length>2){
          let saveStateObj_line_3 = {
            chips: this.secondMoreChips,
            searchLine: 3,
            operador: this.secondMoreSearchDropdownItemLeft.value
          };
          saveStateArray.push(saveStateObj_line_3);
        }
        this.saveState.setLastSearchChipsSFX(saveStateArray);
      if (this.findMusicaSubscribeCansel !== null) {
        this.findMusicaSubscribeCansel.unsubscribe();
      }
      this.findMusicaSubscribeCansel = this.searchService.findSFX(obj, page).subscribe(tks => {
        this.indexGlobal = null;
        this.loadingTracks = false;
        this.findMusicaSubscribeCansel = null;
        let listMusica = [];
        if(this.searchMode == 'Title'){
          if (tks['Data'] != null) {
            listMusica = this.musicService.mountMusicList(tks['Data'], [], false, false, false, false);
          }
        }
        else if(this.searchMode == 'Catalog'){
          this.catalogsSearchResult = tks['Data'];
          if (this.catalogsSearchResult != null) {
            this.catalogsSearchResult.forEach(catalog => {
              catalog['AlbumList'].forEach(album => {
                let musicsFromAlbum = [];
                album['TrackList'].forEach(track => {
                  musicsFromAlbum.push(track);
                });
                musicsFromAlbum = this.musicService.mountMusicList(musicsFromAlbum, [], false, false, false, false); 
                album['finalTrackList'] = musicsFromAlbum;
                album['finalTrackList'].forEach(track => {
                  listMusica.push(track);
                });
              });
            });
          }
        }
        else if(this.searchMode == 'Album'){
          this.albunsSearchResult =  tks['Data'];
          if (this.albunsSearchResult != null) {
            this.albunsSearchResult.forEach(album => {
              let musicsFromAlbum = [];
              album['TrackList'].forEach(track => {
                musicsFromAlbum.push(track);
              });
              musicsFromAlbum = this.musicService.mountMusicList(musicsFromAlbum, [], false, false, false, false); 
              album['finalTrackList'] = musicsFromAlbum;
              album['finalTrackList'].forEach(track => {
                listMusica.push(track);
              });
            });
          }
        }
        this.tracks = listMusica;
        if (this.tracks) {
          const number = this.authService.user.Pref_PageResults;
          this.itensPerPage = number === undefined ? this.itensPerPage : number;
          this.pager = this.pagerService.getPager(this.tracks.length, page, this.itensPerPage);
          this.pager.totalPages = tks['NumberOfPages'];
          this.pager.totalItems = tks['Total'];
          this.pager.currentPage = page;
        }

        const obj = {
          pager : this.pager,
          musics : listMusica,
          searchMode : tks['GroupBy'],
          page: page
        };
        this.saveState.setLastFindSFX(obj);

        this.value = +this.pager.currentPage;
        this.tracks.forEach(track => {
          track.isTrackSelected = false;
        });
        this.tracks.forEach((track, iterador) => {
          track.isTrackSelected = false;
          this.playerHistory.selectedSongsSFX.forEach(selectedTrack => {
            if (track.id == selectedTrack.id) {
              track.isTrackSelected = true;
            }
          });
        });
        if (action != null && action == 'next') {
          this.goToTrack(1);
          this.indexGlobal = 0;
          const obj = {
            track: this.tracks[this.indexGlobal],
            i: this.indexGlobal,
            tracks: this.tracks,
            from: 'sfx',
            search: this.saveState.getLastSearchSFX()
          };
          this.dataService.updateTrackSelected(obj);
        } else if (action != null && action == 'previous') {
          this.goToTrack(this.tracks.length);
          this.indexGlobal = this.tracks.length - 1;
          const obj = {
            track: this.tracks[this.indexGlobal],
            i: this.indexGlobal,
            tracks: this.tracks,
            from: 'sfx',
            search: this.saveState.getLastSearchSFX()
          };
          this.dataService.updateTrackSelected(obj);        }
        this.updateMusics();
        setTimeout(() => {
          if (this.selectedTrack != null) {
            if ($(this.selectedTrack.container).length > 0) {
              const iterator = parseInt($(this.selectedTrack.container).data('iterador'), 10);
              if (this.tracks[iterator] != undefined) {
                this.indexGlobal = iterator;
                this.tracks[iterator].isPlaying = true;
              }
            }
          }
        }, 100);
      });
    } else {
      if (this.tracks) {
        const number = this.authService.user.Pref_PageResults;
        // this.itensPerPage = number === undefined ? this.itensPerPage : number;
        // this.pager = this.pagerService.getPager(this.tracks.length, page, this.itensPerPage);
      }
      this.value = +this.pager.currentPage;
      this.firstTimeSearchingTerm = false;
      this.updateMusics();
      this.tracks.forEach((track, iterador) => {
        track.isTrackSelected = false;
        this.playerHistory.selectedSongsSFX.forEach(selectedTrack => {
          if (track.id == selectedTrack.id) {
            track.isTrackSelected = true;
          }
        });
      });
    } 
    $("html, body").animate({ scrollTop: 0 }, "slow");
  } 

  updateMusics(){
    this.tracks.forEach((track, it) => {
      let containerIt = it;
      track.container = '#track-' + containerIt + '-' + track.metadataID + '-progress';
      track.timeContainer = '#currentTime-track-' + containerIt + '-' + track.metadataID;
      if(this.dataService.getFPobject() != null){
        for (let index = 0; index < this.dataService.getFPobject()['tracks'].length; index++) {
          const fpTrack = this.dataService.getFPobject()['tracks'][index];
          if(fpTrack.id == track.id){
            fpTrack.container = track.container;
            fpTrack.timeContainer = track.timeContainer;
            track.currentTime = fpTrack.currentTime;
            track.currentTimeDisplay = fpTrack.currentTimeDisplay;
            break;
          } 
        }
      }
      if(this.dataService.getFPobject() != null && this.dataService.getFPobject()['track'] != null && track.id == this.dataService.getFPobject()['track'].id){
        this.dataService.getFPobject()['track'].container = track.container;
        this.dataService.getFPobject()['track'].timeContainer = track.timeContainer;
      }
      const width = track.currentTime * 100 / track.durationSecs;
      if(width != 0 && width != 100){
        track.playerWidth = width + "%";
        track.borderRight = '1px solid black';
      }
    });
  }

  onFocus() {
    const inputElem = <HTMLInputElement>this.pageInput.nativeElement;
    inputElem.select();
  }

  goToTrack(index) {
    setTimeout(() => {
      const offset = 110 * (index - 1);
      if (offset === undefined) {
        return;
      }
      const positionTop = offset;
      $('html, body').scrollTop(positionTop);
    }, 200);
  }

  searchClean(): void {
    if (this.findMusicaSubscribeCansel != null) {
      this.findMusicaSubscribeCansel.unsubscribe();
      this.findMusicaSubscribeCansel = null;
      this.loadingTracks = false;
    }
    this.firstTimeSearchingTerm = false;
    this.chips = [];
    this.moreChips = [];
    this.secondMoreChips = [];
    $("#row-one").val('').focus();
    $("#row-two").val('');
    $("#row-three").val('');
    this.isAddRemoveLine = false;
    this.isAdd = false;
    this.isSecondAdd = false;
    this.isResult = false;
    this.moreSearchDropdownItemLeft = this.moreSearchDropdownItemsLeft[0];
    this.secondMoreSearchDropdownItemLeft = this.secondMoreSearchDropdownItemsLeft[0];
    this.dataService.updateIsResult(this.isResult);
    this.getCategories();
    this.saveState.setLastSearchSFX(null);
    this.saveState.removelastSearchSFX();
    this.minBPMone = this.maxBPMone = this.minDURone = this.maxDURone = null;
    this.getPlaceholder();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  checkIfItWasContext(chip) {
    if (!chip.isChipPlus) {
      if (chip.hasOwnProperty('context')) {
        if (chip.context != "") {
          chip.context = '';
        }
      }
    }
  }

  getPlaceholder(isDefault = false){
    if(this.placeholderRequestSubscriber != null){
      this.placeholderRequestSubscriber.unsubscribe();
    }
    this.placeholderRequestSubscriber = this.searchService.getPlaceholderSFX(isDefault).subscribe(placeholder => {
      this.placeholderRequestSubscriber = null;
      this.placeholder = placeholder;
    });
  }

  checkIfGetNewPlaceholder(selector){
    const text = $(selector).val();
    if(this.chips.length > 0 || text.trim() != ""){
      return;
    }
    this.getPlaceholder();
  }
  
  //ddls
  getCategories(){
    if(this.isResult){
      let searchParmetersSize = this.searchParmeters.length;
      let searchText = searchParmetersSize == 1 ? this.searchParmeters[0]["text"] : "";
      let searchContext = searchParmetersSize == 1 ? this.searchParmeters[0]["context"] : "";
      this.sfxService.getCategories(searchText, searchContext).subscribe(genres => {
        this.processCats(genres);
      });
    } else {
      this.sfxService.getCategories('', '').subscribe(genres => {
        this.processCats(genres);
      });
    }
  }

  processCats(genres){
    const tempGenres = this.processGeneres(genres);
    this.genres['A'] = this.getGenresByLetter(tempGenres['A']);
    this.genres['C'] = this.getGenresByLetter(tempGenres['C']);
    this.genres['D'] = this.getGenresByLetter(tempGenres['D']);
    this.genres['E'] = this.getGenresByLetter(tempGenres['E']);
    this.genres['F'] = this.getGenresByLetter(tempGenres['F']);
    this.genres['Q'] = this.getGenresByLetter(tempGenres['Q']);
    this.genres['J'] = this.getGenresByLetter(tempGenres['J']);
    for (let type in this.genres) {
      for (let genre in this.genres[type]) {
        this.genres[type][genre].open = false;
      }
    }
    this.setTabCols();
  }

  processGeneres(genres) {
    const temp_genres = {
      A: [],
      C: [],
      D: [],
      E: [],
      F: [],
      J: [],
      Q: []
    };
    genres.forEach(genre => {
      const obj = {
        name: genre.Ctg,
        context: genre.Ctx,
        subCategory: []
      };
      genre.SCtg.forEach(subCat => {
        obj.subCategory.push({ name: subCat.Ctg, contextName: '', context: subCat.SCtx });
      });
      if (genre.Tp == 'A') {
        temp_genres.A.push(obj);
      } else if (genre.Tp == 'C') {
        temp_genres.C.push(obj);
      } else if (genre.Tp == 'D') {
        temp_genres.D.push(obj);
      } else if (genre.Tp == 'E') {
        temp_genres.E.push(obj);
      } else if (genre.Tp == 'F') {
        temp_genres.F.push(obj);
      } else if (genre.Tp == 'J') {
        obj['new'] = genre.IsNewRelease;
        obj['pref'] = genre.IsPreferred;
        temp_genres.J.push(obj);
      } else if (genre.Tp == 'Q') {
        temp_genres.Q.push(obj);
      }
    });
    return temp_genres;
  }

  newCompare(string1, string2, genre) {
    let str1 = string1.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    let str2 = string2.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    if (genre.open) {
      let exists = false;
      for (let i = 0; i < genre.subgenres.length; i++) {
        let str3 = genre.subgenres[i].name.toLowerCase();
        if (str3.includes(str2)) {
          exists = true;
          break;
        }
      }
      if (exists) {
        return true;
      }
    }

    if (str1.includes(str2)) {
      return true;
    } else {
      return false;
    }
  }

  checkIfSelected(genre) {
    let alreadySelected = false;

    this.chips.forEach(chip => {
      if (chip.name == genre.name && chip.context == genre.context) {
        alreadySelected = true;
        return;
      }
    });
    if (alreadySelected) {
      let styles = {
        'color': '#f16824',
        'font-weight': 'bold'
      };
      return styles;
    } else {
      let styles = {
        'color': '#676767',
        'font-weight': 'regular'
      };
      return styles;
    }
  }

  getGenresByLetter(_genres): any[] {
    const lista = [];
    for (const key in _genres) {
      if (_genres.hasOwnProperty(key)) {
        const element = _genres[key];
        const subgenres = [];
        for (const key2 in element.subCategory) {
          if (element.subCategory.hasOwnProperty(key2)) {
            const subelement = element.subCategory[key2];
            subgenres.push({ 'name': subelement.name, 'context': subelement.context, 'contextName': subelement.contextName });
          }
        }
        if (subgenres.length === 0) {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0, 'contextName': element.contextName });
        } else {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0, 'subgenres': subgenres, 'contextName': element.contextName });
        }
      }
    }
    return lista;
  }

  setTabCols(): void {
    if ($(window).width() >= 1200) {
      this.tabCols = 6;
    } else if ($(window).width() >= 992) {
      this.tabCols = 4;
    } else if ($(window).width() >= 640) {
      this.tabCols = 4;
    } else if ($(window).width() >= 320) {
      this.tabCols = 2;
    } else if ($(window).width() < 320) {
      this.tabCols = 2;
    }
    const self = this;
    this.genres['A'].forEach(function (item, index) {

      item.containerId = Math.floor(index / (self.genres['A'].length / self.tabCols));
    });
    this.genres['C'].forEach(function (item, index) {
      item.containerId = Math.floor(index / (self.genres['C'].length / self.tabCols));
    });
    this.genres['D'].forEach(function (item, index) {
      item.containerId = Math.floor(index / (self.genres['D'].length / self.tabCols));
    });
    this.genres['E'].forEach(function (item, index) {
      item.containerId = Math.floor(index / (self.genres['E'].length / self.tabCols));
    });
    this.genres['F'].forEach(function (item, index) {
      item.containerId = Math.floor(index / (self.genres['E'].length / self.tabCols));
    });
    this.genres['Q'].forEach(function (item, index) {
      item.containerId = Math.floor(index / (self.genres['Q'].length / self.tabCols));
    });
    this.genres['J'].forEach(function (item, index) {
      item.containerId = Math.floor(index / (self.genres['J'].length / self.tabCols));
    });
  }

  genresOverHasElements(letter: string) {
    if (this.genres === undefined) { return false; }
    if (this.genres[letter] === undefined) { return false; }
    if (this.genres[letter].length === 0) {
      return false;
    }
    return true;
  }

  openTab(tab) {
    if (tab == this.tabOpen) {
      this.tabOpen = null;
    } else {
      this.tabOpen = tab;
      if (this.tabOpen == 'tempos') {
        this.addValueToBPMandDuration();
      }
      this.countClicksOutTabs = 1;
      this.genresSearchString = "";
      this.temposSearchString = "";
      this.cataloguesSearchString = "";
    }
  }

  addValueToBPMandDuration() {
    if (this.tabOpen == 'tempos') {
        if (this.minBPMone != null) {
          setTimeout(() => {
            $('#min-BPM').val(this.minBPMone);
          }, 75);
        }
        if (this.maxBPMone != null) {
          setTimeout(() => {
            $('#max-BPM').val(this.maxBPMone);
          }, 75);
        }
        if (this.minDURone != null) {
          setTimeout(() => {
            $('#min-DUR').val(this.minDURone);
          }, 75);
        }
        if (this.maxDURone != null) {
          setTimeout(() => {
            $('#max-DUR').val(this.maxDURone);
          }, 75);
        }
    }
  }

  genresNameTab(letter): String {
    return this.i18n.nameByLetterMarterCategory(this.i18n.language.toUpperCase(), letter);
  }

  closeTabs(): void {
    if (this.countClicksOutTabs > 1) {
      this.tabOpen = null
      this.countClicksOutTabs = 1;
    }

    if (this.tabOpen != null) {
      this.countClicksOutTabs++;
    }
  }

  //albuns
  getSFXalbuns(){
    this.sfxService.getSFXalbuns().subscribe(albuns => {
      const idioma = this.i18n.language;
      let path = '';
      let albunslist = [];
      switch (idioma) {
        case 'pt':
        case 'es':
        case 'en':
          path = albuns[idioma].path + '/';
          albunslist = albuns[idioma].sfx_albuns;
          break;
        default:
          path = albuns['en'].path + '/';
          albunslist = albuns['en'].sfx_albuns;
          break;
      }
      const listalbuns = [];
      for (let index = 0; index < albunslist.length; index++) {
        const element = albunslist[index];
        listalbuns[index] = {
          'id': index,
          'name': element.name,
          'description': element.description,
          'reference': element.code,
          'thumb': environment.baseUrl + 'image/album/' + element.catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_') +
            '/' + element.code.replace(new RegExp(' & ', 'g'), '').replace(new RegExp(' ', 'g'), '_') + '/?&token=' + this.authService.user.token + '&username=' + this.authService.user.username,
          'showHome': true,
          'J': element.J,
          'K': element.K,
          'catalog': element.catalog,
          'externalLink': element.external_link
        };
      }
      this.albuns = listalbuns;
      this.loadAlbunsSFX = false;
    });
  }
  
  filterAlbums(albums) {
    return albums.showHome === true;
  }


  //actions
  toggleCatalog(k){
    this.showMenuMobile = [];
    $("#albuns_catalog"+k).slideToggle();
  }

  toggleAlbum(k,j){
    this.showMenuMobile = [];
    $("#tracks_catalog"+k+"-"+j).slideToggle();
  }
  
  selectTrack($event, index) {
    if($event != null){
      $event.stopPropagation();
      if($event.shiftKey){ 
        this.checkTracksToSelect(index);
        return;
      }
    }
    let count = 0;
    this.tracks[index].isTrackSelected = !this.tracks[index].isTrackSelected;
    if (this.tracks[index].isTrackSelected) {
      this.playerHistory.addSongToSelectedSFX(this.tracks[index]);
      const id = this.tracks[index].id;
      this.tracks.forEach(element => {
        if (element.id == id) {
          element.isTrackSelected = true;
          this.dataService.setLastTrackSelectedSFX({id:index, page: this.pager.currentPage});
        }
      });
    } else {
      this.playerHistory.removeSongFromSelectedSFX(this.tracks[index]);
      const id = this.tracks[index].id;
      this.tracks.forEach(element => {
        if (element.id == id) {
          element.isTrackSelected = false;
        }
      });
    }
  }

  selectAllTracks(tracks) {
    let areAlreadySelected = true;
    let firsTrackOfPage = 0;
    let lastTrackOfPage = this.tracks.length - 1;
    for (let i = 0; i < tracks.length; i++) {
      const element = tracks[i];
      if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
        if (!element.isTrackSelected) {
          areAlreadySelected = false;
        }
      }
    }
    if (areAlreadySelected) {
      for (let i = 0; i < tracks.length; i++) {
        const element = tracks[i];
        if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
          element.isTrackSelected = false;
          this.playerHistory.removeSongFromSelectedSFX(element);
        }
      }
    } else {
      for (let i = 0; i < tracks.length; i++) {
        const element = tracks[i];
        if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
          element.isTrackSelected = true;
          this.playerHistory.addSongToSelectedSFX(element);
        }
      }
    }
  }

  deselectAllTracks(tracks) {
    tracks.map(function (x) {
      x.isTrackSelected = false;
      return x;
    });
    this.countTracksSelected = 0;
    this.playerHistory.addSongToSelectedSFX(null);
  }

  clickOpenInfo($event, i, track) {
    let pos = i;
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if($event !=undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    this.showTrackInfo[pos] = !this.showTrackInfo[pos];
    if (this.showTrackInfo[pos]) {
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
        this.updateContainer();
        this.value = +this.pager.currentPage;
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
              var xaMyComments = data["MyComments"].split("~");
              track.MyComments = xaMyComments[0];
              track.MyRating = data["MyRating"];

              if (xaMyComments[1]!="") {track.genres = xaMyComments[1].split(";");} else {track.genres = "";}
              if (xaMyComments[2]!="") {track.moods = xaMyComments[2].split(";");} else {track.moods = "";}
              if (xaMyComments[3]!="") {track.instruments = xaMyComments[3].split(";");} else {track.instruments = "";}
              if (xaMyComments[4]!="") {track.tempos = xaMyComments[4].split(";");} else {track.tempos = "";}
              if (xaMyComments[5]!="") {track.periods = xaMyComments[5].split(";");} else {track.periods = "";}
              if (xaMyComments[6]!="") {track.keys = xaMyComments[6].split(";");} else {track.keys = "";}
              if (xaMyComments[7]!="") {track.keywords = xaMyComments[7]} else {track.keywords = "";}
          }
        });
      } else {
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (j != i) {
            this.showTrackInfo[j] = false;
          }
        }
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      }
    }
  }

  updateContainer() {
    let isSomethingPlaying = false;
    for (let id = 0; id < this.tracks.length; id++) {
      this.tracks[id].container = '#track-' + id + '-' + this.tracks[id].metadataID + '-progress';
      if (this.tracks[id].isPlaying) {
        this.indexGlobal = id;
        isSomethingPlaying = true;
      }
      if (this.selectedTrack != null) {
        if (this.tracks[id].id == this.selectedTrack.id) {
          this.selectedTrack.container = this.tracks[id].container;
        }
      }

    }
    if (!isSomethingPlaying) {
      this.indexGlobal = null;
    }
  }

  newPlay(track, i, event) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    if (!$(event.target).hasClass('dropdown-toggle') && !$(event.target).hasClass('dropdown-item')) {
      let obj = {
        track: track,
        i: i,
        tracks: this.tracks,
        from: 'sfx',
        search: this.saveState.getLastSearchSFX()
      };
      if(this.dataService.checkIfPlaying(obj.track)){
        window['globalWavesurfer'].playPause();
        window['beepPlayer'].playPause();
        return;
      }
      if (this.indexGlobal == null) {
        this.dataService.updateTrackSelected(obj);
      } else {
        if (this.indexGlobal == i) {
          window['globalWavesurfer'].playPause();
          window['beepPlayer'].playPause();
          if (window['globalWavesurfer'].isPlaying()) {
            track.isPlaying = true;
          } else {
            obj['action'] = 'pause';
            track.isPlaying = false;
          }
          this.dataService.updateTrackSelected(obj);
        } else {
          this.dataService.updateTrackSelected(obj);
        }
      }
    }
    this.indexGlobal = i;
  }

  showRelated(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      event.stopPropagation();
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    this.wasDBL = true;
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    setTimeout(() => {
      this.wasDBL = false;
    }, 500);
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackRelatedOrSimilar[i] = !this.showTrackRelatedOrSimilar[i];
      if (this.showTrackRelatedOrSimilar[i]) {
        let decrement = 0;
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.tracks[j]) || ('isSimilar' in this.tracks[j]) || ('isFromAlbum' in this.tracks[j])) {
            this.tracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackRelatedOrSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showAlbumTracks[j] = false;
        }
        if(this.searchMode == 'Catalog'){
          this.removeSecondaryMusicsSearchCatalog();
        }
        if(this.searchMode == 'Album'){
          this.removeSecondaryMusicsSearchAlbum();
        }
        pos -= decrement;
        this.loadingTracks = true;
        this.searchService.findMusicsRelated(track.id).subscribe(data => {
          let musics = this.musicService.mountMusicList(data, [], true, false, false, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
          if(this.searchMode == 'Catalog'){
            this.insertSecondaryMusicsSearchCatalogs(track, musics);
          }
          if(this.searchMode == 'Album'){
            this.insertSecondaryMusicsSearchAlbum(track, musics);
          }
          this.updateContainer();
          this.loadingTracks = false;
        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.tracks.length; id++) {
          if (!('isRelated' in this.tracks[id]) && !('isSimilar' in this.tracks[id]) && !('isFromAlbum' in this.tracks[id])) {
            break;
          } else {
            if (('isRelated' in this.tracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.tracks.splice(idsToRemove[j], 1);
        }
        if(this.searchMode == 'Catalog'){
          this.removeSecondaryMusicsSearchCatalog();
        }
        if(this.searchMode == 'Album'){
          this.removeSecondaryMusicsSearchAlbum();
        }
        this.updateContainer();
      }
    }
  }

  getMusicsFromAlbum(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showAlbumTracks[i] = !this.showAlbumTracks[i];
      if (this.showAlbumTracks[i]) {
        let decrement = 0;
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.tracks[j]) || ('isSimilar' in this.tracks[j]) || ('isFromAlbum' in this.tracks[j])) {
            this.tracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showAlbumTracks[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
        }
        if(this.searchMode == 'Catalog'){
          this.removeSecondaryMusicsSearchCatalog();
        }
        if(this.searchMode == 'Album'){
          this.removeSecondaryMusicsSearchAlbum();
        }
        pos -= decrement;
        this.loadingTracks = true;
        this.albumService.getSameAlbum(track).subscribe(data => {
          let musics = this.musicService.mountMusicList(data, [], false, false, true, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
          if(this.searchMode == 'Catalog'){
            this.insertSecondaryMusicsSearchCatalogs(track, musics);
          }
          if(this.searchMode == 'Album'){
            this.insertSecondaryMusicsSearchAlbum(track, musics);
          }
          this.updateContainer();
          this.checkSelected();
          this.loadingTracks = false;
        });
      } else {
        const idsToRemove = [];
        for (let id = pos + 1; id < this.tracks.length; id++) {
          if (!('isRelated' in this.tracks[id]) && !('isSimilar' in this.tracks[id]) && !('isFromAlbum' in this.tracks[id])) {
            break;
          } else {
            if (('isFromAlbum' in this.tracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.tracks.splice(idsToRemove[j], 1);
        }
        if(this.searchMode == 'Catalog'){
          this.removeSecondaryMusicsSearchCatalog();
        }
        if(this.searchMode == 'Album'){
          this.removeSecondaryMusicsSearchAlbum();
        }
        this.updateContainer();
        this.checkSelected();
      }
    }
  }

  checkSelected() {
    this.tracks.forEach(track => {
      track.isTrackSelected = false;
      this.playerHistory.selectedSongsSFX.forEach(selectedTrack => {
        if (track.id == selectedTrack.id) {
          track.isTrackSelected = true;
        }
      });
    });
  }


  getPositionClicked(track, i, event) {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    setTimeout(() => {
      if(!this.wasDBL){
        let posX;
        posX = event.offsetX > 0 ? event.offsetX : event.layerX;
        const width = $('#track-' + i + '-' + track.metadataID).width();
        const percentage = posX * 100 / width;
        const duration = moment.duration('00:' + track.duration).asSeconds();
        const playFrom = percentage * duration / 100;
        track.currentTime = playFrom;
        track.currentTimeDisplay = moment('2015-01-01').startOf('day').seconds(playFrom).format('mm:ss');

        let url;
        if (this.authService.isLoggedIn) {
          url = environment.baseUrl + 'music/streaming/' + track.id + '/?token=' + this.authService.user.token;
        } else {
          url = environment.baseUrl + 'music/streaming/free/' + track.ProjectsID + '/' + track.id;
        }
        track.url = url;

        const obj = {
          track: track,
          i: i,
          tracks: this.tracks,
          from: 'sfx',
          search: this.saveState.getLastSearchSFX()
        };
        this.dataService.updateTrackSelected(obj);
        this.indexGlobal = i;
      }
    }, 400);
  }

  // showSimilars(event, i, track) {
  //   if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
  //     event.stopPropagation();
  //     let pos = i;
  //     this.showTrackSimilar[i] = !this.showTrackSimilar[i];
  //     if (this.showTrackSimilar[i]) {
  //       let decrement = 0;
  //       for (let j = this.tracks.length - 1; j >= 0; j--) {
  //         if (('isRelated' in this.tracks[j]) || ('isSimilar' in this.tracks[j]) || ('isFromAlbum' in this.tracks[j])) {
  //           this.tracks.splice(j, 1);
  //           if (j < pos) {
  //             decrement++;
  //           }
  //         }
  //         if (j != i) {
  //           this.showTrackSimilar[j] = false;
  //         }
  //         this.showTrackInfo[j] = false;
  //         this.showTrackRelatedOrSimilar[j] = false;
  //         this.showAlbumTracks[j] = false;
  //       }
  //       if(this.searchMode == 'Catalog'){
  //         this.removeSecondaryMusicsSearchCatalog();
  //       }
  //       if(this.searchMode == 'Album'){
  //         this.removeSecondaryMusicsSearchAlbum();
  //       }
  //       pos -= decrement;
  //       this.loadingTracks = true;
  //       this.searchService.findMusicsSimilars(track.id, track.titleId).subscribe(data => {
  //         let musics = this.musicService.mountMusicList(data, [], false, true, false, false);
  //         musics = this.cleanActualMusic(track, musics);
  //         const start = pos + 1;
  //         this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
  //         if(this.searchMode == 'Catalog'){
  //           this.insertSecondaryMusicsSearchCatalogs(track, musics);
  //         }
  //         if(this.searchMode == 'Album'){
  //           this.insertSecondaryMusicsSearchAlbum(track, musics);
  //         }
  //         this.updateContainer();
  //         this.loadingTracks = false;
  //       });
  //     } else {
  //       let idsToRemove = [];
  //       for (let id = pos + 1; id < this.tracks.length; id++) {
  //         if (!('isRelated' in this.tracks[id]) && !('isSimilar' in this.tracks[id]) && !('isFromAlbum' in this.tracks[id])) {
  //           break;
  //         } else {
  //           if (('isSimilar' in this.tracks[id])) {
  //             this.showTrackInfo[id] = false;
  //             this.showTrackSimilar[id] = false;
  //             this.showAlbumTracks[id] = false;
  //             idsToRemove.push(id);
  //           }
  //         }
  //       }
  //       for (let j = idsToRemove.length - 1; j >= 0; j--) {
  //         this.tracks.splice(idsToRemove[j], 1);
  //       }
  //       if(this.searchMode == 'Catalog'){
  //         this.removeSecondaryMusicsSearchCatalog();
  //       }
  //       if(this.searchMode == 'Album'){
  //         this.removeSecondaryMusicsSearchAlbum();
  //       }
  //       this.updateContainer();
  //     }
  //   }
  // }

  // adicionar uma track ao projecto selecionado (line-by-line )
  addToProjects(event, track, i): void {
    event.stopPropagation();
    setTimeout(() => {
      $(".dropdown-menu").removeClass('show');  
    }, 0);
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    if (!track.isProjectIconEnabeled) {
      const project = this.dataService.getSelectedProject();
      const normalizedData = {
        'ProjectsID': project.ProjectsID,
        'Context1J': track.Context1J,
        'Context1K': track.Context1K,
        'Track': track.track,
        'TrackTitle': track.name,
        'TrackTime': track.duration,
        'Description': ''
      };

      const musicList = track.id;
      const type = this.authService.user.Pref_DownloadFormat;
      this.selectedProject = this.dataService.getSelectedProject();
      const projectId = this.selectedProject.ProjectsID;

      if (this.prevXhrRequest != null) {
        this.prevXhrRequest.unsubscribe();
        this.prevXhrRequest = null;
      }

      this.prevXhrRequest =
        this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
          const errorList = data.ErrorList;
          const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
          if (errorList != undefined) {
            if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
              this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
              let instance = this.projectListExistanceModalComponent.componentInstance;
              instance.projectList = data['MusicInOtherProjectsList'];
              this.dataService.updateModalOpen(true);
              this.modalOpen = true;
              this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
                this.dataService.updateModalOpen(false);
                this.modalOpen = false;
                if (result['action'] == 'continue') {
                  this.projectService.setTrackToProject(normalizedData).subscribe(() => {
                    project.Titles = (parseInt(project.Titles, 10)) + 1;
                    this.dataService.updateSelectedProject(project);
                    this.navbar.justGetProjects();
                  });
                }
              });
            }
            else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
              this.projectService.setTrackToProject(normalizedData).subscribe(() => {
                project.Titles = (parseInt(project.Titles, 10)) + 1;
                this.dataService.updateSelectedProject(project);
                this.navbar.justGetProjects();
              });
            } else {
              if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
            }
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
          this.prevXhrRequest = null;
        });
    }
  }

  oneDownloadToRuleThemAll(i, event, track, selectedType) {
    if (this.authService.isLoggedIn && this.authService.user.Accs_MaxDownloads === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
      if(event != undefined && event != null && event.ctrlKey){
        if(i != null){
          this.selectTrack(null, i);
        }
        return;
      }
      if(event !=undefined && event!= null && event.shiftKey){ 
        if(i != null){
          this.checkTracksToSelect(i);
        }
        return;
      }
    }

    let musicList = this.downloadService.prepareDownloadList(track, this.tracks);
    let type = this.downloadService.prepareType(selectedType);
    let projectId = this.downloadService.prepareProjectId(this.dataService.getSelectedProject());
    let emails = null;

    this.downloadService.DownloadWithValidation(projectId, type, musicList, this.navbar);

  }

  //utilitaries
  getAlbumImgSearchByAlbum(album){
    //FALTA REF DO CATALOGO PARA IR BUSCAR A IMAGEM
    // return "http://staging.vimusica.com/api/image/album/Minim/MNM045/?&token="+ this.authService.user.token + '&username=' + this.authService.user.username;
    return environment.baseUrl + 'image/album/' +
    album.CatalogImageName + '/' + album.AlbumReference + '/' +
    '?token=' + this.authService.user.token + '&username=' + this.authService.user.username;
  }

  cleanActualMusic(track, musics) {
    for (let j = musics.length - 1; j >= 0; j--) {
      const music = musics[j];
      if (music.id == track.id) {
        musics.splice(j, 1);
      }
    }
    return musics;
  }

  insertSecondaryMusicsSearchCatalogs(track, musics){
    let finalCatalog = null;
    let finalAlbum = null;
    let finalTrackIndex = null;
    for (let indexC = 0; indexC < this.catalogsSearchResult.length; indexC++) {
      const catalog = this.catalogsSearchResult[indexC];
      const albuns = catalog['AlbumList'];
      for (let indexA = 0; indexA < albuns.length; indexA++) {
        const album = albuns[indexA];
        const albumTracks = album['finalTrackList'];
        for (let indexT = 0; indexT < albumTracks.length; indexT++) {
          const albumTrack = albumTracks[indexT];
          if(albumTrack.id == track.id){
            finalCatalog = indexC;
            finalAlbum = indexA;
            finalTrackIndex = indexT;
            break;
          }
        }
        if(finalAlbum != null){
          break;
        }
      }
      if(finalCatalog != null){
        break;
      }
    }
    for (let it = 0; it < musics.length; it++) {
      const music = musics[it];
      this.catalogsSearchResult[finalCatalog]['AlbumList'][finalAlbum]['finalTrackList'].splice( finalTrackIndex+it+1, 0, music);
    }
  }

  removeSecondaryMusicsSearchCatalog(){
    this.catalogsSearchResult.forEach(catalog => {
      catalog['AlbumList'].forEach(album => {
        for (let index = album['finalTrackList'].length-1; index >= 0; index--) {
          const musica = album['finalTrackList'][index];
          if(('isRelated' in musica) || ('isSimilar' in musica) || ('isFromAlbum' in musica)){
            album['finalTrackList'].splice(index, 1);
          }
        }
      });
    });
  }

  insertSecondaryMusicsSearchAlbum(track, musics){
    let finalAlbum = null;
    let finalTrackIndex = null;
  
      for (let indexA = 0; indexA < this.albunsSearchResult.length; indexA++) {
        const album = this.albunsSearchResult[indexA];
        const albumTracks = album['finalTrackList'];
        for (let indexT = 0; indexT < albumTracks.length; indexT++) {
          const albumTrack = albumTracks[indexT];
          if(albumTrack.id == track.id){
            finalAlbum = indexA;
            finalTrackIndex = indexT;
            break;
          }
        }
        if(finalAlbum != null){
          break;
        }
      }
    
    for (let it = 0; it < musics.length; it++) {
      const music = musics[it];
      this.albunsSearchResult[finalAlbum]['finalTrackList'].splice( finalTrackIndex+it+1, 0, music);
    }
  }

  removeSecondaryMusicsSearchAlbum(){
    this.albunsSearchResult.forEach(album => {
      for (let index = album['finalTrackList'].length-1; index >= 0; index--) {
        const musica = album['finalTrackList'][index];
        if(('isRelated' in musica) || ('isSimilar' in musica) || ('isFromAlbum' in musica)){
          album['finalTrackList'].splice(index, 1);
        }
      }
    });
  }

  getRealIndex(track){
    let id = null;
    for (let it = 0; it < this.tracks.length; it++) {
      const tempTrack = this.tracks[it];
      if(('isRelated' in track) || ('isSimilar' in track) || ('isFromAlbum' in track)){
        if(track.id == tempTrack.id && (('isRelated' in tempTrack) || ('isSimilar' in tempTrack) || ('isFromAlbum' in tempTrack))){
          id = it;
          break;
        }
      } else {
        if(track.id == tempTrack.id && !('isRelated' in tempTrack) && !('isSimilar' in tempTrack) && !('isFromAlbum' in tempTrack)){
          id = it;
          break;
        }
      }
    }
    return id;
  }

  getCatalogImg(catalog){
    return environment.baseUrl + 'image/catalog/?trackCatalog=' +
    catalog.CatalogImageName +
    '&token=' + this.authService.user.token + '&username=' + this.authService.user.username;
  }

  getAlbumImg(catalog, album){
    return environment.baseUrl + 'image/album/' +
    catalog.CatalogImageName + '/' + album.AlbumReference + '/' +
    '?&token=' + this.authService.user.token + '&username=' + this.authService.user.username;
  }

  checkIfMusicExistsOnSelectedProject(track) {
    if (this.selectedProjectsTracks != undefined) {
      if (this.selectedProjectsTracks.some(e => e.id == track.id)) {
        track.isProjectIconEnabeled = true;
        return true;
      } else {
        track.isProjectIconEnabeled = false;
        return false;
      }
    } else {
      return false;
    }
  }
  

  getWidth(id) {
    let div = document.getElementById(id);
    let width = div.offsetWidth;
    return width + "px";
  }


  setZIndex(id) {
    if ($('.track-download-dropdown').is(":visible") || $('.track-projects-dropdown').is(":visible") || $('.track-actions-mobile').is(":visible")) {

    } else {
      $(".track").css("z-index", "1");
      $("#" + id).css("z-index", "12");
    }
  }

  setZIndexForce(id,  event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    $(".track").css("z-index", "1");
    $("#" + id).css("z-index", "12");
  }

  checkClassForCheckAll(){
    let anySelected = false;
    let allSelected = true;
    for (let it = 0; it < this.tracks.length; it++) {
      const track = this.tracks[it];
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)){
        if(track.isTrackSelected){
          anySelected =  true;
        } else {
          allSelected = false;
        }
      }
    }
    if(allSelected && this.tracks.length>0){
      return 'invertColor';
    } else if(anySelected && this.tracks.length>0){
      return 'fullOpacity';
    } else{
      return '';
    }
  }

  removeExtraSounds(page) {
    this.firstTimeSearchingTerm = false;
    this.setPage(parseInt(page, 10));
  }

  checkSrc(){
    var video = document.getElementById("video_"+this.selectedVideo) as HTMLVideoElement;
    if(video.paused){
      return '../../assets/images/play_icon.png';
    } else{
      return '../../assets/images/pause_button.png';
    }
  } 

  checkSound(){
    var video = document.getElementById("video_"+this.selectedVideo) as HTMLVideoElement;
    if(video.muted){
      return '../../assets/images/sound_on.png';
    } else{
      return '../../assets/images/sound_off.png';
    }
  }

  togglePlayVideo(){
    var video = document.getElementById("video_"+this.selectedVideo) as HTMLVideoElement;
    if(video.paused){
      video.play();
    } else{
      video.pause();
    }
  }

  toggleSoundVideo(){
    var video = document.getElementById("video_"+this.selectedVideo) as HTMLVideoElement;
    if(video.muted){
      video.muted= false;
    } else{
      video.muted= true;
    }
  }

  checkIfFreezePlaying(){
    if(window['globalWavesurfer'].isPlaying()){
      return true;
    }
    return false;
  }



  //BPM E DURAÇÃO

  checkIfSearch() {
    const minBPM = $('#min-BPM').val();
    const maxBPM = $('#max-BPM').val();
    const minDUR = $('#min-DUR').val();
    const maxDUR = $('#max-DUR').val();

    if (minBPM.trim() != '' || maxBPM.trim() != '' || minDUR.trim() != '' || maxDUR.trim() != '') {
      this.search();
      this.closeTabs();
    }
  }

  checkWhereToAddBPMMin() {
    let value = $('#min-BPM').val();
    if (value.trim() == "") {
      value = null;
      this.minBPMone = null;
    }
    this.minBPMone = value;
    $('#min-BPM').val(value);
  }

  checkWhereToAddBPMMax() {
    let value = $('#max-BPM').val();
    if (value.trim() == "") {
      value = null;
      this.maxBPMone = null;
    }
    this.maxBPMone = value;
    $('#max-BPM').val(value);
  }

  checkWhereToAddDURMin() {
    let value = $('#min-DUR').val().replace(/^0+/, '');
    if (value.trim() == "") {
      // value = null;
      value = "";
      this.minDURone = null;
      return;
    }
    if (!value.includes(':')) {
      if (value < 60) {
        if (value < 10) {
          value = '0' + value;
        }
        value = "00:" + value;
      } else if (value >= 100) {
        let secs = value.slice(-2);
        let mins = value.slice(0, -2);
        if (mins < 10) {
          mins = '0' + mins;
        }
        if (secs < 60) {
          value = mins + ':' + secs;
        } else {
          alert("Tempo Inválido");
          value = "00:00";
          return;
        }
      } else {
        alert("Tempo Inválido");
        value = "00:00";
        return;
      }
    }
    if (value.trim() == "") {
      value = null;
    }
    this.minDURone = value; 
    $('#min-DUR').val(value);
  }

  checkWhereToAddDURMax() {
    let value = $('#max-DUR').val().replace(/^0+/, '');
    if (value.trim() == "") {
      // value = null;
      this.maxDURone = null;
      value = "";
      return;
    }
    if (!value.includes(':')) {
      if (value < 60) {
        if (value < 10) {
          value = '0' + value;
        }
        value = "00:" + value;
      } else if (value >= 100) {
        let secs = value.slice(-2);
        let mins = value.slice(0, -2);
        if (mins < 10) {
          mins = '0' + mins;
        }
        if (secs < 60) {
          value = mins + ':' + secs;
        } else {
          alert("Tempo Inválido");
          value = "00:00";
          return;
        }
      } else {
        alert("Tempo Inválido");
        value = "00:00";
        return;
      }
    }
    this.maxDURone = value;
    $('#max-DUR').val(value);
  }

  addCommentAndRating(track, event, i) {
    if(event !=undefined && event!= null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    // if(event !=undefined && event!= null && event.shiftKey){ 
    //   this.checkTracksToSelect(i);
    //   return;
    // }
    const rating = track.MyRating;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };

    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  removeRating(track, event, i) {
    if(event !=undefined && event!= null && event.ctrlKey){
      this.selectTrack(null, i);
      return;
    }
    if(event !=undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    track.MyRating = "";
    const rating = track.MyRating;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };
    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }


  checkTracksToSelect(i){
    this.clearSelection();  
    let last_index = this.dataService.getLastTrackSelectedSFX();
    let initial_index = -1;
    let initial_page = 1;
    if(last_index != null){
      initial_index = last_index['id']; 
      initial_page = last_index['page'];
    }    
    let actual_index = i;
    let actual_page = this.pager.currentPage;


    if(actual_page == initial_page){
      if(initial_index > actual_index){
        for (let it = actual_index; it < initial_index; it++) {
          this.playerHistory.addSongToSelectedSFX(this.tracks[it]);
          const id = this.tracks[it].id;
          this.tracks.forEach(element => {
            if (element.id == id) {
              element.isTrackSelected = true;
              this.dataService.setLastTrackSelectedSFX({id:it, page:actual_page});
            }
          });
        }
    } 
    else {
        for (let it = initial_index +1; it <= actual_index; it++) {
          this.playerHistory.addSongToSelectedSFX(this.tracks[it]);
          const id = this.tracks[it].id;
          this.tracks.forEach(element => {
            if (element.id == id) {
              element.isTrackSelected = true;
              this.dataService.setLastTrackSelectedSFX({id:it, page:actual_page});
            }
          });
        }
      }
      setTimeout(() => {
        this.clearSelection();  
      }, 0);
    } else {
      let searchObj = {};
      if(initial_index == -1){
        initial_index = 0;
      }
      if(actual_page>initial_page){
        searchObj = {
          "IsSFX":true,
          "StartIndex":initial_index,
          "StartPage":initial_page,
          "EndIndex":actual_index,
          "EndPage":actual_page
        };
      } else {
        searchObj = {
          "IsSFX":true,
          "StartIndex":actual_index,
          "StartPage":actual_page,
          "EndIndex":initial_index,
          "EndPage":initial_page
        };
      }
      this.searchService.getTracksToSelect(searchObj).subscribe(metadataids => {
        metadataids.forEach(metadataid => {
          var final_obj = {
            Context1J: metadataid.Context1J,
            Context1K: metadataid.Context1K,
            track: metadataid.Track,
            name: metadataid.TrackTitle,
            duration: metadataid.TrackTime,
            description: metadataid.Description,
            id: metadataid.MetaDataId,
            Track: metadataid.Track,
            isTrackSelected: true,
          };
          this.playerHistory.addSongToSelectedSFX(final_obj);
        });
        this.checkSelected();
        setTimeout(() => {
          this.clearSelection();  
        }, 0);
      });
    }
  }

  
  clearSelection()
  {
   if (window.getSelection) {
     window.getSelection().removeAllRanges();
    }
  }
  

  //ADVANCED SEARCH 
  addRow(): void {
    if (this.isAdd) {
      this.isSecondAdd = true;
      this.lastFocusInput = 'row-three';
    } else {
      this.isAdd = true;
      this.lastFocusInput = 'row-two';
    }
  }

  removeRow(): void {
    if (this.isSecondAdd) {
      this.isSecondAdd = false;
      this.secondMoreChips = [];
      const rowTwoElement: HTMLInputElement = document.getElementById('row-two') as HTMLInputElement;
      rowTwoElement.focus();
      this.lastFocusInput = 'row-two';
    } else {
      this.isAdd = false;
      this.moreChips = [];
      const rowOneElement: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
      rowOneElement.focus();
      this.lastFocusInput = 'row-one';
    }
  }

  selectMoreSearchDropdownItemLeft(item) {
    this.moreSearchDropdownItemLeft = item;
  }

  selectSecondMoreSearchDropdownItemLeft(item) {
    this.secondMoreSearchDropdownItemLeft = item;
  }

  checkIfPreDeleteChipInAbsolutePosition(evt, it){
    const val = $("#secondary-input"+it).text();
    if(val.trim() != ''){
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it];
    const content = tempChip.name;
    if(it == this.chips.length - 1 ){
      this.chips.pop();
      $("#row-one").val(content);
      this.setSelectionRange(document.getElementById('row-one'), 0,0);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
    } else {
      this.chips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
        $("#secondary-input" + (it)).text(content).focus();  
      }, 0);
    }
  } 


  checkHowManyChips(id, evt){
    evt.stopPropagation();
    evt.preventDefault();
    setTimeout(() => {
      let text = $("#"+id).val();
      let carretPos = this.getCaretPos(document.getElementById(id));
      let chip1Text = text.substring(0, carretPos);
      let chip2Text = text.substring(carretPos, text.length);
      let chip1 = {name: chip1Text.replace(new RegExp('_', 'g'), ' '), isChipPlus: true}; 
      let chip2 = {name: chip2Text.replace(new RegExp('_', 'g'), ' '), isChipPlus: true};
      if(chip1Text.trim() != '' && chip2Text.trim() != ''){
        if(id == 'row-one'){
          this.chips.push(chip1);
          this.chips.push(chip2);
        } else if(id == 'row-two'){
          this.moreChips.push(chip1);
          this.moreChips.push(chip2);
        } else if (id == 'row-three'){
          this.secondMoreChips.push(chip1);
          this.secondMoreChips.push(chip2);
        }
      } else {
        if(chip1Text.trim() != '' && chip2Text.trim() == ''){
          if(id == 'row-one'){
            this.chips.push(chip1);
          } else if(id == 'row-two'){
            this.moreChips.push(chip1);
          } else if(id=='row-three'){
            this.secondMoreChips.push(chip1);
          }
        } else if(chip1Text.trim() == '' && chip2Text.trim() != ''){
          if(id == 'row-one'){
            this.chips.push(chip2);
          } else if(id == 'row-two'){
            this.moreChips.push(chip2);
          } else if(id=='row-three'){
            this.secondMoreChips.push(chip2);
          }
        }
      }
      $("#"+id).val("");
      if(id == 'row-one'){
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
      } else if(id == 'row-two'){
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
        });
      } else if(id == 'row-three'){
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
        });
      } 
    }, 0);
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
      return oField.selectionStart;
    } else {
      return 0;
    }
  }

  setCursor(id, pos) { 
    let tag = document.getElementById(id); 
    let setpos = document.createRange(); 
    let set = window.getSelection(); 
    setpos.setStart(tag.childNodes[0], pos); 
    setpos.collapse(true); 
    set.removeAllRanges();  
    set.addRange(setpos); 
    tag.focus(); 
  } 

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      let range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }

  getCaretPosition(element) {
    let caretOffset = 0;
    let range = window.getSelection().getRangeAt(0);
    let preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
    return caretOffset;
  }

  chipsNavitagion(evt, side, from){
    evt.stopPropagation();
    const value = from == 'row-one' ? $("#row-one").val() : $("#secondary-input"+from).text();
    if(value.trim() != ''){
      return;
    }    
    const focused = $(':focus');
    const id = focused.attr('id');
    if(side == 'previous'){
      if(id == "row-one"){
        if(this.chips.length > 0){
          $("#secondary-input" + (this.chips.length-1)).focus();
        }
      } else if(focused.hasClass('secondary-search-input')){
        const focusedIt = Number(id.replace('secondary-input',''));
        const targetId = focusedIt - 1;
        if(targetId >= 0){
          $("#secondary-input" + targetId).focus();
        } 
      }
    } else {
      if(focused.hasClass('secondary-search-input')){
        const focusedIt = Number(id.replace('secondary-input',''));
        const targetId = focusedIt + 1;
        if(targetId < this.chips.length){
          $("#secondary-input" + targetId).focus();
        } else {
          $("#row-one").focus();
        }
      }
    }
  }

  checkIfDeleteChipInAbsolutePosition(evt, it, id){
    let val;
    if(id == 'row-one'){
      val = $("#"+id).val();
    } else {
      val = $("#"+id).text();
    }
    if(val.trim() != ''){
      return;
    }
    if(it == 0){
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it-1];
    const content = tempChip.name;
    if(it == this.chips.length){
      this.chips.pop();
      $("#row-one").val(content);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
    } else {
      this.chips.splice(it-1, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
        $("#secondary-input" + (it-1)).text(content);  
        this.setCursor("secondary-input"+(it-1), content.length);
      }, 0);
    }
  }

  checkHowManyChipsAbsPos(id, i, evt, clas){
    evt.stopPropagation();
    evt.preventDefault();
    setTimeout(() => {
      let text = $("#"+id).text();
      let carretPos = this.getCaretPosition(document.getElementById(id));
      let chip1Text = text.substring(0, carretPos);
      let chip2Text = text.substring(carretPos, text.length);
      let chip1 = {name: chip1Text.replace(new RegExp('_', 'g'), ' '), isChipPlus: true}; 
      let chip2 = {name: chip2Text.replace(new RegExp('_', 'g'), ' '), isChipPlus: true};
      if(chip1Text.trim() != '' && chip2Text.trim() != ''){
        if(clas == 0){
          this.chips.splice(i, 0, chip1);
          this.chips.splice(i+1, 0, chip2);
        } else if(clas == 1){
          this.moreChips.splice(i, 0, chip1);
          this.moreChips.splice(i+1, 0, chip2);
        } else if (clas == 2){
          this.secondMoreChips.splice(i, 0, chip1);
          this.secondMoreChips.splice(i+1, 0, chip2);
        }
      } else {
        if(chip1Text.trim() != '' && chip2Text.trim() == ''){
          if(clas == 0){
            this.chips.splice(i, 0, chip1);
          } else if(clas == 1){
            this.moreChips.splice(i, 0, chip1);
          } else if(clas == 2){
            this.secondMoreChips.splice(i, 0, chip1);
          }
        } else if(chip1Text.trim() == '' && chip2Text.trim() != ''){
          if(clas == 0){
            this.chips.splice(i, 0, chip2);
          } else if(clas == 1){
            this.moreChips.splice(i, 0, chip2);
          } else if(clas == 2){
            this.secondMoreChips.splice(i, 0, chip2);
          }
        }
      }
      $("#"+id).text("");
      if(clas == 0){
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
      } else if(clas == 1){
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
        });
      } else if(clas == 2){
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
        });
      }
    }, 0);
  }

  //2a LINHA
  chipsMoreNavitagion(evt, side, from){
    evt.stopPropagation();
    const value = from == 'row-two' ? $("#row-two").val() : $("#secondary-input-more"+from).text();
    if(value.trim() != ''){
      return;
    }    
    const focused = $(':focus');
    const id = focused.attr('id');
    if(side == 'previous'){
      if(id == "row-two"){
        if(this.moreChips.length > 0){
          $("#secondary-input-more" + (this.moreChips.length-1)).focus();
        }
      } else if(focused.hasClass('secondary-search-input-more')){
        const focusedIt = Number(id.replace('secondary-input-more',''));
        const targetId = focusedIt - 1;
        if(targetId >= 0){
          $("#secondary-input-more" + targetId).focus();
        } 
      }
    } else {
      if(focused.hasClass('secondary-search-input-more')){
        const focusedIt = Number(id.replace('secondary-input-more',''));
        const targetId = focusedIt + 1;
        if(targetId < this.moreChips.length){
          $("#secondary-input-more" + targetId).focus();
        } else {
          $("#row-two").focus();
        }
      }
    }
  }

  checkIfDeleteMoreChipInAbsolutePosition(evt, it, id){
    let val;
    if(id == 'row-two'){
      val = $("#"+id).val();
    } else {
      val = $("#"+id).text();
    }
    if(val.trim() != ''){
      return;
    }
    if(it == 0){
      return;
    }
    evt.preventDefault();
    const tempChip = this.moreChips[it-1];
    const content = tempChip.name;
    if(it == this.moreChips.length){
      this.moreChips.pop();
      $("#row-two").val(content);
      this.secondaryInputsMore = [];
      this.moreChips.forEach((chip, id) => {
        this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
      });
    } else {
      this.moreChips.splice(it-1, 1);
      setTimeout(() => {
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
        });
        $("#secondary-input-more" + (it-1)).text(content);  
        this.setCursor("secondary-input-more"+(it-1), content.length);
      }, 0);
    }
  }

  checkIfPreDeleteMoreChipInAbsolutePosition(evt, it){
    const val = $("#secondary-input-more"+it).text();
    if(val.trim() != ''){
      return;
    }
    evt.preventDefault();
    const tempChip = this.moreChips[it];
    const content = tempChip.name;
    if(it == this.moreChips.length - 1 ){
      this.moreChips.pop();
      $("#row-two").val(content);
      this.setSelectionRange(document.getElementById('row-two'), 0,0);
      this.secondaryInputsMore = [];
      this.moreChips.forEach((chip, id) => {
        this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
      });
    } else {
      this.moreChips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
        });
        $("#secondary-input-more" + (it)).text(content).focus();  
      }, 0);
    }
  } 

  checkIfChangeMoreChip($event, side, i){
    let posI = this.getCaretPosition(document.getElementById("secondChip"+i));
    setTimeout(() => {
      let posF = this.getCaretPosition(document.getElementById("secondChip"+i));
      if(side == 'previous'){
        if(posI == 0 && posF == 0){
          $("#secondary-input-more"+i).focus();
        }
      } else {
        if(posI == $("#secondChip"+i).text().length && posF == $("#secondChip"+i).text().length){
         let next = i+1;
         if(document.getElementById('secondary-input-more'+next)!=null){
           $("#secondary-input-more"+next).focus();
         }else {
          $("#row-two").focus();
         }
        }
      }
    }, 10);
  }

  //3a LINHA
  chipsSecondMoreNavitagion(evt, side, from){
    evt.stopPropagation();
    const value = from == 'row-three' ? $("#row-three").val() : $("#secondary-input-second-more"+from).text();
    if(value.trim() != ''){
      return;
    }    
    const focused = $(':focus');
    const id = focused.attr('id');
    if(side == 'previous'){
      if(id == "row-three"){
        if(this.secondMoreChips.length > 0){
          $("#secondary-input-second-more" + (this.secondMoreChips.length-1)).focus();
        }
      } else if(focused.hasClass('secondary-search-input-second-more')){
        const focusedIt = Number(id.replace('secondary-input-second-more',''));
        const targetId = focusedIt - 1;
        if(targetId >= 0){
          $("#secondary-input-second-more" + targetId).focus();
        } 
      }
    } else {
      if(focused.hasClass('secondary-search-input-second-more')){
        const focusedIt = Number(id.replace('secondary-input-second-more',''));
        const targetId = focusedIt + 1;
        if(targetId < this.secondMoreChips.length){
          $("#secondary-input-second-more" + targetId).focus();
        } else {
          $("#row-three").focus();
        }
      }
    }
  }

  checkIfDeleteSecondMoreChipInAbsolutePosition(evt, it, id){
    let val;
    if(id == 'row-three'){
      val = $("#"+id).val();
    } else {
      val = $("#"+id).text();
    }
    if(val.trim() != ''){
      return;
    }
    if(it == 0){
      return;
    }
    evt.preventDefault();
    const tempChip = this.secondMoreChips[it-1];
    const content = tempChip.name;
    if(it == this.secondMoreChips.length){
      this.secondMoreChips.pop();
      $("#row-three").val(content);
      this.secondaryInputsSecondMore = [];
      this.secondMoreChips.forEach((chip, id) => {
        this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
      });
    } else {
      this.secondMoreChips.splice(it-1, 1);
      setTimeout(() => {
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
        });
        $("#secondary-input-second-more" + (it-1)).text(content);  
        this.setCursor("secondary-input-second-more"+(it-1), content.length);
      }, 0);
    }
  }

  checkIfPreDeleteSecondMoreChipInAbsolutePosition(evt, it){
    const val = $("#secondary-input-second-more"+it).text();
    if(val.trim() != ''){
      return;
    }
    evt.preventDefault();
    const tempChip = this.secondMoreChips[it];
    const content = tempChip.name;
    if(it == this.secondMoreChips.length - 1 ){
      this.secondMoreChips.pop();
      $("#row-three").val(content);
      this.setSelectionRange(document.getElementById('row-three'), 0,0);
      this.secondaryInputsSecondMore = [];
      this.secondMoreChips.forEach((chip, id) => {
        this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
      });
    } else {
      this.secondMoreChips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
        });
        $("#secondary-input-second-more" + (it)).text(content).focus();  
      }, 0);
    }
  }

  checkIfChangeSecondMoreChip($event, side, i){
    let posI = this.getCaretPosition(document.getElementById("secondMoreChip"+i));
    setTimeout(() => {
      let posF = this.getCaretPosition(document.getElementById("secondMoreChip"+i));
      if(side == 'previous'){
        if(posI == 0 && posF == 0){
          $("#secondary-input-second-more"+i).focus();
        }
      } else {
        if(posI == $("#secondMoreChip"+i).text().length && posF == $("#secondMoreChip"+i).text().length){
         let next = i+1;
         if(document.getElementById('secondary-input-second-more'+next)!=null){
           $("#secondary-input-second-more"+next).focus();
         }else {
          $("#row-three").focus();
         }
        }
      }
    }, 10);
  }

  checkIfDeleteChip(chip, i) {
    setTimeout(() => {
      const texto = $("#chip" + i).text();
      if (texto.length == 0) {
        this.deleteChip(chip);
      }
    }, 10);
  }

  checkIfDeleteMoreChip(chip, i) {
    setTimeout(() => {
      const texto = $("#secondChip" + i).text();
      if (texto.length == 0) {
        this.deleteMoreChip(chip);
      }
    }, 10);
  }

  checkIfDeleteSecondMoreChip(chip, i) {
    setTimeout(() => {
      const texto = $("#secondMoreChip" + i).text();
      if (texto.length == 0) {
        this.deleteSecondMoreChip(chip);
      }
    }, 10);
  }

  checkIfDeleteChipFromSearchBar() {
    const el = document.getElementById('row-one') as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.chips.length > 0) {
        const tempChip = this.chips[this.chips.length - 1];
        const text = tempChip.name + " ";
        this.deleteChip(tempChip);
        el.value = text;
      }
    }
  }

  checkIfDeleteMoreChipFromSearchBar() {
    const el = document.getElementById('row-two') as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.moreChips.length > 0) {
        const tempChip = this.moreChips[this.moreChips.length - 1];
        const text = tempChip.name + " ";
        this.deleteMoreChip(tempChip);
        el.value = text;
      }
    }
  }

  checkIfDeleteSecondChipFromSearchBar() {
    const el = document.getElementById('row-three') as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.secondMoreChips.length > 0) {
        const tempChip = this.secondMoreChips[this.secondMoreChips.length - 1];
        const text = tempChip.name + " ";
        this.deleteSecondMoreChip(tempChip);
        el.value = text;
      }
    }
  }

  changeValueInSecondaryInput(index) {
    const el = document.getElementById('secondary-input' + index) as HTMLInputElement;
    const value = el.value;
    if (value.length > 0) {
      el.style.width = "auto";
    } else {
      el.style.width = "5px";
    }
  }

  changeValueInSecondaryInputMore(index) {
    const el = document.getElementById('secondary-input-more' + index) as HTMLInputElement;
    const value = el.value;
    if (value.length > 0) {
      el.style.width = "auto";
    } else {
      el.style.width = "5px";
    }
  }

  changeValueInThirdInputMore(index) {
    const el = document.getElementById('secondary-input-second-more' + index) as HTMLInputElement;
    const value = el.value;
    if (value.length > 0) {
      el.style.width = "auto";
    } else {
      el.style.width = "5px";
    }
  }

  addChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById('secondary-input' + i) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";

    if (value.trim() != "") {
      this.addChip(value.replace(new RegExp('_', 'g'), ' '), i);
    }
  }

  addMoreChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById('secondary-input-more' + i) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";
    if (value.trim() != "") {
      this.addMoreChip(value, i);
    }
  }

  addSecondChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById('secondary-input-second-more' + i) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";
    if (value.trim() != "") {
      this.addSecondMoreChip(value, i);
    }
  }

  addMoreChip(chip, position): void {
    if (typeof chip === 'string') {
      if (chip.includes(' ')) {
        const chipstoAdd = chip.split(' ');
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addMoreChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty('name')) {
      _chip = chip;
      chip = chip.name;
    }
    chip = chip.trim();
    if (chip != null) {
      if (!chip || this.moreChips.length > 5) {
        return;
      }
      if (chip.charAt(0) === '-') {
        this.isChipPlus = false;
        chip = chip.substr(1);
      } else {
        this.isChipPlus = true;
      }

      chip = chip.replace(new RegExp('_', 'g'), ' ');

      const result = {
        'name': chip,
        'isChipPlus': this.isChipPlus
      };

      if (_chip !== null && _chip.hasOwnProperty('name')) {
        result['context'] = _chip.context;
      }

      if (position == null) {
        this.moreChips.push(result);
      } else {
        this.moreChips.splice(position, 0, result);
      }

      this.secondaryInputsMore = [];
      this.moreChips.forEach((chip, id) => {
        this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
      });
    }
  }

  addSecondMoreChip(chip, position): void {
    if (typeof chip === 'string') {
      if (chip.includes(' ')) {
        const chipstoAdd = chip.split(' ');
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addSecondMoreChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty('name')) {
      _chip = chip;
      chip = chip.name;
    }
    if (chip != null) {
      chip = chip.trim();
      if (!chip || this.secondMoreChips.length > 5) {
        return;
      }
      if (chip.charAt(0) === '-') {
        this.isChipPlus = false;
        chip = chip.substr(1);
      } else {
        this.isChipPlus = true;
      }
      chip = chip.replace(new RegExp('_', 'g'), ' ');
      const result = {
        'name': chip,
        'isChipPlus': this.isChipPlus
      };
      if (_chip !== null && _chip.hasOwnProperty('name')) {
        result['context'] = _chip.context;
      }
      if (position == null) {
        this.secondMoreChips.push(result);
      } else {
        this.secondMoreChips.splice(position, 0, result);
      }
      this.secondaryInputsSecondMore = [];
      this.secondMoreChips.forEach((chip, id) => {
        this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
      });
    }
  }

  deleteChip(chip): void {
    this.chips = this.chips.filter(c => c !== chip);
    if (this.chips.length >= 1) {
      this.search();
    }
    if (this.chips.length === 0) {
      this.searchClean();
    }
  }

  deleteMoreChip(chip): void {
    this.moreChips = this.moreChips.filter(m => m !== chip);
    this.secondaryInputsMore = [];
    this.moreChips.forEach((chip, id) => {
      this.secondaryInputsMore.push({ input: 'secondary-input-more' + id, value: '' });
    });
  }
 
  deleteSecondMoreChip(chip): void {
    this.secondMoreChips = this.secondMoreChips.filter(m => m !== chip);
    this.secondaryInputsSecondMore = [];
    this.secondMoreChips.forEach((chip, id) => {
      this.secondaryInputsSecondMore.push({ input: 'secondary-input-second-more' + id, value: '' });
    });
  }

  manageResultsTopPosition() {
    if (this.isAddRemoveLine && !this.isAdd && !this.isSecondAdd) {
      return 'oneLineOpen';
    } else if (this.isAddRemoveLine && this.isAdd && !this.isSecondAdd) {
      return 'twoLineOpen';
    } else if (this.isAddRemoveLine && this.isAdd && this.isSecondAdd) {
      return 'threeLineOpen';
    }
  }
}
