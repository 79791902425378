import { Component, OnInit } from '@angular/core';
import { Contact } from '../contact';
import { ContactService } from '../contact.service';
import { I18nGlvService } from '../i18n-glv.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  model = new Contact();
  submitted = false;
  contactForm: FormGroup;

  capchaImageSrc = null;
  captcha = "";
  capchaKey = null;
  loadingCapcha = true;
  isCapchaValid = false;

  constructor(private contactService: ContactService, private fb: FormBuilder,
    public authService: AuthService, public i18n: I18nGlvService,
    public router: Router, private sanitizer: DomSanitizer, private http: HttpClient) {
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', []],
      message: ['', [Validators.required]],
    });
    if (this.authService.isLoggedIn == true) {
      this.isCapchaValid = true;
    } else {
      this.getCaptcha();
    }

  }

  onSubmit() {
    let data = {
      name: this.contactForm.get('name').value,
      telephone: this.contactForm.get('phone').value,
      message: this.contactForm.get('message').value,
      email: this.contactForm.get('email').value,
      language: this.i18n.language.toUpperCase()
    };
    this.contactService.addContact(data).subscribe(data => {
      setTimeout(() => {
        this.router.navigateByUrl('/');
      }, 500);
    });
  }

  clearInputs(): void {
    this.model = new Contact();
  }

  getCaptcha() {
    const key = this.uuidv4();
    const base = environment.baseUrl + 'captcha/image/?uid=' + key;
    return this.http.get<any[]>(base)
      .subscribe(json => {
        this.capchaImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(json['image']);
        this.capchaKey = json['key'];
        this.loadingCapcha = false;
      });
  }

  checkCapcha() {
    const el = document.getElementById('captchaText') as HTMLInputElement;
    const valueInserted = el.value;
    const base = environment.baseUrl + 'captcha/validate/?uid=' + this.capchaKey + '&key=' + valueInserted;
    return this.http.get<any>(base)
      .subscribe(isValid => {
        this.isCapchaValid = isValid;
      });
  }

  uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  get name() {
    return this.contactForm.get('name');
  }

  get email() {
    return this.contactForm.get('email');
  }

  get phone() {
    return this.contactForm.get('phone');
  }

  get message() {
    return this.contactForm.get('message');
  }

}
