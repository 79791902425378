import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { SlideService } from '../slide.service';
import { Slide } from '../slide';
import { forEach } from '@angular/router/src/utils/collection';
import { I18nGlvService } from '../i18n-glv.service';
import { HomeinfoService } from '../homeinfo.service';


declare var $: any;

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  slides: Slide[];

  constructor(private slideService: SlideService, public i18n: I18nGlvService, private homeInfo: HomeinfoService) {
  }

  ngOnInit() {
    this.getSlides();
    this.runSlider();

    let timeoutID;

    function setup() {
      document.addEventListener('mousemove', resetTimer, false);
      document.addEventListener('mousedown', resetTimer, false);
      document.addEventListener('keypress', resetTimer, false);
      document.addEventListener('DOMMouseScroll', resetTimer, false);
      document.addEventListener('mousewheel', resetTimer, false);
      document.addEventListener('touchmove', resetTimer, false);
      document.addEventListener('MSPointerMove', resetTimer, false);

      startTimer();
    }

    setup();

    function startTimer() {
      // wait 1 second before calling goInactive
      timeoutID = window.setTimeout(goInactive, 1000);
    }

    function resetTimer(e) {
      window.clearTimeout(timeoutID);

      goActive();
    }

    function goInactive() {
      // $('.carousel-indicators').addClass('inactive');
    }

    function goActive() {
      $('.carousel-indicators').removeClass('inactive');
      startTimer();
    }
  }

  runSlider(): void {
    $('#home-slider').carousel({
      interval: 4000
    });
  }

  getSlides(): void {
    const tempSlides = this.homeInfo.getHomeslides();
    if (tempSlides.length == 0) {
      this.slideService.getSlides().subscribe(slides => {
        const idioma = this.i18n.language;
        let sliders = [];
        switch (idioma) {
          case 'pt':
          case 'es':
          case 'en':
            sliders = slides[idioma].sliders;
            break;
          default:
            sliders = slides['en'].sliders;
            break;
        }
        const listSlides = [];
        for (let index = 0; index < sliders.length; index++) {
          const element = sliders[index];
          listSlides[index] = { 'id': index, 'image': element.file };
        }
        this.slides = listSlides;
        this.homeInfo.updateHomeSlides(this.slides);
      });
    } else {
      this.slides = tempSlides;
    }
  }
}
