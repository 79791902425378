import { Injectable, Optional } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class I18nGlvService {
  private _language = '';
  private _region = 'en';
  private regionUrl = `${environment.baseUrl}v2/global/getcountry/`;  // URL to web api
  public isLanguageReady = false;

  constructor(public authService: AuthService, private http: HttpClient) {
    const _language = navigator.language.split('-')[0];
    switch (_language) {
      case 'pt':
      case 'es':
      case 'en':
        this._language = _language;
        break;
      default:
        this._language = 'en';
        break;
    }
    if (localStorage.getItem('selectedLang')) {
      this._language = localStorage.getItem('selectedLang');
    } else {
      this.http.get<any>(this.regionUrl).subscribe(data => {
        switch (data.Data.toLowerCase()) {
          case 'pt':
          case 'es':
          case 'en':
            this._region = data.Data;
            this._language = data.Data.toLowerCase();
            break;
          default:
            this._region = 'en';
            this._language = 'en';
            break;
        }
        this.isLanguageReady = true;
      });
    }
  }

  set language(lang: string) {
    /* Se tem login e perfLanguage Usa se não tira do browser */
    if (this.authService.user === null || this.authService.user.username === '') {
      this._language = lang;
    }
    localStorage.setItem('selectedLang', lang);
  }

  get language() {
    /** Se tem login e perfLanguage Usa se não tira do browser */
    if (this.authService.user === null) {
      return this._language;
    }
    return (this.authService.user.prefLanguage !== undefined)
      ? this.authService.user.prefLanguage
      : this._language;
  }


  get region() {
    return this._region;
  }

  updateLanguage(language) {
    this._language = language;
  }

// no ficheiro index.html vai a API buscar este script:
//
//    <script src="https://api.vimusica.com/api/scripts/i18n"></script> 

  getTranslationsBD(key) {
	key=key.toLowerCase();
	key=key.replaceAll(' ','-');
	key=key.replaceAll('º','');
	key=key.replaceAll('.','');
	key=key.replaceAll('(','');
	key=key.replaceAll(')','');
	var xsPre = '';
	var xsSuf = '';
	if (key.endsWith('?')) {xsSuf='?';key=key.replace('?','');}
	if (key.endsWith('!')) {xsSuf='!';key=key.replace('!','');}
	if (key.endsWith(':')) {xsSuf=':';key=key.replace(':','');}
	if (key.endsWith('.')) {xsSuf='.';key=key.replace('.','');}
	if (this.language.toUpperCase()=='ES' && xsSuf=='?') {xsPre='¿';}
	if (this.language.toUpperCase()=='ES' && xsSuf=='!') {xsPre='¡';}
    if (key in window['serverI18n']) {
      return xsPre+window['serverI18n'][key][this.language.toUpperCase()]+xsSuf;
    }
	return "["+xsPre+key+xsSuf+"]";
  }

  getTextFooter(key: any): string {
    const language = this.language.toUpperCase();
    const data = {
      'PT': {
        'fale-connosco': 'Fale Connosco',
        'tel': '+351 217956195',
        'tel2': '',
        'fax': '',
        'morada': 'Av. Barbosa du Bocage, 23 - 1 D <br/> 1000-070 Lisboa',
        'direitos': 'Todos os direitos reservados',
        'loc': 'https://goo.gl/maps/DmgwWqaGaRw',
        'name': 'Portugal',
        'all-rights': 'Todos os direitos reservados',
        'copyright': 'AVISO LEGAL',
        'privacy-policy': 'POLÍTICA DE PRIVACIDADE',
        'terms': 'TERMOS & CONDIÇÕES',
      },
      'ES': {
        'fale-connosco': 'Por favor Contacte Con',
        'tel': '+34 914023214',
        'tel2': '',
        'fax': '',
        'morada_old': 'C/ Francisco Silvela, 42 - 1 <br/> 28028 Madrid',
        'morada': 'C/ María Molina, 39 - 8 <br/> 28006 Madrid',
        'direitos': 'Todos los derechos reservados',
        'loc_old': 'https://goo.gl/maps/bW2s1mGP6eJ2',
        'loc': 'https://goo.gl/maps/gab72N6z456VgTX39',
        'name': 'España',
        'all-rights': 'Todos los derechos reservados',
        'copyright': 'AVISO LEGAL',
        'privacy-policy': 'POLÍTICA DE PRIVACIDAD',
        'terms': 'TÉRMINOS & CONDICIONES',
      },
      'EN': {
        'fale-connosco': 'Please Contact',
        'tel': '+351 217956195',
        'tel2': '+34 914023214',
        'fax': '+351 217973817',
        'morada': 'Av. Barbosa du Bocage, 23 - 1 D <br/> 1000-070 Lisboa',
        'direitos': 'All rights reserved',
        'loc': 'https://goo.gl/maps/DmgwWqaGaRw',
        'name': 'Rest Of The World',
        'all-rights': 'All rights reserved',
        'copyright': 'COPYRIGHT NOTICE',
        'privacy-policy': 'PRIVACY POLICY',
        'terms': 'TERMS & CONDITIONS',
      },
    };
    if (data[language][key] !== undefined) {
      return data[language][key];
    }
  }

  nameByLetterMarterCategory(language: any, letter: any): String {
    const data = {
      'PT': {
        'A': 'Géneros',
        'C': 'Estados de Espírito',
        'D': 'Instrumentos',
        'E': 'Tempos',
        'F': 'Períodos',
        'I': 'Diversas',
        'Q': 'Tons',
        'J': 'Catálogos'
      },
      'ES': {
        'A': 'Géneros',
        'C': 'Estados de Ánimo',
        'D': 'Instrumentos',
        'E': 'Tempos',
        'F': 'Períodos',
        'I': 'Diversas',
        'Q': 'Tonos',
        'J': 'Catálogos'
      },
      'EN': {
        'A': 'Genres',
        'C': 'Moods',
        'D': 'Instruments',
        'E': 'Tempos',
        'F': 'Periods',
        'I': 'Miscellaneous',
        'Q': 'Keys',
        'J': 'Catalogues'
      },
    };
    if (data[language][letter] !== undefined) {
      return data[language][letter];
    }
    return '';
  }

}
