import { Component, OnInit } from '@angular/core';
import { I18nGlvService } from '../i18n-glv.service';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-project-list-existance-modal',
  templateUrl: './project-list-existance-modal.component.html',
  styleUrls: ['./project-list-existance-modal.component.scss']
})
export class ProjectListExistanceModalComponent implements OnInit {

  projectList = null;
  constructor(public dialogRef: MatDialogRef<ProjectListExistanceModalComponent>, public i18n: I18nGlvService) { }

  ngOnInit() {
  }

  cancelar() {
    this.dialogRef.close({ action: 'cancel' });
  }

  proceed() {
    this.dialogRef.close({ action: 'continue' });
  }
}
