import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Register } from './register';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private registersUrl = `${environment.baseUrl}register`;  // URL to web api
  private infoUrl = environment.baseUrl + 'info'; 
  constructor(private http: HttpClient, private messageService: MessageService) {
  }

  /** Log a RegisterService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`RegisterService: ${message}`);
  }


  getBusinessTypes() {
    return this.http.get<any[]>(this.registersUrl + '/businessTypes')
      .pipe(
        tap(_ => this.log('get businessTypes')),
        catchError(this.handleError('businessTypes', []))
      );
  }

  /** POST: add a new register to the server */
  addRegister(data): Observable<Register> {
    return this.http.post<Register>(this.registersUrl, data, httpOptions).pipe(
      tap((register: Register) => this.log(`add register`)),
      catchError(this.handleError<Register>('addRegister'))
    );
  }

  getTerms(){
    return this.http.get<any[]>(this.infoUrl)
      .pipe(
        tap(_ => { })
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
