import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { I18nGlvService } from './i18n-glv.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ProjectListExistanceModalComponent } from './project-list-existance-modal/project-list-existance-modal.component';
import { DownloadZipMailComponent } from './download-zip-mail/download-zip-mail.component';
import { MessageDialogService } from "./message-dialog.service";
import { DataService } from './data.service';
import { NavbarComponent } from './navbar/navbar.component';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {
  private downloadUrl = `${environment.baseUrl}download/`;
  public showModalMusicInOtherProjects = null;
  projectListExistanceModalComponent: MatDialogRef<ProjectListExistanceModalComponent>;
  downloadZipMailComponent: MatDialogRef<DownloadZipMailComponent>;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private messageService: MessageService,
    public i18n: I18nGlvService,
    private dataService: DataService,

    public messageDialog: MessageDialogService,
    public ProjectListExistanceModalComponent: MatDialog,
    public DownloadZipMailComponent: MatDialog
  ) { }

  private log(message: string) {
    this.messageService.add(`GenreService: ${message}`);
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  prepareDownloadList(track, selectedSongs, selectAll = false) {
    let musicList = "";
    if (track != null) {
      musicList = '' + track.id;
    } else {
      for (let i = 0; i < selectedSongs.length; i++) {
        if (selectedSongs[i].isTrackSelected && selectAll == false) {
          musicList += selectedSongs[i].id + ",";
        } else if (selectAll == true) {
          musicList += selectedSongs[i].MetaDataID + ",";
        }
      }
      musicList = musicList.substring(0, musicList.length - 1);
    }
    return musicList;
  }

  prepareType(selectedType) {
    let type;
    if (selectedType == null) {
      if (this.authService.isLoggedIn == false) {
        type = 'MP3_32_ZIP'
      } else {
        type = this.authService.user.Pref_DownloadFormat;
      }

    } else {
      type = selectedType.DownloadFormat;
    }
    return type;
  }

  prepareProjectId(project, projectID = null) {
    if (projectID != null) {
      return projectID;
    }
    return project.ProjectsID;
  }

  private async getUrlData(URL) {
    const data = await this.http.get(URL).toPromise();
    return data;
  }
  
  fDownloadMessage(data)
  // PG:
  {
	let message = "";
	if (data != null) 
	{
		if(data.hasOwnProperty('message'))
		{
			message=data.message.trim();
		}
		else
		{
			message=data.trim();
		}
	}
	if (message.indexOf('Permission denied')!=-1)
	{
		alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));
		return false;
	}
	else
	{
		return true;
	}
  }

  async DownloadWithValidation(projectId, type, musicList, navbar: NavbarComponent) {
    const URL = this.downloadUrl + 'validation/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
    let validationReturn = await this.getUrlData(URL);
    let data = validationReturn['data'];
	if (!this.fDownloadMessage(data)) {return false}
    if (validationReturn['status'] == 200) {
      if (data != null && data["stepVerification"] == true) {
        this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
        let instance = this.projectListExistanceModalComponent.componentInstance;
        instance.projectList = data['musicList'];
        this.dataService.updateModalOpen(true);
        // this.modalOpen = true;
        this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
          this.dataService.updateModalOpen(false);
          // this.modalOpen = false;
          if (result['action'] == 'continue') {
            this.messageDialog.openMessageDialog("creating-zip-file-please-wait");
            this.DownloadFile(projectId, type, musicList, navbar);
          }
        });
      } else {
        // this.messageDialog.openMessageDialog("creating-zip-file-please-wait");
        this.DownloadFile(projectId, type, musicList, navbar);
      }
    } else {
		alert(this.i18n.getTranslationsBD('impossible-to-download'));
    }
  }

  async DownloadFile(projectId, type, musicList, navbar: NavbarComponent) {
    const token = this.authService.isLoggedIn ? this.authService.user.token : '0000000000';

    let URL = this.downloadUrl + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + token;


    if (type.toUpperCase().includes('EMAIL')) {
      this.downloadZipMailComponent = this.DownloadZipMailComponent.open(DownloadZipMailComponent, { disableClose: true });
      let instance = this.downloadZipMailComponent.componentInstance;
      instance.emails = this.authService.isLoggedIn ? this.authService.user.email + ";" : "";
      instance.type = type;
      instance.projectId = projectId;
      instance.musicList = musicList;
      this.dataService.updateModalOpen(true);
      // this.modalOpen = true;
      this.downloadZipMailComponent.afterClosed().subscribe((result) => {
        this.dataService.updateModalOpen(false);
        if(!('action' in result)){
          // this.modalOpen = false;
          let emails = result.emails;
          URL += '&emails=' + emails;
          this.messageDialog.openMessageDialog("creating-zip-file-please-wait");
          this.getDownloadEmail(URL);
        } else {
          this.messageDialog.closeMessageDialog();
        }
      });
    } else {
      this.messageDialog.openMessageDialog("creating-zip-file-please-wait");
      this.getDownload(URL, navbar);
    }
  }

  async getDownloadEmail(URL) {
    let downloadReturn = await this.getUrlData(URL);
    let data = downloadReturn['data'];
    this.messageDialog.closeMessageDialog();
	if (data.message.indexOf('Permission denied')!=-1)
	{
		alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));
		return;
	}
    if (downloadReturn['status'] == 200) {
      if (data != null) {
        alert(this.i18n.getTranslationsBD('an-email-as-been-sent-with-a-link-to-download'));
      } else {
        alert(this.i18n.getTranslationsBD('impossible-to-download'));
      }
    } else {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
    }
  }

  async getDownload(URL, navbar: NavbarComponent) {
    let downloadReturn = await this.getUrlData(URL);
    let data = downloadReturn['data'];
    this.messageDialog.closeMessageDialog();
	if (data.message.indexOf('Permission denied')!=-1)
	{
		alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));
		return;
	}
    if (downloadReturn['status'] == 200) {
      if (data != null) { 
        let a = document.createElement('a');
        var file_name =  downloadReturn['data']['linkToDownload'];
		let i=file_name.lastIndexOf('/');
		if (i>-1) {
			file_name=file_name.substring(i+1)
		}								   
        //file_name = file_name.replace( environment.baseUrl , "");
        a.setAttribute('download', file_name);
        a.href = downloadReturn['data']['linkToDownload'];
        document.body.appendChild(a);
        a.click();
        a.remove();
        if(!window.location.href.includes('projects')){
          if (navbar != null) {
            navbar.justGetProjects();
          }
        } 
        if(data.hasOwnProperty('message') && data.message.trim() != ""){
          alert(data.message);
        }
      } else {
        alert(this.i18n.getTranslationsBD('impossible-to-download'));
      }
    } else {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
    }
  } 
}
