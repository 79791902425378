import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeinfoService {

  homeAlbuns = null;
  homeSlides = [];
  homePlaylists = [];
  homeSliderPlaylists = [];
  homeVideos = [];
  newReleases = [];
  constructor() { }

  getNewReleases() {
    return this.newReleases;
  }

  updateNewReleases(releases) {
    this.newReleases = releases;
  }

  updateHomeVideos(videos) {
    this.homeVideos = videos;
  }

  getHomeVideos() {
    return this.homeVideos;
  }

  updateHomeSliderPlaylists(list) {
    this.homeSliderPlaylists = list;
  }

  getHomeSliderPlaylists() {
    return this.homeSliderPlaylists;
  }

  updateHomeAlbuns(albuns) {
    this.homeAlbuns = albuns;
  }

  getHomeAlbuns() {
    return this.homeAlbuns;
  }

  updateHomePlaylists(playlists) {
    this.homePlaylists = playlists;
  }

  getHomePlaylists() {
    return this.homePlaylists;
  }

  updateHomeSlides(slides) {
    this.homeSlides = slides;
  }

  getHomeslides() {
    return this.homeSlides;
  }

}
