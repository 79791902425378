import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private isCatalogSelectedObs$ = new Subject();
  private isResultObs$ = new Subject<boolean>();
  private isSearchPlaylistObs$ = new Subject();
  private isSearchProjectObs$ = new Subject();
  private isAlbumSelectedObs$ = new Subject();
  private trackSelected$ = new Subject();
  private selectedProject: any;
  public selectedTrack = null;
  private projects: any[];
  private downloadFormats: any[];
  private SFXdownloadFormats: any[];
  private _tracks = null;
  private projects$ = new Subject();
  private selectedProject$ = new Subject();
  private modalOpen$ = new Subject();
  private loopOption$ = new Subject();
  private container$ = new Subject();
  private selectedProjectTracks$ = new Subject();
  private selectedAlbum = null;
  public navFromNewReleases = false;
  public scrollTopNewReleases = 0;
  private FPobject = null;
  public routerSubscriber = null;
  public lastURL = null;
  public reloadPlaylist = false;
  
  linkSetted = false;

  private changePageCatalog$ = new Subject<number>();
  private changePageSFX$ = new Subject<number>();
  
  private lastSearchObj = null;

  private initial_playlist_groups = [];

  private initial_catalogs_groups = [];

  private all_advancedOptions_data_catalogs = null;

  
  private lastTrackSelectedSearch = null;
  getLastTrackSelectedSearch(){
    return this.lastTrackSelectedSearch; 
  }
  setLastTrackSelectedSearch(obj){
    this.lastTrackSelectedSearch = obj;
  }


  private lastTrackSelectedSFX = null;
  getLastTrackSelectedSFX(){
    return this.lastTrackSelectedSFX; 
  }
  setLastTrackSelectedSFX(obj){
    this.lastTrackSelectedSFX = obj;
  }


  private lastTrackSelectedCatalog = null;
  getLastTrackSelectedCatalog(){
    return this.lastTrackSelectedCatalog; 
  }
  setLastTrackSelectedCatalog(index){
    this.lastTrackSelectedCatalog = index;
  }


  private lastTrackSelectedPlaylists = null;
  getLastTrackSelectedPlaylists(){
    return this.lastTrackSelectedPlaylists; 
  }
  setLastTrackSelectedPlaylists(index){
    this.lastTrackSelectedPlaylists = index;
  }


  private lastTrackSelectedProjects = null;
  getLastTrackSelectedProject(){
    return this.lastTrackSelectedProjects; 
  }
  setLastTrackSelectedProjec(index){
    this.lastTrackSelectedProjects = index;
  }



  setAdvCatalogsOptions(data){
    this.all_advancedOptions_data_catalogs = data;
  }
  getAdvCatalogsOptions(){
    return this.all_advancedOptions_data_catalogs;
  }
  getInitialCatalogsGroups(){
    return this.initial_catalogs_groups;
  }
  setInitialCatalogsGroups(array){
    this.initial_catalogs_groups = array;
  }

  setLastURL(url){
    this.lastURL = url;
  }
  getLastURL(){
    return this.lastURL;
  }

  getInitialPlaylistsGroups(){
    return this.initial_playlist_groups;
  }

  setInitialPlaylistsGroups(array){
    this.initial_playlist_groups = array;
  }


  updateSFXDownloadFormats(SFXdownloadFormats) {
    this.SFXdownloadFormats = SFXdownloadFormats;
  }

  getSFXDownloadFormats() {
    return this.SFXdownloadFormats;
  }

  getLastSearchObj(){
    return this.lastSearchObj;
  }

  setLastSearchObj(obj){
    this.lastSearchObj = obj;
  }

  getPageSFX() {
    return this.changePageSFX$;
  }

  changePageSFX(page){
    this.changePageSFX$.next(page);
  }


  getPageCatalogos() {
    return this.changePageCatalog$;
  }

  changePageCatalgos(page){
    this.changePageCatalog$.next(page);
  }

  getRouterSub(){
    return this.routerSubscriber;
  }

  updateRouterSub(obj){
    this.routerSubscriber = obj;
  }

  private files = [];


  getFiles(){
    return this.files;
  }

  setFiles(files){
    this.files = files;
  }

  linkIsSetted(val){
    this.linkSetted = val;
  }

  getIfLinkIsSetted(){
    return this.linkSetted;
  }


  updateFPobject(obj) {
    this.FPobject = obj;
  }

  updateFPobjectTracks(tracks) {
    if(this.FPobject!=null){
      this.FPobject.tracks = tracks;
    }
  }

  getFPobject() {
    return this.FPobject;
  }



  updateSelectedProjectTracks(tracks) {
    this.selectedProjectTracks$.next(tracks);
    this._tracks = tracks;
  }

  getSelectedProjectTracks() {
    return this.selectedProjectTracks$;
  }


  
  updateContainer(value) {
    this.container$.next(value);
  }

  getContainer() {
    return this.container$;
  }

  updateLoopOption(value) {
    this.loopOption$.next(value);
  }

  getLoopOption() {
    return this.loopOption$;
  }

  updateModalOpen(value) {
    this.modalOpen$.next(value);
  }

  getModalOpen() {
    return this.modalOpen$;
  }

  updateSelectedAlbum(album) {
    this.selectedAlbum = album;
  }

  getSelectedAlbum() {
    return this.selectedAlbum;
  }

  updateDownloadFormats(DownloadFormats) {
    this.downloadFormats = DownloadFormats;
  }

  getDownloadFormats() {
    return this.downloadFormats;
  }

  updateSelectedProject(project) {
    this.selectedProject = project;
  }

  getSelectedProject() {
    return this.selectedProject;
  }

  getIsCatalogSelected() {
    return this.isCatalogSelectedObs$;
  }

  updateIsCatalogSelected(data: boolean) {
    this.isCatalogSelectedObs$.next(data);
  }

  getIsResult() {
    return this.isResultObs$;
  }

  updateIsResult(data: boolean) {
    this.isResultObs$.next(data);
  }

  getIsSearchPlaylist() {
    return this.isSearchPlaylistObs$;
  }

  updateIsSearchPlaylist(data: boolean) {
    this.isSearchPlaylistObs$.next(data);
  }

  getIsSearchProject() {
    return this.isSearchProjectObs$;
  }

  updateIsSearchProject(data: boolean) {
    this.isSearchProjectObs$.next(data);
  }

  getIsAlbumSelected() {
    return this.isAlbumSelectedObs$;
  }

  updateIsAlbumSelected(data: boolean) {
    this.isAlbumSelectedObs$.next(data);
  }

  getTrackSelected() {
    return this.trackSelected$;
  }
  //
  updateTrackSelected(data: any) {
    this.selectedTrack = data['track'];
    this.trackSelected$.next(data);
  }

  compareWithSelected(track){
    if(this.selectedTrack == null || (this.selectedTrack != null && this.selectedTrack.id != track.id)){
      return true;
    } 
    return false;
  }

  justUpdateTrackSelected(track){
    this.selectedTrack = track;
  }

  checkIfPlaying(track){
    if(this.selectedTrack != null){
    }
    if(this.selectedTrack != null && this.selectedTrack.id == track.id){
      return true;
    }else{
      return false;
    }
  }


  findProjectsSubscribeCancelService = null;
  getProjectsSERVICE() {
    return this.projects$;
  }
  updateProjectsSERVICE(data) {
    this.projects$.next(data);
  }


  navBarProjectSubscriber = null;
  projectProjectSubscriber = null;
  getProjectsSelectedSERVICE() {
    return this.selectedProject$;
  }

  updateProjectsServiceSERVICE(data, newFlag = null) {
    data['new'] = newFlag;
    this.selectedProject$.next(data);
  }

  updateProjects(projects) {
    this.projects = projects;
  }

  getProjects() {
    return this.projects;
  }

  selectedProjectsTracks() {
    return this._tracks;
  }


  projectLoadingSubscriber = null;
  private projectsLoading$ = new Subject();
  getProjectLoading(){
    return this.projectsLoading$;
  }

  updategetProjectLoading(data) {
    this.projectsLoading$.next(data);
  }

  findProjectsSubscribeCancel = null;

  getSelectedTracks = null;
}
