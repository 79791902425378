import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { I18nGlvService } from '../i18n-glv.service';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';
import { ProjectService } from '../project.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-share-projects',
  templateUrl: './share-projects.component.html',
  styleUrls: ['./share-projects.component.scss']
})
export class ShareProjectsComponent implements OnInit {

  constructor(private userService: UserService, public i18n: I18nGlvService,
    public dialogRef: MatDialogRef<ShareProjectsComponent>,
    private projectService: ProjectService, public authService: AuthService) { }
  project = null;
  users = null;
  loading = true;
  cliente = '';
  clientes = [];
  myUser = null;
  VAT = null;
  ID = null;
  CLIENTNAME = null;

  selectedClient = null;
  canChooseClients = false;
  count = 0;
  defaultUsers = [
    {
      Id: -1,
      FirstName: 'Playlist',
      LastName: ' Portugal',
      onlyAccess: false,
      writeAccess: false
    },
    {
      Id: -2,
      FirstName: 'Playlist',
      LastName: ' España',
      onlyAccess: false,
      writeAccess: false
    }
  ];

  ngOnInit() {
	if (this.authService.user.level == 5 || 
		this.authService.user.username == 'spain' || 
		this.authService.user.username == 'portugal') {
	  this.canChooseClients = true;
	}
    this.userService.getClient(this.authService.user.clientId).subscribe(client => {
      this.VAT = client.VAT;
      this.ID = client.Id;
      this.CLIENTNAME = client.CommercialName;
      if ((this.VAT == '501251405' || this.VAT == 'B-81207433' || this.VAT == 'B81207433') && (client.CommercialName == 'Vimúsica' || client.CommercialName == 'Vimúsica España')) {
        this.canChooseClients = true;
      }
      this.searchClient(client.CommercialName);
    });
  }

  changeCliente(id) {
    this.clientes.forEach(client => {
      if (client.id == id) {
        this.selectedClient = client.id;
        this.users = client.users;
        this.users.forEach(user => {
          user.onlyAccess = false;
          user.writeAccess = false;
        });
        return;
      }
    });
  }

  disableCheckbox(project) {
    if (project.ShareRO != null) {
      if (project.ShareRO.includes(this.authService.user.id)) {
        return true
      }
    }
    return false;
  }

  searchClient(search) {
    if (search.length >= 3) {
      this.loading = true;
      this.userService.searchClients(search).subscribe(clients => {
        if (clients != undefined) {
          this.clientes = [];
          this.users = [];
          if (clients.length > 0) {
            clients.forEach(client => {
              const temporaryClient = {
                id: client.Id,
                nome: client.CompanyName,
                users: client.Users,
                vat: client.VAT
              };
              this.clientes.push(temporaryClient);
            });
            this.count++;
            if (this.count == 1) {
              for (let i = 0; i < this.clientes.length; i++) {
                if (this.clientes[i].vat == this.VAT && this.clientes[i].id == this.ID) {
                  this.selectedClient = this.clientes[i].id;
                  this.users = this.clientes[i].users;
                  break;
                }
              }
            } else {
              this.selectedClient = this.clientes[0].id;
              this.users = this.clientes[0].users;
            }

            if ((this.VAT == '501251405' || this.VAT == 'B-81207433' || this.VAT == 'B81207433') && (this.CLIENTNAME == 'Vimúsica' || this.CLIENTNAME == 'Vimúsica España')) {
              for (let index = 0; index < this.defaultUsers.length; index++) {
                const user = this.defaultUsers[index];
                this.users.push(user);
              }
            }

            if (this.project.ShareRO != null && this.project.ShareRO.includes(this.authService.user.id)) {
              for (let i = this.users.length - 1; i >= 0; i--) {
                if (this.users[i].Id != this.authService.user.id) {
                  this.users.splice(i, 1);
                } else {
                  this.users[i].onlyAccess = false;
                  this.users[i].writeAccess = false;
                }
              }
            } else {
              for (let i = this.users.length - 1; i >= 0; i--) {
                if (this.users[i].Id == this.project.tbUtilizadoresID) {
                  this.users.splice(i, 1);
                } else {
                  this.users[i].onlyAccess = false;
                  this.users[i].writeAccess = false;
                }
              }
            }
          }
        }
        this.loading = false;

        if (this.project.ShareRW != null && this.project.ShareRW != "") {
          const ShareRW = this.project.ShareRW;
          const usersArray = this.project.ShareRW.split(';');
          usersArray.forEach(userRW => {
            this.users.forEach(user => {
              if (parseInt(userRW.substr(1), 10) == user.Id) {
                user.writeAccess = true;
                return;
              } else if (user.Id === this.project.tbUtilizadoresID) {
                user.writeAccess = true;
                return;
              }
            });
          });
        }
        if (this.project.ShareRO != null && this.project.ShareRO != "") {
          const ShareRO = this.project.ShareRO;
          const usersArray = this.project.ShareRO.split(';');
          usersArray.forEach(userRO => {
            this.users.forEach(user => {
              if (parseInt(userRO.substr(1), 10) == user.Id) {
                user.onlyAccess = true;
                return;
              }
            });
          });
        }
      });
    }
  }

  cancelar() {
    //event.stopPropagation();
    this.dialogRef.close({ action: 'cancel' });
  }

  shareProjects() {
    let body = {
      ProjectId: this.project.ProjectsID,
      ShareList: [],
    };

    let userArray = [];
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i];
      if (user.onlyAccess || user.writeAccess) {
        let userObj = {
          userId: user.Id,
          shareType: 0
        };
        if (user.onlyAccess && !user.writeAccess) {
          userObj.shareType = 0;
        }
        if (!user.onlyAccess && user.writeAccess) {
          userObj.shareType = 1;
        }
        userArray.push(userObj);
      }
    }
    body.ShareList = userArray;

    // if(userArray.length > 0){
    //   this.projectService.shareProject(body).subscribe(result => {
    //     this.dialogRef.close({action: result});
    //   });
    if (body.ProjectId != 0) {
      this.projectService.shareProject(body).subscribe(result => {
        this.dialogRef.close({ action: result });
      });
    } else {
      this.dialogRef.close({ action: '' });
    }
  }

  checkWriteAccess(readAccess, user) {
    if (readAccess) {
      user.writeAccess = false;
    }
  }

  checkReadAccess(writeAccess, user) {
    if (writeAccess) {
      user.onlyAccess = false;
    }
  }
}
