import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Slide } from './slide';
import { SlidePlaylist } from './slide';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SlideService {

  private slidesUrl = environment.baseUrl + 'home/sliders';  // URL to web api
  private slidesPlaylistsUrl = environment.baseUrl + 'home/playlists';  // URL to web api

  constructor(private http: HttpClient, private messageService: MessageService) {
  }

  /** Log a SlideService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`SlideService: ${message}`);
  }

  /** GET slides from the server */
  getSlides(): Observable<any[]> {
    return this.http.get<any[]>(this.slidesUrl)
      .pipe(
        tap(),
        catchError(this.handleError('getSlides', []))
      );
  }

  /** GET slidesPlaylists from the server */
  getSlidesPlaylists(): Observable<SlidePlaylist[]> {
    return this.http.get<SlidePlaylist[]>(this.slidesPlaylistsUrl)
      .pipe(
        tap(),
        catchError(this.handleError('getSlidesPlaylists', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
