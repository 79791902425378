import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material';
import { NewprojectmodalComponent } from '../newprojectmodal/newprojectmodal.component';
import { I18nGlvService } from '../i18n-glv.service';



@Component({
  selector: 'app-track-operations-modal',
  templateUrl: './track-operations-modal.component.html',
  styleUrls: ['./track-operations-modal.component.scss']
})
export class TrackOperationsModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TrackOperationsModalComponent>, private projectService: ProjectService,
    public i18n: I18nGlvService, public NewprojectmodalComponent: MatDialog) { }

  operation = null;
  tracksToOperate = null;
  projects = null;
  projectID = null;
  selectedProject = null;
  showSelectedProject = false;
  mode = null;
  newProjectdialogRef: MatDialogRef<NewprojectmodalComponent>;

  showMoveButton = false;

  summary = false;
  summaryText = "";

  ngOnInit() {
  }

  cancelar() {
    this.dialogRef.close({ action: 'cancel' });
  }

  close() {
    this.dialogRef.close({ action: 'close' });
  }

  //MODAIS
  newProject() {
    this.newProjectdialogRef = this.NewprojectmodalComponent.open(NewprojectmodalComponent, { disableClose: true });
    let instance = this.newProjectdialogRef.componentInstance;
    instance.project = null;
    this.newProjectdialogRef.afterClosed().subscribe((result) => {
      if (result.message == "success") {
        this.getProjects();
      }
    });
  }

  getProjects() {
    this.projectService.getProjects().subscribe(projects => {
      this.projects = projects['Data'];
      this.selectedProject = this.projects[0].ProjectsID;
    });
  }


  criar(operation) {
    if (this.showSelectedProject) {
      this.projectService.setMultipleTracksToProject(this.tracksToOperate, this.selectedProject).subscribe(result => {
        this.dialogRef.close({ action: 'copy sucess' });
      });
    } else {
      let body = {
        oldProject: this.projectID,
        newProject: this.selectedProject,
        trackList: this.tracksToOperate.map(function (track) { return { ProjectsIID: track.ProjectsIID }; }),
        copy: (operation == 'copiar') ? true : false
      };
      this.projectService.moveTracksToProject(body).subscribe(result => {
        this.summary = true;
        if (result.hasOwnProperty('tracks_ignored')) {
          this.summaryText = result['tracks_ignored'];
        } else if (result.hasOwnProperty('tracks_moved')) {
          this.summaryText = result['tracks_moved'];
        } else {
	  if (result['project_error'].indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          let dialogMessage = (operation == 'copiar') ? 'copy sucess' : 'move sucess';
          this.dialogRef.close({ action: dialogMessage });
        }
      });
    }
  }
}
