import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { ProjectService } from '../project.service';
import { PagerService } from '../pager.service';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Album } from '../album';
import { AlbumService } from '../album.service';
import { I18nGlvService } from '../i18n-glv.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { PlaylistService } from '../playlist.service';
import { SearchService } from '../search.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NewprojectmodalComponent } from '../newprojectmodal/newprojectmodal.component';
import { DeleteProjectComponent } from '../delete-project/delete-project.component';
import { DeleteTrackFromProjectComponent } from '../delete-track-from-project/delete-track-from-project.component';
import { TrackOperationsModalComponent } from '../track-operations-modal/track-operations-modal.component';
import { UserService } from '../user.service';
import { GenreService } from '../genre.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { MusicService } from '../music.service';
import { DownloadZipMailComponent } from '../download-zip-mail/download-zip-mail.component';
import { PlayerHistoryService } from '../player-history.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Location } from '@angular/common';
import { ProjectListExistanceModalComponent } from '../project-list-existance-modal/project-list-existance-modal.component';
import * as moment from 'moment';
import { DownloadFilesService } from "../download-files.service";
import { SaveStateService } from '../save-state.service';


declare var $: any;
const WaveSurfer = window['WaveSurfer'];

@Component({
  providers: [NavbarComponent],
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss']
})

export class PlaylistsComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  tracks = [];
  projects = [];
  isCatalogSelected: any;
  value: number;
  projectID: number;
  pager: any = {};
  loadingTracks = false;
  loadingProjects = true;
  loadingTracksSecondary = false;
  isAdd = false;
  albums: Album[];
  isSpeechBubbleOpen = true;
  isSelectAllTracks = false;
  countTracksSelected = 0;
  isTrackHover = [];
  isPlayerExtras = [];
  showTrackInfo = [];
  wavesurfer = [];
  hiddenPlayer;
  productiontypes = [];
  modalOpen = false;
  volume = 1;
  isLoop = false;
  isPlayAllManually = false;
  isPlayAllAutomatic = false;
  isPlayAllRandom = false;
  isPlayAllSelected = false;
  isPlayAllSelectedRandom = false;
  isSetIntervalDefined = null;
  indexGlobal: number;
  // search
  chips = [];
  isChipPlus = true;
  moreChips: any[];
  secondMoreChips: any[];
  orderBy = null;
  fromInsade = false;
  // paged items
  pagedItems: any[];
  isProjectsFilter: any;
  isAddRemoveLine: any;
  searchProjects: any;
  // sortProjects: any;
  closeTab: any;
  sortType: string;
  sortReverse = false;
  dialogRef: MatDialogRef<NewprojectmodalComponent>;
  deleteDialogRef: MatDialogRef<DeleteProjectComponent>;
  deleteTrackDialog: MatDialogRef<DeleteTrackFromProjectComponent>;
  trackOperationsDialog: MatDialogRef<TrackOperationsModalComponent>;
  downloadZipMailComponent: MatDialogRef<DownloadZipMailComponent>;
  projectListExistanceModalComponent: MatDialogRef<ProjectListExistanceModalComponent>;
  selectedProject: any;
  pagedTracks = [];
  trackPager: any = {};
  trackPage = 1;
  pageValue: number;
  itensPerPage = 1000;
  showTrackRelatedOrSimilar = [];
  showTrackSimilar = [];
  showFromAlbum = [];
  projetos = [];
  selectedProjecto: any;
  tonos = [];
  modal = false;
  downloadFormats = [];
  prefDownloadLabel = '';
  downlaodOneTrackAsZip: any;
  findMusicaSubscribeCansel = null;
  actualMusic;
  secondaryInputs = [];
  language: any;

  showMenuMobile = [];
  clickTimeOut = null;

  selectedProjectsTracks: any;

  prevXhrRequest = null;
  wasDBL = false;
  nr_of_playlists = 0;
  findMusicaSubscribeCancel = null;
  constructor(public router: Router, private route: ActivatedRoute, private projectService: ProjectService,
    private pagerService: PagerService, private dataService: DataService, private albumService: AlbumService,
    public authService: AuthService, private searchService: SearchService, private musicService: MusicService,
    public i18n: I18nGlvService, public newprojectmodalComponent: MatDialog, public ProjectListExistanceModalComponent: MatDialog,
    public deleteProjectComponent: MatDialog, public deleteTrackFromProjectComponent: MatDialog,
    public trackOperationsModalComponent: MatDialog, private genreService: GenreService,
    private playlistService: PlaylistService, private userService: UserService, private navbar: NavbarComponent,
    public DownloadZipMailComponent: MatDialog, public playerHistory: PlayerHistoryService, public location: Location,
    public saveState: SaveStateService, public downloadService: DownloadFilesService) {

	  if (this.authService.isLoggedIn && this.authService.user.username=='spainsh') {
		  // PG:
		  this.router.navigateByUrl("/");
		  return;
	  }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkOpenDDLs();
  }

  checkOpenDDLs(){
    if (window.matchMedia('(min-width: 320px)').matches && !window.matchMedia('(min-width: 640px)').matches) {
      $('.show').removeClass('show');
    } if (window.matchMedia('(min-width: 640px)').matches) {
      this.showMenuMobile = [];
    }
  }

  checkTracksToSelect(i){
    this.clearSelection();  
    let last_index = this.dataService.getLastTrackSelectedPlaylists();
    let initial_index = -1;
    if(last_index != null){
      initial_index = last_index; 
    }    
    if(initial_index == i){
      this.selectTrack(null, i, this.tracks, true);
      return;
    }
    if(initial_index > i){
      for (let it = i; it < initial_index; it++) {
        const id = this.tracks[it].id;
        this.tracks.forEach(element => {
          if (element.id == id) {
            element.isTrackSelected = true;
            this.dataService.setLastTrackSelectedCatalog(it);
          }
        });
      }
    } else {
      for (let it = initial_index +1; it <= i; it++) {
        const id = this.tracks[it].id;
        this.tracks.forEach(element => {
          if (element.id == id) {
            element.isTrackSelected = true;
            this.dataService.setLastTrackSelectedCatalog(it);
          }
        });
      }
    }
    let count = 0;
    this.tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    this.countTracksSelected = count;
    setTimeout(() => {
      this.clearSelection();  
    }, 0);
  }

  clearSelection()
  {
   if (window.getSelection) {
     window.getSelection().removeAllRanges();
    }
  }
  
  removeTracksFromProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      if(i != null){
        this.selectTrack(null, i, this.pagedTracks);
        return;
      }
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    let tracksToDelete = [];
    if (track != null) {
      const obj = {
        Context1J: track.Context1J,
        Context1K: track.Context1K,
        Track: track.Track,
        MetaDataID: track.id
      }
      tracksToDelete.push(obj);
    } else {
      for (let i = 0; i < this.tracks.length; i++) {
        if (this.tracks[i].isTrackSelected) {
          const obj = {
            Context1J: this.tracks[i].Context1J,
            Context1K: this.tracks[i].Context1K,
            Track: this.tracks[i]['Track'],
            MetaDataID: this.tracks[i].id
          };
          tracksToDelete.push(obj);
        }
      }
    }
    this.selectedProjecto = this.dataService.getSelectedProject();
    this.modalOpen = true;
    this.deleteTrackDialog = this.deleteTrackFromProjectComponent.open(DeleteTrackFromProjectComponent, { disableClose: true });
    let instance = this.deleteTrackDialog.componentInstance;
    instance.projectID = this.selectedProjecto.ProjectsID;
    instance.tracksToDelete = tracksToDelete;
    this.deleteTrackDialog.afterClosed().subscribe((result) => {
      this.navbar.justGetProjects();
      this.modalOpen = false;
    });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.downlaodOneTrackAsZip = this.authService.user.Pref_DownloadSingleTrackZip;
      this.getGenres();
      this.dataService.setLastTrackSelectedPlaylists(null);
      this.downloadFormats = this.dataService.getDownloadFormats();
      if (this.downloadFormats == undefined) {
        this.userService.getPreferedDownload(this.authService.user.id).subscribe(downloads => {
          this.dataService.updateDownloadFormats(downloads);
          this.downloadFormats = this.dataService.getDownloadFormats();
          this.downloadFormats.forEach(format => {
            if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
              this.prefDownloadLabel = format.DownloadFormatEN;
              return;
            }
          });
        });
      }
      this.loadProjectData();
    } else {
      this.language = setInterval(() => {
        if (this.i18n.isLanguageReady) {
          this.loadProjectData();
          clearInterval(this.language)
        }
      }, 100);
    }
  }

  loadProjectData() {
    let playlists_temp = this.saveState.getLastFindPlaylists();
    let playlists_chips_temp =  this.saveState.getLastSearchChipsPlaylists();
    let pId = window.location.href.split("/");    
    if(pId[4]!=undefined){
      this.chips = [];
      this.secondaryInputs = [];
      this.saveState.setLastFindPlaylists(null);
      this.saveState.removelastSearchPlaylists();
      $("#row-one").val('');   
      $("#keywords_title").val('');
      $("#keywords_keywords").val('');
      this.isAddRemoveLine = false;
      this.getProjects();
    }
    else{
      if(playlists_temp == null && playlists_chips_temp == null || 
         playlists_temp == null && ((playlists_chips_temp.Text.trim() == "" || playlists_chips_temp.Text.trim() == ",") && (playlists_chips_temp.Keywords.trim() == "" || playlists_chips_temp.Keywords.trim() == ",") && (playlists_chips_temp.TitleKeywords.trim() == "" || playlists_chips_temp.TitleKeywords.trim() == ","))){
        if(this.dataService.getInitialPlaylistsGroups() == undefined || this.dataService.getInitialPlaylistsGroups().length == 0){
          this.getProjects();
        } else {
          this.projects = this.dataService.getInitialPlaylistsGroups();
          this.nr_of_playlists = 0;
          this.projects.forEach(projectGroup => {
            projectGroup.isVisible =true;
            projectGroup.PlayLists.forEach(playlist => {
              playlist.isVisible;
              this.nr_of_playlists++;
            });
          });
          this.loadingProjects = false;
        }
      } else {
        if(playlists_temp == null){
          let obj = playlists_chips_temp;
          if(obj.Keywords != "" || obj.TitleKeywords != ""){
            setTimeout(() => {
              let chips = obj.Text.split(",");
              chips.forEach(element => {
                this.addChip(element, null);
              });
              setTimeout(() => {
                this.getProjects('search'); 
               }, 1);
            }, 1);
          } else {
            this.isAddRemoveLine = false;
             let chips = obj.Text.split(",");
             chips.forEach(element => {
              this.addChip(element, null);
             });
             setTimeout(() => {
              this.getProjects('search');
             }, 1);
          }
        } else {
          if(playlists_chips_temp.Keywords != "" || playlists_chips_temp.TitleKeywords != ""){
            this.isAddRemoveLine = true;
            setTimeout(() => {
              let keywords_keywords = playlists_chips_temp.Keywords.replace(/,/g, " ");
              let keywords_title = playlists_chips_temp.TitleKeywords.replace(/,/g, " ");
              $("#keywords_title").val(keywords_title);
              $("#keywords_keywords").val(keywords_keywords);  
             }, 1);
          } else{
            this.isAddRemoveLine = false;
          }
          setTimeout(() => {
            let chips = playlists_chips_temp.Text.split(",");
            chips.forEach(element => {
              this.addChip(element, null);
            });
          }, 1);
          this.projects = playlists_temp;
          this.dataService.setInitialPlaylistsGroups(playlists_temp);
          this.projects.forEach((project_group, index) => {
            project_group.PlayLists.forEach((project) => {
              this.nr_of_playlists++;
              project['isOpen'] = false;
              const productionTypeCode = project.ProductionType1 + project.ProductionType2 + project.ProductionType3;
              if (productionTypeCode != 0 && productionTypeCode.trim() != '') {
                let productionTypeLabel = '';
                this.productiontypes.forEach(productionType => {
                  if (productionTypeCode == productionType.Code) {
                    const language = this.i18n.language.toUpperCase();
                    productionTypeLabel = productionType[language];
                    project.productionTypeLabel = productionTypeLabel;
                    return;
                  }
                });
              }
            });
          });
          this.nr_of_playlists = 0;
          this.projects.forEach(project => {
            project.PlayLists.forEach(playlists => {
              if(playlists.isVisible){
                this.nr_of_playlists++;
              }
            });
          });
          this.loadingProjects = false;
        }
      }
    }
  
    this.getAlbums();
    this.getProductionTypes();

    this.dataService.getSelectedProjectTracks().subscribe(data => {
      this.selectedProjectsTracks = data;
    });

    this.dataService.getIsCatalogSelected().subscribe(data => {
      this.isCatalogSelected = data;
    });
    this.dataService.getModalOpen().subscribe(value => {
      this.modalOpen = value as boolean;
    });
  }

  checkIfMusicExistsOnSelectedProject(track) {
    if (this.selectedProjectsTracks != undefined) {
      if (this.selectedProjectsTracks.some(e => e.id == track.id)) {
        track.isProjectIconEnabeled = true;
        return true;
      } else {
        track.isProjectIconEnabeled = false;
        return false;
      }
    } else {
      return false;
    }
  }

  getGenres(): void {
    let already_genres = this.saveState.getInitialGenres();
    if(already_genres != null){
      const tempGenres = already_genres;
      this.tonos = this.getGenresByLetter(tempGenres['Q']);
    } else {
      this.genreService.getGenres().subscribe(genres => {
        const tempGenres = this.processGeneres(genres);
        this.tonos = this.getGenresByLetter(tempGenres['Q']);
      });
    }
  }

  processGeneres(genres) {
    const temp_genres = {
      A: [],
      C: [],
      D: [],
      E: [],
      F: [],
      J: [],
      Q: []
    };
    genres.forEach(genre => {
      const obj = {
        name: genre.Ctg,
        context: genre.Ctx,
        subCategory: []
      };
      genre.SCtg.forEach(subCat => {
        obj.subCategory.push({ name: subCat.Ctg, contextName: '', context: subCat.SCtx });
      });

      if (genre.Tp == 'A') {
        temp_genres.A.push(obj);
      } else if (genre.Tp == 'C') {
        temp_genres.C.push(obj);
      } else if (genre.Tp == 'D') {
        temp_genres.D.push(obj);
      } else if (genre.Tp == 'E') {
        temp_genres.E.push(obj);
      } else if (genre.Tp == 'F') {
        temp_genres.F.push(obj);
      } else if (genre.Tp == 'J') {
        temp_genres.J.push(obj);
      } else if (genre.Tp == 'Q') {
        temp_genres.Q.push(obj);
      }
    });
    return temp_genres;
  }

  getGenresByLetter(_genres): any[] {
    const lista = [];
    for (const key in _genres) {
      if (_genres.hasOwnProperty(key)) {
        const element = _genres[key];
        const subgenres = [];
        for (const key2 in element.subCategory) {
          if (element.subCategory.hasOwnProperty(key2)) {
            const subelement = element.subCategory[key2];
            subgenres.push({ 'name': subelement.name, 'context': subelement.context });
          }
        }
        if (subgenres.length === 0) {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0 });
        } else {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0, 'subgenres': subgenres });
        }
      }
    }
    return lista;
  }

  // ADD TO PROJECTS - removed temporarly
  // add Playlist To Project, playlist line-by-line - opens modal
  operateAllMusicsFromProject(playlist) {
    this.projectService.getProjectTracks(playlist.ProjectsID).subscribe(tks => {
      if (tks.length > 0) {
        let tracksToOperate = [];
        for (let a = 0; a < tks.length; a++) {
          const normalizedData = {
            'ProjectsID': playlist.ProjectsID,
            'ProjectsIID': tks[a].ProjectsIID,
            'Context1J': tks[a].Context1J,
            'Context1K': tks[a].Context1K,
            'Track': tks[a].Track,
            'TrackTitle': tks[a].TrackTitle,
            'TrackTime': tks[a].TrackTime,
            'Description': tks[a].Description
          };
          tracksToOperate.push(normalizedData);
        }
        this.projetos = this.dataService.getProjects();
        this.trackOperationsDialog = this.trackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
        this.modalOpen = true;
        let instance = this.trackOperationsDialog.componentInstance;
        instance.tracksToOperate = tracksToOperate;
        instance.projects = this.projetos;
        instance.projectID = playlist.ProjectsID;
        instance.mode = "playlists";

        this.trackOperationsDialog.afterClosed().subscribe((result) => {
          this.modalOpen = false;
          if (result.action == 'move sucess') {
            this.countTracksSelected = 0;
          } else if (result.action == 'copy sucess') {
            this.countTracksSelected = 0;
          }
          this.navbar.justGetProjects();
          this.ngOnInit();
        });
      } else {
        alert("A playlist está vazia");
      }
    });
  }

  // add Playlist To selected project, playlist line-by-line - removed temporarly
  addPlaylistToSelectedProject($event, playlist) {
    event.stopPropagation();
    if (this.tracks != undefined && this.tracks.length > 0) {
      const tracksToAdd = [];
      for (let id = 0; id < this.tracks.length; id++) {
        const obj = {
          Context1J: this.tracks[id].Context1J,
          Context1K: this.tracks[id].Context1K,
          Track: this.tracks[id].track,
          TrackTitle: this.tracks[id].name,
          TrackTime: this.tracks[id].duration,
          Description: this.tracks[id].description,
          isTrackSelected: true,
          metadataID: this.tracks[id].id
        };
        tracksToAdd.push(obj);
      }
      this.selectedProjecto = this.dataService.getSelectedProject();
      this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProjecto.ProjectsID).subscribe(result => {
        this.navbar.justGetProjects();
      });
    } else {
      this.projectService.getProjectTracks(playlist.ProjectsID).subscribe(tks => {
        if (tks.length > 0) {
          this.tracks = tks;
          const tracksToAdd = [];
          for (let id = 0; id < this.tracks.length; id++) {
            const obj = {
              Context1J: this.tracks[id].Context1J,
              Context1K: this.tracks[id].Context1K,
              Track: this.tracks[id].Track,
            };
            tracksToAdd.push(obj);
          }

          this.selectedProjecto = this.dataService.getSelectedProject();
          this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProjecto.ProjectsID).subscribe(result => {
            this.navbar.justGetProjects();
          });

        } else {
          alert("A playlist está vazia");
        }
      });
    }

  }
 
  // add multiple tracks to project, playlist track results - opens modal - removed temporarly
  trackOperations(operation) {
    const tracksToOperate = [];
    for (let a = 0; a < this.tracks.length; a++) {
      if (this.tracks[a].isTrackSelected) {
        const normalizedData = {
          'ProjectsID': this.selectedProject.ProjectsID,
          'ProjectsIID': this.tracks[a].projectIID,
          'Context1J': this.tracks[a].Context1J,
          'Context1K': this.tracks[a].Context1K,
          'Track': this.tracks[a].track,
          'TrackTitle': this.tracks[a].name,
          'TrackTime': this.tracks[a].duration,
          'Description': ''
        };

        tracksToOperate.push(normalizedData);
      }
    }
    this.projetos = this.dataService.getProjects();
    this.selectedProjecto = this.dataService.getSelectedProject();
    this.modalOpen = true;
    this.trackOperationsDialog = this.trackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
    const instance = this.trackOperationsDialog.componentInstance;
    instance.operation = operation;
    instance.tracksToOperate = tracksToOperate;
    instance.projects = this.projetos;
    instance.projectID = this.projectID;
    instance.mode = "playlists";
    this.trackOperationsDialog.afterClosed().subscribe((result) => {
      this.getProjects();
      this.countTracksSelected = 0;
      this.modalOpen = false;
      this.navbar.justGetProjects();
    });
  }

  // add multiple tracks To selected project,  playlist track results
  addMultipleTracksToSelectedProject($event) {
    event.stopPropagation();
    let musicList = "";
    const tracksToAdd = [];
    for (let idx = 0; idx < this.tracks.length; idx++) {
      if (this.tracks[idx].isTrackSelected) {
        const obj = {
          Context1J: this.tracks[idx].Context1J,
          Context1K: this.tracks[idx].Context1K,
          Track: this.tracks[idx].track,
          TrackTitle: this.tracks[idx].name,
          TrackTime: this.tracks[idx].duration,
          Description: this.tracks[idx].description,
          isTrackSelected: true,
          metadataID: this.tracks[idx].id
        };
        tracksToAdd.push(obj);
        musicList += this.tracks[idx].id + ",";
      }
    }
    musicList = musicList.substring(0, musicList.length - 1);
    const type = this.authService.user.Pref_DownloadFormat;
    this.selectedProjecto = this.dataService.getSelectedProject();
    const projectId = this.selectedProjecto.ProjectsID;

    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest =
      this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {

        const errorList = data.ErrorList;
        const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
            this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
            let instance = this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data['MusicInOtherProjectsList'];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
              this.dataService.updateModalOpen(false);
              this.modalOpen = false;
              if (result['action'] == 'continue') {
                this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProjecto.ProjectsID).subscribe(result => {
                  this.navbar.justGetProjects();
                });
              }
            });
          }
          else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
            this.projectService.setMultipleTracksToProject(tracksToAdd, this.selectedProjecto.ProjectsID).subscribe(result => {
              this.navbar.justGetProjects();
            });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });


  }

  // add title to selected project, playlist tracks, track line-by-line
  addToProjects(event, track, i): void {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    if (!track.isProjectIconEnabeled) {
      const project = this.dataService.getSelectedProject();
      const normalizedData = {
        'ProjectsID': project.ProjectsID,
        'Context1J': track.Context1J,
        'Context1K': track.Context1K,
        'Track': track.track,
        'TrackTitle': track.name,
        'TrackTime': track.duration,
        'Description': ''
      };

      const musicList = track.id;
      const type = this.authService.user.Pref_DownloadFormat;
      this.selectedProjecto = this.dataService.getSelectedProject();
      const projectId = this.selectedProjecto.ProjectsID;

      if (this.prevXhrRequest != null) {
        this.prevXhrRequest.unsubscribe();
        this.prevXhrRequest = null;
      }

      this.prevXhrRequest =
        this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
          const errorList = data.ErrorList;
          const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
          if (errorList != undefined) {
            if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
              this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
              let instance = this.projectListExistanceModalComponent.componentInstance;
              instance.projectList = data['MusicInOtherProjectsList'];
              this.dataService.updateModalOpen(true);
              this.modalOpen = true;
              this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
                this.dataService.updateModalOpen(false);
                this.modalOpen = false;
                if (result['action'] == 'continue') {
                  this.projectService.setTrackToProject(normalizedData).subscribe(() => {
                    project.Titles = (parseInt(project.Titles, 10)) + 1;
                    this.dataService.updateSelectedProject(project);
                    this.navbar.justGetProjects();
                  });
                }
              });
            }
            else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
              this.projectService.setTrackToProject(normalizedData).subscribe(() => {
                project.Titles = (parseInt(project.Titles, 10)) + 1;
                this.dataService.updateSelectedProject(project);
                this.navbar.justGetProjects();
              });
            } else {
              if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
            }
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
          this.prevXhrRequest = null;
        });
    }
  }

  // add title to project, playlist tracks, track line-by-line, opens modal - removed
  addOneTrackToProject(track) {
    let tracksToAdd = [];

    let obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
      Track: track.track,
      TrackTitle: track.name,
      TrackTime: track.duration,
      Description: track.description,
      isTrackSelected: true,
      metadataID: track.id
    }
    tracksToAdd.push(obj);
    this.projetos = this.dataService.getProjects();
    this.modalOpen = true;
    this.trackOperationsDialog = this.trackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
    let instance = this.trackOperationsDialog.componentInstance;
    instance.tracksToOperate = tracksToAdd;
    instance.projects = this.projetos;
    instance.projectID = null;
    instance.mode = "playlists";
    this.trackOperationsDialog.afterClosed().subscribe((result) => {
      let success = false;
      this.countTracksSelected = 0;
      this.navbar.justGetProjects();
      this.modalOpen = false;
    });
  }

  // needs refactor
  addAlbumsToProject() {
    const tracksToAdd = [];
    let stringValidate = "";
    for (let id = 0; id < this.tracks.length; id++) {
      const track = this.tracks[id];
      if (track.isTrackSelected) {
        const obj = {
          Context1J: track.Context1J,
          Context1K: track.Context1K,
        };
        tracksToAdd.push(obj);
        stringValidate += track.Context1J + "_" + track.Context1K + ",";
      }
    }
    stringValidate = stringValidate.substring(0, stringValidate.length - 1);
    this.selectedProjecto = this.dataService.getSelectedProject();
    this.projectService.validateAlbumExistance(this.selectedProjecto.ProjectsID, stringValidate).subscribe(data => {
      const errorList = data.ErrorList;
      if (errorList != undefined) {
        if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
          this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
          let instance = this.projectListExistanceModalComponent.componentInstance;
          instance.projectList = data['MusicInOtherProjectsList'];
          this.dataService.updateModalOpen(true);
          this.modalOpen = true;
          this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
            this.dataService.updateModalOpen(false);
            this.modalOpen = false;
            if (result['action'] == 'continue') {
              this.projectService.addAlbunsToProject(this.selectedProjecto.ProjectsID, tracksToAdd).subscribe(data => {
                this.navbar.justGetProjects();
              });
            }
          });
        } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
          this.projectService.addAlbunsToProject(this.selectedProjecto.ProjectsID, tracksToAdd).subscribe(data => {
            this.navbar.justGetProjects();
          });
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      } else {
        if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
      }
    });
  }

  // add album to project
  addAlbumToProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    let tracksToAdd = [];
    const stringValidate = track.Context1J + "_" + track.Context1K;
    const obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
    };
    tracksToAdd.push(obj);
    this.selectedProjecto = this.dataService.getSelectedProject();
    this.projectService.validateAlbumExistance(this.selectedProjecto.ProjectsID, stringValidate).subscribe(data => {
      const errorList = data.ErrorList;
      if (errorList != undefined) {
        if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
          this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
          let instance = this.projectListExistanceModalComponent.componentInstance;
          instance.projectList = data['MusicInOtherProjectsList'];
          this.dataService.updateModalOpen(true);
          this.modalOpen = true;
          this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
            this.dataService.updateModalOpen(false);
            this.modalOpen = false;
            if (result['action'] == 'continue') {
              this.projectService.addAlbunsToProject(this.selectedProjecto.ProjectsID, tracksToAdd).subscribe(data => {
                this.navbar.justGetProjects();
              });
            }
          });
        } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1) || Object.entries(errorList).length === 0) {
          this.projectService.addAlbunsToProject(this.selectedProjecto.ProjectsID, tracksToAdd).subscribe(data => {
            this.navbar.justGetProjects();
          });
        }
        else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      } else {
        if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
      }
    });
  }

  // delete albuns from project
  deleteAlbumsFromProject() {
    const tracksToDelete = [];
    for (let id = 0; id < this.tracks.length; id++) {
      const track = this.tracks[id];
      if (track.isTrackSelected) {
        const obj = {
          Context1J: track.Context1J,
          Context1K: track.Context1K,
        };
        tracksToDelete.push(obj);
      }
    }
    this.selectedProjecto = this.dataService.getSelectedProject();
    this.projectService.deleteAlbunsToProject(this.selectedProjecto.ProjectsID, tracksToDelete).subscribe(data => {
      this.navbar.justGetProjects();
    });
  }

  // delete Album from project
  deleteAlbumFromProject(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    const tracksToDelete = [];
    const obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
    };
    tracksToDelete.push(obj);
    this.selectedProjecto = this.dataService.getSelectedProject();
    this.projectService.deleteAlbunsToProject(this.selectedProjecto.ProjectsID, tracksToDelete).subscribe(data => {
      this.navbar.justGetProjects();
    });
  }

  downloadAllTracksOfPlaylist($event, playlist, format) {
    if ($event != null) {
      $event.stopPropagation();
    }
    this.projectService.getProjectTracks(playlist.ProjectsID).subscribe(tks => {
      let musicList = "";
      for (let i = 0; i < tks.length; i++) {
        musicList += tks[i].MetaDataID + ",";

      }
      musicList = musicList.substring(0, musicList.length - 1);
      let type;

      if (format == null) {
        type = this.authService.user.Pref_DownloadFormat;
      } else {
        type = format.DownloadFormat;
      }

      this.selectedProjecto = this.dataService.getSelectedProject();
      const projectId = this.selectedProjecto.ProjectsID;
      this.loadingTracks = true;

      if (this.prevXhrRequest != null) {
        this.prevXhrRequest.unsubscribe();
        this.prevXhrRequest = null;
      }

      this.prevXhrRequest =
        this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
          const errorList = data.ErrorList;
          const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
          if (errorList != undefined) {
            if (errorList.hasOwnProperty('MusicNotExistsInThisProject') || Object.entries(errorList).length === 0) {
              let a = document.createElement("a");
              a.setAttribute("type", "hidden"); // make it hidden if needed

              a.href = url;
              document.body.appendChild(a);
              a.click();
              a.remove();
              this.navbar.justGetProjects();
            } else {
              if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
            }
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
          this.prevXhrRequest = null;
        });

    });
  }

  oneDownloadToRuleThemAll(event, track, selectedType, i) {
    if (this.authService.isLoggedIn && this.authService.user.Accs_MaxDownloads === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
      if(event != undefined && event != null && event.ctrlKey){
        if(i != null){
          this.selectTrack(null, i, this.pagedTracks);
          return;
        }
      }
      if(event != undefined && event != null && event.shiftKey){ 
        if(i != null){
          this.checkTracksToSelect(i);
          return;
        }
      }
    }

    let musicList = this.downloadService.prepareDownloadList(track, this.tracks);
    let type = this.downloadService.prepareType(selectedType);
    let projectId = this.downloadService.prepareProjectId(this.dataService.getSelectedProject());
    let emails = null;

    this.downloadService.DownloadWithValidation(projectId, type, musicList, this.navbar);
  }
  
  setTracksPage(page: number, gi, i, where, action = null) {
    if (page < 1 || page > this.trackPager.totalPages || isNaN(page)) {
      return;
    }

    this.indexGlobal = null;
    this.tracks.map(function (x) {
      if (x !== undefined) {
        x.isloadToWave = false;
        // x.isPlaying = false;
      }
    });

    if (this.tracks) {
      // this.tracks = this.musicService.checkAllAlreadyPlayed(this.tracks);
      this.trackPager = this.pagerService.getPager(this.tracks.length, page, this.itensPerPage);
      this.pagedTracks = this.tracks.slice(this.trackPager.startIndex, this.trackPager.endIndex + 1);
    }
    this.pageValue = +this.trackPager.currentPage;
    if (action != null && action == 'next') {
      this.goToTrack(1);

      let url;
      if (this.authService.isLoggedIn) {
        url = environment.baseUrl + 'music/streaming/' + this.pagedTracks[0].id + '/?token=' + this.authService.user.token;
      } else {
        url = environment.baseUrl + 'music/streaming/free/' + this.pagedTracks[0].ProjectsID + '/' + this.pagedTracks[0].id;
      }
      this.pagedTracks[0].url = url;
      const obj = {
        track: this.pagedTracks[0],
        i: 0,
        tracks: this.pagedTracks
      };
      this.indexGlobal = 0;
      this.dataService.updateTrackSelected(obj);
      this.pagedTracks[0].isPlaying = true;
    } else if (action != null && action == 'previous') {
      this.goToTrack(this.pagedTracks.length);

      let url;
      if (this.authService.isLoggedIn) {
        url = environment.baseUrl + 'music/streaming/' + this.pagedTracks[this.pagedTracks.length - 1].id + '/?token=' + this.authService.user.token;
      } else {
        url = environment.baseUrl + 'music/streaming/free/' + this.pagedTracks[this.pagedTracks.length - 1].ProjectsID + '/' + this.pagedTracks[this.pagedTracks.length - 1].id;
      }
      this.pagedTracks[this.pagedTracks.length - 1].url = url;
      const obj = {
        track: this.pagedTracks[this.pagedTracks.length - 1],
        i: this.pagedTracks.length - 1,
        tracks: this.pagedTracks
      };
      this.indexGlobal = this.pagedTracks.length - 1;
      this.dataService.updateTrackSelected(obj);
      this.pagedTracks[this.indexGlobal].isPlaying = true;
    }
    setTimeout(() => {
      if($("#playlist-group-body"+gi).css("display") == "none"){
        $("#playlist-group-body"+gi).slideToggle();
        setTimeout(() => {
          $("#project-tracks-"+gi+"-"+i).slideToggle(); 
          setTimeout(() => {
            if(where == 'to_project'){
              this.goToPlaylistGroup(gi, i);
            } else {
              this.goToPlaylistGroup(gi, null);
            }
            this.updateMusics();
          }, 400); 
        }, 400);
      } else {
        $("#project-tracks-"+gi+"-"+i).slideToggle();
        this.goToPlaylistGroup(gi, i);
        this.updateMusics();
      }  
    }, 0);
  }

  goToPlaylistGroup(gi, i,timeout = null){
    if( i == null ){
      const target_project = this.projects[gi];
      this.projects =  this.dataService.getInitialPlaylistsGroups();
      for (let index = this.projects.length-1; index >= 0; index--) {
        const element = this.projects[index];
        if(element.Id == target_project.Id){
          this.projects.splice(index, 1); 
          break;
        }
      }
      this.projects.unshift(target_project);
      var firstVisible = $(".playlist-group-body").first();
      const goTo = firstVisible.offset().top - $("#main-nav").height() - $("#secondary-navbar").height() - $(".playlist-group-header").height() - $(".resultsSum-container").height();
      // $('html, body').scrollTop(goTo);  
      $("html, body").animate({ scrollTop: goTo }, "slow");
    } else {
      const goTo = $("#project-tracks-0-"+i).offset().top - $("#heading-project-0-"+i).height() - $("#main-nav").height() - $("#secondary-navbar").height() - $(".resultsSum-container").height();
      // $('html, body').scrollTop(goTo); 
      $("html, body").animate({ scrollTop: goTo }, "slow"); 
    }
  }

  goToTrack(index) {
    setTimeout(() => {
      $("html, body").animate({ scrollTop: 0 }, "slow"); 
    }, 200);
  }

  // ALBUNS
  getAlbums(): void {
    this.albumService.getAlbums().subscribe(albums => {
      const idioma = this.i18n.language;
      let path = '';
      let albumslist = [];
      switch (idioma) {
        case 'pt':
        case 'es':
        case 'en':
          path = albums[idioma].path + '/';
          albumslist = albums[idioma].albuns;
          break;
        default:
          path = albums['en'].path + '/';
          albumslist = albums['en'].albuns;
          break;
      }
      const listalbuns = [];
      for (let index = 0; index < albumslist.length; index++) {
        const element = albumslist[index];

        listalbuns[index] = {
          'id': index,
          'name': element.name,
          'description': element.description,
          'reference': element.code,
          'image': element.file,
          'thumb': element.thumb,
          'showHome': true
        };
      }
      this.albums = listalbuns;
      this.setPage(1);
    });
  }

  getProjects(method = null) {
    this.loadingProjects = true;
    this.showTrackInfo.length = 0;
    if (!this.authService.isLoggedIn) {
      this.nr_of_playlists = 0;
      this.playlistService.getPlaylists().subscribe(playlist => {
        const idioma = this.i18n.region.toLocaleLowerCase();
        let playlists = [];
        switch (idioma) {
          case 'pt':
          case 'es':
          case 'en':
            playlists = playlist[idioma].playlistgroups;
            break;
          default:
            playlists = playlist['en'].playlistgroups;
            break;
        }

        const listPlaylists = [];
        let selectedIndex = null;
        for (let index = 0; index < playlists.length; index++) {
          const element_group = playlists[index];
          for (let index = 0; index < element_group.PlayLists.length; index++) {
            const element = element_group.PlayLists[index];
            const temp_playlist = {
              'id': index,
              'name': element.name,
              'description': element.description,
              'code': element.code,
              'image': element.file,
              'thumb': element.thumb,
              'internal_link': element.internal_link,
  
              'ProjectsID': parseInt(element.code, 10),
              'tbUtilizadoresID': 0,
              'ProjectTitle': element.name,
              'ProjectSubTitle': element.description,
              Client: 'Demo',
              Comments: null,
              Company: null,
              DiscPerc: null,
              Downloaded: 'N',
              DtPayed: null,
              EMail: null,
              LicenseYears: null,
              LicenseYearsInput: null,
              ModifiedDate: '2019-03-16T22:09:10.35',
              PayStatus: '5',
              Payed: 'S',
              PostalCode: null,
              ProductService: null,
              ProductionDuration: '00:00:00',
              ProductionType1: null,
              ProductionType2: null,
              ProductionType3: null,
              ProductionType4: null,
              ProductionType5: null,
              ShareRO: 'U-2',
              ShareRW: '',
              Shared: 'X',
              StartDate: '2016-01-16T12:10:07.773',
              Status: '1',
              TaxPerc: 0,
              Titles: '',
              VAT: null,
              VATTaxCode: '',
              ValueBase: null,
              ValueDisc: null,
              ValueQt: 0,
              ValueTax: 0,
              ValueTotal: 0,
              ValueUnit: 0,
              tbPrecosCod: '',
              isOpen: false
            };
            element_group.PlayLists[index] = temp_playlist;
            listPlaylists.push(temp_playlist);
          }

        }
        this.projects = playlists;
        this.projects.forEach(projectGroup => {
          projectGroup.isVisible = true;
          projectGroup.PlayLists.forEach(playlist => {
            playlist.isVisible = true;
          });
        });

        this.dataService.setInitialPlaylistsGroups(playlists);
        let projectList = "";
        listPlaylists.forEach(project => {
          projectList += project.ProjectsID + ",";
          this.nr_of_playlists ++;
        });

        projectList = projectList.substring(0, projectList.length - 1);
        this.projectService.getFreePlaylistTitlesNr(projectList).subscribe(data => {
          data['Data'].forEach((el, it) => {
            for (let it = 0; it < this.projects.length; it++) {
              const element_group = this.projects[it];
              for (let it2 = 0; it2 < element_group.PlayLists.length; it2++) {
                const project = element_group.PlayLists[it2];
                if (project.ProjectsID == el['ProjectId']) {
                  project.Titles = el['Titles'];
                  break;
                }
              }
            }
          });
          this.loadingProjects = false;
          this.readProjectId();
        });  
        
      });
      return;
    }
    this.nr_of_playlists = 0;
    let obj ={
      Text :''
    };
    if (this.findMusicaSubscribeCancel !== null) {
      this.findMusicaSubscribeCancel.unsubscribe();
    }
    this.findMusicaSubscribeCancel = this.playlistService.searchPlaylistNew(obj).subscribe(projects => {
      this.projects = projects['Data'];
      this.projects.forEach(project => {
        project.isVisible = true;
        project.PlayLists.forEach(playlist => {
          playlist.isVisible = true;
          if(playlist.ProjectComments == null){
            playlist.ProjectComments = "";
          }
        });
      });
      this.dataService.setInitialPlaylistsGroups(projects['Data']);
      let indexOfSelected = null;
      this.projects.forEach((project_group, index) => {
        project_group.PlayLists.forEach((project) => {
          this.nr_of_playlists++;
          project['isOpen'] = false;
          const productionTypeCode = project.ProductionType1 + project.ProductionType2 + project.ProductionType3;
          if (productionTypeCode != 0 && productionTypeCode.trim() != '') {
            let productionTypeLabel = '';
            this.productiontypes.forEach(productionType => {
              if (productionTypeCode == productionType.Code) {
                const language = this.i18n.language.toUpperCase();
                productionTypeLabel = productionType[language];
                project.productionTypeLabel = productionTypeLabel;
                return;
              }
            });
          }
          if (project.ProjectsID == this.projectID) {
            this.selectedProject = project;
            // this.selectedProject['isOpen'] = true;
            indexOfSelected = index;
          }
        });
      });
      this.readProjectId();
      if(method !== null){
        this.search(null);
      } else {
        this.loadingProjects = false;
      }
    });
  }
  
  selectedGroup = null;
  togglePlaylist_group(projectGroupId){
    let timeout = 0;
    this.showMenuMobile = [];
    this.projects.forEach(projectGroup => {
      projectGroup.PlayLists.forEach(project => {
        project.isOpen = false;
      });
    });
    if(this.selectedGroup !== null){
      $("#playlist-group-body"+this.selectedGroup).slideToggle();
      if(this.selectedGroup == projectGroupId){
        this.selectedGroup = null;
        return;
      } else {
        timeout = 450;
      }
    } 
    setTimeout(() => {
      this.selectedGroup = projectGroupId;
      let temp_project; 
      for (let it = 0; it < this.projects.length; it++) {
        const candidate = this.projects[it];
        if(candidate.Id == this.selectedGroup){
          temp_project = candidate;
          this.projects.splice(it, 1);
          break;
        }
      }
      this.projects.unshift(temp_project);
      $("#playlist-group-body"+this.selectedGroup).slideToggle();
      this.updateMusics();
      $("html, body").animate({ scrollTop: 0 }, "slow"); 
    }, timeout);
  }

  addTempos($event, track) {
    $event.stopPropagation();
    return false;
  }

  getProductionTypes() {
    if (!this.authService.isLoggedIn) {
      return;
    }
    this.projectService.getProductionTypes().subscribe(data => {
      this.productiontypes = data;
    });
  }

  readProjectId() {
    // READ PROJECT ID 
    this.route.params.subscribe(params => {
      const my_projectID = params.id;
      if (my_projectID !== undefined) {
        if (this.route.snapshot.url[2]) {
          if (this.route.snapshot.url[2].path == 'playSingle') {
            this.isPlayAllManually = true;
            this.isPlayAllAutomatic = false;
            this.isPlayAllRandom = false;
            this.isPlayAllSelected = false;
            this.isPlayAllSelectedRandom = false;
          } else if (this.route.snapshot.url[2].path == 'playAll') {
            this.isPlayAllAutomatic = true;
            this.isPlayAllManually = false;
            this.isPlayAllRandom = false;
            this.isPlayAllSelected = false;
            this.isPlayAllSelectedRandom = false;
          } else if (this.route.snapshot.url[2].path == 'playShuffle') {
            this.isPlayAllAutomatic = false;
            this.isPlayAllManually = false;
            this.isPlayAllRandom = true;
            this.isPlayAllSelected = false;
            this.isPlayAllSelectedRandom = false;
          }
        } else {
          this.isPlayAllManually = false;
          this.isPlayAllAutomatic = false;
          this.isPlayAllRandom = false;
          this.isPlayAllSelected = false;
          this.isPlayAllSelectedRandom = false;
        }
        let gi = null;
        let i = null;
        let group = null;
        for (let it = 0; it < this.projects.length; it++) {
          const project_group = this.projects[it];
          for (let it2 = 0; it2 < project_group.PlayLists.length; it2++) {
            const project = project_group.PlayLists[it2];
            if(project.ProjectsID == my_projectID){
              gi = it;
              i = it2;   
              group = project_group;       
              break;
            }
          }
          if(gi != null && i != null){
            break;
          }
        }
        setTimeout(() => {
          this.togglePlaylist_group(group.Id);  
        }, 0);
      }
    });
  }

  getTracks(projectID, gi, i, where): void {
    this.showTrackInfo.length = 0;
    if (!this.authService.isLoggedIn) {
      this.projectService.getFreePlaylistTacks(projectID).subscribe(tks => {
        this.showTrackInfo = [];
        const listMusica = [];
        for (const key in tks) {
          if (tks.hasOwnProperty(key)) {
            const musica = tks[key];
            const music = {
              id: musica.MetaDataID,
              name: musica.TrackTitle,
              projectIID: musica.ProjectsIID,
              ProjectsID: musica.ProjectsID,

              cover: environment.baseUrl + 'image/album/free/' + musica.ProjectsID + '/' +
                musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_')
                + '/' + musica.Reference,

              link: '',
              duration: musica.TrackTime,
              durationSecs: moment.duration('00:' + musica.TrackTime).asSeconds(),
              reference: musica.Reference,
              track: parseInt(musica.Track, 10),
              isPlaying: false,
              catalog: musica.Catalog,
              titleId: musica.WorkID,
              albumTitle: musica.AlbumTitle,
              albumId: musica.WorkID,
              authors: musica.Authors,
              label: musica.Publisher,
              genres: [''],
              mindStates: [''],
              instruments: [''],
              bpm: musica.BPM,
              keyWords: musica.Keywords,
              description: musica.Description,
              isloadToWave: false,
              metadataID: musica.MetaDataID,
              btVersao: musica.btVersao,
              Seq: musica.Seq,
              timeIcon: null,
              time: null,
              tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
              periodos: musica.Categories.Periods,
              times: musica.Categories.Times,
              // tons: musica.Categories.Keys,
              keys: musica.KeyCode,
              Relateds: musica.Relateds,
              Context1J: musica.Context1J,
              Context1K: musica.Context1K,
              Context1Q: musica.Context1Q,
              Context1E: musica.Context1E,
              Context1I: musica.Context1I,
              Categories: musica.Categories,
              Contexts: musica.Contexts,
              TrackTitle: musica.TrackTitle,
              Authors: musica.Authors,
              Publisher: musica.Publisher,
              Artist: musica.Artist,
              Conductor: musica.Conductor,
              Orchestra: musica.Orchestra,
              Choir: musica.Choir,
              Soloist: musica.Soloist,
              Instrumentalist: musica.Instrumentalist,
              Intensity: musica.Intensity,
              Vocals: musica.Vocals,
              ReferenceIVM: musica.Reference,
              Track: musica.Track,
              currentTime: 0,
              currentTimeDisplay: '00:00',
              show: false,
              url: this.authService.isLoggedIn ? environment.baseUrl + 'music/streaming/' + musica.MetaDataID + '/?token=' + this.authService.user.token : environment.baseUrl + 'music/streaming/free/' + musica.ProjectsID + '/' + musica.MetaDataID,
              alreadyPlayed: this.checkIfAlreadyPlayed(musica.MetaDataID)

            };
            const finalMusic = this.getMusicTono(music);
            listMusica.push(finalMusic);
          }
        }
        this.tracks = listMusica;
        this.tracks.map(function (x) {
          x.isTrackSelected = false;
          return x;
        });
        this.loadingTracks = false;
        if (this.isPlayAllManually) {
          const event = {
            clientX: 0
          };
          setTimeout(() => {
            this.newPlay(this.tracks[0], 0, event);
          }, 1000);
        } else if (this.isPlayAllAutomatic) {
          const event = {
            clientX: 0
          };
          setTimeout(() => {
            this.newPlay(this.tracks[0], 0, event);
          }, 1000);
        } else if (this.isPlayAllRandom) {
          const event = {
            clientX: 0
          };
          setTimeout(() => {
            this.newPlay(this.tracks[0], 0, event);
          }, 1000);
        }
        this.trackPager.totalPages = undefined;
        this.setTracksPage(1, gi, i, where);
       
      });
      return;
    }
    const keywords =  $("#keywords_keywords").val() != undefined ? $("#keywords_keywords").val().split(" ").join(",") : '';
    let obj = {
      PlaylistId: projectID, 
      Keywords: keywords
    };
    this.saveState.setLastSearchPlaylistsTracks(obj);
    this.loadingTracks = true;
    this.findMusicaSubscribeCansel = this.projectService.getPlaylistTracks(obj).subscribe(tks => {
      this.findMusicaSubscribeCansel = null;
      const listMusica = [];
      for (const key in tks) {
        if (tks.hasOwnProperty(key)) {
          const musica = tks[key];
          const labelTime = ' ';
          const TimeLabel = ' ';
          const music = {
            id: musica.MetaDataID,
            name: musica.TrackTitle,
            projectIID: musica.ProjectsIID,
            cover: environment.baseUrl + 'image/album/' + musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~')
              .replace(new RegExp(' ', 'g'), '_') + '/' + musica.Reference + '/?token=' +
              this.authService.user.token + '&username=' + this.authService.user.username,
            link: 'https://vimusicaapi.glove-it.pt/api/music/streaming/11340771/?token=' +
              this.authService.user.token + '&username=' + this.authService.user.username,
            duration: musica.TrackTime,
            durationSecs: moment.duration('00:' + musica.TrackTime).asSeconds(),
            reference: musica.Reference,
            track: parseInt(musica.Track, 10),
            catalog: musica.Catalog,
            titleId: musica.WorkID,
            albumTitle: musica.AlbumTitle,
            albumId: musica.WorkID,
            authors: musica.hasOwnProperty('AuthorsComposers') ? [musica.AuthorsComposers] : [musica.Authors],
            label: musica.Publisher,
            publisher: musica.Publisher,
            genres:
              musica.Categories.Genres[this.i18n.language.toUpperCase()],
            mindStates:
              musica.Categories.Moods[this.i18n.language.toUpperCase()],
            instruments:
              musica.Categories.Instruments[this.i18n.language.toUpperCase()],
            bpm: musica.BPM,
            // keyWords:
            //   musica.Categories.Keys[this.i18n.language.toUpperCase()],
            description: musica.Description,
            isloadToWave: false,
            tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
            times: musica.Categories.Times,
            periodos: musica.Categories.Periods,
            tons: musica.Categories.Keys,
            keys: musica.KeyCode,
            metadataID: musica.MetaDataID,
            metaresultsID: musica.MetaresultsID,
            btVersao: (musica.btVersao === ' ') ? 0 : parseInt(musica.btVersao, 10),
            Relateds: musica.Relateds,
            Context1J: musica.Context1J,
            Context1K: musica.Context1K,
            Context1Q: musica.Context1Q,
            Context1E: musica.Context1E,
            Context1I: musica.Context1I,
            Categories: musica.Categories,
            Contexts: musica.Contexts,
            TrackTitle: musica.TrackTitle,
            Authors: musica.AuthorsComposers,
            Seq: musica.Seq,
            timeIcon: null,
            time: null,
            Publisher: musica.Publisher,
            Artist: musica.Artist,
            Conductor: musica.Conductor,
            Orchestra: musica.Orchestra,
            Choir: musica.Choir,
            Soloist: musica.Soloist,
            Instrumentalist: musica.Instrumentalist,
            Intensity: musica.Intensity,
            Vocals: musica.Vocals,
            ReferenceIVM: musica.Reference,
            Track: musica.Track,
            UpdateRelease: musica.UpdateRelease,
            NewRelease: musica.NewRelease,
            palavrasChave: musica.Keywords,
            letra: musica.Lirics,
            currentTime: 0,
            currentTimeDisplay: '00:00',
            show: false,
            alreadyPlayed: this.checkIfAlreadyPlayed(musica.MetaDataID),
          }; 
          music['isPlaying'] = this.dataService.checkIfPlaying(music);
          music['url'] = `${environment.baseUrl}v2/fplayer/play/${music['id']}/?token=${this.authService.user.token}&t=${key}`;
          music['info_url'] = `${environment.baseUrl}v2/fplayer/musicInfo/${music['id']}/?token=${this.authService.user.token}&t=${key}`;
          listMusica.push(music);
        }
      }
      this.tracks = listMusica;
      this.tracks.map(function (x) {
        x.isTrackSelected = false;
        return x;
      });
      this.loadingTracks = false;
      if (this.isPlayAllManually) {
        const event = {
          clientX: 0
        };
        setTimeout(() => {
          this.newPlay(this.tracks[0], 0, event);
        }, 1000);
      } else if (this.isPlayAllAutomatic) {
        const event = {
          clientX: 0
        };
        setTimeout(() => {
          this.newPlay(this.tracks[0], 0, event);
        }, 1000);
      } else if (this.isPlayAllRandom) {
        const event = {
          clientX: 0
        };
        setTimeout(() => {
          this.newPlay(this.tracks[0], 0, event);
        }, 1000);
      }
      this.trackPager.totalPages = undefined;
      this.setTracksPage(1, gi, i, where);
    });
  }

  checkIfAlreadyPlayed(track){
    if(track.alreadyPlayed && $(track.timeContainer).text().trim() == "00:00" && this.dataService.compareWithSelected(track)){
      return 'alreadyPlayed';
    }
    return '';
  }

  getMusicTono(musica) {
    if (musica.Context1Q != null && musica.Context1Q != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (musica.Context1Q == this.tonos[i].context) {
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.Contexts != null && musica.Contexts != '') {
      let contextArray = musica.Contexts.split(" ");
      for (let i = 0; i < this.tonos.length; i++) {
        if (contextArray.includes(this.tonos[i].context)) {
          musica.Context1Q = this.tonos[i].context;
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.KeyCode != null && musica.KeyCode != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (this.tonos[i].name.charAt(0) == musica.KeyCode) {
          musica.Context1Q = this.tonos[i].context;
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else {
      musica.Context1Q = null;
      musica.tono = null;
      return musica;
    }
    return musica;
  }

  clickOpenInfo($event, i, track) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    let pos = i;
    this.showTrackInfo[i] = !this.showTrackInfo[i];
    if (this.showTrackInfo[pos]) {
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
        this.updateContainer();
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      } else {
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (j != i) {
            this.showTrackInfo[j] = false;
          }
        }
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      }
    }
  }

  addCommentAndRating(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    const rating = track.MyRating != undefined ? track.MyRating : null;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };

    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  removeRating(track, event, i) {
    if(event != undefined && event!= null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    track.MyRating = "";
    const rating = track.MyRating;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };
    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages || isNaN(page)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.albums.length, page);

    // get current page of items
    this.pagedItems = this.albums.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.value = +this.pager.currentPage; // + Cast String to Number
  }

  setProject(project, gi, i) {
    if (project.ProjectsID != this.projectID) {
      this.fromInsade = true;
      this.projectID = project.ProjectsID;
      this.selectedProject = project;
      this.selectedProject['isOpen'] = true;
      if(i != 0){
        var temp_project = this.projects[gi].PlayLists[i];
        this.projects[gi].PlayLists.splice(i, 1);
        this.projects[gi].PlayLists.unshift(temp_project);
        i = 0;
      }
      this.getTracks(this.projectID, gi, i, 'to_project');
      this.location.go('./playlists/' + this.projectID);
      this.countTracksSelected = 0;
      // this.goToPlaylistGroup(gi, i);
    }
  }

  checkIfSameProject(project, gi, i, event) {  
    if (this.selectedProject != undefined && this.selectedProject != null && project.ProjectsID == this.selectedProject.ProjectsID) {
      if($("#project-tracks-"+gi+"-"+i).is(':visible')){
        $("#project-tracks-"+gi+"-"+i).slideToggle(); 
        setTimeout(() => {
          this.selectedProject['isOpen'] = false;
          this.updateMusics();  
        }, 400);
      } else {
        let last_obj = this.saveState.getLastSearchChipsPlaylists();
        let lastSearchTracks = this.saveState.getLastSearchPlaylistsTracks();
        let lastKeywordsSearch = last_obj != null ? last_obj.Keywords : '';
        let lastKeywordsTracks = lastSearchTracks != null ? lastSearchTracks.Keywords : '';
        if(lastKeywordsSearch != lastKeywordsTracks){
          this.selectedProject['isOpen'] = true;
          this.getTracks(this.projectID, gi, i, 'to_project');
          this.countTracksSelected = 0;
        } else {
          this.selectedProject['isOpen'] = true;
          project.isOpen= true;
          setTimeout(() => {
            $("#project-tracks-"+gi+"-"+i).slideToggle();
             this.goToPlaylistGroup(gi, i);
            setTimeout(() => {
              this.updateMusics();  
            }, 400);  
          }, 0);
        }
      }
    } else {
      this.setProject(project, gi, i);
      this.dataService.setLastTrackSelectedPlaylists(null);
    }
  }

  getWidth(id) {
    let div = document.getElementById(id);
    let width = div.offsetWidth;
    return width + "px";
  }

  updateMusics(){
    setTimeout(() => {
      this.tracks.forEach((track, it) => {
        let containerIt = it;
        track.container = '#track-' + containerIt + '-' + track.metadataID + '-progress';
        track.timeContainer = '#currentTime-track-' + containerIt + '-' + track.metadataID;
        if(this.dataService.getFPobject() != null){
          for (let index = 0; index < this.dataService.getFPobject()['tracks'].length; index++) {
            const fpTrack = this.dataService.getFPobject()['tracks'][index];
            if(fpTrack.id == track.id){
              fpTrack.container = track.container;
              fpTrack.timeContainer = track.timeContainer;
              track.currentTime = fpTrack.currentTime;
              track.currentTimeDisplay = fpTrack.currentTimeDisplay;
              break;
            } 
          }
        }
        if(this.dataService.getFPobject() != null && this.dataService.getFPobject()['track'] != null && track.id == this.dataService.getFPobject()['track'].id){
          this.dataService.getFPobject()['track'].container = track.container;
          this.dataService.getFPobject()['track'].timeContainer = track.timeContainer;
        }
        const width = track.currentTime * 100 / track.durationSecs;
        if(width != 0 && width != 100){
          track.playerWidth = width + "%";
          track.borderRight = '1px solid black';
        }
      });
    }, 10);
  }

  selectAllTracks(tracks) {
    this.isSelectAllTracks = !this.isSelectAllTracks;
    if (!this.isSelectAllTracks) {
      this.deselectAllTracks(tracks);
      return;
    }
    let count = 0;
    tracks.map(function (x) {
      x.isTrackSelected = true;
      count++;
      return x;
    });
    this.countTracksSelected = count;
  }

  deselectAllTracks(tracks) {
    tracks.map(function (x) {
      x.isTrackSelected = false;
      return x;
    });
    this.countTracksSelected = 0.000;
    this.isSelectAllTracks = false;
  }

  openPlayerExtras(index) {
    for (let i = 0, len = this.tracks.length; i < len; i++) {
      this.isPlayerExtras[i] = false;
    }
    this.isPlayerExtras[index] = true;
  }

  getWaveformImage(track): string {
    if (!this.authService.isLoggedIn) {
      return this.getWaveImageFreeMusic(track);
    } else {
      let base = `${environment.baseUrl}image/waveform/`;
      base += track.id + '/?token=' +
        this.authService.user.token + '&username=' + this.authService.user.username;

      return base;
    }
  }

  getWaveImageFreeMusic(track): string {
    let base = `${environment.baseUrl}image/waveform/`;
    base += 'free/' + track.ProjectsID + '/' + track.metadataID;
    return base;
  }

  listArrayStringToString(object: string[]): string {
    return object.join(', ') + '.';
  }

  globalKeys(event) {
    event.stopPropagation();

    if (event.code == 'Space') {
      const focused = document.activeElement;
      if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
        event.preventDefault();
      }
    }
    if (!event.ctrlKey && !event.shiftKey && !event.altKey) {
      if ($("#row-one") != null && !this.modalOpen && this.router.url.includes('/playlists')) {
        const focused = document.activeElement;
        if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
          const lockedKeys = ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "Enter", " "];
          if (!lockedKeys.includes(event.key)) {
            event.stopPropagation();
            const input: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
            input.focus();
          }
        }
      }
    }
  }

  checkFreeze() {
    if ($("#freeze").height() == 0) {
      return '';
    } else {
      if(!this.isAddRemoveLine){
        return 'paddingBottomFP';
      } else {
        return 'paddingBottomFPAS';
      }
    }
  }

  playNext($event, gi, index) {
    $event.stopPropagation();
    if($event != undefined && $event!= null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    
    const positionAbsolute = ((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    if (positionAbsolute === this.tracks.length - 1) {
      return;
    }
    if (index === this.pagedTracks.length - 1) {
      this.setTracksPage(this.trackPager.currentPage + 1, gi, index, 'to_project', 'next');
    } else {
      const next = (index + 1) % this.tracks.length;
      let url;
      if (this.authService.isLoggedIn) {
        url = environment.baseUrl + 'music/streaming/' + this.pagedTracks[next].id + '/?token=' + this.authService.user.token;
      } else {
        url = environment.baseUrl + 'music/streaming/free/' + this.pagedTracks[next].ProjectsID + '/' + this.pagedTracks[next].id;
      }
      this.pagedTracks[next].url = url;
      const obj = {
        track: this.pagedTracks[next],
        i: next,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = next;
      this.pagedTracks[next].isPlaying = true;
    }
  }

  playPrev($event, gi, index) {
    $event.stopPropagation();
    if($event != undefined && $event!= null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    const positionAbsolute = ((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    if (positionAbsolute === 0 && this.pageValue == 1) {
      return;
    }
    if (index === 0) {
      this.setTracksPage(this.trackPager.currentPage - 1, gi, index, 'to_project', 'previous');
    } else {
      const prev = (index - 1) % this.itensPerPage;
      let url;
      if (this.authService.isLoggedIn) {
        url = environment.baseUrl + 'music/streaming/' + this.pagedTracks[prev].id + '/?token=' + this.authService.user.token;
      } else {
        url = environment.baseUrl + 'music/streaming/free/' + this.pagedTracks[prev].ProjectsID + '/' + this.pagedTracks[prev].id;
      }
      this.pagedTracks[prev].url = url;
      const obj = {
        track: this.pagedTracks[prev],
        i: prev,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = prev;
      this.pagedTracks[prev].isPlaying = true;
    }
  }

  skipBackward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event!= null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipBackward(10);
      this.indexGlobal = index;
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime + 10;
      if (this.tracks[index].currentTime > this.tracks[index].durationSecs) {
        this.tracks[index].currentTime = 0;
      }
      let url;
      if (this.authService.isLoggedIn) {
        url = environment.baseUrl + 'music/streaming/' + this.pagedTracks[index].id + '/?token=' + this.authService.user.token;
      } else {
        url = environment.baseUrl + 'music/streaming/free/' + this.pagedTracks[index].ProjectsID + '/' + this.pagedTracks[index].id;
      }
      this.pagedTracks[index].url = url;
      const obj = {
        track: this.pagedTracks[index],
        i: index,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
      setTimeout(() => {
        this.indexGlobal = index;
      }, 0);
    }
  }

  skipForward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event!= null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event!= null && $event.shiftKey){ 
      this.checkTracksToSelect(index);
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipForward(10);
      this.indexGlobal = index;
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime + 10;
      if (this.tracks[index].currentTime > this.tracks[index].durationSecs) {
        this.tracks[index].currentTime = 0;
      }
      let url;
      if (this.authService.isLoggedIn) {
        url = environment.baseUrl + 'music/streaming/' + this.pagedTracks[index].id + '/?token=' + this.authService.user.token;
      } else {
        url = environment.baseUrl + 'music/streaming/free/' + this.pagedTracks[index].ProjectsID + '/' + this.pagedTracks[index].id;
      }
      this.pagedTracks[index].url = url;
      const obj = {
        track: this.pagedTracks[index],
        i: index,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
      setTimeout(() => {
        this.indexGlobal = index;
      }, 0);
    }

  }

  loadExtraInfo(iterador: number): void {
    const positionWaveList = iterador;
    //const trackID = this.tracks[positionWaveList].metadataID;
    const trackID = 11340771;
    this.searchService.getMetaInfoMusic(trackID).subscribe(music => {
      const track = this.tracks[positionWaveList];
      const lang = this.i18n.language.toUpperCase();
      track.keyWords = music[lang + '_Keywords'].split(';');
      track.authors = music.AuthorsComposers;
      track.bpm = music.BPM;
      track.label = music.publisher;
      track.description = music[lang + '_Description'];
      track.description = music[lang + '_Description'];
    });
  }

  selectTrack($event, index, tracks, state = null) {
    if($event != null){
      $event.stopPropagation();
      if($event.shiftKey){ 
        this.checkTracksToSelect(index);
        return;
      }
    }
    let count = 0;
    if(state != null){
      this.tracks[index].isTrackSelected = state;
    }
    else {
      tracks[index].isTrackSelected = !tracks[index].isTrackSelected;
    }
    if(tracks[index].isTrackSelected){
      this.dataService.setLastTrackSelectedPlaylists(index);
    }
    tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    this.countTracksSelected = count;
  }

  newPlay(track, i, event){
    if(this.authService.isLoggedIn){
      if(event != undefined && event!= null && event.ctrlKey){
        this.selectTrack(null, i, this.pagedTracks);
        return;
      }
      if(event != undefined && event!= null && event.shiftKey){ 
        this.checkTracksToSelect(i);
        return;
      }
    }
    if(!$(event.target).hasClass('dropdown-toggle') && !$(event.target).hasClass('dropdown-item')) {
      let obj = {
        track: track,
        i: i,
        tracks: this.pagedTracks
      };
      if(this.dataService.checkIfPlaying(obj.track)){
        window['globalWavesurfer'].playPause();
        return;
      }
      if (this.indexGlobal == null) {
        this.dataService.updateTrackSelected(obj);
      } else {
        if (this.indexGlobal == i) {
          window['globalWavesurfer'].playPause();
          if (window['globalWavesurfer'].isPlaying()) {
            track.isPlaying = true;
          } else {
            obj['action'] = 'pause';
            track.isPlaying = false;
          }
          this.dataService.updateTrackSelected(obj);
        } else {
          this.dataService.updateTrackSelected(obj);
        }
      }
    }
    this.indexGlobal = i;
  }

  getPositionClicked(track, i, event) {
    event.stopPropagation();
    if(this.authService.isLoggedIn){
      if(event != undefined && event!= null && event.ctrlKey){
        this.selectTrack(null, i, this.pagedTracks);
        return;
      }
      if(event != undefined && event!= null && event.shiftKey){ 
        this.checkTracksToSelect(i);
        return;
      }
    }
    setTimeout(() => {
      if(!this.wasDBL){
        let posX;
        posX = event.offsetX > 0 ? event.offsetX : event.layerX;
        const width = $('#track-' + i + '-' + track.metadataID).width();
        const percentage = posX * 100 / width;
        const duration = moment.duration('00:' + track.duration).asSeconds();
        const playFrom = percentage * duration / 100;
        track.currentTime = playFrom;
        track.currentTimeDisplay = moment('2015-01-01').startOf('day').seconds(playFrom).format('mm:ss');

        let url;
        if (this.authService.isLoggedIn) {
          // url = environment.baseUrl + 'music/streaming/' + track.id + '/?token=' + this.authService.user.token;
          url = environment.baseUrl + 'v2/fplayer/play/' + track.id + '/?token=' + this.authService.user.token+'&t='+i;
        } else {
          url = environment.baseUrl + 'music/streaming/free/' + track.ProjectsID + '/' + track.id;
        }
        track.url = url;

        const obj = {
          track: track,
          i: i,
          tracks: this.pagedTracks
        };
        this.dataService.updateTrackSelected(obj);
        this.indexGlobal = i;
      }
    }, 400);
  }

  setFocusPlayer(el): void {
    const elem = document.getElementById(el);
    const rect = elem.getBoundingClientRect();
    const top = rect.top + window.scrollY;
    if ($(window).width() < 576) {
      window.scrollTo(0, top - 365);
      return;
    }
    if ($(window).width() < 1900) {
      window.scrollTo(0, top - 365);
      return;
    }
    window.scrollTo(0, top - 365);
    return;
  }

  // SEARCH
  addChip(chip, position): void {
    let _chip = null;
    if (chip.hasOwnProperty('name')) {
      _chip = chip;
      chip = chip.name;
    }
    chip = chip.trim();
    if (!chip || this.chips.length > 5) {
      return;
    }
    if (chip.charAt(0) === '-') {
      this.isChipPlus = false;
      chip = chip.substr(1);
    } else {
      this.isChipPlus = true;
    }
    chip = chip.replace('_', ' ');

    const result = {
      'name': chip,
      'isChipPlus': this.isChipPlus
    };
    if (_chip !== null && _chip.hasOwnProperty('name')) {
      result['context'] = _chip.context;
    }
    if (position == null) {
      this.chips.push(result);
    } else {
      this.chips.splice(position, 0, result);
    }

    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
      this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
    });
  }

  addChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById('secondary-input' + i) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";

    if (value.trim() != "") {
      this.addChip(value, i);
    }
  }

  checkIfDeleteChip(chip) {
    if (chip.name.length == 0) {
      this.deleteChip(chip);
    }
  }

  checkIfDeleteChipFromSearchBar() {
    const el = document.getElementById('row-one') as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.chips.length > 0) {
        const tempChip = this.chips[this.chips.length - 1];
        const text = tempChip.name + " ";
        this.deleteChip(tempChip);
        el.value = text;
      }
    }
  }

  deleteChip(chip): void {
    this.chips = this.chips.filter(c => c !== chip);
    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
      this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
    });
    if (this.chips.length >= 1) {
      this.search(null);
    }
    if (this.chips.length === 0) {
      if(!this.isAddRemoveLine){
        this.searchClean();
      } else {
        this.search(null);
      }
    }
  }

  searchClean(): void {
    this.chips = [];
    this.secondaryInputs = [];
    this.moreChips = [];
    this.secondMoreChips = [];

    this.saveState.setLastFindPlaylists(null);
    this.saveState.removelastSearchPlaylists();

    $("#row-one").val('');   
    $("#keywords_title").val('');
    $("#keywords_keywords").val('');
    this.isAddRemoveLine = false;
    this.search(null);
  }

  search(event){
    if(event != null){
      if((event.keyCode >= 48 && event.keyCode <= 57) ||  (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 32){
        this.loadingProjects = true;
      } else {
        return;
      }
    }
    if(this.selectedGroup != null){
      this.togglePlaylist_group(this.selectedGroup);
    }
    this.projects.forEach(projectGroup => {
      projectGroup.PlayLists.forEach(project => {
        project.isOpen = false;
      });
    });
    this.loadingProjects = true;
    this.nr_of_playlists = 0;
    let texts_to_compare = this.getNormalSearchTerms();
    if(texts_to_compare.length == 0){
      this.projects.forEach(projectGroup => {
        projectGroup.isVisible = true;
        projectGroup.PlayLists.forEach(playlist => {
          playlist.isVisible = true;
          this.nr_of_playlists++;
        });
      });  
    } else {
      this.projects.forEach(projectGroup => {
        var isGroupVisible = false;
        projectGroup.PlayLists.forEach(playlist => {
          let has_one_of_chips = false;
          for (let it = 0; it < texts_to_compare.length; it++) {
            const text_to_compare = texts_to_compare[it];
            if(playlist.ProjectTitle.toUpperCase().includes(text_to_compare.toUpperCase()) || playlist.ProjectSubTitle.toUpperCase().includes(text_to_compare.toUpperCase())){
              has_one_of_chips = true;
              break;
            }
          }
          if(has_one_of_chips){
            playlist.isVisible = true;
            this.nr_of_playlists++;
            isGroupVisible = true;
          } else {
            playlist.isVisible = false;
          }
        });
        if(isGroupVisible){
          projectGroup.isVisible = true;
        } else {
          projectGroup.isVisible = false;
        }
      });
    }
    let keywords_title = [];
    let keywords_keywords = [];
    if(this.isAddRemoveLine) { 
      keywords_title =$("#keywords_title").val().split(" ");
      keywords_keywords = $("#keywords_keywords").val().split(" ");
      let obj = this.saveState.getLastSearchChipsPlaylists();
      let searchType = 'immediate';
      if(obj != null){
        let last_keywords = obj.Keywords;
        if(last_keywords == keywords_keywords){
          searchType = 'immediate';
        } else {
          searchType = 'advanced';
        }
      }        
      if(keywords_keywords.length == 0){
        searchType = 'immediate';
      }
      if(searchType == 'immediate'){
        this.projects.forEach(projectGroup => {
          if(projectGroup.isVisible){
            for(let i = 0; i < projectGroup.PlayLists.length; i++){
              const playlist = projectGroup.PlayLists[i];
              if(playlist.isVisible){
                let has_all_keywords_title = true;
                if(keywords_title.length > 0){
                  for (let it = 0; it < keywords_title.length; it++) {
                    const title_kw = keywords_title[it];
                    if(!playlist.ProjectTitle.toUpperCase().includes(title_kw.toUpperCase()) && !playlist.ProjectSubTitle.toUpperCase().includes(title_kw.toUpperCase())){
                      has_all_keywords_title = false;
                      break;
                    }
                  }
                  if(!has_all_keywords_title){
                    playlist.isVisible = false;
                    this.nr_of_playlists--;
                  }
                }
              }
            }
          }
        });
        this.projects.forEach(projectGroup => {
          let isAnyVisible = false;
          for(let i = 0; i< projectGroup.PlayLists.length; i++){  
            if(projectGroup.PlayLists[i].isVisible){
              isAnyVisible =  true;
              break;
            }
          }
          projectGroup.isVisible = isAnyVisible;
        });
      } else {
        let obj = {
          Text: texts_to_compare.join(","),
          Keywords: keywords_keywords.join(","),
          TitleKeywords: keywords_title.join(",")
        };
        this.nr_of_playlists = 0;
        if (this.findMusicaSubscribeCancel !== null) {
          this.findMusicaSubscribeCancel.unsubscribe();
        }
        this.findMusicaSubscribeCancel = this.playlistService.searchPlaylistNew(obj).subscribe(projects => {
          let temp_projects = projects['Data'];
          this.projects.forEach(projectGroup => {
            let isVisible = false;
            let candidate = null;
            for (let it = 0; it < temp_projects.length; it++) {
              const candidate_temp = temp_projects[it];
              if(candidate_temp.Id == projectGroup.Id){
                isVisible = true;
                candidate = candidate_temp;
                break;
              }
            }
            if(!isVisible){
              projectGroup.isVisible = false;
              projectGroup.PlayLists.forEach(playlist => {
                playlist.isVisible = false;
              });
            } else {
              projectGroup.PlayLists.forEach(playlist => {
                let isPLvisible = false;
                for (let index = 0; index < candidate.PlayLists.length; index++) {
                  const candidate_PL = candidate.PlayLists[index];
                  if(candidate_PL.ProjectsID == playlist.ProjectsID){
                    isPLvisible = true;
                    break;
                  }
                }
                playlist.isVisible = isPLvisible; 
                if(playlist.isVisible){
                  this.nr_of_playlists++;
                }
              });
            }
          });
          if(this.nr_of_playlists>0){
            this.saveState.setLastFindPlaylists(this.projects);
            this.saveState.setLastSearchChipsPlaylists(obj);
          }
          this.loadingProjects = false;
        });
        return;
      }
    }
    let obj = {
      Text: texts_to_compare.join(","),
      Keywords: keywords_keywords.join(","),
      TitleKeywords: keywords_title.join(",")
    };

    if(this.nr_of_playlists>0){
      this.saveState.setLastFindPlaylists(this.projects);
      this.saveState.setLastSearchChipsPlaylists(obj);
    }
    this.loadingProjects = false;
    
  }

  getNormalSearchTerms() {
    const rowOneElement: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
    const rowOneInput: string = rowOneElement.value;
    const rowOneElementAfter: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
    const rowOneInputAfter: string = rowOneElementAfter.value;
    let texts_to_compare = [];
    this.chips.forEach(chip => {
      texts_to_compare.push(chip.name);
    });
    this.secondaryInputs.forEach(input => {
      if(input.value.trim() != ''){
        texts_to_compare.push(input.value.name);
      }
    });
    if(rowOneInputAfter.trim() != ""){
      texts_to_compare.push(rowOneInputAfter.trim());
    }
    return texts_to_compare;
  }

  cleanActualMusic(track, musics) {
    for (let j = musics.length - 1; j >= 0; j--) {
      const music = musics[j];
      if (music.id == track.id) {
        musics.splice(j, 1);
      }
    }
    return musics;
  }

  showSimilars(event, i, track) {
    event.stopPropagation();
    if(event != undefined && event!= null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event!= null && event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    if (!('isSimilar' in track) && !('isRelated' in track) && !('isFromAlbum' in track)) {
      let pos = i;
      this.showTrackSimilar[i] = !this.showTrackSimilar[i];
      if (this.showTrackSimilar[i]) {
        let decrement = 0;
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
          this.showFromAlbum[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsSimilars(track.id, track.titleId).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, false, true, false, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          this.updateContainer();
        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isSimilar' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
      }
    }
  }

  showRelated(event, i, track) {
    if(this.authService.isLoggedIn){
      if(event != undefined && event!= null && event.ctrlKey){
        event.stopPropagation();
        this.selectTrack(null, i, this.pagedTracks);
        return;
      }
      if(event != undefined && event!= null && event.shiftKey){ 
        this.checkTracksToSelect(i);
        return;
      }
      this.wasDBL = true;
      if (window.getSelection) {window.getSelection().removeAllRanges();}
      setTimeout(() => {
        this.wasDBL = false;
      }, 500);
    }
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackRelatedOrSimilar[i] = !this.showTrackRelatedOrSimilar[i];
      if (this.showTrackRelatedOrSimilar[i]) {
        let decrement = 0;
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackRelatedOrSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showFromAlbum[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsRelated(track.id).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, true, false, false, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          this.updateContainer();
        });
      } else {
        const idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isRelated' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
      }
    }
  }

  updateContainer() {
    let isSomethingPlaying = false;
    for (let id = 0; id < this.pagedTracks.length; id++) {
      this.pagedTracks[id].container = '#track-' + id + '-' + this.pagedTracks[id].metadataID + '-progress';
      if (this.pagedTracks[id].isPlaying) {
        this.indexGlobal = id;
        isSomethingPlaying = true;
      }
    }
    this.dataService.updateContainer({ tracks: this.pagedTracks });
    if (!isSomethingPlaying) {
      this.indexGlobal = null;
    }
  }

  getMusicsFromAlbum(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null &&  event.shiftKey){ 
      this.checkTracksToSelect(i);
      return;
    }
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showFromAlbum[i] = !this.showFromAlbum[i];
      if (this.showFromAlbum[i]) {
        let decrement = 0;
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showFromAlbum[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.albumService.getSameAlbum(track).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, false, false, true, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          this.updateContainer();
        });
      } else {
        const idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isFromAlbum' in this.pagedTracks[id])) {
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
      }
    }
  }

  stopPropagation(event, i) {
    event.stopPropagation();
    if(this.authService.isLoggedIn){
      if(event != undefined && event != null && event.ctrlKey){
        if(i!=null){
          this.selectTrack(null, i, this.pagedTracks);
          return;
        }
      }
      if(event != undefined && event != null && event.shiftKey){ 
        if(i!=null){
          this.checkTracksToSelect(i);
          return;
        }
      }
    }
  }

  fim(id) {
    this.viewPort.scrollToIndex(id, 'smooth');
  }

  updateChipValue(id, chip) {
    event.preventDefault();
    const texto = $("#" + id).text();
    chip.name = texto;
  }

  setZIndex(id) {
    if ($('.track-download-dropdown').is(":visible") || $('.track-projects-dropdown').is(":visible") || $('.track-actions-mobile').is(":visible")) {
    } else {
      $(".track").css("z-index", "1");
      $("#" + id).css("z-index", "12");
    }
  }

  setZIndexForce(id, event, i) {
    if(this.authService.isLoggedIn){
      if(event != undefined && event != null && event.ctrlKey){
        this.selectTrack(null, i, this.pagedTracks);
        return;
      }
      if(event != undefined && event != null && event.shiftKey){ 
        this.checkTracksToSelect(i);
        return;
      }
    }
    $(".track").css("z-index", "1");
    $("#" + id).css("z-index", "12");
  }

  mySubString(string, number){
    var resultString = string;
    if(resultString.length > number){
      return resultString.substr(0, number) + "...";
    } else {
      return resultString;
    }
  }

  checkClassForCheckAll(){
    let anySelected = false;
    let allSelected = true;
    for (let it = 0; it < this.pagedTracks.length; it++) {
      const track = this.pagedTracks[it];
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)){
        if(track.isTrackSelected){
          anySelected =  true;
        } else {
          allSelected = false;
        }
      }
    }
    if(allSelected && this.pagedTracks.length>0){
      return 'invertColor';
    } else if(anySelected && this.pagedTracks.length>0){
      return 'fullOpacity';
    } else{
      return '';
    }
  }

  checkIfFreezePlaying(){
    if(window['globalWavesurfer'].isPlaying()){
      return true;
    }
    return false;
  }
  

  //////////////// TESTE NAVEGAÇÃO CHIPS //////////////// 
  //1a LINHA
  chipsNavitagion(evt, side, from){
    evt.stopPropagation();
    const value = from == 'row-one' ? $("#row-one").val() : $("#secondary-input"+from).text();
    if(value.trim() != ''){
      return;
    }    
    const focused = $(':focus');
    const id = focused.attr('id');
    if(side == 'previous'){
      if(id == "row-one"){
        if(this.chips.length > 0){
          $("#secondary-input" + (this.chips.length-1)).focus();
        }
      } else if(focused.hasClass('secondary-search-input')){
        const focusedIt = Number(id.replace('secondary-input',''));
        const targetId = focusedIt - 1;
        if(targetId >= 0){
          $("#secondary-input" + targetId).focus();
        } 
      }
    } else {
      if(focused.hasClass('secondary-search-input')){
        const focusedIt = Number(id.replace('secondary-input',''));
        const targetId = focusedIt + 1;
        if(targetId < this.chips.length){
          $("#secondary-input" + targetId).focus();
        } else {
          $("#row-one").focus();
        }
      }
    }
  }

  checkIfDeleteChipInAbsolutePosition(evt, it, id){
    let val;
    if(id == 'row-one'){
      val = $("#"+id).val();
    } else {
      val = $("#"+id).text();
    }
    if(val.trim() != ''){
      return;
    }
    if(it == 0){
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it-1];
    const content = tempChip.name;
    if(it == this.chips.length){
      this.chips.pop();
      $("#row-one").val(content);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
    } else {
      this.chips.splice(it-1, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
        $("#secondary-input" + (it-1)).text(content);  
        this.setCursor("secondary-input"+(it-1), content.length);
      }, 0);
    }
  }

  setCursor(id, pos) { 
    let tag = document.getElementById(id); 
    let setpos = document.createRange(); 
    let set = window.getSelection(); 
    setpos.setStart(tag.childNodes[0], pos); 
    setpos.collapse(true); 
    set.removeAllRanges();  
    set.addRange(setpos); 
    tag.focus(); 
  } 

  checkIfPreDeleteChipInAbsolutePosition(evt, it){
    const val = $("#secondary-input"+it).text();
    if(val.trim() != ''){
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it];
    const content = tempChip.name;
    if(it == this.chips.length - 1 ){
      this.chips.pop();
      $("#row-one").val(content);
      this.setSelectionRange(document.getElementById('row-one'), 0,0);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      });
    } else {
      this.chips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
        });
        $("#secondary-input" + (it)).text(content).focus();  
      }, 0);
    }
  } 

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      let range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == '0') {
      return oField.selectionStart;
    } else {
      return 0;
    }
  }

  getCaretPosition(element) {
    let caretOffset = 0;
    let range = window.getSelection().getRangeAt(0);
    let preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
    return caretOffset;
  }

  checkIfChangeChip($event, side, i){
    let posI = this.getCaretPosition(document.getElementById("chip"+i));
    setTimeout(() => {
      let posF = this.getCaretPosition(document.getElementById("chip"+i));
      if(side == 'previous'){
        if(posI == 0 && posF == 0){
          $("#secondary-input"+i).focus();
        }
      } else {
        if(posI == $("#chip"+i).text().length && posF == $("#chip"+i).text().length){
         let next = i+1;
         if(document.getElementById('secondary-input'+next)!=null){
           $("#secondary-input"+next).focus();
         }else {
          $("#row-one").focus();
         }
        }
      }
      
    }, 10);
  }
}

