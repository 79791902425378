import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project.service';
import { I18nGlvService } from '../i18n-glv.service';
import { MusicService } from '../music.service';
import { environment } from '../../environments/environment';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { DownloadZipMailComponent } from '../download-zip-mail/download-zip-mail.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SendProjectEmailComponent } from '../send-project-email/send-project-email.component';
import { DataService } from '../data.service';
import { AuthService } from '../auth/auth.service';

import { DownloadFilesService } from "../download-files.service";
import * as moment from 'moment';

import { NewprojectmodalComponent } from "../newprojectmodal/newprojectmodal.component";


declare var $: any;
const WaveSurfer = window['WaveSurfer'];

@Component({
  selector: 'app-shared-projects',
  templateUrl: './shared-projects.component.html',
  styleUrls: ['./shared-projects.component.scss']
})
export class SharedProjectsComponent implements OnInit {

  constructor(private projectService: ProjectService,
    public NewprojectmodalComponent: MatDialog,
    private route: ActivatedRoute,
    public i18n: I18nGlvService,
    private musicService: MusicService,
    private searchService: SearchService,
    private router: Router,
    public DownloadZipMailComponent: MatDialog,
    public SendProjectEmailComponent: MatDialog,
    private dataService: DataService,
    public downloadService: DownloadFilesService,
    public authService: AuthService) {
    if (window['globalWavesurfer'] !== undefined) {
      this.globalWavesurfer = window['globalWavesurfer'];
    }
  }

  sendProjectEmailComponent: MatDialogRef<SendProjectEmailComponent>;
  downloadZipMailComponent: MatDialogRef<DownloadZipMailComponent>;
  token = null;
  project = null;
  tracks = [];
  loadingProjects = true;
  loadingTracksSecondary = false;
  isWavesurferReady = false;
  countTracksSelected = 0;
  resultsNr = 0;
  downloadFormats = [];
  standardFormat = '';
  isTrackHover = [];
  isPlayerExtras = [];
  showTrackInfo = [];
  wavesurfer = [];
  productiontypes = [];
  volume = 1;
  indexGlobal = null;
  isPlayAllManually = false;
  isPlayAllAutomatic = false;
  isPlayAllRandom = false;
  isPlayAllSelected = false;
  isPlayAllSelectedRandom = false;
  isLoop = false;
  isSelectAllTracks = false;
  modalOpen = false;
  prefDownloadLabel = "";
  allProjectInfo = null;
  globalWavesurfer = null;
  prevXhrRequest = null;
  anyPlaying = false;
  selectedTrack = null;
  showMenuMobile = [];
  loopOptions = 'all_manual';
  wasDBL = false;
  timestampGuid = null;
  dialogRef: MatDialogRef<NewprojectmodalComponent>;
  lCanPlay = true;
  anyInputSelected = null;
  changing = false;

  reply() {
    this.modalOpen = true;
    this.sendProjectEmailComponent = this.SendProjectEmailComponent.open(SendProjectEmailComponent, { disableClose: true });
    let instance = this.sendProjectEmailComponent.componentInstance;
    instance.project = this.project;
    instance.user = null;
    instance.userEmail = this.allProjectInfo.userEmail;
    instance.userName = this.allProjectInfo.userName;

    this.sendProjectEmailComponent.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      if (result.action == "result") {
        const nome = result.nome;
        alert(this.i18n.getTranslationsBD('your-email-has-been-sent-to') + ' ' + nome);
      }
    });
  }

  ngOnInit() {
    this.readToken();
	this.login();
    setTimeout(() => {
      $('#username').blur();
    }, 10);
    if(this.authService.user == null){
      this.setSharedProjectsGuid();
    }
  }
  
	login() {
		var x='spain';
		if (this.authService && this.authService.isLoggedIn && this.authService.user.username=='spainsh') {
			return;
		}
		this.authService
			.login('spainsh',"shspain")
			.subscribe(
				(data) => {
					if (data !== undefined) {
						this.authService.isLoggedIn = true;
					}
				},
				(error) => {
					// Handle error
					console.error('Login failed:', error);
				}
			);
	}

	editProject() {
	  if (
		this.project.ShareRO == null ||
		!this.project.ShareRO.includes(this.authService.user.id)
	  ) {
		this.dataService.updateModalOpen(true);
		this.modalOpen = true;
		this.dialogRef = this.NewprojectmodalComponent.open(
		  NewprojectmodalComponent,
		  { disableClose: true }
		);
		let instance = this.dialogRef.componentInstance;
		instance.project = this.project;
		this.dialogRef.afterClosed().subscribe((result) => {
		  this.modalOpen = false;
		  this.getProjectByToken(this.token);
		  this.router.navigate(["/sharedProjects/"+this.token]);
		  //return;
		  //this.dataService.updateModalOpen(false);
		  //this.router.navigateByUrl('/projects', { skipLocationChange: true }).then(() => {
		  //this.router.navigate(["/sharedProjects/"+this.token]);
		  //});
		});
	  }
	}
	
  setSharedProjectsGuid(){
    let guid = localStorage.getItem('sharedProjectsGuid');
    if(guid!=null){
      localStorage.removeItem('sharedProjectsGuid');
    }
    const date = new Date();
    this.timestampGuid = date.getDate() + date.getMonth() + date.getFullYear() +date.getTime();
    localStorage.setItem('sharedProjectsGuid', this.timestampGuid);
  }

  readToken() {
    this.route.params.subscribe(params => {
      this.token = params.token;
      this.getProjectByToken(this.token);
    });
  }

	onBlur(i) {
		this.lCanPlay = true;
		// PG: $$$
	}

  onFocus(i) {
    this.lCanPlay = false;
    this.anyInputSelected = i;
  }

  changeInputDurationUsed(event, i, track) {
    if (this.anyInputSelected != null) {
      if (!this.changing) {
        if (event.key === 'ArrowUp') {
          event.stopPropagation();
          i-- < 0;
          if (i < 0) {
            i = 0;
          }
          this.updateDurationUsed(track);
          //this.changing = true;
        }
        if (event.key === 'ArrowDown') {
          event.stopPropagation();
          i++;
          if (i >= this.tracks.length) {
            i = this.tracks.length - 1;
          }
          this.updateDurationUsed(track);
          //this.changing = true;
        }
		const x='percentage-used-' + i;
		const element = document.getElementById(x);
		if (element) {
			element.focus();
		}
      }
    }
  }

  fisProject2024b(project)
  {
	if (!this.authService.isLoggedIn) {return false;}
	if (this.authService.user.clientCountry.toLowerCase() != 'es') {return false;}
	if (project==null) {return true;} // new project
	if (project.StartDate instanceof Date) {
		let year=project.StartDate.substring(0,4);
		if (parseInt(year)<2024) {return false;}
	}
	return true;
  }

	
  fCanChangeOrDeleteProject(project) {
	// se opção de delete to project fica enable
	// se não poder fazer delete tb nao pode mudar os tempos de utilização das obras
	if (project == null) {return false;}
	if (!this.fisProject2024b(project)) {return false;}
	if (project.Status!='1') {return false;}
	return true;
}
	

  updateDurationUsed(track) {
	// PG: $$$
    if (!track.DurationUsed.trim().includes(":")) {
      if (track.DurationUsed < 60) {
        if (track.DurationUsed < 10) {
          track.DurationUsed = '0' + track.DurationUsed;
        }
        track.DurationUsed = "00:" + track.DurationUsed;
      } else if (track.DurationUsed >= 100) {
        let secs = track.DurationUsed.slice(-2);
        let mins = track.DurationUsed.slice(0, -2);
        if (mins < 10) {
          mins = '0' + mins;
        }
        if (secs < 60) {
          track.DurationUsed = mins + ':' + secs;
        } else {
          alert("Tempo Inválido");
          track.DurationUsed = "00:00";
          return;
        }
      } else {
        alert("Tempo Inválido");
        track.DurationUsed = "00:00";
        return;
      }
    }

    const projectId = this.project.ProjectsID;
    const trackId = track.projectIID;
    this.projectService.updateMusicUsageTime(projectId, trackId, track.DurationUsed.trim()).subscribe(data => {
    });
  }

  getProjectByToken(token) {
    this.projectService.getProjectByToken(token).subscribe(project => {
      this.downloadFormats = project['downloadPref'];
      this.project = project['project'];
      this.standardFormat = project['standartDownloadPref'];
      this.allProjectInfo = project;
      this.downloadFormats.forEach(format => {
        if (format.DownloadFormat == this.standardFormat) {
          this.prefDownloadLabel = format.DownloadFormatEN;
          return;
        }
      });


      if (this.project.AllowAccess == "S") {
        this.projectService.getFreePlaylistTacks(this.project.ProjectsID).subscribe(tks => {
          const listMusica = [];
          for (const key in tks) {

            // if (tks.hasOwnProperty(key)) {
            //     const musica = tks[key];
            //     listMusica.push(
            //         {
            //             id: musica.MetaDataID,
            //             name: musica.TrackTitle,
            //             projectIID: musica.ProjectsIID,
            //             ProjectsID: musica.ProjectsID,

            //             cover: environment.baseUrl + 'image/album/free/' + musica.ProjectsID + '/' +
            //             musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_')
            //             + '/' + musica.Reference.replace(new RegExp(' ', 'g'), '_') + '/',

            //             link: '',
            //             waveform: environment.baseUrl + 'image/waveform/free/' + musica.ProjectsID + '/' + musica.MetaDataID,
            //             duration: musica.TrackTime,
            //             reference: musica.Reference,
            //             track: parseInt(musica.Track, 10),
            //             isPlaying: false,
            //             catalog: musica.Catalog,
            //             titleId: musica.WorkID,
            //             albumTitle: musica.Catalog,
            //             albumId: musica.WorkID,
            //             authors: musica.Authors,
            //             label: musica.Publisher,
            //             genres: [''],
            //             mindStates: [''],
            //             instruments: [''],
            //             bpm: musica.BPM,
            //             keyWords: musica.Keywords,
            //             description: musica.Description,
            //             isloadToWave: false,
            //             metadataID: musica.MetaDataID,
            //             btVersao: musica.btVersao,
            //             Seq: musica.Seq,
            //             timeIcon: null,
            //             time: null,
            //             tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
            //             times: musica.Categories.Times,
            //             keys: musica.KeyCode,
            //             Relateds: musica.Relateds,
            //             Context1J: musica.Context1J,
            //             Context1K: musica.Context1K,
            //             Context1Q: musica.Context1Q,
            //             Context1E: musica.Context1E,
            //             Context1I: musica.Context1I,
            //             Categories: musica.Categories,
            //             Contexts: musica.Contexts,
            //             TrackTitle: musica.TrackTitle,
            //             Authors: musica.Authors,
            //             Publisher: musica.Publisher,
            //             ReferenceIVM: musica.Reference,
            //             Track: musica.Track
            //         }
            //     );
            // }

            if (tks.hasOwnProperty(key)) {
              const musica = tks[key];
              const music = {
                id: musica.MetaDataID,
                name: musica.TrackTitle,
                projectIID: musica.ProjectsIID,
                ProjectsID: musica.ProjectsID,
                cover: environment.baseUrl + 'image/album/free/' + musica.ProjectsID + '/' +
                  musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_')
                  + '/' + musica.Reference,
                link: '',
                duration: musica.TrackTime,
                DurationUsed: musica.DurationUsed,
                reference: musica.Reference,
                track: parseInt(musica.Track, 10),
                isPlaying: false,
                catalog: musica.Catalog,
                titleId: musica.WorkID,
                albumTitle: musica.AlbumTitle,
                albumId: musica.WorkID,
                authors: musica.Authors,
                label: musica.Publisher,
                genres:
                  musica.Categories.Genres[this.i18n.language.toUpperCase()],
                mindStates:
                  musica.Categories.Moods[this.i18n.language.toUpperCase()],
                instruments:
                  musica.Categories.Instruments[this.i18n.language.toUpperCase()],
                bpm: musica.BPM,
                keyWords:
                  musica.Categories.Keys[this.i18n.language.toUpperCase()],
                description: musica.Description,
                isloadToWave: false,
                MetaDataID: musica.MetaDataID,
                metadataID: musica.MetaDataID,
                btVersao: musica.btVersao,
                Seq: musica.Seq,
                timeIcon: null,
                time: null,
                tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
                periodos: musica.Categories.Periods,
                times: musica.Categories.Times,
                tons: musica.Categories.Keys,
                keys: musica.KeyCode,
                Relateds: musica.Relateds,
                Context1J: musica.Context1J,
                Context1K: musica.Context1K,
                Context1Q: musica.Context1Q,
                Context1E: musica.Context1E,
                Context1I: musica.Context1I,
                Categories: musica.Categories,
                Contexts: musica.Contexts,
                TrackTitle: musica.TrackTitle,
                Authors: musica.Authors,
                Publisher: musica.Publisher,
                publisher: musica.Publisher,
                Artist: musica.Artist,
                Conductor: musica.Conductor,
                Orchestra: musica.Orchestra,
                Choir: musica.Choir,
                Soloist: musica.Soloist,
                Instrumentalist: musica.Instrumentalist,
                Intensity: musica.Intensity,
                Vocals: musica.Vocals,
                ReferenceIVM: musica.Reference,
                palavrasChave: musica.Keywords,
                letra: musica.Lirics,
                Track: musica.Track,
                currentTime: 0,
                currentTimeDisplay: '00:00',
                show: false,
                url: environment.baseUrl + 'music/streaming/free/' + musica.ProjectsID + '/' + musica.MetaDataID
              };

              listMusica.push(music);
            }

          }

          this.tracks = listMusica;
          this.loadingProjects = false;
          this.resultsNr = this.tracks.length;
          this.isWavesurferReady = true;
          this.updateMusics();
        });
      } else {
        this.router.navigate(['']);
      }
    });
  }

  updateMusics(){
    this.tracks.forEach((track, it) => {
      let containerIt = it;
      track.container = '#track-' + containerIt + '-' + track.metadataID + '-progress';
      track.timeContainer = '#currentTime-track-' + containerIt + '-' + track.metadataID;
      const width = track.currentTime * 100 / track.durationSecs;
      if(width != 0 && width != 100){
        track.playerWidth = width + "%";
        track.borderRight = '1px solid black';
      }
    });
  }

  selectAllTracks(tracks) {
    this.isSelectAllTracks = !this.isSelectAllTracks;
    if (!this.isSelectAllTracks) {
      this.deselectAllTracks(tracks);
      return;
    }
    let countSelected = 0;
    tracks.map(function (x) {
      x.isTrackSelected = true;
      countSelected++;
      return x;
    });
    this.countTracksSelected = countSelected;
  }

  deselectAllTracks(tracks) {
    tracks.map(function (x) {
      x.isTrackSelected = false;
      return x;
    });
    this.countTracksSelected = 0.000;
    this.isSelectAllTracks = false;
  }

  playPrev($event, index) {
    $event.stopPropagation();
    const positionAbsolute = index;
    if (positionAbsolute === 0) {
      return;
    }
    const prev = index - 1;
    const obj = {
      track: this.tracks[prev],
      i: prev,
      tracks: this.tracks
    };
    this.dataService.updateTrackSelected(obj);
    this.indexGlobal = prev;
    this.tracks[prev].isPlaying = true;

  }

  playNext($event, index) {
    $event.stopPropagation();
    const positionAbsolute = index;
    if (positionAbsolute === this.tracks.length - 1) {
      return;
    }
    const next = index + 1;
    const obj = {
      track: this.tracks[next],
      i: next,
      tracks: this.tracks
    };
    this.dataService.updateTrackSelected(obj);
    this.indexGlobal = next;
    this.tracks[next].isPlaying = true;
  }

  selectTrack(evt, index, tracks) {
    evt.stopPropagation();
    let count = 0;
    tracks[index].isTrackSelected = !tracks[index].isTrackSelected;
    tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    this.countTracksSelected = count;
  }

  skipBackward($event, index) {
    $event.stopPropagation();
    this.tracks[index].currentTime = this.tracks[index].currentTime - 10;
    if (this.tracks[index].currentTime < 0) {
      this.tracks[index].currentTime = 0;
    }
    const obj = {
      track: this.tracks[index],
      i: index,
      tracks: this.tracks
    };
    this.dataService.updateTrackSelected(obj);
    this.indexGlobal = index;
  }

  skipForward($event, index) {
    $event.stopPropagation();
    this.tracks[index].currentTime = this.tracks[index].currentTime + 10;
    if (this.tracks[index].currentTime > this.tracks[index].durationSecs) {
      this.tracks[index].currentTime = 0;
    }
    const obj = {
      track: this.tracks[index],
      i: index,
      tracks: this.tracks
    };
    this.dataService.updateTrackSelected(obj);
    this.indexGlobal = index;
  }

  

  oneDownloadToRuleThemAll(event, track, selectedType) {
    if (this.authService.isLoggedIn && this.authService.user.Accs_MaxDownloads === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
    }

    let musicList = this.downloadService.prepareDownloadList(track, this.tracks, true);
    let type = this.downloadService.prepareType(selectedType);
    let projectId = this.downloadService.prepareProjectId(this.dataService.getSelectedProject(), this.project.ProjectsID);

    this.downloadService.DownloadFile(projectId, type, musicList, null);
  }

  setZIndex(id) {
    if ($('.track-download-dropdown').is(":visible") || $('.track-projects-dropdown').is(":visible") || $('.track-actions-mobile').is(":visible")) {

    } else {
      $(".track").css("z-index", "1");
      $("#" + id).css("z-index", "12");
    }
  }

  setZIndexForce(id) {
    $(".track").css("z-index", "1");
    $("#" + id).css("z-index", "12");
  }

  getWaveImageFreeMusic(track): string {
    let base = `${environment.baseUrl}image/waveform/`;
    base += 'free/' + track.ProjectsID + '/' + track.MetaDataID;
    return base;
  }

  newPlay(track, i, event) {
    if (!$(event.target).hasClass('dropdown-toggle') && !$(event.target).hasClass('dropdown-item')) {
      let obj = {
        track: track,
        i: i,
        tracks: this.tracks
      };
      if (this.indexGlobal == null) {
        this.dataService.updateTrackSelected(obj);
      } else {
        if (this.indexGlobal == i) {
          window['globalWavesurfer'].playPause();
          if (window['globalWavesurfer'].isPlaying()) {
            track.isPlaying = true;
          } else {
            obj['action'] = 'pause';
            track.isPlaying = false;
          }
          this.dataService.updateTrackSelected(obj);
        } else {
          this.dataService.updateTrackSelected(obj);
        }
      }
    }
    this.indexGlobal = i;
  }

  getPositionClicked(track, i, event) {
    event.stopPropagation();
    setTimeout(() => {
      if(!this.wasDBL){
        const posX = event.offsetX > 0 ? event.offsetX : event.layerX;
        const width = $('#track-' + i + '-' + track.metadataID).width();
        const percentage = posX * 100 / width;
        const duration = moment.duration('00:' + track.duration).asSeconds();
        const playFrom = percentage * duration / 100;
        track.currentTime = playFrom;
        track.currentTimeDisplay = moment('2015-01-01').startOf('day').seconds(playFrom).format('mm:ss');
        const obj = {
          track: track,
          i: i,
          tracks: this.tracks
        };
        this.dataService.updateTrackSelected(obj);
        this.indexGlobal = i;
      }
    }, 400);
  }

  getWidth(id) {
    let div = document.getElementById(id);
    let width = div.offsetWidth;
    return width + "px";
  }
  
  getDateInRightFormat(date) {
    let string = moment(date).format('YYYY-MM-DD');
    return string.replace(/-/g, '.');
  }

  preventPropagation($event) {
    $event.stopPropagation();
  }

  checkCommentSize(comment,it){
    if(document.getElementById("project_comments_"+it) != null){
      var height = $("#project_comments_"+it).height(); 
      var scrollHeight = document.getElementById("project_comments_"+it).scrollHeight;
      if(height == scrollHeight){
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  clickOpenInfo($event, i, track) {
    $event.stopPropagation();
    let pos = i;
    this.showTrackInfo[pos] = !this.showTrackInfo[pos];
    if (this.showTrackInfo[pos]) {
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
        this.updateContainer();
      } else {
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (j != i) {
            this.showTrackInfo[j] = false;
          }
        }
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        // this.searchService.getRatingAndComments(track.MetaDataID).subscribe(data => {
        //   if (data != null) {
        //     track.MyComments = data['MyComments'];
        //     track.MyRating = data['MyRating'];
        //   }
        // });
      }

    }

  }

  updateContainer() {
    let isSomethingPlaying = false;
    for (let id = 0; id < this.tracks.length; id++) {
      this.tracks[id].container = '#track-' + id + '-' + this.tracks[id].MetaDataID + '-progress';
      if (this.tracks[id].isPlaying) {
        this.indexGlobal = id;
        isSomethingPlaying = true;
      }
    }
    this.dataService.updateContainer({ tracks: this.tracks });
    if (!isSomethingPlaying) {
      this.indexGlobal = null;
    }
  }

  showTrackRelatedOrSimilar = [];

  cleanActualMusic(track, musics) {
    for (let j = musics.length - 1; j >= 0; j--) {
      const music = musics[j];
      if (music.id == track.id) {
        musics.splice(j, 1);
      }
    }
    return musics;
  }

  showRelated(event, i, track) {
    this.wasDBL = true;
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    setTimeout(() => {
      this.wasDBL = false;
    }, 500);
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackRelatedOrSimilar[i] = !this.showTrackRelatedOrSimilar[i];
      if (this.showTrackRelatedOrSimilar[i]) {
        let decrement = 0;
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.tracks[j]) || ('isSimilar' in this.tracks[j]) || ('isFromAlbum' in this.tracks[j])) {
            this.tracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackRelatedOrSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsRelated(track.id).subscribe(data => {
          // let musics = this.musicService.mountMusicList(data, [], true, false, false, false);
          this.loadingTracksSecondary = false;
          let musics = [];
          data.forEach(musica => {
            const music = {
              id: musica.MetaDataID,
              name: musica.TrackTitle,
              projectIID: musica.ProjectsIID,
              ProjectsID: this.tracks[0].ProjectsID,
              cover: environment.baseUrl + 'image/album/free/' + this.tracks[0].ProjectsID + '/' +
                musica.Catalog.replace(new RegExp(' & ', 'g'), '').replace('+','~').replace(new RegExp(' ', 'g'), '_')
                + '/' + musica.Reference,

              link: '',
              duration: musica.TrackTime,
              DurationUsed: musica.DurationUsed,
              reference: musica.Reference,
              track: parseInt(musica.Track, 10),
              isPlaying: false,
              catalog: musica.Catalog,
              titleId: musica.WorkID,
              albumTitle: musica.AlbumTitle,
              albumId: musica.WorkID,
              authors: musica.Authors,
              label: musica.Publisher,
              genres:
                  musica.Categories.Genres[this.i18n.language.toUpperCase()],
                mindStates:
                  musica.Categories.Moods[this.i18n.language.toUpperCase()],
                instruments:
                  musica.Categories.Instruments[this.i18n.language.toUpperCase()],
                bpm: musica.BPM,
                keyWords:
                  musica.Categories.Keys[this.i18n.language.toUpperCase()],
                description: musica.Description,
              isloadToWave: false,
              MetaDataID: musica.MetaDataID,
              metadataID: musica.MetaDataID,
              btVersao: musica.btVersao,
              Seq: musica.Seq,
              timeIcon: null,
              time: null,
              tempos: { 'EN': musica.TempoEN, 'PT': musica.TempoPT, 'ES': musica.TempoES },
              periodos: musica.Categories.Periods,
              times: musica.Categories.Times,
              tons: musica.Categories.Keys,
              keys: musica.KeyCode,
              Relateds: musica.Relateds,
              Context1J: musica.Context1J,
              Context1K: musica.Context1K,
              Context1Q: musica.Context1Q,
              Context1E: musica.Context1E,
              Context1I: musica.Context1I,
              Categories: musica.Categories,
              Contexts: musica.Contexts,
              TrackTitle: musica.TrackTitle,
              Authors: musica.Authors,
              Publisher: musica.Publisher,
              publisher: musica.Publisher,
              Artist: musica.Artist,
              Conductor: musica.Conductor,
              Orchestra: musica.Orchestra,
              Choir: musica.Choir,
              Soloist: musica.Soloist,
              Instrumentalist: musica.Instrumentalist,
              Intensity: musica.Intensity,
              Vocals: musica.Vocals,
              ReferenceIVM: musica.Reference,
              palavrasChave: musica.Keywords,
              letra: musica.Lirics,
              Track: musica.Track,
              currentTime: 0,
              currentTimeDisplay: '00:00',
              show: false,
              isRelated: true,
              url: environment.baseUrl + 'music/streaming/free/' + this.tracks[0].ProjectsID + '/' + musica.MetaDataID
            };
            musics.push(music);
          });

          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
          this.updateContainer();
          // this.checkSelected();
        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.tracks.length; id++) {
          if (!('isRelated' in this.tracks[id]) && !('isSimilar' in this.tracks[id]) && !('isFromAlbum' in this.tracks[id])) {
            break;
          } else {
            if (('isRelated' in this.tracks[id])) {
              this.showTrackInfo[id] = false;
              // this.showTrackSimilar[id] = false;
              // this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.tracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
        // this.checkSelected();
      }
    }
  }

  mySubString(string, number){
    var resultString = string;
    if(resultString.length > number){
      return resultString.substr(0, number) + "...";
    } else {
      return resultString;
    }
  }
  
  checkClassForCheckAll(){
    let anySelected = false;
    let allSelected = true;
    for (let it = 0; it < this.tracks.length; it++) {
      const track = this.tracks[it];
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)){
        if(track.isTrackSelected){
          anySelected =  true;
        } else {
          allSelected = false;
        }
      }
    }
    if(allSelected && this.tracks.length>0){
      return 'invertColor';
    } else if(anySelected && this.tracks.length>0){
      return 'fullOpacity';
    } else{
      return '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkOpenDDLs();
  }

  checkOpenDDLs(){
    if (window.matchMedia('(min-width: 320px)').matches && !window.matchMedia('(min-width: 640px)').matches) {
      $('.show').removeClass('show');
    } if (window.matchMedia('(min-width: 640px)').matches) {
      this.showMenuMobile = [];
    }
  }

  checkIfFreezePlaying(){
    if(window['globalWavesurfer'].isPlaying()){
      return true;
    }
    return false;
  }
  
}
