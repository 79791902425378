import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthService } from '../auth/auth.service';
import { Validators, FormGroup, FormBuilder, ValidationErrors } from '@angular/forms';
import { ContactService } from '../contact.service';
import { I18nGlvService } from '../i18n-glv.service';
import {formatDate} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FreePlaylistsComponent implements OnInit {

	// PG:
	maxSugestions=[];
	min=formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');

	xsLanguage="en";
	xsContactEMailViz="vimusica";
	xsContactEMailAddress=this.xsContactEMailViz+"@vimusica.com";
	xsTelefone="+351 217956195";

	xiLyrics2 = 0;  
	xsLyrics2 = "";
    interval: any;
  //

  isSearchPlaylist: any;
  isLyrics = true; // já não é usado
  isFormValidated: any;
  isProjectsFilter: any;
  isAddRemoveLine: any;
  closeTab: any;
  searchProjects: any;
  sortProjects: any;

  // deadline: any;

  constructor(private dataService: DataService, public authService: AuthService, private fb: FormBuilder,
    private contactService: ContactService, public i18n: I18nGlvService) {
  }

  playlist: FormGroup;
  submited = false;

  ngOnInit() 
  {
	this.interval = setInterval(() => 
	{
        if (this.i18n.isLanguageReady) {
          this.createForm();
          clearInterval(this.interval);
        }
	}, 100);
    this.createForm(); // não devia estar aqui, mas se tirar dá erro no HTML
  }

  createForm(){

    this.maxSugestions = [
		this.i18n.getTranslationsBD('No'.toLowerCase()).toUpperCase(), 
		this.i18n.getTranslationsBD('Yes'.toLowerCase()).toUpperCase(), 
		this.i18n.getTranslationsBD('Both'.toLowerCase()).toUpperCase(), 
		this.i18n.getTranslationsBD('OnlyAahs'.toLowerCase()).toUpperCase()
		];
	this.fLyrics2(0);

	if (this.i18n.language=='es')
	{
		this.xsContactEMailViz='contacto';
		this.xsTelefone="+34 914023214";
	}
	else
	{
		this.xsContactEMailViz='vimusica';
	}
	this.xsContactEMailAddress=this.xsContactEMailViz+"@vimusica.com";
	
	var xsEmail = '';
	if (this.authService.isLoggedIn) 
	{
		xsEmail=this.authService.user.email;
	}

    this.playlist = this.fb.group({
      Nome: ['', [Validators.required]],
      Email: [xsEmail, [Validators.required]],
      Telefone: ['', []],
      Empresa: ['', []],
      Deadline: ['', [Validators.required]],
      Brief: ['', [Validators.required]],
      Letra: ['', []],
      Duracao: ['', []],
      Comentarios: ['', []],
    });
  }
  
  fLyrics2(xiAction) {
	this.xiLyrics2+=xiAction;
	if (this.xiLyrics2<0) {this.xiLyrics2=this.maxSugestions.length-1};
	if (this.xiLyrics2>this.maxSugestions.length-1) {this.xiLyrics2=0};
	this.xsLyrics2=this.maxSugestions[this.xiLyrics2];
  }

  resetForm() {
    this.playlist.patchValue({
      Nome: '',
      Email: '',
      Telefone: '',
      Empresa: '',
      Deadline: '',
      Brief: '',
      Letra: '',
      Duracao: '',
      Comentarios: ''
    });
  }

  sendPlaylist() {
    this.submited = true;
    let hasErrors = false;
    Object.keys(this.playlist.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.playlist.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          hasErrors = true;
        });
      }
    });

    if (!hasErrors) {
      var body = {
        EmailFrom: this.playlist.get('Email').value,
        Name: this.playlist.get('Nome').value,
        Telephone: this.playlist.get('Telefone').value,
        Company: this.playlist.get('Empresa').value,
        Deadline: this.playlist.get('Deadline').value,
        Brief: this.playlist.get('Brief').value,
        Lyrics: this.isLyrics ? "SIM" : "NÃO",
        MusicDuration: this.playlist.get('Duracao').value,
        AdditionalComments: this.playlist.get('Comentarios').value,
      };

      this.contactService.addFreePlaylistForm(body).subscribe(data => {
        alert(this.i18n.getTranslationsBD("your-request-has-been-sent-to-vimusica-and-will-be-analysed-we-will-contact-you-soon"));
      });
    }
  }





  // GETTERS
  get Nome() {
    return this.playlist.get('Nome');
  }
  get Email() {
    return this.playlist.get('Email');
  }
  get Telefone() {
    return this.playlist.get('Telefone');
  }
  get Empresa() {
    return this.playlist.get('Empresa');
  }
  get Deadline() {
    return this.playlist.get('Deadline');
  }
  get Brief() {
    return this.playlist.get('Brief');
  }
  get Letra() {
    return this.playlist.get('Letra');
  }
  get Duracao() {
    return this.playlist.get('Duracao');
  }
  get Comentarios() {
    return this.playlist.get('Comentarios');
  }

}
