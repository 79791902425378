import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Genre } from './genre';
import { I18nGlvService } from './i18n-glv.service';
import { AuthService } from './auth/auth.service';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenreService {
  private genresUrl = `${environment.baseUrl}categories`;  // URL to web api
  private categorySelected = [];
  // https://vimusicaapi.glove-it.pt/api/categories
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public authService: AuthService
  ) {
  }

  /** Log a GenreService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`GenreService: ${message}`);
  }

  getGenresSearch(searchText = "", searchContexts = ""): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    let fromUriParameters = "?";
    fromUriParameters += searchText != "" ? "terms=" + searchText + "&" : "";
    fromUriParameters += searchContexts != "" ? "contexts=" + searchContexts : "";

    return this.http.get<Genre[]>(this.genresUrl + '/search' + fromUriParameters, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  /** GET genres from the server */
  getGenres(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.get<Genre[]>(this.genresUrl, httpOptions)
      .pipe(
        tap(_ => this.log('fetched genres')),
        catchError(this.handleError('getGenres', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
